import React from 'react'
import PropTypes from 'prop-types'

import ButtonComponent from '../../Buttons/ButtonComponent'
import Icon from '../../Form/FormBasicElements/Icon'
import Image from '../../Form/FormBasicElements/Image'
import InternalOrExternal from '../../Buttons/InternalOrExternal'

import {
  JobBoxButtonContainer,
  JobBoxCities,
  JobBoxCitiesContainer,
  JobBoxCompanyName,
  JobBoxCompanyNameContainer,
  JobBoxContainer,
  JobBoxCreatedAt,
  JobBoxCreatedAtContainer,
  JobBoxDetailsContainer,
  JobBoxDetailsContent,
  JobBoxImageContainer,
  JobBoxTitle,
  JobBoxTypeAndButtonContainer,
  JobBoxTypeContainer,
  JobBoxTypeContainerMobile
} from './Atoms'

import {
  assignClickType,
  checkIfClickable,
  checkIfLink,
  checkIfNoImage,
  chooseIconType
} from './helpers'
import {
  clickTypes,
  jobTypes
} from './const'

const JobBox = ({
  cities,
  clickType,
  companyName,
  createdAt,
  hideImage,
  image,
  levelOfHighlighting,
  link,
  onClick,
  title
}) => (
  <JobBoxContainer data-test='JobBoxContainer'>
    {
      !hideImage && (
        <JobBoxImageContainer data-test='JobBoxImageContainer'>
          <Image
            alt={companyName}
            data-test='JobBoxImage'
            scaleMe={false}
            source={checkIfNoImage(image)}
          />
        </JobBoxImageContainer>
      )
    }

    {
      levelOfHighlighting && (
        <JobBoxTypeContainerMobile data-test='JobBoxTypeContainerMobile'>
          <Icon
            data-test='JobBoxTypeIconMobile'
            icon={chooseIconType(levelOfHighlighting)}
          />
        </JobBoxTypeContainerMobile>
      )
    }

    <JobBoxDetailsContainer data-test='JobBoxDetailsContainer'>
      <JobBoxDetailsContent data-test='JobBoxDetailsContent'>
        {
          clickType
            ? (
              <InternalOrExternal
                data-test='JobBoxLink'
                display='inline-flex'
                link={checkIfLink(clickType, link)}
                onClick={checkIfClickable(clickType, onClick)}
                type={assignClickType(clickType)}
              >
                <JobBoxTitle data-test='JobBoxTitleClickable'>
                  {title}
                </JobBoxTitle>
              </InternalOrExternal>
            )

            : (
              <JobBoxTitle data-test='JobBoxTitle'>
                {title}
              </JobBoxTitle>
            )
        }

        <JobBoxCompanyNameContainer data-test='JobBoxCompanyNameContainer'>
          <JobBoxCompanyName data-test='JobBoxCompanyName'>
            {companyName}
          </JobBoxCompanyName>
        </JobBoxCompanyNameContainer>

        {
          cities && (
            <JobBoxCitiesContainer data-test='JobBoxCitiesContainer'>
              <JobBoxCities data-test='JobBoxCities'>
                {cities}
              </JobBoxCities>
            </JobBoxCitiesContainer>
          )
        }
      </JobBoxDetailsContent>

      <JobBoxCreatedAtContainer data-test='JobBoxCreatedAtContainer'>
        <JobBoxCreatedAt data-test='JobBoxCreatedAtLabel'>
          {'Dodano:'}
        </JobBoxCreatedAt>

        <JobBoxCreatedAt data-test='JobBoxCreatedAt'>
          {createdAt}
        </JobBoxCreatedAt>
      </JobBoxCreatedAtContainer>
    </JobBoxDetailsContainer>

    <JobBoxTypeAndButtonContainer
      data-test='JobBoxTypeAndButtonContainer'
      levelOfHighlighting={levelOfHighlighting}
    >
      {
        levelOfHighlighting && (
          <JobBoxTypeContainer data-test='JobBoxTypeContainer'>
            <Icon
              data-test='JobBoxTypeIcon'
              icon={chooseIconType(levelOfHighlighting)}
            />
          </JobBoxTypeContainer>
        )
      }

      {
        clickType && (
          <JobBoxButtonContainer data-test='JobBoxButtonContainer'>
            <ButtonComponent
              align='center'
              bgColor='pinkColor'
              color='whiteColor'
              data-test='JobBoxButton'
              display='inline-flex'
              fontBold
              label='Zobacz'
              link={checkIfLink(clickType, link)}
              onClick={checkIfClickable(clickType, onClick)}
              size='sizeM'
              type={assignClickType(clickType)}
            />
          </JobBoxButtonContainer>
        )
      }
    </JobBoxTypeAndButtonContainer>
  </JobBoxContainer>
)

export default JobBox

JobBox.propTypes = {
  cities: PropTypes.string,
  clickType: PropTypes.oneOf(clickTypes),
  companyName: PropTypes.string,
  createdAt: PropTypes.string,
  hideImage: PropTypes.bool,
  image: PropTypes.string,
  levelOfHighlighting: PropTypes.oneOf(jobTypes),
  link: PropTypes.string,
  onClick: PropTypes.func,
  title: PropTypes.string
}

JobBox.defaultProps = {
  cities: '',
  companyName: '',
  createdAt: '',
  link: '',
  title: ''
}
