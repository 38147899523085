import types from './types'
import { createReducer } from '../../utils'

const initialState = {
  data: [],
  error: null,
  isLoading: false
}

const valuesReducer = createReducer(initialState)({
  [types.FETCH_ERROR]: (state, action) => ({ data: [], isLoading: false, error: action.payload }),
  [types.FETCH_REQUESTED]: () => ({ data: [], isLoading: true, error: null }),
  [types.FETCH_SUCCESS]: (state, action) => {
    const data = action.payload.map((value) => ({
      label: value.name,
      _id: value._id
    }))

    return { data, isLoading: false, error: null }
  }
})

export default valuesReducer
