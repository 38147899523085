import React from 'react'
import PropTypes from 'prop-types'

import {
  PrimaryEventContainer,
  PrimaryEventContent,
  PrimaryEventDate,
  PrimaryEventDateContainer,
  PrimaryEventHeader,
  PrimaryEventHeaderLeftContainer,
  PrimaryEventLocationIconContainer,
  PrimaryEventLogoIconContainer,
  PrimaryEventName,
  PrimaryEventPlace,
  PrimaryEventPlaceContainer
} from './components'

import FormTwoColumns from '../Form/FormWrappers/FormTwoColumns'
import Icon from '../Form/FormBasicElements/Icon'
import Label from '../Form/FormBasicElements/Label'
import PrimaryEventLocationIcon from '../../assets/icons/subtitle/subtitle_pin.svg'
import PrimaryEventLogoIcon from '../../assets/icons/logo_heart.svg'
import translationsList from '../../stabs/translationsList'

const PrimaryEvent = ({
  date,
  name,
  place
}) => (
  <PrimaryEventContainer>
    <PrimaryEventHeader>
      <PrimaryEventHeaderLeftContainer>
        <PrimaryEventLocationIconContainer>
          <Icon icon={PrimaryEventLocationIcon} />
        </PrimaryEventLocationIconContainer>

        <PrimaryEventName data-test='PrimaryEventName'>{name}</PrimaryEventName>
      </PrimaryEventHeaderLeftContainer>

      <PrimaryEventLogoIconContainer>
        <Icon icon={PrimaryEventLogoIcon} />
      </PrimaryEventLogoIconContainer>
    </PrimaryEventHeader>

    <PrimaryEventContent>
      <FormTwoColumns>
        <PrimaryEventPlaceContainer>
          <Label label={translationsList.homepage.events.labels.placeLabel} />
          <PrimaryEventPlace data-test='PrimaryEventPlace'>{place}</PrimaryEventPlace>
        </PrimaryEventPlaceContainer>

        <PrimaryEventDateContainer>
          <Label label={translationsList.homepage.events.labels.dateLabel} />
          <PrimaryEventDate data-test='PrimaryEventDate'>{date}</PrimaryEventDate>
        </PrimaryEventDateContainer>
      </FormTwoColumns>
    </PrimaryEventContent>
  </PrimaryEventContainer>
)

export default PrimaryEvent

PrimaryEvent.propTypes = {
  date: PropTypes.string,
  name: PropTypes.string,
  place: PropTypes.string
}
