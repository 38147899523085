import styled from 'styled-components'

import {
  PurpleBoxShadowColor,
  PurpleColor,
  PurpleColorM,
  PurpleColorS,
  WhiteColor
} from '../../../assets/styles/colors'

export const EmployerProfileLeftContainer = styled.div`
  flex-shrink: 0;
  text-align: left;

  @media (min-width: 1600px) {
    margin: 0 60px 0 0;
    width: 220px;
  }

  @media (min-width: 1430px) and (max-width: 1599px) {
    margin: 0 50px 0 0;
    width: 200px;
  }

  @media (min-width: 1200px) and (max-width: 1429px) {
    margin: 40px 50px 0 0;
    width: 200px;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    margin: 40px 30px 0 0;
    width: 190px;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    margin: 40px 30px 0 0;
    width: 180px;
  }

  @media (min-width: 320px) and (max-width: 767px) {
    background-color: ${WhiteColor};
    bottom: 0;
    border-right: 3px solid ${PurpleColorS};
    box-shadow: ${PurpleBoxShadowColor};
    left: 0;
    margin: 40px 0 0 0;
    position: absolute;
    top: 0;
    width: 220px;
    z-index: 15;

    display: ${({ isOpen }) => isOpen
      ? 'block'
      : 'none'
    };
  }
`

export const EmployerProfileLeftContent = styled.div`
  @media (min-width: 576px) and (max-width: 767px) {
    padding: 0 20px 0 0;
  }

  @media (min-width: 320px) and (max-width: 575px) {
    padding: 0 15px 0 0;
  }
`

export const EmployerProfileLeftLogoContainer = styled.div`
  background-color: ${WhiteColor};
  border-radius: 20px;
  box-shadow: ${PurpleBoxShadowColor};
  height: 210px;

  & > div {
    padding: 0px
  }

  img {
    border-radius: 20px;
  }
`

export const EmployerProfileLeftNameContainer = styled.div`
  color: ${PurpleColor};
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  margin: 20px 0 0;
`

export const EmployerProfileLeftTabsContainer = styled.div`
  @media (min-width: 768px) {
    display: none;
  }

  @media (min-width: 320px) and (max-width: 767px) {
    margin: 20px 0 0;
  }
`

export const EmployerProfileLeftIndustriesContainer = styled.div`
  margin: 20px 0 0;
`

export const EmployerProfileLeftIndustriesListContainer = styled.ul`
  color: ${PurpleColorM};
  font-size: 10px;
  line-height: 21px;
  list-style: none;
  margin: 0;
  padding: 0;
`

export const EmployerProfileLeftIndustry = styled.li``

export const EmployerProfileLeftCompatibilityContainer = styled.div`
  color: ${PurpleColorM};
  font-size: 14px;
  line-height: 21px;
  padding: 34px 0 0;
`

export const EmployerProfileLeftApplyButtonContainer = styled.div`
  padding: 50px 0 0;
`

export const EmployerProfileLeftButtonsContainer = styled.div`
  display: grid;
  grid-row-gap: 12px;

  @media (min-width: 1600px) {
    padding: 60px 0 0;
  }

  @media (min-width: 1200px) and (max-width: 1599px) {
    padding: 50px 0 0;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    padding: 40px 0 0;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    padding: 30px 0 0;
  }

  @media (min-width: 320px) and (max-width: 767px) {
    padding: 20px 0 0;
  }
`

export const EmployerProfileLeftSocialMediaContainer = styled.div`
  @media (min-width: 1200px) {
    padding: 50px 0 0;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    padding: 40px 0 0;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    padding: 30px 0 0;
  }

  @media (min-width: 320px) and (max-width: 767px) {
    padding: 20px 0 0;
  }

  @media (min-width: 320px) and (max-width: 575px) {
    & > div {
      justify-content: flex-start;
    }
  }
`
