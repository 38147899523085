import React from 'react'
import PropTypes from 'prop-types'

import Image from '../../Form/FormBasicElements/Image'
import TabsMenu from '../../Tabs/TabsMenu'

import {
  EmployerProfileRightContainer,
  EmployerProfileRightImageContainer,
  EmployerProfileRightTabsContainer,
  EmployerProfileRightTabsContent
} from './Atoms'

import { chooseTab } from './helpers'
import { companyTabsTypes } from './const'

const EmployerProfileRight = ({
  articlesList,
  awardsList,
  benefitsList,
  chatUrl,
  image,
  jobsList,
  officesList,
  onOptionChange,
  option,
  ourMission,
  recruitersList,
  recruitmentDetails,
  tabsList,
  text,
  valueTags,
  videosList
}) => (
  <EmployerProfileRightContainer data-test='EmployerProfileRightContainer'>
    {
      image && (
        <EmployerProfileRightImageContainer data-test='EmployerProfileRightImageContainer'>
          <Image
            data-test='EmployerProfileRightImage'
            scaleMe={false}
            source={image}
          />
        </EmployerProfileRightImageContainer>
      )
    }

    <EmployerProfileRightTabsContainer
      data-test='EmployerProfileRightTabsContainer'
      image={image}
    >
      <TabsMenu
        data-test='TabsMenu'
        itemsList={tabsList}
        onClick={onOptionChange}
      />
    </EmployerProfileRightTabsContainer>

    <EmployerProfileRightTabsContent data-test='EmployerProfileRightTabsContent'>
      {
        chooseTab(
          articlesList,
          awardsList,
          benefitsList,
          chatUrl,
          jobsList,
          officesList,
          option,
          ourMission,
          recruitersList,
          recruitmentDetails,
          text,
          valueTags,
          videosList,
          onOptionChange
        )
      }
    </EmployerProfileRightTabsContent>
  </EmployerProfileRightContainer>
)

export default EmployerProfileRight

EmployerProfileRight.propTypes = {
  articlesList: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string,
      isPublished: PropTypes.bool,
      text: PropTypes.string,
      thumbnailUrl: PropTypes.string,
      title: PropTypes.string
    })
  ),
  awardsList: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string,
      description: PropTypes.string,
      name: PropTypes.string,
      place: PropTypes.string
    })
  ),
  benefitsList: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      icon: PropTypes.string,
      title: PropTypes.string
    })
  ),
  chatUrl: PropTypes.string,
  image: PropTypes.string,
  jobsList: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string,
      title: PropTypes.string.isRequired
    })
  ),
  officesList: PropTypes.arrayOf(
    PropTypes.shape({
      city: PropTypes.string,
      itemsList: PropTypes.arrayOf(PropTypes.string),
      name: PropTypes.string,
      street: PropTypes.string
    })
  ),
  option: PropTypes.oneOf(Object.keys(companyTabsTypes)),
  ourMission: PropTypes.string,
  recruitmentDetails: PropTypes.shape({
    assessmentCenter: PropTypes.bool,
    departments: PropTypes.string,
    employmentTypes: PropTypes.arrayOf(PropTypes.string),
    jobInterview: PropTypes.bool,
    needsCv: PropTypes.bool,
    needsMotivationalList: PropTypes.bool,
    place: PropTypes.string,
    preferredCandidates: PropTypes.arrayOf(PropTypes.string),
    preferredDegreeCourses: PropTypes.string
  }),
  recruitersList: PropTypes.arrayOf(
    PropTypes.shape({
      avatarUrl: PropTypes.string,
      email: PropTypes.string,
      itemsList: PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.string
        })
      ),
      name: PropTypes.string,
      role: PropTypes.string
    })
  ),
  tabsList: PropTypes.arrayOf(
    PropTypes.shape({
      isActive: PropTypes.bool,
      label: PropTypes.string
    })
  ),
  text: PropTypes.string,
  valueTags: PropTypes.arrayOf(
    PropTypes.shape({
      isMostImportant: PropTypes.bool,
      isSelected: PropTypes.bool,
      label: PropTypes.string
    })
  ),
  videosList: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      thumbnailUrl: PropTypes.string
    })
  )
}
