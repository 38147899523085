import React from 'react'
import PropTypes from 'prop-types'

import AuthService from '../../store/services/AuthService'

import BannerItem from '../../components/BannerItem'
import ButtonComponent from '../../components/Buttons/ButtonComponent'
import ButtonPlay from '../../components/Buttons/ButtonPlay'
import ChooseDayContainer from '../../containers/ComponentContainers/ChooseDayContainer'
import CompanyItems from '../../components/Companies/CompanyItems'
import ControlsContainer from '../../containers/ComponentContainers/ControlsContainer'
import Image from '../../components/Form/FormBasicElements/Image'
import IndustriesContainer from '../../containers/ComponentContainers/IndustriesContainer'
import InnerWrapper from '../../components/Wrappers/InnerWrapper'
import Location from '../../components/Location'
import Section from '../../components/Section'
import SelectableTagsContainer from '../../containers/ComponentContainers/TagsContainer'
import Site from '../../components/Wrappers/Site'
import SpacingLine from '../../components/SpacingLine'
import Steps from '../../components/Steps/Steps'
import Subtitle from '../../components/Subtitle'
import TextValueTextValue from '../../components/Form/FormBasicElements/TextValueTextValue'
import TrainingsContainer from '../../containers/ComponentContainers/TrainingsContainer'
import ZonesContainer from '../../containers/ComponentContainers/ZonesContainer'

import InfoBox from '../../components/InfoBox'
import Grid from '../../components/Grid'
import CompanyBox from '../../components/Boxes/CompanyBox'
import isExpoDay from '../../utils/isExpoDay'

import mobileHomepageCover from '../../assets/img/home_bg.jpg'
import translationsList from '../../stabs/translationsList'

import {
  CompaniesContainer,
  CompaniesControls,
  SectionEight,
  SectionFour,
  SectionNine,
  SectionNineButtonContainer,
  SectionOne,
  SectionOneButtonContainer,
  SectionOneContainer,
  SectionOneMobileMovieButtonContainer,
  SectionOneMobileMovieContainer,
  SectionOnePlayButtonContainer,
  SectionSix,
  SectionThree,
  SectionThreeSubtitleContainer,
  SectionTwo,
  TrainingsControlsContainer,
  SectionCommonContainer
} from './components'

import {
  companiesDiamond,
  companiesPlatinum,
  companiesPartner,
  companiesStandard
} from './helpers'

const EventPage = (props) => (
  <>
    <Site
      isLoggedOn={AuthService.loggedIn()}
      menuItemsList={translationsList.topMenu.linksList}
      withNav
    >
      <SectionOneMobileMovieContainer>
        <Image source={mobileHomepageCover} />
      </SectionOneMobileMovieContainer>

      <SectionOne>
        <InnerWrapper>
          <SectionOneContainer>
            <Section
              description={translationsList.sectionOne.description}
              title={translationsList.sectionOne.title}
            />
          </SectionOneContainer>
        </InnerWrapper>

        
      </SectionOne>

      <InnerWrapper>
        <SectionTwo>
          <Subtitle
            asH1
            icon='pin'
            label={translationsList.sectionTwo.title}
            level='h1'
          />

         <InfoBox
                calendarUrl={props.event.calendarUrl}
                dates={props.event.date}
                timeStart={props.event.timeStart}
                timeEnd={props.event.timeEnd}
              />
        </SectionTwo>

        <SpacingLine
          id='trainings'
          isFixed
        />

        <SectionThree>
          <Section
            alignCenter
            description={translationsList.sectionThree.description}
            title={translationsList.sectionThree.title}
            level='h2'
          />

          <SectionThreeSubtitleContainer>
            <Subtitle
              icon='tag'
              label={translationsList.sectionThree.subtitle.text}
            />
          </SectionThreeSubtitleContainer>

          <SelectableTagsContainer />
        </SectionThree>

        {/*<ChooseDayContainer /> */}

        <SectionFour>
          <TrainingsContainer
            trainings={props.trainings}
          />

          <TrainingsControlsContainer>
            <ControlsContainer type='trainings' />
          </TrainingsControlsContainer>
        </SectionFour>

        <SpacingLine
          id='companies'
          isFixed
        />

        <Section
          alignCenter
          description={translationsList.sectionFive.description}
          title={translationsList.sectionFive.title}
          level='h2'
        />

        <Subtitle
          icon='star'
          label={translationsList.sectionFive.subtitle.text}
        />

        <IndustriesContainer />

        <SectionSix>
          <TextValueTextValue
            textOne={translationsList.form.stepFour.foundLabel}
            textTwo={translationsList.form.stepFour.employersLabel}
            valueOne={props.companies.length}
          />

          




          <CompaniesContainer>
              {companiesDiamond(props.companies).length !== 0 && (
                <>
                  <SectionCommonContainer>
                    <Subtitle
                      icon='star'
                      label='Kluczowi pracodawcy Targów Pracy'
                    />
                  </SectionCommonContainer>

                  <Grid
                    columns={1}
                    noOutsideMargin
                  >
                    {
                      companiesDiamond(props.companies).map((company, index) => (
                        <CompanyBox
                          buttonLabel='Poznaj firmę'
                          image={company.logoUrl}
                          isFast={company.hasQuickRecruitment}
                          isFavourite={company.isFavourite}
                          key={index}
                          link={`/pracodawcy/${company.slug}`}
                          name={company.name}
                          // onClickIsFavourite={() => toggleFavourite(company._id)} // TODO
                          showTop={isExpoDay() && company.chatUrl}
                          showRightColumn
                          value='Jesteśmy online'
                          valueLabel={company.text}
                          videoUrl={company.videos[0] !== undefined ? company.videos[0].url : ''}
                          withIsFastButton={company.withIsFastButton}
                        />
                      ))
                    }
                  </Grid>
                </>
              )}

              {companiesPlatinum(props.companies).length !== 0 && (
                <>
                  <SectionCommonContainer>
                    <Subtitle
                      icon='star'
                      label='Polecani pracodawcy Targów Pracy'
                    />
                  </SectionCommonContainer>

                  <Grid
                    columns={1}
                    noOutsideMargin
                  >
                    {
                      companiesPlatinum(props.companies).map((company, index) => (
                        <CompanyBox
                          buttonLabel='Poznaj firmę'
                          image={company.logoUrl}
                          isFast={company.hasQuickRecruitment}
                          isFavourite={company.isFavourite}
                          key={index}
                          link={`/pracodawcy/${company.slug}`}
                          name={company.name}
                          // onClickIsFavourite={() => toggleFavourite(company._id)} // TODO
                          showTop={isExpoDay() && company.chatUrl}
                          showRightColumn
                          value='Jesteśmy online'
                          valueLabel={company.text}
                          videoUrl={company.videos[0] !== undefined ? company.videos[0].url : ''}
                          withIsFastButton={company.withIsFastButton}
                          type={'platinium'}
                        />
                      ))
                    }
                  </Grid>
                </>
              )}

              {companiesStandard(props.companies).length !== 0 && (
                <>
                  <SectionCommonContainer>
                    <Subtitle
                      icon='star'
                      label='Pozostali pracodawcy Targów Pracy'
                    />
                  </SectionCommonContainer>

                  <Grid
                    columns={4}
                    noOutsideMargin
                  >
                    {
                      companiesStandard(props.companies).map((company, index) => (
                        <CompanyBox
                          buttonLabel='Poznaj firmę'
                          image={company.logoUrl}
                          isFast={company.hasQuickRecruitment}
                          isFavourite={company.isFavourite}
                          key={index}
                          link={`/pracodawcy/${company.slug}`}
                          name={company.name}
                          // onClickIsFavourite={() => toggleFavourite(company._id)} // TODO
                          showTop={isExpoDay() && company.chatUrl}
                          showRightColumn={company.showRightColumn}
                          value='Jesteśmy online'
                          valueLabel={company.text}
                          videoUrl={company.videoUrl}
                          withIsFastButton={company.withIsFastButton}
                        />
                      ))
                    }
                  </Grid>
                </>
              )}

            {companiesPartner(props.companies).length !== 0 && (
              <>
                <SectionCommonContainer>
                  <Subtitle
                    icon='star'
                    label='Poznaj także'
                  />
                </SectionCommonContainer>

                <Grid
                  columns={4}
                  noOutsideMargin
                >
                  {
                    companiesPartner(props.companies).map((company, index) => (
                      <CompanyBox
                        buttonLabel='Poznaj firmę'
                        image={company.logoUrl}
                        isFast={company.hasQuickRecruitment}
                        isFavourite={company.isFavourite}
                        key={index}
                        link={`/pracodawcy/${company.slug}`}
                        name={company.name}
                        // onClickIsFavourite={() => toggleFavourite(company._id)} // TODO
                        showTop={isExpoDay() && company.chatUrl}
                        showRightColumn={company.showRightColumn}
                        value='Jesteśmy online'
                        valueLabel={company.text}
                        videoUrl={company.videoUrl}
                        withIsFastButton={company.withIsFastButton}
                      />
                    ))
                  }
                </Grid>
              </>
            )}
            </CompaniesContainer>

            <CompaniesControls>
              {/*
                
                  <ControlsContainer type='companies' />
                

              */}
            </CompaniesControls>


        </SectionSix>

        <BannerItem
          buttonLabel={translationsList.banners.bannersList[0].buttonLabel}
          description={translationsList.banners.bannersList[0].description}
          link={translationsList.banners.bannersList[0].link}
          title={translationsList.banners.bannersList[0].title}
        />

      </InnerWrapper>
    </Site>
  </>
)

EventPage.propTypes = {
  modal: PropTypes.string,
  event: PropTypes.shape({
    _id: PropTypes.string,
    calendarUrl: PropTypes.string,
    city: PropTypes.string,
    date: PropTypes.string,
    drivingUrl: PropTypes.string,
    name: PropTypes.string,
    place: PropTypes.string,
    timeEnd: PropTypes.string,
    timeStart: PropTypes.string
  })
}

export default EventPage
