import React from 'react'
import PropTypes from 'prop-types'
import Svg from 'react-inlinesvg'

import {
  IconContainer
} from './components'

const Icon = ({
  icon
}) => (
  <IconContainer>
    {
      icon && (
        <Svg src={icon} />
      )
    }
  </IconContainer>
)

export default Icon

Icon.propTypes = {
  icon: PropTypes.string
}
