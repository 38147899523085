import React from 'react'
import PropTypes from 'prop-types'

import {
  FormPanelContainer,
  FormPanelContent,
  FormPanelErrorsContainer,
  FormPanelLeftFader,
  FormPanelRightFader
} from './components'

const FormPanel = ({
  boxShadow,
  children,
  errors,
  height,
  noMargin,
  paddingSize,
  withFader
}) => (
  <FormPanelContainer
    boxShadow={boxShadow}
    data-test='FormPanelContainer'
    height={height}
    noMargin={noMargin}
    paddingSize={paddingSize}
  >
    {
      withFader && <FormPanelLeftFader data-test='FormPanelLeftFader' />
    }

    {
      errors && (
        <FormPanelErrorsContainer data-test='FormPanelErrorsContainer'>
          {errors}
        </FormPanelErrorsContainer>
      )
    }

    <FormPanelContent data-test='FormPanelContent'>
      {children}
    </FormPanelContent>

    {
      withFader && <FormPanelRightFader data-test='FormPanelRightFader' />
    }
  </FormPanelContainer>
)

export default FormPanel

FormPanel.propTypes = {
  boxShadow: PropTypes.oneOf(['noShadow']),
  children: PropTypes.any,
  errors: PropTypes.string,
  height: PropTypes.string,
  noMargin: PropTypes.bool,
  paddingSize: PropTypes.oneOf(['noPadding', 'sizeS', 'sizeM']),
  withFader: PropTypes.bool
}

FormPanel.defaultProp = {
  noMargin: false,
  withFader: false
}
