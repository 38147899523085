import React, { useState } from 'react'
import ChooseFileField from '../../../../components/Form/FormFields/ChooseFileField'
import ApiService from '../../../../store/services/ApiService'
import * as companyAPI from '../../../../store/api/company'
import eventToFormData from '../../../../utils/forms/eventToFormData'

const CompanyBannerUploadContainer = ({ value, error, touched, handleField }) => {
  const [state, setState] = useState({
    isLoading: false,
    apiError: ''
  })

  const handleChange = (e) => {
    setState({ ...state, isLoading: true })
    const formData = eventToFormData(e)

    ApiService.fetch(companyAPI.uploadBanner(formData))
      .then((data) => {
        setState({ ...state, isLoading: false })
        handleField('bannerUrl', data.url)
      })
      .catch(err => setState({ ...state, apiError: err }))
  }

  return (
    <ChooseFileField
      buttonLabel={value === undefined || value === '' ? 'Wybierz plik' : 'Dodano!'}
      data-test='ChooseFileFieldOne'
      errorMessage={error}
      hasError={error && touched}
      label='Zdjęcie w tle profilu Twojej firmy'
      name='banner'
      onChange={handleChange}
      isLoading={state.isLoading}
      text='Dodaj „cover photo” profilu Twojej firmy (Wymiary: 1050px/330px)'
    />
  )
}

export default CompanyBannerUploadContainer
