import styled from 'styled-components'

export const SlideButtonsWrapperContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: hidden;
`

export const SlideButtonsWrapperButtonsContainer = styled.div`
  display: grid;
  justify-items: flex-start;
`

export const SlideButtonsWrapperButtonsContent = styled.div`
  display: inline-grid;
  grid-column-gap: 10px;
  grid-template-columns: auto auto;

  @media (min-width: 1200px) {
    padding: 30px 0 0;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    padding: 20px 0 0;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    padding: 10px 0 0;
  }
`

export const SlideButtonsWrapperButtonContainer = styled.div`
  height: 50px;
  width: 50px;
`
