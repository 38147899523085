import styled from 'styled-components'

export const ButtonPlayContainer = styled.div`
  & img {
    border-radius: 100%;
  }
  
  @media (min-width: 1200px) {
    width: 120px;
    height: 120px;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    width: 80px;
    height: 80px;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    width: 120px;
    height: 120px;
  }
  
  @media (min-width: 320px) and (max-width: 767px) {
    width: 80px;
    height: 80px;
  }
`
