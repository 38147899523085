import createSimpleFetchingEpic from '../../utils/createSimpleFetchingEpic'

import * as recruitmentAPI from '../../api/recruitment'

import actions from './actions'
import types from './types'

const fetchValues = createSimpleFetchingEpic(
  types.FETCH_REQUESTED,
  recruitmentAPI.getAllJobOffers().toObject(),
  actions.fetchJobOfferSuccess,
  actions.fetchJobOfferError
)

export default {
  fetchValues
}
