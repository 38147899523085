import React from 'react'
import PropTypes from 'prop-types'

import Icon from '../../Form/FormBasicElements/Icon'
import InternalOrExternal from '../../Buttons/InternalOrExternal'

import {
  SliderButtonContainer
} from './components'

import arrowDownPath from '../../../assets/icons/arrow_down.svg'

const SliderButton = ({
  onClick,
  position
}) => (
  <InternalOrExternal onClick={onClick}>
    {
      position && (
        <SliderButtonContainer position={position}>
          <Icon icon={arrowDownPath} />
        </SliderButtonContainer>
      )
    }
  </InternalOrExternal>
)

export default SliderButton

SliderButton.propTypes = {
  onClick: PropTypes.func,
  position: PropTypes.oneOf(['up', 'left', 'right', 'bottom'])
}
