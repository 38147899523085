import React from 'react'
import PropTypes from 'prop-types'

import Icon from '../../../Form/FormBasicElements/Icon'

import {
  RecruitmentDetailsItemIconContainer,
  RecruitmentDetailsItemLabel,
  RecruitmentDetailsItemLabelContainer,
  RecruitmentDetailsItemListElement,
  RecruitmentDetailsItemValue,
  RecruitmentDetailsItemValueContainer
} from './components'

import listIconPath from '../../../../assets/icons/list_check.svg'

const RecruitmentDetailsItem = ({
  label,
  value
}) => (
  <RecruitmentDetailsItemListElement>
    <RecruitmentDetailsItemLabelContainer>
      <RecruitmentDetailsItemIconContainer>
        <Icon icon={listIconPath} />
      </RecruitmentDetailsItemIconContainer>

      <RecruitmentDetailsItemLabel>{label}</RecruitmentDetailsItemLabel>
    </RecruitmentDetailsItemLabelContainer>

    <RecruitmentDetailsItemValueContainer>
      <RecruitmentDetailsItemValue>{value}</RecruitmentDetailsItemValue>
    </RecruitmentDetailsItemValueContainer>
  </RecruitmentDetailsItemListElement>
)

export default RecruitmentDetailsItem

RecruitmentDetailsItem.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string
}

RecruitmentDetailsItem.defaultProps = {
  label: '',
  value: ''
}
