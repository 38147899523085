import { createSelector } from 'reselect'
import { companiesSelectors } from '../companies'

const getIndustriesState = (state) => state.industries
const getIndustries = (state) => state.industries.data

const getSelectedIndustries = createSelector(
  getIndustries,
  (industries) => industries.filter((industry) => industry.isActive)
)

const getCompanyIndustries = (companyId) => createSelector(
  [getIndustries, companiesSelectors.getCompaniesContent],
  (industries, companies) => {
    const selectedCompanies = companies.filter((company) => company._id === companyId)

    if (selectedCompanies.length === 0) {
      return []
    }

    return industries.filter((industry) => selectedCompanies[0].industries.includes(industry._id))
  }
)

export default {
  getIndustries,
  getSelectedIndustries,
  getIndustriesState,
  getCompanyIndustries
}
