import types from './types'

const fetchValuesError = (error) => ({
  type: types.FETCH_ERROR,
  payload: error
})

const fetchValuesRequested = () => ({
  type: types.FETCH_REQUESTED
})

const fetchValuesSuccess = (data) => ({
  type: types.FETCH_SUCCESS,
  payload: data
})

const toggleUserValue = (valueId) => ({
  type: types.TOGGLE_USER_VALUE,
  payload: {
    valueId
  }
})

export default {
  fetchValuesError,
  fetchValuesRequested,
  fetchValuesSuccess,
  toggleUserValue
}
