export default (obj, body) => {
  console.log(obj, body)

  const newObj = {headers: [], ...obj}

  delete newObj.headers['Content-Type']
  newObj.body = body
  newObj.toObject = () => newObj;

  return newObj
}
