import styled from 'styled-components'

import {
  FBColor,
  LinkedinColor,
  TwitterColor,
  WhiteColor,
  YTColor,
  IGColor
} from '../../../assets/styles/colors'

export const SocialItemLinkContainer = styled.div`
  margin: 0 8px 8px 0;
`

export const SocialItemContainer = styled.div`
  display: inline-grid;
  grid-template-columns: 18px 1fr;
  grid-column-gap: 8px;
  align-content: center;
  padding: 7px 10px;
  line-height: 18px;
  font-size: 12px;
  color: ${WhiteColor};
  border-radius: 6px;

  ${props => {
    switch (props.option) {
      case 'facebook': return `background-color: ${FBColor};`
      case 'linkedin': return `background-color: ${LinkedinColor};`
      case 'twitter': return `background-color: ${TwitterColor};`
      case 'youtube': return `background-color: ${YTColor};`
      case 'instagram': return `background: ${IGColor};`
      default: return 'background-color: transparent;'
    }
  }}
`
// TODO ADD SECURING OF ICON COLOR

export const SocialItemLabel = styled.span``
