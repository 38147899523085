const FETCH_ERROR = 'myCompany/FETCH_ERROR'
const FETCH_REQUESTED = 'myCompany/FETCH_REQUESTED'
const FETCH_SUCCESS = 'myCompany/FETCH_SUCCESS'

const ADD_INDUSTRY = 'myCompany/ADD_INDUSTRY'
const REMOVE_INDUSTRY = 'myCompany/REMOVE_INDUSTRY'

export default {
  ADD_INDUSTRY,
  FETCH_ERROR,
  FETCH_REQUESTED,
  FETCH_SUCCESS,
  REMOVE_INDUSTRY
}
