export default (fieldName, item, isMulti, values, functions) => {
  functions.setFieldTouched(fieldName, true)
  const newItem = item.value === undefined ? item.label : item.value

  const newFieldValue = values[fieldName]

  if (Array.isArray(newFieldValue)) {
    if (newFieldValue.includes(newItem)) {
      functions.setFieldValue(fieldName, newFieldValue.filter((el) => el !== newItem))
      return
    }

    functions.setFieldValue(fieldName, [...newFieldValue, newItem].sort())
    return
  }

  functions.setFieldValue(fieldName, newItem)
}

// TODO remove isMulti
