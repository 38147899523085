import types from './types'

const fetchJobOfferError = (error) => ({
  type: types.FETCH_ERROR,
  payload: error
})

const fetchJobOfferRequested = () => ({
  type: types.FETCH_REQUESTED
})

const fetchJobOfferSuccess = (data) => ({
  type: types.FETCH_SUCCESS,
  payload: { data }
})

const addJobOffer = (data) => ({
  type: types.ADD,
  payload: { data }
})

const updateJobOffer = (data) => ({
  type: types.UPDATE,
  payload: { data }
})

export default {
  addJobOffer,
  updateJobOffer,
  fetchJobOfferError,
  fetchJobOfferRequested,
  fetchJobOfferSuccess
}
