import React from 'react'
import PropTypes from 'prop-types'

import SocialItem from '../SocialItem'

import {
  SocialListContainer
} from './components'

const SocialList = ({
  facebook,
  linkedin,
  twitter,
  youtube,
  instagram
}) => (
  <SocialListContainer>
    {facebook &&
      <SocialItem
        data-test='facebook'
        link={facebook}
        option='facebook'
      />}

    {linkedin &&
      <SocialItem
        data-test='linkedin'
        link={linkedin}
        option='linkedin'
      />}

    {twitter &&
      <SocialItem
        data-test='twitter'
        link={twitter}
        option='twitter'
      />}

    {youtube &&
      <SocialItem
        data-test='youtube'
        link={youtube}
        option='youtube'
      />}

    {instagram &&
      <SocialItem
        data-test='instagram'
        link={instagram}
        option='instagram'
      />}
  </SocialListContainer>
)

export default SocialList

SocialList.propTypes = {
  facebook: PropTypes.string,
  linkedin: PropTypes.string,
  twitter: PropTypes.string,
  youtube: PropTypes.string
}
