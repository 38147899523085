import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { trainingsActions, trainingsSelectors } from '../../../store/ducks/trainings'
import { companiesActions, companiesSelectors } from '../../../store/ducks/companies'

import Controls from '../../../components/Controls'
import translationsList from '../../../stabs/translationsList'

const ControlsContainer = ({ type }) => {
  const dispatch = useDispatch()
  const trainingsSelected = useSelector(trainingsSelectors.getTrainings)
  const trainingsAll = useSelector(trainingsSelectors.getTrainingsAll)

  console.log( trainingsSelected );
  console.log( trainingsAll );

  const companiesSelected = useSelector(companiesSelectors.getCompanies)
  const companiesAll = useSelector(companiesSelectors.getCompaniesContent)

  const actions = {
    trainings: {
      showMore: () => dispatch(trainingsActions.showMoreTrainings()),
      showLess: () => dispatch(trainingsActions.showLessTrainings()),
      hideMore: trainingsSelected.length === trainingsAll.length || trainingsSelected.length === 0,
      hideLess: trainingsSelected.length <= 5
    },
    companies: {
      showMore: () => dispatch(companiesActions.showMoreCompanies()),
      showLess: () => dispatch(companiesActions.showLessCompanies()),
      hideMore: companiesSelected.length === companiesAll.length || companiesSelected.length === 0,
      hideLess: companiesSelected.length <= 63
    }
  }
  const handleOnClickLeft = actions[type].showMore
  const handleOnClickRight = actions[type].showLess

  return (
    <Controls
      labelLeft={translationsList.controls.labels.buttonShowMore}
      labelRight={translationsList.controls.labels.buttonHide}
      onClickLeft={handleOnClickLeft}
      onClickRight={handleOnClickRight}
      hideLeft={actions[type].hideMore}
      hideRight={actions[type].hideLess}
    />
  )
}

export default ControlsContainer
