// export const trackingID = 'UA-31738354-1'

export const PageView = () => {
  // window.gtag('config', trackingID, { page_path: window.location.pathname + window.location.search })
}

export const Event = (category, action, label, value) => {
  // window.gtag('event', action, {
  //   event_category: category,
  //   event_label: label,
  //   value: value
  // })
}
