import React, { useState } from 'react'
import ChooseFileField from '../../../../components/Form/FormFields/ChooseFileField'
import ApiService from '../../../../store/services/ApiService'
import * as knowledgeAPI from '../../../../store/api/knowledge'
import eventToFormData from '../../../../utils/forms/eventToFormData'
import { toast } from 'react-toastify'

const CompanyArticleThumbnailUploadContainer = ({ value, error, touched, handleField }) => {
  const [state, setState] = useState({
    isLoading: false,
    apiError: ''
  })

  const handleChange = (e) => {
    setState({ ...state, isLoading: true })
    const formData = eventToFormData(e)

    ApiService.fetch(knowledgeAPI.uploadArticleThumbnail(formData))
      .then((data) => {
        setState({ ...state, isLoading: false })
        handleField('articleThumbnailUrl', data.url)
      })
      .catch(err => {
        toast.error('Wystąpił błąd!', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        })
        console.error(err)
        setState({ ...state, apiError: err })
      })
  }

  return (
    <ChooseFileField
      buttonLabel={value === undefined || value === '' ? 'Wybierz plik' : 'Dodano!'}
      errorMessage={error}
      hasError={error && touched}
      label='Zdjęcie nagłówkowe'
      name='articleThumbnail'
      onChange={handleChange}
      isLoading={state.isLoading}
      text='Zalecany rozmiar pliku: 930x380'
    />
  )
}

export default CompanyArticleThumbnailUploadContainer
