import createSimpleFetchingEpic from '../../utils/createSimpleFetchingEpic'

import * as companyAPI from '../../api/company'

import actions from './actions'
import types from './types'

const fetchIndustries = createSimpleFetchingEpic(
  types.FETCH_REQUESTED,
  companyAPI.getAllIndustries().toObject(),
  actions.fetchIndustriesSuccess,
  actions.fetchIndustriesError
)

export default {
  fetchIndustries
}
