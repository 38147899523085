import { createSelector } from 'reselect'

import CompatibilityService from '../../services/CompatibilityService'

import { industriesSelectors } from '../industries'
import { myUserSelectors } from '../my-user'

export const getCompaniesDisplay = (state) => state.companies.display
export const getCompaniesLoadState = (state) => state.companies.isLoading
export const getCompaniesContent = (state) => state.companies.data.filter((company) => company.isPublished)

export const getCompatibleCompanies = (withSR) => createSelector(
  [getFilteredCompanies, myUserSelectors.getMyUserData],
  (companies, user) => {
    const sortedCompanies = {
      over90: [],
      over80: [],
      rest: []
    }

    companies
      .filter((company) => {
        if (withSR) {
          return company.hasQuickRecruitment
        }

        return true
      })
      .map((company) => ({
        ...company,
        compatibility: CompatibilityService({
          userImportantValues: user.importantValues,
          userValues: user.values,
          companyValues: company.values
        })
      }))
      .forEach((company) => {
        if (company.compatibility >= 90) {
          sortedCompanies.over90.push(company)
        } else if (company.compatibility >= 80) {
          sortedCompanies.over80.push(company)
        } else {
          sortedCompanies.rest.push(company)
        }
      })

    return [[...sortedCompanies.over90], [...sortedCompanies.over80], [...sortedCompanies.rest]]
  }
)

export const getFilteredCompanies = createSelector(
  [getCompaniesContent, industriesSelectors.getSelectedIndustries],
  (content, industries) => content.filter((company) => {
    if (industries.length === 0) {
      return true
    }

    const selectedIndustriesIds = industries.map((industry) => industry._id)
    const companyIndustriesIds = company.industries

    return selectedIndustriesIds.some(industryId => companyIndustriesIds.indexOf(industryId) > -1)
  })
)

export const getCompanies = createSelector(
  [getCompaniesDisplay, getFilteredCompanies],
  (display, content) => content.slice(0, display)
)

export default {
  getCompanies,
  getCompaniesContent,
  getCompaniesDisplay,
  getCompatibleCompanies,
  getCompaniesLoadState,
  getFilteredCompanies
}
