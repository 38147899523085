import styled from 'styled-components'

import { PurpleBoxShadowColor } from '../../../assets/styles/colors'

export const EmployerProfileRightContainer = styled.div`
  width: 100%;

  @media (max-width: 1429px) {
    margin: 40px 0 0;
  }
`

export const EmployerProfileRightImageContainer = styled.div`
  border-radius: 20px;
  box-shadow: ${PurpleBoxShadowColor};
  overflow: hidden;

  & > div {
    padding: 0;
  }

  @media (min-width: 992px) {
    height: 250px;
  }

  @media (min-width: 576px) and (max-width: 991px) {
    height: 170px;
  }

  @media (max-width: 575px) {
    height: 130px;
  }
`

export const EmployerProfileRightTabsContainer = styled.div`
  ${({ image }) => image && `
    @media (min-width: 768px) {
      margin: 30px 0 0;
    }
  `}

  @media (max-width: 767px) {
    display: none;
  }
`

export const EmployerProfileRightTabsContent = styled.div`
  min-height: 420px;
  margin: 40px 0 0;
`
