import types from './types'

const loadCompanies = (data) => ({
  type: types.LOAD,
  payload: data
})

const showMoreCompanies = () => ({
  type: types.SHOW_MORE
})

const showLessCompanies = () => ({
  type: types.SHOW_LESS
})

export default {
  loadCompanies,
  showLessCompanies,
  showMoreCompanies
}
