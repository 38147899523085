import React from 'react'
import PropTypes from 'prop-types'

import BenefitItem from './BenefitItem'

import iconGifts from '../../../assets/icons/benefits/benefits_gifts.svg'
import iconHealthCare from '../../../assets/icons/benefits/benefits_health_care.svg'
import iconInsurance from '../../../assets/icons/benefits/benefits_insurance.svg'
import iconLayette from '../../../assets/icons/benefits/benefits_layette.svg'
import iconMentor from '../../../assets/icons/benefits/benefits_mentor.svg'
import iconPreparing from '../../../assets/icons/benefits/benefits_preparing.svg'
import iconSafety from '../../../assets/icons/benefits/benefits_safety.svg'
import benefit2 from '../../../assets/icons/benefits/2.svg'
import benefit3 from '../../../assets/icons/benefits/3.svg'
import benefit4 from '../../../assets/icons/benefits/4.svg'
import benefit5 from '../../../assets/icons/benefits/5.svg'
import benefit6 from '../../../assets/icons/benefits/6.svg'
import benefit7 from '../../../assets/icons/benefits/7.svg'

const mapIconToFile = (icon) => {
  const icons = {
    iconGifts,
    iconHealthCare,
    iconInsurance,
    iconLayette,
    iconMentor,
    iconPreparing,
    iconSafety,
    benefit2,
    benefit3,
    benefit4,
    benefit5,
    benefit6,
    benefit7
  }

  return icons[icon]
}

const BenefitItems = ({
  itemsList
}) => (
  <>
    {itemsList.map((item, id) =>
      <BenefitItem
        description={item.description}
        icon={mapIconToFile(item.icon)}
        key={id}
        title={item.title}
      />
    )}
  </>
)

export default BenefitItems

BenefitItems.propTypes = {
  itemsList: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      icon: PropTypes.string,
      title: PropTypes.string
    })
  )
}
