import styled from 'styled-components'

import { PinkColor } from '../../../assets/styles/colors'

export const JobsLinkContainer = styled.div`
  @media (min-width: 768px) {
    margin: 0 0 30px;
  }

  @media (max-width: 767px) {
    margin: 0 0 25px;
  }
`

export const JobsLiveContainer = styled.div`
  margin: 30px 0 0;

  @media (min-width: 768px) {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
`

export const JobsLiveText = styled.div`
  color: ${PinkColor};
  font-size: 16px;
  line-height: 21px;
`

export const JobsLiveButtonContainer = styled.div`
  flex-shrink: 0;

  @media (min-width: 768px) {
    margin: 0 0 0 20px;
  }

  @media (max-width: 767px) {
    margin: 20px 0 0;
  }
`
