import React from 'react'
import PropTypes from 'prop-types'

import Image from '../../../FormBasicElements/Image'

import {
  RightSlideElementContainer,
  RightSlideElementContent,
  RightSlideElementText,
  RightSlideElementTitle
} from './components'

const RightSlideElement = ({
  image,
  text,
  title
}) => (
  <RightSlideElementContainer>
    {image &&
      <Image source={image} />}

    <RightSlideElementContent>
      {title &&
        <RightSlideElementTitle>{title}</RightSlideElementTitle>}

      {text &&
        <RightSlideElementText>{text}</RightSlideElementText>}
    </RightSlideElementContent>
  </RightSlideElementContainer>
)

export default RightSlideElement

RightSlideElement.propTypes = {
  image: PropTypes.string,
  text: PropTypes.string,
  title: PropTypes.string
}
