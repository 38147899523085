import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { tagsActions, tagsSelectors } from '../../../store/ducks/tags'

import SelectableItems from '../../../components/SelectableItems/SelectableItems'

const SelectableTagsContainer = () => {
  const tagsState = useSelector(tagsSelectors.getTagsState)

  const dispatch = useDispatch()
  useEffect(() => {
    if (tagsState.isLoading === false) {
      if (tagsState.data === null || tagsState.data.length === 0) {
        dispatch(tagsActions.fetchTagsRequested())
      }
    }
  }, [dispatch, tagsState])

  const tags = useSelector(tagsSelectors.getTagsOnlyUsedInTrainings)

  return (
    <SelectableItems
      align='center'
      isEditable
      itemsList={tags}
      onClick={(key) => dispatch(tagsActions.toggleTag(key))}
    />
  )
}

export default SelectableTagsContainer
