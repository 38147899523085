import React from 'react'
import PropTypes from 'prop-types'

import InternalOrExternal from '../../../Buttons/InternalOrExternal'

import {
  ChooseFileButton,
  ChooseFileContainer,
  ChooseFileText
} from './components'

const ChooseFile = ({
  buttonLabel,
  hasError,
  onClick,
  text
}) => (
  <ChooseFileContainer text={Boolean(text)}>
    <InternalOrExternal onClick={onClick}>
      <ChooseFileButton hasError={hasError}>{buttonLabel}</ChooseFileButton>
    </InternalOrExternal>

    {text &&
      <ChooseFileText>{text}</ChooseFileText>}
  </ChooseFileContainer>
)

export default ChooseFile

ChooseFile.propTypes = {
  buttonLabel: PropTypes.string,
  hasError: PropTypes.bool,
  onClick: PropTypes.func,
  text: PropTypes.string
}
