import React from 'react'
import ReactPlayer from 'react-player'
import PropTypes from 'prop-types'

import ButtonComponent from '../../Buttons/ButtonComponent'
// import Icon from '../../Form/FormBasicElements/Icon'
import Image from '../../Form/FormBasicElements/Image'
// import InternalOrExternal from '../../Buttons/InternalOrExternal'

import {
  CompanyBoxButtonContainer,
  CompanyBoxContainer,
  CompanyBoxContent,
  CompanyBoxContentContainer,
  // CompanyBoxIconsContainer,
  CompanyBoxImageContainer,
  // CompanyBoxIsFastRecruitment,
  // CompanyBoxIsFavourite,
  CompanyBoxText,
  CompanyBoxTopContainer,
  CompanyBoxValue,
  CompanyBoxValueContainer,
  CompanyBoxVideo
} from './Atoms'

// import fastRecruitmentIcon from '../../../assets/icons/hands.svg'
// import favouriteIcon from '../../../assets/icons/heart.svg'

const CompanyBox = ({
  buttonLabel,
  image,
  isFast,
  isFavourite,
  key,
  link,
  name,
  onClickIsFavourite,
  showRightColumn,
  showTop, // withInfo // TODO
  value,
  valueLabel, // text // TODO
  videoUrl,
  withIsFastButton,
  type
}) => (
  <CompanyBoxContainer data-test='CompanyBoxContainer'>
    {
      showTop && (
        <CompanyBoxTopContainer
          data-test='CompanyBoxTopContainer'
          showTop={showTop}
        >
          <CompanyBoxValueContainer data-test='CompanyBoxValueContainer'>
            <CompanyBoxValue data-test='CompanyBoxValue'>
              {value}
            </CompanyBoxValue>

            <CompanyBoxText data-test='CompanyBoxText'>
              {valueLabel}
            </CompanyBoxText>
          </CompanyBoxValueContainer>

          {/* <CompanyBoxIconsContainer data-test='CompanyBoxIconsContainer'> // TODO
            {
              withIsFastButton && (
                <CompanyBoxIsFastRecruitment
                  data-test='CompanyBoxIsFastRecruitment'
                  isFast={isFast}
                >
                  <Icon
                    data-test='CompanyBoxIsFastRecruitmentIcon'
                    icon={fastRecruitmentIcon}
                  />
                </CompanyBoxIsFastRecruitment>
              )
            }

            <CompanyBoxIsFavourite
              data-test='CompanyBoxIsFavourite'
              isFavourite={isFavourite}
            >
              <InternalOrExternal
                data-test='CompanyBoxIsFavouriteLink'
                onClick={onClickIsFavourite}
              >
                <Icon
                  data-test='CompanyBoxIsFavouriteIcon'
                  icon={favouriteIcon}
                />
              </InternalOrExternal>
            </CompanyBoxIsFavourite>
          </CompanyBoxIconsContainer> */}
        </CompanyBoxTopContainer>
      )
    }

    <CompanyBoxContentContainer
      data-test='CompanyBoxContentContainer'
      showRightColumn={showRightColumn}
    >
      <CompanyBoxContent
        data-test='CompanyBoxContent'
        showRightColumn={showRightColumn}
      >
        <CompanyBoxImageContainer data-test='CompanyBoxImageContainer'>
          <Image
            alt={name}
            data-test='CompanyBoxImage'
            scaleMe={false}
            source={image}
          />
        </CompanyBoxImageContainer>

        <CompanyBoxButtonContainer data-test='CompanyBoxButtonContainer'>
          <ButtonComponent
            align='center'
            bgColor='purpleColor'
            color='whiteColor'
            data-test='CompanyBoxButton'
            display='inline-flex'
            fontBold
            label={buttonLabel}
            link={link}
            size='sizeM'
            type='internal'
          />
        </CompanyBoxButtonContainer>
      </CompanyBoxContent>

      {
        showRightColumn && videoUrl && type != 'platinium' && (
          <CompanyBoxVideo
            data-test='CompanyBoxVideo'
            showRightColumn={showRightColumn}
          >
            <ReactPlayer
              data-test='CompanyBoxVideoPlayer'
              height='100%'
              url={videoUrl}
              width='100%'
              controls='true'
            />
          </CompanyBoxVideo>
        )
      }
    </CompanyBoxContentContainer>
  </CompanyBoxContainer>
)

export default CompanyBox

CompanyBox.propTypes = {
  buttonLabel: PropTypes.string,
  image: PropTypes.string,
  isFast: PropTypes.bool,
  isFavourite: PropTypes.bool,
  link: PropTypes.string,
  name: PropTypes.string,
  onClickIsFavourite: PropTypes.func,
  showRightColumn: PropTypes.bool,
  showTop: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  valueLabel: PropTypes.string,
  withIsFastButton: PropTypes.bool
}

CompanyBox.defaultProps = {
  onClickIsFavourite: () => null // TODO
}
