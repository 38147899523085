import React from 'react'
import PropTypes from 'prop-types'

import Image from '../Form/FormBasicElements/Image'

import {
  LoaderContainer,
  LoaderContent
} from './components'

import loaderImagePath from '../../assets/img/loader.gif'

const Loader = ({
  isLoading
}) => (
  <>
    {isLoading &&
      <LoaderContainer>
        <LoaderContent>
          <Image
            scaleMe={false}
            source={loaderImagePath}
          />
        </LoaderContent>
      </LoaderContainer>}
  </>
)

export default Loader

Loader.propTypes = {
  isLoading: PropTypes.bool
}

Loader.defaultProps = {
  isLoading: false
}
