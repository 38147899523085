import React from 'react'
import PropTypes from 'prop-types'

import ButtonComponent from '../Buttons/ButtonComponent'
import Label from '../Form/FormBasicElements/Label'

import {
  LocationButtonContainer,
  LocationContainer,
  LocationContent,
  LocationText
} from './components'

import translationsList from '../../stabs/translationsList'

const Location = ({
  address,
  calendarUrl,
  dateTime,
  drivingUrl
}) => (
  <LocationContainer>
    <LocationContent>
      <Label
        data-test='locationPlace'
        label={translationsList.locations.labels.placeLabel}
      />

      <LocationText data-test='locationTextAddress'>{address}</LocationText>

      <LocationButtonContainer data-test='locationButtonContainerOne'>
        <ButtonComponent
          bgColor='purpleColor'
          color='whiteColor'
          data-test='locationFind'
          fontBold
          fullWidth
          inNewWindow
          label={translationsList.locations.labels.buttonLabel}
          link={drivingUrl}
          size='sizeM'
          type='external'
        />
      </LocationButtonContainer>

      <Label
        data-test='locationDateAndTime'
        label={translationsList.locations.labels.dateAndTimeLabel}
      />

      <LocationText data-test='locationTextDate'>
        {dateTime.date.replace(/-\d\d/, '') + ' ' + dateTime.timeStart + ' - ' + dateTime.timeEnd} <br />
        {dateTime.date.substring(3) + ' ' + dateTime.timeStart + ' - ' + dateTime.timeEnd}
      </LocationText>

      <LocationButtonContainer data-test='locationButtonContainerTwo'>
        <ButtonComponent
          bgColor='purpleColorS'
          color='purpleColor'
          data-test='locationCalendar'
          fontBold
          fullWidth
          inNewWindow
          label={translationsList.locations.labels.addToCalendarButtonLabel}
          link={calendarUrl}
          size='sizeM'
          type='external'
        />
      </LocationButtonContainer>
    </LocationContent>
  </LocationContainer>
)

export default Location

Location.propTypes = {
  address: PropTypes.string,
  calendarUrl: PropTypes.string,
  dateTime: PropTypes.shape({
    date: PropTypes.string,
    timeEnd: PropTypes.string,
    timeStart: PropTypes.string
  }),
  drivingUrl: PropTypes.string
}

Location.defaultProps = {
  address: '',
  calendarUrl: '',
  dateTime: {
    date: '',
    timeEnd: '',
    timeStart: ''
  },
  drivingUrl: ''
}
