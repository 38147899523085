import React from 'react'
import PropTypes from 'prop-types'

import {
  LabelContainer,
  LabelRequired
} from './components'

const Label = ({
  id,
  label,
  required
}) => (
  <LabelContainer htmlFor={id}>
    {required &&
      <LabelRequired>*</LabelRequired>}

    {label}
  </LabelContainer>
)

export default Label

Label.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool
}

Label.defaultProps = {
  id: '',
  label: '',
  required: false
}
