import styled from 'styled-components'

import {
  PurpleBoxShadowColor,
  PurpleColorL,
  PurpleColorM,
  PurpleColorS,
  WhiteColor
} from '../../../assets/styles/colors'

export const AddedItemContainer = styled.div`
  display: grid;
  grid-column-gap: 40px;
  grid-template-columns: 1fr auto;
  text-align: left;

  ${props => {
    switch (props.isInsideForm) {
      case true: return `
        border-bottom: 2px solid ${PurpleColorS};
        margin: 0 0 30px;
        padding: 0 0 30px;
      `
      default: return `
        background-color: ${WhiteColor};
        box-shadow: ${PurpleBoxShadowColor};
        padding: 30px 60px;
      `
    }
  }}
`

export const AddedItemTextContainer = styled.div``

export const AddedItemButtonsContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 40px;
`

export const AddedItemTitle = styled.div`
  color: ${PurpleColorL};
  font-size: 16px;
  font-weight: 600;
  line-height: 25px;
`

export const AddedItemDescription = styled.div`
  color: ${PurpleColorM};
  font-size: 12px;
  line-height: 18px;
  padding: 6px 0 0;
`
