import React from 'react'
import PropTypes from 'prop-types'

import SliderButton from '../SliderButton'

import {
  SlideButtonsWrapperButtonContainer,
  SlideButtonsWrapperButtonsContainer,
  SlideButtonsWrapperButtonsContent,
  SlideButtonsWrapperContent
} from './components'

class SlideButtonsWrapper extends React.Component {
  constructor (props) {
    super(props)
    this.sliderContent = React.createRef()

    this.onClickLeftHandler.bind(this)
    this.onClickRightHandler.bind(this)
  }

  onClickLeftHandler = () => {
    this.refs.sliderContent.scrollLeft -= 50
  }

  onClickRightHandler = () => {
    this.refs.sliderContent.scrollLeft += 50
  }

  render () {
    const handleOnClickLeftHandler = this.onClickLeftHandler
    const handleOnClickRightHandler = this.onClickRightHandler

    return (
      <>
        <SlideButtonsWrapperContent ref='sliderContent'>
          {this.props.children}
        </SlideButtonsWrapperContent>

        <SlideButtonsWrapperButtonsContainer>
          <SlideButtonsWrapperButtonsContent>
            <SlideButtonsWrapperButtonContainer data-test='leftArrowContainer'>
              {!this.props.hideLeft && (
                <SliderButton
                  data-test='leftArrow'
                  onClick={handleOnClickLeftHandler}
                  position='left'
                />
              )}
            </SlideButtonsWrapperButtonContainer>

            <SlideButtonsWrapperButtonContainer data-test='rightArrowContainer'>
              {!this.props.hideRight && (
                <SliderButton
                  data-test='rightArrow'
                  onClick={handleOnClickRightHandler}
                  position='right'
                />
              )}
            </SlideButtonsWrapperButtonContainer>
          </SlideButtonsWrapperButtonsContent>
        </SlideButtonsWrapperButtonsContainer>
      </>
    )
  }
}

export default SlideButtonsWrapper

SlideButtonsWrapper.defaultProps = {
  hideLeft: false,
  hideRight: false
}

SlideButtonsWrapper.propTypes = {
  children: PropTypes.any
}
