import React from 'react'
import PropTypes from 'prop-types'

import Icon from '../../Form/FormBasicElements/Icon'
import Image from '../../Form/FormBasicElements/Image'
import InternalOrExternal from '../../Buttons/InternalOrExternal'

import {
  RecruiterCardBoxAvailability,
  RecruiterCardBoxAvailabilityContainer,
  RecruiterCardBoxAvailabilityDay,
  RecruiterCardBoxAvailabilityHeader,
  RecruiterCardBoxContainer,
  RecruiterCardBoxContent,
  RecruiterCardBoxEmailContainer,
  RecruiterCardBoxEmailLink,
  RecruiterCardBoxImageContainer,
  RecruiterCardBoxNameAndSurname,
  RecruiterCardBoxNameAndSurnameContainer,
  RecruiterCardBoxPosition,
  RecruiterCardBoxPositionContainer,
  RecruiterCardBoxSocialLinkedinContainer,
  RecruiterCardBoxSocialMediaContainer
} from './Atoms'

import linkedinIcon from '../../../assets/icons/socialMedia/linkedin.svg'

const RecruiterCardBox = ({
  avatarUrl,
  dateOne,
  dateTwo,
  email,
  firstName,
  lastName,
  linkedin,
  position,
  showAvailability,
  timeOne,
  timeTwo
}) => (
  <RecruiterCardBoxContainer data-test='RecruiterCardBoxContainer'>
    {
      avatarUrl && (
        <RecruiterCardBoxImageContainer data-test='RecruiterCardBoxImageContainer'>
          <Image
            alt={firstName + ' ' + lastName}
            data-test='RecruiterCardBoxImage'
            scaleMe={false}
            source={avatarUrl}
          />
        </RecruiterCardBoxImageContainer>
      )
    }

    <RecruiterCardBoxContent
      avatarUrl={avatarUrl}
      data-test='RecruiterCardBoxContent'
    >
      <RecruiterCardBoxNameAndSurnameContainer data-test='RecruiterCardBoxNameAndSurnameContainer'>
        <RecruiterCardBoxNameAndSurname data-test='RecruiterCardBoxNameAndSurname'>
          {firstName + ' ' + lastName}
        </RecruiterCardBoxNameAndSurname>
      </RecruiterCardBoxNameAndSurnameContainer>

      <RecruiterCardBoxPositionContainer data-test='RecruiterCardBoxPositionContainer'>
        <RecruiterCardBoxPosition data-test='RecruiterCardBoxPosition'>
          {position}
        </RecruiterCardBoxPosition>
      </RecruiterCardBoxPositionContainer>

      <RecruiterCardBoxEmailContainer data-test='RecruiterCardBoxEmailContainer'>
        <RecruiterCardBoxEmailLink
          data-test='RecruiterCardBoxEmailLink'
          href={`mailto:${email}`}
        >
          {email}
        </RecruiterCardBoxEmailLink>
      </RecruiterCardBoxEmailContainer>

      {
        linkedin && (
          <RecruiterCardBoxSocialMediaContainer data-test='RecruiterCardBoxSocialMediaContainer'>
            <RecruiterCardBoxSocialLinkedinContainer data-test='RecruiterCardBoxSocialLinkedinContainer'>
              <InternalOrExternal
                data-test='RecruiterCardBoxSocialLinkedinLink'
                inNewWindow
                link={linkedin}
                type='external'
              >
                <Icon
                  data-test='RecruiterCardBoxSocialLinkedinIcon'
                  icon={linkedinIcon}
                />
              </InternalOrExternal>
            </RecruiterCardBoxSocialLinkedinContainer>
          </RecruiterCardBoxSocialMediaContainer>
        )
      }

      {
        showAvailability && (
          <RecruiterCardBoxAvailabilityContainer data-test='RecruiterCardBoxAvailabilityContainer'>
            <RecruiterCardBoxAvailabilityHeader data-test='RecruiterCardBoxAvailabilityHeader'>
              {'Dostępność online:'}
            </RecruiterCardBoxAvailabilityHeader>

            <>
              <RecruiterCardBoxAvailabilityDay data-test='RecruiterCardBoxAvailabilityDayOne'>
                <RecruiterCardBoxAvailability data-test='RecruiterCardBoxAvailabilityDateOne'>
                  {dateOne + ' ' + timeOne}
                </RecruiterCardBoxAvailability>
              </RecruiterCardBoxAvailabilityDay>

              <RecruiterCardBoxAvailabilityDay data-test='RecruiterCardBoxAvailabilityDayTwo'>
                <RecruiterCardBoxAvailability data-test='RecruiterCardBoxAvailabilityDateTwo'>
                  {dateTwo + ' ' + timeTwo}
                </RecruiterCardBoxAvailability>
              </RecruiterCardBoxAvailabilityDay>
            </>
          </RecruiterCardBoxAvailabilityContainer>
        )
      }
    </RecruiterCardBoxContent>
  </RecruiterCardBoxContainer>
)

export default RecruiterCardBox

RecruiterCardBox.propTypes = {
  avatarUrl: PropTypes.string,
  dateOne: PropTypes.string,
  dateTwo: PropTypes.string,
  email: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  linkedin: PropTypes.string,
  position: PropTypes.string,
  showAvailability: PropTypes.bool,
  timeOne: PropTypes.string,
  timeTwo: PropTypes.string
}

RecruiterCardBox.defaultProps = {
  avatarUrl: '',
  dateOne: '',
  dateTwo: '',
  email: '',
  firstName: '',
  lastName: '',
  linkedin: '',
  position: '',
  showAvailability: false,
  timeOne: '',
  timeTwo: ''
}
