import React from 'react'
import PropTypes from 'prop-types'

import Errors from '../../FormBasicElements/Errors'
import Input from '../../FormBasicElements/Input'
import Label from '../../FormBasicElements/Label'

import {
  InputFieldContainer
} from './components'

const InputField = ({
  disabled,
  errorMessage,
  hasError,
  id,
  label,
  name,
  onBlur,
  onChange,
  placeholder,
  required,
  type,
  value
}) => (
  <InputFieldContainer>
    <Label
      id={id}
      label={label}
      required={required}
    />

    <Input
      disabled={disabled}
      hasError={hasError}
      id={id}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      placeholder={placeholder}
      type={type}
      value={value}
    />

    <Errors
      errorMessage={errorMessage}
      hasError={hasError}
    />
  </InputFieldContainer>
)

export default InputField

InputField.propTypes = {
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  hasError: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.string
}
