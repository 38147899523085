import React from 'react'
import PropTypes from 'prop-types'

import {
  DescriptionContainer
} from './components'

const Description = ({
  alignCenter,
  description,
  html
}) => (
  <>

  	{ description
	  ? [html
	  	? <DescriptionContainer dangerouslySetInnerHTML={{__html: description}} />
	  	: <DescriptionContainer alignCenter={alignCenter}>{description}</DescriptionContainer>

	  ]
	  : null
	}

    
  </>
)

export default Description

Description.propTypes = {
  alignCenter: PropTypes.bool,
  description: PropTypes.string,
  html: PropTypes.bool
}
