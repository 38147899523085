import types from './types'
import { createReducer } from '../../utils'

const initialState = {
  data: [],
  error: null,
  isLoading: false
}

const cvReducer = createReducer(initialState)({
  [types.FETCH_ERROR]: (state, action) => ({ data: initialState.data, isLoading: false, error: action.payload }),
  [types.FETCH_REQUESTED]: () => ({ data: initialState.data, isLoading: true, error: null }),
  [types.FETCH_SUCCESS]: (state, action) => ({ data: action.payload, isLoading: false, error: null, a: true })
})

export default cvReducer
