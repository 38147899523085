import React from 'react'
import PropTypes from 'prop-types'

import Icon from '../../Form/FormBasicElements/Icon'
import InternalOrExternal from '../InternalOrExternal'

import {
  ButtonPlayContainer
} from './components'

import iconPlayPath from '../../../assets/icons/play.svg'

const ButtonPlay = ({
  link
}) => (
  <ButtonPlayContainer>
    <InternalOrExternal
      link={link}
      type='internal'
    >
      <Icon icon={iconPlayPath} />
    </InternalOrExternal>
  </ButtonPlayContainer>
)

export default ButtonPlay

ButtonPlay.propTypes = {
  link: PropTypes.string
}
