import styled from 'styled-components'

import { LabelContainer } from '../../Form/FormBasicElements/Label/components'

export const TrainingsHeaderContainer = styled.div`
  display: grid;
  grid-column-gap: 30px;

  @media (min-width: 1600px) {
    grid-template-columns: 130px auto 570px;
  }

  @media (min-width: 1200px) and (max-width: 1599px) {
    grid-template-columns: 130px auto 470px;
  }

  @media (min-width: 320px) and (max-width: 1199px) {
    ${LabelContainer} {
      &:last-child {
        display: none;
      }
    }
  }
  
  @media (min-width: 992px) {
    padding: 0px 40px;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    grid-template-columns: 110px 1fr auto auto;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    grid-template-columns: 100px 1fr auto auto;
  }

  @media (min-width: 320px) and (max-width: 767px) {
    grid-template-columns: 50px 1fr auto auto;
  }

  @media (min-width: 576px) and (max-width: 991px) {
    padding: 0 20px;
  }

  @media (min-width: 320px) and (max-width: 575px) {
    padding: 0 15px;

    ${LabelContainer} {
      &:first-child {
        display: none;
      }
    }
  }
`
