import styled from 'styled-components'

export const FormStepsWrapperStepsContainer = styled.div`
  margin: 50px 0 0;
`

export const FormStepsWrapperDescriptionContainer = styled.div`
  max-width: 1000px;

  @media (min-width: 1200px) {
    margin: 100px auto 0;
  }

  @media (min-width: 768px) and (max-width: 1199px) {
    margin: 70px auto 0;
  }

  @media (max-width: 767px) {
    margin: 40px auto 0;
  }
`

export const FormStepsWrapperArrowsContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 30px 35px 0;
`

export const FormStepsWrapperArrowPrevContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`

export const FormStepsWrapperArrowNextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`

export const FormStepsWrapperContainer = styled.div``
