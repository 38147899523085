const FETCH_ERROR = 'values/FETCH_ERROR'
const FETCH_REQUESTED = 'values/FETCH_REQUESTED'
const FETCH_SUCCESS = 'values/FETCH_SUCCESS'

const TOGGLE_USER_VALUE = 'values/TOGGLE_USER'

export default {
  FETCH_ERROR,
  FETCH_REQUESTED,
  FETCH_SUCCESS,
  TOGGLE_USER_VALUE
}
