import types from './types'

const fetchMyUserError = (error) => ({
  type: types.FETCH_ERROR,
  payload: error
})

const fetchMyUserRequested = () => ({
  type: types.FETCH_REQUESTED
})

const cleanUp = () => ({
  type: types.CLEAN_UP
})

const fetchMyUserSuccess = (data) => ({
  type: types.FETCH_SUCCESS,
  payload: data
})

const toggleValue = (valueId, action) => ({
  type: types.TOGGLE_VALUE,
  payload: { valueId, action }
})

export default {
  fetchMyUserError,
  fetchMyUserRequested,
  fetchMyUserSuccess,
  toggleValue,
  cleanUp
}
