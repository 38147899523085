import React from 'react'
import PropTypes from 'prop-types'

import {
  CheckBoxCheckContainer,
  CheckBoxContainer,
  CheckBoxContent,
  CheckBoxInput,
  CheckboxCheck
} from './components'

const CheckBox = ({
  checked,
  children,
  name,
  onChange
}) => (
  <CheckBoxContainer>
    <CheckBoxCheckContainer>
      <CheckBoxInput
        checked={checked}
        data-test='checkBoxInput'
        onChange={() => onChange(name)}
        type='checkbox'
      />

      <CheckboxCheck />
    </CheckBoxCheckContainer>

    <CheckBoxContent data-test='checkBoxContent'>{children}</CheckBoxContent>
  </CheckBoxContainer>
)

export default CheckBox

CheckBox.propTypes = {
  checked: PropTypes.bool,
  children: PropTypes.any,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired
}

CheckBox.defaultProps = {
  checked: false
}
