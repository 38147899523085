import React from 'react'
import PropTypes from 'prop-types'

import {
  CountdownComponentUIColumn,
  CountdownComponentUIContainer,
  CountdownComponentUILabel,
  CountdownComponentUIValue
} from './Atoms'

const CountdownComponentUI = ({
  days,
  hours,
  minutes,
  seconds
}) => (
  <CountdownComponentUIContainer data-test='CountdownComponentUIContainer'>
    <CountdownComponentUIColumn data-test='CountdownComponentUIColumnDays'>
      <CountdownComponentUIValue data-test='CountdownComponentUIValueDays'>
        {days}
      </CountdownComponentUIValue>

      <CountdownComponentUILabel data-test='CountdownComponentUILabelDays'>
        {'Dni'}
      </CountdownComponentUILabel>
    </CountdownComponentUIColumn>

    <CountdownComponentUIColumn data-test='CountdownComponentUIColumnHours'>
      <CountdownComponentUIValue data-test='CountdownComponentUIValueHours'>
        {hours}
      </CountdownComponentUIValue>

      <CountdownComponentUILabel data-test='CountdownComponentUILabelHours'>
        {'Godzin'}
      </CountdownComponentUILabel>
    </CountdownComponentUIColumn>

    <CountdownComponentUIColumn data-test='CountdownComponentUIColumnMinutes'>
      <CountdownComponentUIValue data-test='CountdownComponentUIValueMinutes'>
        {minutes}
      </CountdownComponentUIValue>

      <CountdownComponentUILabel data-test='CountdownComponentUILabelMinutes'>
        {'Minut'}
      </CountdownComponentUILabel>
    </CountdownComponentUIColumn>

    <CountdownComponentUIColumn data-test='CountdownComponentUIColumnSeconds'>
      <CountdownComponentUIValue data-test='CountdownComponentUIValueSeconds'>
        {seconds}
      </CountdownComponentUIValue>

      <CountdownComponentUILabel data-test='CountdownComponentUILabelSeconds'>
        {'Sekund'}
      </CountdownComponentUILabel>
    </CountdownComponentUIColumn>
  </CountdownComponentUIContainer>
)

export default CountdownComponentUI

CountdownComponentUI.propTypes = {
  days: PropTypes.number,
  hours: PropTypes.number,
  minutes: PropTypes.number,
  seconds: PropTypes.number
}
