import React from 'react'
import PropTypes from 'prop-types'

import Icon from '../../Form/FormBasicElements/Icon'
import InternalOrExternal from '../../Buttons/InternalOrExternal'

import {
  SocialItemContainer,
  SocialItemLabel,
  SocialItemLinkContainer
} from './components'

import facebookIcon from '../../../assets/icons/socialMedia/facebook.svg'
import linkedinIcon from '../../../assets/icons/socialMedia/linkedin.svg'
import twitterIcon from '../../../assets/icons/socialMedia/twitter.svg'
import youtubeIcon from '../../../assets/icons/socialMedia/youtube.svg'
import instagramIcon from '../../../assets/icons/socialMedia/instagram.svg'

const iconsCollection = (
  option
) => {
  switch (option) {
    case 'facebook': return facebookIcon
    case 'linkedin': return linkedinIcon
    case 'twitter': return twitterIcon
    case 'youtube': return youtubeIcon
    case 'instagram': return instagramIcon
    default: return ''
  }
}

const labelsCollection = (
  option
) => {
  switch (option) {
    case 'facebook': return 'Lubię to!'
    case 'linkedin': return 'Obserwuj!'
    case 'twitter': return 'Obserwuj!'
    case 'instagram': return 'Obserwuj!'
    case 'youtube': return 'Subskrybuj!'
    default: return ''
  }
}

const SocialItem = ({
  option,
  link
}) => (
  <>
    {option &&
      <SocialItemLinkContainer>
        <InternalOrExternal
          inNewWindow
          link={link}
          type='external'
        >
          <SocialItemContainer option={option}>
            <Icon icon={iconsCollection(option)} />

            <SocialItemLabel>{labelsCollection(option)}</SocialItemLabel>
          </SocialItemContainer>
        </InternalOrExternal>
      </SocialItemLinkContainer>}
  </>
)

export default SocialItem

SocialItem.propTypes = {
  link: PropTypes.string,
  option: PropTypes.oneOf(['facebook', 'linkedin', 'twitter', 'youtube', 'instagram'])
}
