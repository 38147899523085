import types from './types'
import { createReducer } from '../../utils'

const initialState = {
  data: null,
  filterText: null,
  error: null,
  isLoading: false
}

const articlesReducer = createReducer(initialState)({
  [types.SET_FILTER]: (state, action) => ({ ...state, filterText: state.filterText === action.payload.filterText ? null : action.payload.filterText }),
  [types.FETCH_ERROR]: (state, action) => ({ data: null, isLoading: false, error: action.payload, filterText: state.filterText }),
  [types.FETCH_REQUESTED]: (state) => ({ data: null, isLoading: true, error: null, filterText: state.filterText }),
  [types.FETCH_SUCCESS]: (state, action) => ({
    data: action.payload,
    isLoading: false,
    error: null,
    filterText: state.filterText
  })
})

export default articlesReducer
