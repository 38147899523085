import React from 'react'
import PropTypes from 'prop-types'

import ButtonComponent from '../../Buttons/ButtonComponent'
import Icon from '../../Form/FormBasicElements/Icon'
import Image from '../../Form/FormBasicElements/Image'
import InternalOrExternal from '../../Buttons/InternalOrExternal'

import {
  CompanyItemButtonMoreContainer,
  CompanyItemContainer,
  CompanyItemIconContainer,
  CompanyItemText,
  CompanyItemTextContainer,
  CompanyItemTextValue,
  CompanyItemWithInfoContainer
} from './components'

import iconFastPath from '../../../assets/icons/hands.svg'
import iconFavouritePath from '../../../assets/icons/heart.svg'

const CompanyItem = ({
  buttonLabel,
  image,
  insideBox,
  isFast,
  isFavourite,
  link,
  name,
  onClickIsFavourite,
  text,
  value,
  withInfo,
  withIsFastButton,
  withSize
}) => (
  <CompanyItemContainer
    insideBox={insideBox}
    withSize={withSize}
  >
    <InternalOrExternal
      link={link}
      type='internal'
    >
      <Image
        alt={name}
        scaleMe={false}
        source={image}
      />
    </InternalOrExternal>

    {withInfo &&
      <CompanyItemWithInfoContainer withIsFastButton={withIsFastButton}>
        <CompanyItemTextContainer>
          <CompanyItemTextValue>{value}</CompanyItemTextValue>

          <CompanyItemText>{text}</CompanyItemText>
        </CompanyItemTextContainer>

        {withIsFastButton &&
          <CompanyItemIconContainer
            data-test='isFastContainer'
            isFast={isFast}
          >
            <Icon
              data-test='isFastIcon'
              icon={iconFastPath}
            />
          </CompanyItemIconContainer>}

        <CompanyItemIconContainer
          data-test='isFavouriteContainer'
          isFavourite={isFavourite}
        >
          <InternalOrExternal
            data-test='isFavouriteLink'
            onClick={onClickIsFavourite}
          >
            <Icon
              data-test='isFavouriteIcon'
              icon={iconFavouritePath}
            />
          </InternalOrExternal>
        </CompanyItemIconContainer>
      </CompanyItemWithInfoContainer>}

    <CompanyItemButtonMoreContainer insideBox={insideBox}>
      <ButtonComponent
        bgColor='purpleColor'
        color='whiteColor'
        fontBold
        fullWidth
        label={buttonLabel}
        link={link}
        size='sizeM'
        type='internal'
      />
    </CompanyItemButtonMoreContainer>
  </CompanyItemContainer>
)

export default CompanyItem

CompanyItem.propTypes = {
  buttonLabel: PropTypes.string,
  image: PropTypes.string,
  insideBox: PropTypes.bool,
  isFast: PropTypes.bool,
  isFavourite: PropTypes.bool,
  link: PropTypes.string,
  name: PropTypes.string,
  onClickIsFast: PropTypes.func,
  onClickIsFavourite: PropTypes.func,
  text: PropTypes.string,
  value: PropTypes.string,
  withInfo: PropTypes.bool,
  withIsFastButton: PropTypes.bool,
  withSize: PropTypes.bool
}

CompanyItem.defaultProps = {
  insideBox: false,
  isFast: false,
  isFavourite: false,
  text: '',
  value: '',
  withInfo: false,
  withIsFastButton: false,
  withSize: false
}
