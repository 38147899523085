import React from 'react'
import PropTypes from 'prop-types'

import InternalOrExternal from '../../Buttons/InternalOrExternal'
import SelectableItem from '../SelectableItem'

import {
  SelectableItemsContainer
} from './components'

const SelectableItems = ({
  align,
  isEditable,
  itemsList,
  onClick
}) => (
  <SelectableItemsContainer align={align}>
    {isEditable
      ? (
        <>
          {itemsList.map((item, id) =>
            <InternalOrExternal
              key={id}
              onClick={() => onClick(item._id)}
            >
              <SelectableItem
                isMostImportant={item.isActive && item.isMostImportant}
                isRemoveable={!!isEditable}
                isSelected={item.isActive}
                label={item.label}
              />
            </InternalOrExternal>
          )}
        </>
      )

      : (
        <>
          {itemsList.map((item, id) =>
            <SelectableItem
              isMostImportant={item.isActive && item.isMostImportant}
              isRemoveable={!!isEditable}
              isSelected={item.isActive}
              key={id}
              label={item.label}
            />
          )}
        </>
      )}
  </SelectableItemsContainer>
)

export default SelectableItems

SelectableItems.propTypes = {
  align: PropTypes.oneOf(['center', 'left', 'right']),
  isEditable: PropTypes.bool,
  itemsList: PropTypes.arrayOf(
    PropTypes.shape({
      isMostImportant: PropTypes.bool,
      isActive: PropTypes.bool,
      label: PropTypes.string
    })
  ),
  onClick: PropTypes.func
}

SelectableItems.defaultProps = {
  align: 'left',
  isEditable: false,
  itemsList: [
    {
      isMostImportant: false,
      isActive: false,
      label: ''
    }
  ]
}
