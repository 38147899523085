import React, { useState } from 'react'
import _ from 'lodash'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'

import ApiService from '../../../../store/services/ApiService'
import * as companyAPI from '../../../../store/api/company'

import { myCompanyActions, myCompanySelectors } from '../../../../store/ducks/my-company'

import AddRecruiterForm from '../../../../components/Form/FormComponents/AddRecruiterForm'
import ResetAndSaveButtons from '../../../../components/Form/FormComponents/ResetAndSaveButtons'

import translate from '../../../../stabs/errorMessagesTranslations'

import handleField from '../../../../utils/forms/handleField'

const AddRecruiterFormContainer = () => {
  const [state, setState] = useState({
    error: null,
    isEditing: false,
    isLoading: false,
    editingItemId: null
  })

  const company = useSelector(myCompanySelectors.getMyCompany)

  const dispatch = useDispatch()
  const onSubmit = (values, { setSubmitting }) => {
    setState({ ...state, error: null, isLoading: true })

    values.availability = [values.availability1, values.availability2].join('/')

    const newValues = _.omit(values, ['availability1', 'availability2'])

    console.log(newValues)

    ApiService
      .fetch(state.isEditing ? companyAPI.updateRecruiter(state.editingItemId, newValues) : companyAPI.addRecruiter(newValues))
      .then(data => {
        setSubmitting(false)

        toast.success('Zapisano!', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        })

        dispatch(myCompanyActions.fetchMyCompanySuccess(data))
      })
      .catch(error => {
        setSubmitting(false)
        console.log(error)
        setState({ ...state, error: translate('pl', error.details.msg), isLoading: false })
      })
  }

  const onDelete = (itemId) => {
    setState({ ...state, error: null, isLoading: true })

    ApiService
      .fetch(companyAPI.removeRecruiter(itemId))
      .then(data => {
        setState({ error: null, isLoading: false, isEditing: false, editingItemId: null })

        toast.success('Usunięto!', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        })

        dispatch(myCompanyActions.fetchMyCompanySuccess(data))
      })
      .catch(error => {
        console.log(error)
        console.log(error.details)
        setState({ error: translate('pl', error.details.msg), isLoading: false, isEditing: false, editingItemId: null })
      })
  }

  const onEdit = (setValues) => (itemId) => {
    setState({ ...state, isEditing: true, editingItemId: itemId })
    const editingItem = company.recruiters.filter((recruiter) => recruiter._id === itemId)[0]

    if (editingItem.availability) {
      editingItem.availability1 = editingItem.availability.split('/')[0] || ''
      editingItem.availability2 = editingItem.availability.split('/')[1] || ''
    } else {
      editingItem.availability1 = ''
      editingItem.availability2 = ''
    }

    setValues(_.omit(editingItem, ['_id', 'goldenline']))
  }

  const onReset = (handleReset) => () => {
    setState({ ...state, isEditing: false, editingItemId: null })
    handleReset()
  }

  const mapItemToDisplay = (items) => items.map((item) => ({
    _id: item._id,
    description: item.position,
    isEditing: state.editingItemId === item._id,
    title: `${item.firstName} ${item.lastName}`
  }))

  return (
    <Formik
      initialValues={{ firstName: '', lastName: '', position: '', email: '', linkedin: '', avatarUrl: '', availability1: '', availability2: '' }}
      validationSchema={Schema}
      onSubmit={onSubmit}
      render={({
        errors,
        handleBlur,
        handleChange,
        handleReset,
        handleSubmit,
        isSubmitting,
        setFieldTouched,
        setFieldValue,
        setValues,
        touched,
        values
      }) => (
        <form onSubmit={handleSubmit}>
          <AddRecruiterForm
            apiError={state.error}
            errors={errors}
            handleBlur={handleBlur}
            handleChange={handleChange}
            isLoading={state.isLoading}
            isEditing={state.isEditing}
            handleDelete={onDelete}
            handleEdit={onEdit(setValues)}
            handleField={(fieldName, value) => handleField(fieldName, value, { setFieldTouched, setFieldValue })}
            items={mapItemToDisplay(company.recruiters)}
            touched={touched}
            values={values}
          />

          <ResetAndSaveButtons
            data-test='ResetAndSaveButtonsOne'
            onClickReset={onReset(handleReset)}
            disabledSave={isSubmitting}
            isEditing={state.isEditing}
            isLoadingSave={state.isLoading}
            isSaveSubmitting
          />
        </form>
      )}
    />
  )
}

const Schema = Yup.object().shape({
  firstName: Yup.string()
    .required('Pole jest wymagane'),
  lastName: Yup.string()
    .required('Pole jest wymagane'),
  position: Yup.string()
    .required('Pole jest wymagane'),
  availability1: Yup.string().matches(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]-(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/, 'Format jest nieprawidłowy'),
  availability2: Yup.string().matches(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]-(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/, 'Format jest nieprawidłowy'),
  email: Yup.string()
    .email('Błędny Email'),
  linkedin: Yup.string()
    .matches(/linkedin/, 'Link powinien prowadzić do serwisu LinkedIn')
    .url('Podany adres musi być poprawnym linkiem')
})

export default AddRecruiterFormContainer
