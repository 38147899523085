import types from './types'
import { createReducer } from '../../utils'

const initialState = {
  data: [],
  error: null,
  isLoading: false
}

const tagsReducer = createReducer(initialState)({
  [types.FETCH_ERROR]: (state, action) => ({ data: [], isLoading: false, error: action.payload }),
  [types.FETCH_REQUESTED]: () => ({ data: [], isLoading: true, error: null }),
  [types.FETCH_SUCCESS]: (state, action) => {
    const data = action.payload.map((tag) => ({
      label: tag.name,
      _id: tag._id,
      isActive: false
    }))

    return { data, isLoading: false, error: null }
  },

  [types.TOGGLE]: (state, action) => ({
    ...state,
    data: state.data.map((item) => {
      if (item._id !== action.payload.key) {
        return item
      }

      return {
        ...item,
        isActive: !item.isActive
      }
    })
  })
})

export default tagsReducer
