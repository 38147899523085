import styled from 'styled-components'

import { PurpleColor } from '../../../assets/styles/colors'

export const StepsContainer = styled.div`
  @media (min-width: 320px) and (max-width: 480px) {
    margin: 0 -10px;
  }
`

export const StepsContent = styled.div`
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  overflow-x: hidden;
`

export const StepsTitle = styled.div`
  color: ${PurpleColor};
  font-weight: 600;

  @media (min-width: 1200px) {
    font-size: 44px;
    line-height: 60px;
    margin: 0 0 100px;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 30px;
    line-height: 40px;
    margin: 0 0 80px;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 28px;
    line-height: 40px;
    margin: 0 0 50px;
  }

  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 26px;
    line-height: 36px;
    margin: 0 0 40px;
  }

  @media (min-width: 320px) and (max-width: 575px) {
    font-size: 24px;
    line-height: 30px;
    margin: 0 0 30px;
  }
`
