import styled from 'styled-components'

import { LabelContainer } from '../Form/FormBasicElements/Label/components'

import { PurpleColorL } from '../../assets/styles/colors'

export const InfoBoxContent = styled.div`
  ${LabelContainer} {
    text-align: center;
  }
`

export const InfoBoxDate = styled.div`
  color: ${PurpleColorL};

  @media (min-width: 768px) {
    line-height: 32px;
    font-size: 24px;
  }

  @media (min-width: 576px) and (max-width: 767px) {
    line-height: 28px;
    font-size: 20px;
  }

  @media (min-width: 320px) and (max-width: 575px) {
    line-height: 26px;
    font-size: 18px;
  }
`

export const InfoBoxRemainedContainer = styled.div`
  @media (min-width: 576px) {
    margin: 30px 0 0;
  }

  @media (min-width: 320px) and (max-width: 575px) {
    margin: 20px 0 0;
  }
`

export const InfoBoxButtonContainer = styled.div`
  @media (min-width: 1200px) {
    margin: 50px auto 0;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    margin: 40px auto 0;
  }

  @media (min-width: 576px) and (max-width: 991px) {
    margin: 30px auto 0;
  }

  @media (min-width: 320px) and (max-width: 575px) {
    margin: 20px auto 0;
  }
`
