import styled from 'styled-components'

import {
  PurpleColorM,
  SilverColorXS
} from '../../../../../assets/styles/colors'

export const TrainingItemHeaderTimeBox = styled.div`
  display: inline-grid;
  grid-column-gap: 3px;
  align-items: center;
  justify-content: center;
  background-color: ${SilverColorXS};
  font-weight: 600;
  color: ${PurpleColorM};
  border-radius: 10px;

  @media (min-width: 992px) {
    line-height: 25px;
    font-size: 16px;
  }

  @media (min-width: 576px) and (max-width: 991px) {
    line-height: 14px;
    font-size: 14px;
  }

  @media (min-width: 768px) {
    grid-auto-flow: column;
    padding: 20px 0;
  }

  @media (min-width: 576px) and (max-width: 767px) {
    padding: 10px 0;
  }

  @media (min-width: 320px) and (max-width: 575px) {
    display: none;
  }
`

export const TrainingItemHeaderTimeBoxTime = styled.span``
