import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { trainingsActions, trainingsSelectors } from '../../../store/ducks/trainings'

import Trainings from '../../../components/Trainings'

const TrainingsContainer = ({ history, dates }) => {
  const dispatch = useDispatch()
  const trainings = useSelector(trainingsSelectors.getTrainings)

  return (
    <Trainings
      // dedicatedTraining
      // dedicatedTrainingText='adsada'
      dates={dates}
      itemsList={trainings}
      onClick={(key) => dispatch(trainingsActions.toggleDescription(key))}
      onClickSign={() => { history.push('/platforma/moje-szkolenia') }}
      withHeader
    />
  )
}

export default withRouter(TrainingsContainer)
