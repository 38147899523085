import styled from 'styled-components'

import {
  PurpleColorM,
  WhiteColor
} from '../../../assets/styles/colors'

import { ImageComponentContainer } from '../../Form/FormBasicElements/Image/components'

export const ZoneRightContainer = styled.div`
  position: relative;
  height: 100%;

  @media (min-width: 320px) and (max-width: 1199px) {
    display: flex;
    flex-direction: column-reverse;
  }
`

export const ZoneImageContainer = styled.div`
  width: 100%;

  @media (min-width: 1200px) {
    padding: 20px 0 35px 50px;
  }

  @media (min-width: 768px) and (max-width: 1199px) {
    margin: 30px 0 0;
  }

  @media (min-width: 576px) and (max-width: 767px) {
    margin: 25px 0 0;
  }

  @media (min-width: 320px) and (max-width: 575px) {
    margin: 20px 0 0;
  }
`

export const ZoneImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 60px;
  overflow: hidden;

  ${ImageComponentContainer} {
    padding: 0;
  }
`

export const ZoneDescription = styled.div`
  padding: 35px 45px;
  background-color: ${WhiteColor};
  color: ${PurpleColorM};
  border-radius: 20px;
  box-shadow: 0 10px 100px 0 rgba(61,50,112,0.13);

  @media (min-width: 1200px) {
    position: absolute;
    left: 0;
    bottom: 0;
    max-width: 570px;
    line-height: 40px;
    font-size: 20px;
    text-align: left;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    padding: 25px 35px;
    line-height: 36px;
    font-size: 18px;
    text-align: center;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    padding: 20px 30px;
    line-height: 28px;
    font-size: 16px;
    text-align: center;
  }

  @media (min-width: 576px) and (max-width: 767px) {
    padding: 15px 20px;
    line-height: 24px;
    font-size: 16px;
    text-align: center;
  }

  @media (min-width: 320px) and (max-width: 575px) {
    padding: 10px 15px;
    line-height: 21px;
    font-size: 14px;
    text-align: center;
  }
`
