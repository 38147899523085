import types from './types'

const fetchArticlesError = (error) => ({
  type: types.FETCH_ERROR,
  payload: error
})

const fetchArticlesRequested = () => ({
  type: types.FETCH_REQUESTED
})

const fetchArticlesSuccess = (data) => ({
  type: types.FETCH_SUCCESS,
  payload: data
})

const setArticlesFilter = (filterText) => ({
  type: types.SET_FILTER,
  payload: { filterText }
})

export default {
  fetchArticlesError,
  fetchArticlesRequested,
  fetchArticlesSuccess,
  setArticlesFilter
}
