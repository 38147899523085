import React from 'react'
import _ from 'lodash'

import CompanyItem from '../../../../components/Companies/CompanyItem'
import { useSelector } from 'react-redux'
import { companiesSelectors } from '../../../../store/ducks/companies'
import translationsList from '../../../../stabs/translationsList'

const SimiliarCompaniesContainer = ({ slug }) => {
  const companies = useSelector(companiesSelectors.getCompaniesContent)

  const [selectedCompany] = companies.filter((company) => company.slug === slug)

  let filteredCompanies = []

  if (selectedCompany !== undefined) {
    filteredCompanies = _.shuffle(companies
    .filter((company) => company._id !== selectedCompany._id))
  }

  return (
    <>
      {filteredCompanies.map((company, id) => (
        <CompanyItem
          buttonLabel={translationsList.companyPanel.moreButton}
          image={company.logoUrl}
          key={id}
          link={`/pracodawcy/${company.slug}`}
          name={company.name}
          withSize
        />
      ))}
    </>
  )
}

export default SimiliarCompaniesContainer
