import React from 'react'

import SpacingLine from '../../../components/SpacingLine'
import Subtitle from '../../../components/Subtitle'
import TextComponent from '../../../components/Form/FormBasicElements/TextComponent'

import {
  FormCompanyStepFiveButtonContainer,
  FormCompanyStepFiveTextComponentContainer
} from './components'
import ArticleGuidlinesFormContainer from '../../../containers/PlatformContainer/CompanyStepsForms/ArticleGuidlinesFormContainer'
import PublishCompanyButtonContainer from '../../../containers/PlatformContainer/CompanyStepsForms/PublishCompanyButtonContainer'
import AddArticleFormContainer from '../../../containers/PlatformContainer/CompanyStepsForms/AddArticleFormContainer'
import ShowArticlesContainer from '../../../containers/PlatformContainer/CompanyStepsForms/ShowArticlesContainer'

const FormCompanyStepFive = () => (
  <>
    <Subtitle
      data-test='subtitleOne'
      icon='education'
      label='Wytyczne artykułu eksperckiego'
    />

    <ArticleGuidlinesFormContainer />

    <SpacingLine
      data-test='spacingLineOne'
      isFixed
    />

     <ShowArticlesContainer />

    <Subtitle
      data-test='subtitleFour'
      icon='education'
      label='Dodaj artykuł ekspercki i jego autora'
    />

    <AddArticleFormContainer />

    <FormCompanyStepFiveButtonContainer>
      <PublishCompanyButtonContainer />
    </FormCompanyStepFiveButtonContainer>

    <FormCompanyStepFiveTextComponentContainer>
      <TextComponent
        align='center'
        text='Uwaga! Zanim profil Pracodawcy zostanie upubliczniony, musi zostać zaakceptowany przez administrację. Obiecujemy, to nie potrwa zbyt długo.'
      />
    </FormCompanyStepFiveTextComponentContainer>
  </>
)

export default FormCompanyStepFive

// TODO Add Translations
