import React from 'react'
import PropTypes from 'prop-types'

import Label from '../../Form/FormBasicElements/Label'
import TrainingItemHeader from './TrainingItemHeader'
import TrainingItemLeft from './TrainingItemLeft'
import TrainingItemSpeaker from './TrainingItemSpeaker'

import {
  TrainingItemContainer,
  TrainingItemContent,
  TrainingItemRightContent
} from './components'

import translationsList from '../../../stabs/translationsList'

const TrainingItem = ({
  dedicatedTraining,
  dedicatedTrainingText,
  isEditable,
  isOpen,
  onClick,
  onClickSign,
  onClickUnsign,
  trainingItemElement
}) => (
  <>
    {
      trainingItemElement && (
        <TrainingItemContainer data-test='TrainingItemContainer'>
          <TrainingItemHeader
            data-test='TrainingItemHeader'
            dedicatedTraining={dedicatedTraining}
            dedicatedTrainingText={dedicatedTrainingText}
            enrolled={trainingItemElement.enrolled}
            isEditable={isEditable}
            isEnrolled={trainingItemElement.isEnrolled}
            isOpen={isOpen}
            limit={trainingItemElement.limit}
            onClick={() => onClick(trainingItemElement._id)}
            onClickSign={() => onClickSign(trainingItemElement._id)}
            onClickUnsign={() => onClickUnsign(trainingItemElement._id)}
            price={trainingItemElement.price}
            speakersList={trainingItemElement.speakers}
            timeEnd={trainingItemElement.timeEnd}
            timeStart={trainingItemElement.timeStart}
            title={trainingItemElement.title}
          />

          {
            isOpen && (
              <TrainingItemContent data-test='TrainingItemContent'>
                <TrainingItemLeft
                  data-test='TrainingItemLeft'
                  description={trainingItemElement.description}
                  enrolled={trainingItemElement.enrolled}
                  isEnrolled={trainingItemElement.isEnrolled}
                  limit={trainingItemElement.limit}
                  onClickSign={() => onClickSign(trainingItemElement._id)}
                  onClickUnsign={() => onClickUnsign(trainingItemElement._id)}
                  place={trainingItemElement.place}
                  tagsList={trainingItemElement.tags}
                  timeEnd={trainingItemElement.timeEnd}
                  timeStart={trainingItemElement.timeStart}
                />

                <TrainingItemRightContent data-test='TrainingItemRightContent'>
                  {
                    trainingItemElement.speakers.length > 0 && ( // TODO Test me !
                      <Label
                        data-test='TrainingItemLabel'
                        label={translationsList.trainings.labels.trainingsSpeakersFieldLabel}
                      />
                    )
                  }

                  {
                    trainingItemElement.speakers.map((item, id) => (
                      <TrainingItemSpeaker
                        avatar={item.avatarUrl}
                        company={item.company}
                        data-test='TrainingItemSpeaker'
                        key={id}
                        name={item.name}
                        position={item.position}
                      />
                    ))
                  }
                </TrainingItemRightContent>
              </TrainingItemContent>
            )
          }
        </TrainingItemContainer>
      )
    }
  </>
)

export default TrainingItem

TrainingItem.propTypes = {
  dedicatedTraining: PropTypes.bool,
  dedicatedTrainingText: PropTypes.string,
  isEditable: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClick: PropTypes.func,
  trainingItemElement: PropTypes.shape({
    _id: PropTypes.string,
    description: PropTypes.string,
    place: PropTypes.string,
    price: PropTypes.string,
    speakersList: PropTypes.arrayOf(
      PropTypes.shape({
        avatar: PropTypes.string,
        company: PropTypes.string,
        name: PropTypes.string,
        position: PropTypes.string
      })
    ),
    tags: PropTypes.arrayOf(
      PropTypes.shape({
        isSelected: PropTypes.bool,
        label: PropTypes.string
      })
    ),
    timeEnd: PropTypes.string,
    timeStart: PropTypes.string,
    title: PropTypes.string
  })
}
