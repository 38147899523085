import styled from 'styled-components'

import {
  PurpleColor
} from '../../../../assets/styles/colors'

export const JobHeaderContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;

  @media (max-width: 767px) {
    flex-direction: column;
  }
`

export const JobHeaderLeftContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 767px) {
    align-items: center;
  }
`

export const JobHeaderTitle = styled.h2`
  color: ${PurpleColor};
  font-weight: bold;
`

export const JobHeaderRightContainer = styled.div`
  @media (min-width: 992px) {
    margin: 0 0 0 30px;
  }
  
  @media (min-width: 768px) and (max-width: 991px) {
    margin: 0 0 0 25px;
  }

  @media (max-width: 767px) {
    margin: 25px 0 0;
  }
`
