import styled from 'styled-components'

import { PurpleColorM } from '../../../../assets/styles/colors'

export const TextComponentContainer = styled.div`
  line-height: 24px;
  font-size: 14px;
  color: ${PurpleColorM};

  ${props => {
    switch (props.align) {
      case 'center': return 'text-align: center;'
      default: return `
        @media (min-width: 768px) {
          text-align: left;
        }

        @media (min-width: 320px) and (max-width: 767px) {
          text-align: center;
        }
      `
    }
  }}
`
