import React from 'react'
import PropTypes from 'prop-types'

import Icon from '../../../Form/FormBasicElements/Icon'

import {
  IconAndTextElementsContainer,
  IconAndTextElementsContent,
  IconAndTextElementsIconContainer,
  IconAndTextElementsLabel
} from './Atoms'

const IconAndTextElements = ({
  itemsList
}) => (
  <IconAndTextElementsContainer data-test='IconAndTextElementsContainer'>
    {
      itemsList.map((item, index) =>
        <IconAndTextElementsContent
          data-test='IconAndTextElementsContent'
          key={index}
        >
          {
            item.icon && (
              <IconAndTextElementsIconContainer data-test='IconAndTextElementsIconContainer'>
                <Icon
                  data-test='IconAndTextElementsIcon'
                  icon={item.icon}
                />
              </IconAndTextElementsIconContainer>
            )
          }

          <IconAndTextElementsLabel
            data-test='IconAndTextElementsLabel'
            href={item.link}
          >
            {item.label}
          </IconAndTextElementsLabel>
        </IconAndTextElementsContent>
      )
    }
  </IconAndTextElementsContainer>
)
export default IconAndTextElements

IconAndTextElements.propTypes = {
  itemsList: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      label: PropTypes.string,
      link: PropTypes.string
    })
  )
}

IconAndTextElements.defaultProps = {
  itemsList: []
}
