import React from 'react'
import PropTypes from 'prop-types'
import Scrollbars from 'react-custom-scrollbars'

import {
  DropdownButtonContainer,
  DropdownContainer,
  DropdownMenuContainer
} from './Atoms'

import {
  AUTO_HIDE,
  MAX_HEIGHT
} from './consts'

class Dropdown extends React.Component {
  constructor (props) {
    super(props)

    this.node = React.createRef()

    this.state = {
      isMenuOpen: false
    }
  }

  hideMenu = () => {
    this.setState({
      isMenuOpen: false
    })
  }

  showMenu = () => {
    this.setState({
      isMenuOpen: true
    })
  }

  toggleMenu = () => {
    this.setState(state => ({
      isMenuOpen: !state.isMenuOpen
    }))
  }

  render () {
    const DropdownButton = this.props.Button
    const DropdownMenuContent = this.props.MenuContent
    const {
      buttonProps,
      containerMinWidth,
      containerWidth,
      disabled,
      menuContentBottom,
      menuContentLeft,
      menuContentProps,
      menuContentRight
    } = this.props

    return (
      <DropdownContainer
        containerMinWidth={containerMinWidth}
        containerWidth={containerWidth}
        data-test='DropdownContainer'
        ref={this.node}
      >
        <DropdownButtonContainer
          data-test='DropdownButtonContainer'
          onClick={() => this.toggleMenu()}
        >
          <DropdownButton
            data-test='DropdownButton'
            isMenuOpen={this.state.isMenuOpen}
            {...buttonProps}
          />
        </DropdownButtonContainer>

        {
          !disabled && this.state.isMenuOpen && (
            <DropdownMenuContainer
              data-test='DropdownMenuContainer'
              menuContentBottom={menuContentBottom}
              menuContentLeft={menuContentLeft}
              menuContentRight={menuContentRight}
            >
              <Scrollbars
                autoHeight
                autoHeightMax={MAX_HEIGHT}
                autoHide
                autoHideDuration={AUTO_HIDE}
                data-test='Scrollbars'
              >
                <DropdownMenuContent
                  data-test='DropdownMenuContent'
                  hideMenu={this.hideMenu}
                  showMenu={this.showMenu}
                  toggleMenu={this.toggleMenu}
                  {...menuContentProps}
                />
              </Scrollbars>
            </DropdownMenuContainer>
          )
        }
      </DropdownContainer>
    )
  }
}

export default Dropdown

Dropdown.propTypes = {
  Button: PropTypes.func.isRequired,
  MenuContent: PropTypes.func.isRequired,
  buttonProps: PropTypes.object,
  containerMinWidth: PropTypes.string,
  containerWidth: PropTypes.string,
  disabled: PropTypes.bool,
  menuContentBottom: PropTypes.string,
  menuContentLeft: PropTypes.string,
  menuContentProps: PropTypes.object,
  menuContentRight: PropTypes.string
}

Dropdown.defaultProps = {
  disabled: false,
  containerWidth: undefined,
  containerMinWidth: undefined,
  menuContentBottom: undefined,
  menuContentLeft: undefined,
  menuContentRight: undefined
}
