import styled from 'styled-components'

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-items: center;
  width: 100%;
  height: 100%;

  & > span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  & svg {
    width: 100%;
  }
`
