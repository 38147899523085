import styled from 'styled-components'

import {
  PinkColor,
  PurpleColorM,
  WhiteColor
} from '../../../assets/styles/colors'

export const ZoneMenuItemContainer = styled.div`
  display: grid;
  align-items: center;
  width: 100%;
  border-radius: 20px;

  ${props => {
    switch (props.isActive) {
      case true: return `
        background-color: ${PinkColor};
        color: ${WhiteColor};
      `
      default: return `
        background-color: ${WhiteColor};
        color: ${PurpleColorM};
      `
    }
  }}
  
  @media (min-width: 1600px) {
    grid-template-columns: 40px 1fr;
    grid-column-gap: 30px;
    padding: 35px 55px;
  } 

  @media (min-width: 1200px) and (max-width: 1599px) {
    grid-template-columns: 36px 1fr;
    grid-column-gap: 20px;
    padding: 30px 40px;
  }

  @media (min-width: 768px) and (max-width: 1199px) {
    grid-template-columns: 30px 1fr;
    grid-column-gap: 15px;
    padding: 10px 15px;
  }

  @media (min-width: 576px) and (max-width: 767px) {
    grid-template-columns: 26px 1fr;
    grid-column-gap: 10px;
    padding: 10px;
  }

  @media (min-width: 320px) and (max-width: 575px) {
    grid-template-columns: 22px 1fr;
    grid-column-gap: 8px;
    padding: 8px;
  }
`

export const ZoneMenuItemIcon = styled.div``

export const ZoneMenuItemText = styled.div`
  text-align: left;

  @media (min-width: 1600px) {
    line-height: 30px;
    font-size: 20px;
  } 

  @media (min-width: 1200px) and (max-width: 1599px) {
    line-height: 28px;
    font-size: 18px;
  }

  @media (min-width: 320px) and (max-width: 1199px) {
    line-height: 26px;
    font-size: 16px;
  }
`
