const FETCH_ERROR = 'tags/FETCH_ERROR'
const FETCH_REQUESTED = 'tags/FETCH_REQUESTED'
const FETCH_SUCCESS = 'tags/FETCH_SUCCESS'

const TOGGLE = 'tags/TOGGLE'

export default {
  FETCH_ERROR,
  FETCH_REQUESTED,
  FETCH_SUCCESS,
  TOGGLE
}
