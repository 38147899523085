import React from 'react'
import PropTypes from 'prop-types'

import {
  Label,
  LabelH1,
  LabelH2,
  Underline
} from './components'

const Title = ({
  alignCenter,
  id,
  label,
  level
}) => (
  <>
    {label &&
      <>
        {level === 'default' && (
          <Label
            alignCenter={alignCenter}
            id={id}
          >
            {label}
          </Label>
        )}

        {level === 'h1' && (
          <LabelH1
            alignCenter={alignCenter}
            id={id}
          >
            {label}
          </LabelH1>
        )}

        {level === 'h2' && (
          <LabelH2
            alignCenter={alignCenter}
            id={id}
          >
            {label}
          </LabelH2>
        )}

        <Underline alignCenter={alignCenter} />
      </>}
  </>
)

export default Title

Title.propTypes = {
  alignCenter: PropTypes.bool,
  id: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  label: PropTypes.string,
  level: PropTypes.oneOf(['default', 'h1', 'h2'])
}

Title.defaultProps = {
  id: 0,
  level: 'default'
}
