import styled from 'styled-components'

import { WhiteColor } from '../../../../../assets/styles/colors'

export const RightSlideElementContainer = styled.li`
  position: relative;
`

export const RightSlideElementContent = styled.div`
  color: ${WhiteColor};
  position: absolute;
  text-align: left;

  @media (min-width: 992px) {
    bottom: 100px;
    left: 50px;
    right: 50px;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    left: 30px;
    right: 30px;
    top: 80px;
  }

  @media (min-width: 320px) and (max-width: 767px) {
    left: 20px;
    right: 20px;
    top: 70px;
  }
`

export const RightSlideElementTitle = styled.div`
  font-weight: 600;

  @media (min-width: 992px) {
    font-size: 36px;
    line-height: 42px;
  }

  @media (min-width: 320px) and (max-width: 991px) {
    font-size: 32px;
    line-height: 36px;
  }
`

export const RightSlideElementText = styled.div`
  font-size: 16px;
  line-height: 27px;
  padding: 20px 0 0;
`
