import React from 'react'
import PropTypes from 'prop-types'

import Icon from '../../../Form/FormBasicElements/Icon'
import Image from '../../../Form/FormBasicElements/Image'
import InternalOrExternal from '../../../Buttons/InternalOrExternal'

import {
  VideoItemButtonPlayContainer,
  VideoItemContainer,
  VideoItemContent,
  VideoItemTitle
} from './components'

import buttonPlayIconPath from '../../../../assets/icons/play.svg'

const VideoItem = ({
  onClick,
  thumbnailUrl,
  url,
  title
}) => {

let thumb = thumbnailUrl.replace('maxresdefault', '0')

return (
  <InternalOrExternal type='external' inNewWindow link={url}>
    <VideoItemContainer>
      {thumbnailUrl &&
        <VideoItemContent bgImg={thumb}>


          <VideoItemButtonPlayContainer>
            <Icon icon={buttonPlayIconPath} />
          </VideoItemButtonPlayContainer>
        </VideoItemContent>}

      {title &&
        <VideoItemTitle>{title}</VideoItemTitle>}
    </VideoItemContainer>
  </InternalOrExternal>
)}

export default VideoItem

VideoItem.propTypes = {
  onClick: PropTypes.func,
  thumbnailUrl: PropTypes.string,
  title: PropTypes.string
}
