import React from 'react'
import PropTypes from 'prop-types'

import Icon from '../../../Form/FormBasicElements/Icon'
import IconText from '../IconText'

import {
  AwardItemContainer,
  AwardItemContent,
  AwardItemDateAndPlaceContainer,
  AwardItemDateContainer,
  AwardItemDescription,
  AwardItemIconContainer,
  AwardItemName,
  AwardItemNameAndDecriptionContainer,
  AwardItemPlaceContainer
} from './components'

import awardIcon from '../../../../assets/icons/award.svg'
import calendarIconPath from '../../../../assets/icons/calendar.svg'
import locationIconPath from '../../../../assets/icons/subtitle/subtitle_pin.svg'

const AwardItem = ({
  contestName,
  date,
  name,
  place
}) => (
  <AwardItemContainer>
    <AwardItemIconContainer>
      <Icon
        data-test='AwardIcon'
        icon={awardIcon}
      />
    </AwardItemIconContainer>

    <AwardItemContent>
      <AwardItemNameAndDecriptionContainer>
        <AwardItemName>{name}</AwardItemName>

        <AwardItemDescription>{contestName}</AwardItemDescription>
      </AwardItemNameAndDecriptionContainer>

      <AwardItemDateAndPlaceContainer>
        <AwardItemDateContainer>
          <IconText
            data-test='Date'
            icon={calendarIconPath}
            text={date}
          />
        </AwardItemDateContainer>

        <AwardItemPlaceContainer>
          <IconText
            data-test='Place'
            icon={locationIconPath}
            text={place}
          />
        </AwardItemPlaceContainer>
      </AwardItemDateAndPlaceContainer>
    </AwardItemContent>
  </AwardItemContainer>
)

export default AwardItem

AwardItem.propTypes = {
  date: PropTypes.string,
  description: PropTypes.string,
  name: PropTypes.string,
  place: PropTypes.string
}

AwardItem.defaultProps = {
  date: '',
  description: '',
  name: '',
  place: ''
}
