import React from 'react'
import PropTypes from 'prop-types'

import Icon from '../../../Form/FormBasicElements/Icon'
import {
  JobDateComponent,
  JobDateContainer,
  JobDateContent,
  JobDateIconContainer,
  JobDatePrefix
} from './components'

import calendarIconPath from '../../../../assets/icons/calendar.svg'

const JobDate = ({
  date,
  prefix
}) => (
  <>
    {
      date && (
        <JobDateContainer data-test='jobDateContainer'>
          <JobDateIconContainer>
            <Icon icon={calendarIconPath} />
          </JobDateIconContainer>

          <JobDateContent>
            <JobDatePrefix data-test='jobPrefix'>{prefix}</JobDatePrefix>

            <JobDateComponent data-test='jobDate'>{date}</JobDateComponent>
          </JobDateContent>
        </JobDateContainer>
      )
    }
  </>
)

export default JobDate

JobDate.propTypes = {
  date: PropTypes.string,
  prefix: PropTypes.string
}

JobDate.defaultProps = {
  prefix: 'Dodano:'
}
