import React from 'react'
import PropTypes from 'prop-types'

import Icon from '../../../Form/FormBasicElements/Icon'

import {
  IconTextContainer,
  IconTextIconContainer,
  IconTextTextContainer
} from './components'

const IconText = ({
  icon,
  text
}) => (
  <IconTextContainer>
    <IconTextIconContainer>
      <Icon icon={icon} />
    </IconTextIconContainer>

    <IconTextTextContainer>{text}</IconTextTextContainer>
  </IconTextContainer>
)

export default IconText

IconText.propTypes = {
  icon: PropTypes.string,
  text: PropTypes.string
}

IconText.defaultProps = {
  icon: '',
  text: ''
}
