import React from 'react'

import FormPanel from '../../FormWrappers/FormPanel'
import FormTwoColumns from '../../FormWrappers/FormTwoColumns'
import InputField from '../../FormFields/InputField'
import RequiredFieldsText from '../../FormBasicElements/RequiredFieldsText'
import SpacingLine from '../../../SpacingLine'

import {
  AddBasicCompanyDataFormChooseFileFieldsContainer
} from './components'
import CompanyVectorLogoUploadContainer from '../../../../containers/ComponentContainers/UploadContainers/CompanyVectorLogoUploadContainer'
import CompanyBannerUploadContainer from '../../../../containers/ComponentContainers/UploadContainers/CompanyBannerUploadContainer'
import CompanyLogoUploadContainer from '../../../../containers/ComponentContainers/UploadContainers/CompanyLogoUploadContainer'

const AddBasicCompanyDataForm = ({
  apiError,
  errors,
  handleBlur,
  handleChange,
  handleField,
  touched,
  values
}) => (
  <FormPanel
    errors={apiError}
    noMargin
  >
    <FormTwoColumns>
      <InputField
        data-test='CompanyName'
        errorMessage={errors.name}
        hasError={errors.name && touched.name}
        id='companyName'
        label='Nazwa firmy'
        name='name'
        onBlur={handleBlur}
        onChange={handleChange}
        placeholder='Wpisz nazwę firmy'
        required
        value={values.name}
      />

      <InputField
        data-test='CompanyWebsite'
        errorMessage={errors.website}
        hasError={errors.website && touched.website}
        id='companyWebsite'
        label='Strona www'
        name='website'
        onBlur={handleBlur}
        onChange={handleChange}
        placeholder='Wpisz stronę www w formacie: „https://www.przyklad.pl”'
        required
        value={values.website}
        type='url'
      />

      <CompanyLogoUploadContainer
        error={errors.logoUrl}
        touched={touched.logoUrl}
        value={values.logoUrl}
        handleField={handleField}
      />

      <AddBasicCompanyDataFormChooseFileFieldsContainer>
        <CompanyVectorLogoUploadContainer
          error={errors.vectorLogoUrl}
          touched={touched.vectorLogoUrl}
          value={values.vectorLogoUrl}
          handleField={handleField}
        />

        <CompanyBannerUploadContainer
          error={errors.bannerUrl}
          touched={touched.bannerUrl}
          value={values.bannerUrl}
          handleField={handleField}
        />
      </AddBasicCompanyDataFormChooseFileFieldsContainer>

    </FormTwoColumns>

    <SpacingLine
      isFixed
      option='SizeS'
    />

    <FormTwoColumns>
      <InputField
        data-test='CompanyWebsite'
        errorMessage={errors.facebook}
        hasError={errors.facebook && touched.facebook}
        id='facebookUrl'
        label='Facebook'
        name='facebook'
        onBlur={handleBlur}
        onChange={handleChange}
        placeholder='Wpisz stronę www'
        value={values.facebook}
        type='url'
      />

      <InputField
        data-test='CompanyWebsite'
        errorMessage={errors.instagram}
        hasError={errors.instagram && touched.instagram}
        id='instagramUrl'
        label='Instagram'
        name='instagram'
        onBlur={handleBlur}
        onChange={handleChange}
        placeholder='Wpisz stronę www'
        value={values.instagram}
        type='url'
      />

      <InputField
        data-test='CompanyWebsite'
        errorMessage={errors.youtube}
        hasError={errors.youtube && touched.youtube}
        id='youtubeUrl'
        label='YouTube'
        name='youtube'
        onBlur={handleBlur}
        onChange={handleChange}
        placeholder='Wpisz stronę www'
        value={values.youtube}
        type='url'
      />

      <InputField
        data-test='CompanyWebsite'
        errorMessage={errors.linkedin}
        hasError={errors.linkedin && touched.linkedin}
        id='linkedinUrl'
        label='LinkedIn'
        name='linkedin'
        onBlur={handleBlur}
        onChange={handleChange}
        placeholder='Wpisz stronę www'
        value={values.linkedin}
        type='url'
      />

      <InputField
        data-test='CompanyWebsite'
        errorMessage={errors.goldenline}
        hasError={errors.goldenline && touched.goldenline}
        id='goldenlineUrl'
        label='Goldenline'
        name='goldenline'
        onBlur={handleBlur}
        onChange={handleChange}
        placeholder='Wpisz stronę www'
        value={values.goldenline}
        type='url'
      />
    </FormTwoColumns>
    <RequiredFieldsText />
  </FormPanel>
)

export default AddBasicCompanyDataForm

// TODO Fix translations
