import React from 'react'
import PropTypes from 'prop-types'

import Icon from '../Form/FormBasicElements/Icon'

import {
  CardDescription,
  CardLeft,
  CardRight,
  CardTitle,
  CardWrapper
} from './components'

const Card = ({
  description,
  icon,
  title
}) => (
  <CardWrapper data-test='CardWrapper'>
    <CardLeft data-test='CardLeft'>
      <Icon icon={icon} data-test='CardIcon' />
    </CardLeft>

    <CardRight data-test='CardRight'>
      <CardTitle data-test='CardTitle'>
        {title}
      </CardTitle>

      <CardDescription data-test='CardDescription'>
        {description}
      </CardDescription>
    </CardRight>
  </CardWrapper>
)

export default Card

Card.propTypes = {
  description: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string
}
