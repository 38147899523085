import _ from 'lodash'
import types from './types'
import { createReducer } from '../../utils'

const initialState = {
  data: {
    _id: '',
    avatar: '',
    birthday: '',
    city: '',
    cityOfLiving: '',
    companyId: null,
    country: '',
    cv: [],
    drivingLicense: '',
    email: '',
    favouriteCompanies: [],
    firstName: '',
    importantValues: [],
    lastName: '',
    linkedinUrl: '',
    marketingApproval: true,
    nationality: '',
    phone: '',
    placeOfBirth: '',
    position: '',
    status: '',
    step: '',
    subscriptionAllowTrial: true,
    subscriptionCustomerId: null,
    subscriptionId: null,
    subscriptionIsActive: false,
    subscriptionIsInRenunciationProcess: false,
    subscriptionType: null,
    values: []
  },
  error: null,
  isLoading: false
}

const myUserReducer = createReducer(initialState)({
  [types.FETCH_ERROR]: (state, action) => ({ data: null, isLoading: false, error: action.payload }),
  [types.FETCH_REQUESTED]: () => ({ data: null, isLoading: true, error: null }),
  [types.CLEAN_UP]: () => initialState,
  [types.FETCH_SUCCESS]: (state, action) => {
    const data = _.pick(action.payload, [
      '_id',
      'avatar',
      'birthday',
      'city',
      'cityOfLiving',
      'companyId',
      'country',
      'cv',
      'drivingLicense',
      'email',
      'favouriteCompanies',
      'firstName',
      'importantValues',
      'lastName',
      'linkedinUrl',
      'marketingApproval',
      'nationality',
      'phone',
      'position',
      'status',
      'step',
      'subscriptionAllowTrial',
      'subscriptionCustomerId',
      'subscriptionId',
      'subscriptionIsActive',
      'subscriptionType',
      'subscriptionIsInRenunciationProcess',
      'values'
    ])

    return { data, isLoading: false, error: null }
  },

  [types.TOGGLE_VALUE]: (state, action) => {
    if (action.payload.action === 'addValue') {
      return ({
        ...state,
        data: {
          ...state.data,
          values: [...state.data.values, action.payload.valueId]
        }
      })
    }

    if (action.payload.action === 'removeValue') {
      return ({
        ...state,
        data: {
          ...state.data,
          values: state.data.values.filter((val) => val !== action.payload.valueId),
          importantValues: state.data.importantValues.filter((val) => val !== action.payload.valueId)
        }
      })
    }

    if (action.payload.action === 'setImportant') {
      return ({
        ...state,
        data: {
          ...state.data,
          values: state.data.values.filter((val) => val !== action.payload.valueId),
          importantValues: [...state.data.importantValues, action.payload.valueId]
        }
      })
    }

    if (action.payload.action === 'removeImportant') {
      return ({
        ...state,
        data: {
          ...state.data,
          values: [...state.data.values, action.payload.valueId],
          importantValues: state.data.importantValues.filter((val) => val !== action.payload.valueId)
        }
      })
    }
  }
})

export default myUserReducer
