export default [
  { isActive: false, label: 'Finanse', _id: 'Finanse' },
  { isActive: false, label: 'IT', _id: 'IT' },
  { isActive: false, label: 'Produkcja', _id: 'Produkcja' },
  { isActive: false, label: 'Marketing', _id: 'Marketing' },
  { isActive: false, label: 'FMCG', _id: 'FMCG' },
  { isActive: false, label: 'Customer Service', _id: 'Customer Service' },
  { isActive: false, label: 'Automotive', _id: 'Automotive' },
  { isActive: false, label: 'Administracja', _id: 'Administracja' },
  { isActive: false, label: 'Poszukiwanie Pracy', _id: 'Poszukiwanie Pracy' },
  { isActive: false, label: 'Rekrutacja', _id: 'Rekrutacja' },
  { isActive: false, label: 'Budowanie Kariery', _id: 'Budowanie Kariery' },
  { isActive: false, label: 'Poszukiwanie Pracy', _id: 'Poszukiwanie Pracy' },
  { isActive: false, label: 'Tworzenie CV', _id: 'Tworzenie CV' },
  { isActive: false, label: 'Rozmowa Rekrutacyjna', _id: 'Rozmowa Rekrutacyjna' },
  { isActive: false, label: 'Rozwój Osobisty', _id: 'Rozwój Osobisty' }
]
