import styled from 'styled-components'

import {
  PinkColor,
  PurpleColorM,
  PurpleColorXL,
  WhiteColor
} from '../../assets/styles/colors'

import { FormPanelContent } from '../Form/FormWrappers/FormPanel/components'

export const IndustriesItemListElement = styled.div`
  align-items: center;
  border-radius: 20px;
  display: flex;

  ${props => {
    switch (props.isActive) {
      case true: return `
        background-color: ${PinkColor};
        border: 0 solid transparent;
      `
      default: return `
        background-color: transparent;
        border: 1px solid ${PurpleColorXL};
        opacity: 0.6;
      `
    }
  }}

  @media (min-width: 1200px) {
    flex-direction: column;
    padding: 20px 40px;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    flex-direction: column;
    padding: 15px 30px;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    margin: 0 10px 10px 0;
    padding: 15px 20px;
  }

  @media (min-width: 576px) and (max-width: 767px) {
    margin: 0 8px 8px 0;
    padding: 10px 15px;
  }

  @media (min-width: 320px) and (max-width: 575px) {
    margin: 0 5px 5px 0;
    padding: 8px 10px;
  }
`

export const IndustriesItemIconContainer = styled.div`
  @media (min-width: 1200px) {
    height: 36px;
    margin: 0 0 10px;
    width: 36px;
  }
  
  @media (min-width: 992px) and (max-width: 1199px) {
    height: 34px;
    margin: 0 0 10px;
    width: 34px;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    height: 32px;
    margin: 0 10px 0 0;
    width: 32px;
  }

  @media (min-width: 576px) and (max-width: 767px) {
    height: 30px;
    margin: 0 10px 0 0;
    width: 30px;
  }

  @media (min-width: 320px) and (max-width: 575px) {
    height: 28px;
    margin: 0 10px 0 0;
    width: 28px;
  }
`

export const IndustriesItemText = styled.div`
  white-space: nowrap;

  ${props => {
    switch (props.isActive) {
      case true: return `color: ${WhiteColor};`
      default: return `color: ${PurpleColorM};`
    }
  }}

  @media (min-width: 1200px) {
    font-size: 18px;
    line-height: 27px;
  }

  @media (min-width: 768px) and (max-width: 1199px) {
    font-size: 16px;
    line-height: 21px;
  }

  @media (min-width: 320px) and (max-width: 767px) {
    font-size: 14px;
    line-height: 18px;
  }
`

export const IndustriesDesktop = styled.div`
  @media (min-width: 992px) {
    display: block;
  }

  @media (min-width: 320px) and (max-width: 991px) {
    display: none;
  }
`

export const IndustriesMobile = styled.div`
  @media (min-width: 992px) {
    display: none;
  }

  @media (min-width: 320px) and (max-width: 991px) {
    display: block;
    
    ${FormPanelContent} {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  }
`
