import React from 'react'
import { withRouter } from 'react-router-dom'

import InnerWrapper from '../../Wrappers/InnerWrapper'
import LoginAndRegister from '../../Form/FormComponents/LoginAndRegister'
import Modal from '../../Modal'

const LoginModal = ({ history }) => (
  <Modal
    isFixed
    onClose={() => history.goBack()}
  >
    <InnerWrapper>
      <LoginAndRegister login />
    </InnerWrapper>
  </Modal>
)

export default withRouter(LoginModal)
