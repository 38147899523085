import styled from 'styled-components'

import { PurpleColorM } from '../../../../assets/styles/colors'

export const JobDateContainer = styled.div`
  display: flex;
  justify-content: start;
  margin: 10px 0 0;
`

export const JobDateIconContainer = styled.div`
  margin: 0 6px 0 0;
  width: 14px;
`

export const JobDateContent = styled.div`
  color: ${PurpleColorM};
  display: flex;
  font-size: 14px;
  justify-content: start;
`

export const JobDatePrefix = styled.div`
  margin: 0 6px 0 0;
`

export const JobDateComponent = styled.div``
