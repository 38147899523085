import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'

import * as companyAPI from '../../../../store/api/company'
import ApiService from '../../../../store/services/ApiService'
import { myCompanyActions, myCompanySelectors } from '../../../../store/ducks/my-company'

import Controls from '../../../../components/Controls'
import PlatformCompanyWrapperModal from '../../../../components/Modals/PlatformCompanyWrapperModal'

import translationsList from '../../../../stabs/translationsList'

const PublishCompanyButtonContainer = () => {
  const [showModal, setShowModal] = useState(false)
  const myCompany = useSelector(myCompanySelectors.getMyCompany)
  const dispatch = useDispatch()
  return (
    <>
      {showModal && (
        <PlatformCompanyWrapperModal company={myCompany} isPreview onClose={() => setShowModal(false)} />
      )}
      <Controls
        labelLeft={myCompany.isPublished ? 'Kliknij, aby zmienić status profilu na prywatny.' : 'Kliknij, aby zmienić status profilu na publiczny.'}
        labelRight={translationsList.form.labels.buttonPreview}
        onClickLeft={() => {
          ApiService.fetch(companyAPI.updateMe({ isPublished: !myCompany.isPublished }))
            .then(data => {
              dispatch(myCompanyActions.fetchMyCompanySuccess(data))
            })
            .catch(() => {
              toast.error('Nastąpił błąd podczas publikacji firmy!', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
              })
            })
        }}
        onClickRight={() => {
          setShowModal(true)
        }}
      />
    </>
  )
}

export default PublishCompanyButtonContainer
