import styled from 'styled-components'

import {
  PurpleColor,
  PurpleColorM,
  Transition,
  WhiteColor
} from '../../assets/styles/colors'

export const CardWrapper = styled.div`
  background-color: ${WhiteColor};
  border-radius: 10px;
  box-shadow: 0 5px 15px 0 rgba(217, 209, 255, 0.3);
  cursor: pointer;
  margin: 0 auto 20px;
  max-width: 770px;
  padding: 30px;
  transition: ${Transition};

  &:hover {
    box-shadow: 0 15px 25px 0 rgba(217, 209, 255, 0.46);
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    padding: 25px;
  }

  @media (min-width: 576px) {
    display: flex;
  }

  @media (min-width: 576px) and (max-width: 991px) {
    padding: 20px;
  }

  @media (min-width: 320px) and (max-width: 575px) {
    padding: 15px;
  }
`

export const CardLeft = styled.div`
  flex: 1;
  
  @media (min-width: 576px) {
    margin: 0 30px 0 0;
  }

  @media (min-width: 320px) and (max-width: 575px) {
    margin: 0 auto;
    width: 40px;
  }
`

export const CardRight = styled.div`
  flex: 5;

  @media (min-width: 576px) {
    text-align: left;
  }
`

export const CardTitle = styled.div`
  color: ${PurpleColor};
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;

  @media (min-width: 768px) {
    margin: 0 0 20px;
  }

  @media (min-width: 576px) and (max-width: 767px) {
    margin: 0 0 15px;
  }

  @media (min-width: 320px) and (max-width: 575px) {
    margin: 0 0 10px;
  }
`

export const CardDescription = styled.div`
  color: ${PurpleColorM};
  font-size: 16px;
  line-height: 24px;
`
