import React from 'react'
import PropTypes from 'prop-types'

import LinkComponent from '../Buttons/LinkComponent'

import {
  ErrorPageContainer,
  ErrorPageLinkContainer,
  ErrorPageLinksContainer,
  ErrorPageNumber,
  ErrorPageText,
  ErrorPageTile
} from './components'

const ErrorPage = ({
  number,
  text,
  title
}) => (
  <ErrorPageContainer>
    <ErrorPageNumber>{number}</ErrorPageNumber>

    <ErrorPageTile>{title}</ErrorPageTile>

    <ErrorPageText>{text}</ErrorPageText>

    <ErrorPageLinksContainer>
      Przejdź do
      <ErrorPageLinkContainer>
        <LinkComponent
          colorText='pinkColorM'
          fontSize='sizeL'
          label='strony głównej'
          link='/'
          type='internal'
        />
      </ErrorPageLinkContainer>
      lub
      <ErrorPageLinkContainer>
        <LinkComponent
          colorText='pinkColorM'
          fontSize='sizeL'
          label='harmonogramu targów pracy Career EXPO.'
          link='/targi-pracy-wroclaw'
          type='internal'
        />
      </ErrorPageLinkContainer>
    </ErrorPageLinksContainer>
  </ErrorPageContainer>
)

export default ErrorPage

ErrorPage.propTypes = {
  number: PropTypes.string,
  text: PropTypes.string,
  title: PropTypes.string
}

ErrorPage.defaultProps = {
  number: '',
  text: '',
  title: ''
}
