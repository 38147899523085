import React from 'react'
import PropTypes from 'prop-types'

import {
  TrainingItemHeaderSpeakerCompany,
  TrainingItemHeaderSpeakerContainer,
  TrainingItemHeaderSpeakerName,
  TrainingItemHeaderSpeakerSeparator,
  TrainingItemHeaderSpeakersContainer
} from './components'

const TrainingItemHeaderSpeakers = ({
  speakersList
}) => (
  <TrainingItemHeaderSpeakersContainer>
    {speakersList &&
      <>
        {speakersList.map((speaker, id) =>
          <TrainingItemHeaderSpeakerContainer key={id}>
            <TrainingItemHeaderSpeakerName>{speaker.name}</TrainingItemHeaderSpeakerName>

            <TrainingItemHeaderSpeakerSeparator>-</TrainingItemHeaderSpeakerSeparator>

            <TrainingItemHeaderSpeakerCompany>{speaker.company}</TrainingItemHeaderSpeakerCompany>
          </TrainingItemHeaderSpeakerContainer>
        )}
      </>}
  </TrainingItemHeaderSpeakersContainer>
)

export default TrainingItemHeaderSpeakers

TrainingItemHeaderSpeakers.propTypes = {
  speakersList: PropTypes.arrayOf(
    PropTypes.shape({
      company: PropTypes.string,
      name: PropTypes.string
    })
  )
}
