import React from 'react'
import PropTypes from 'prop-types'
import { Scrollbars } from 'react-custom-scrollbars'

import Image from '../../Form/FormBasicElements/Image'
import Label from '../../Form/FormBasicElements/Label'
// import Meter from '../../Meter'
import SocialList from '../../SocialMedia/SocialList'
import TabsMenu from '../../Tabs/TabsMenu'
// import ToggleFavouriteButtonContainer from '../../../containers/PageContainers/CompanyProfileContainer/ToggleFavouriteButtonContainer'

import {
  // EmployerProfileLeftApplyButtonContainer,
  // EmployerProfileLeftButtonsContainer,
  // EmployerProfileLeftCompatibilityContainer,
  EmployerProfileLeftContainer,
  EmployerProfileLeftContent,
  EmployerProfileLeftIndustriesContainer,
  EmployerProfileLeftIndustriesListContainer,
  EmployerProfileLeftIndustry,
  EmployerProfileLeftLogoContainer,
  EmployerProfileLeftNameContainer,
  EmployerProfileLeftSocialMediaContainer,
  EmployerProfileLeftTabsContainer
} from './components'

// import translationsList from '../../../stabs/translationsList'

const EmployerProfileLeft = ({
  companyId,
  compatibility,
  hasQuickRecruitment,
  industries,
  isOpen,
  isPreview,
  name,
  onOptionChange,
  socialMedia,
  tabsList,
  thumbnailUrl
}) => (
  <EmployerProfileLeftContainer isOpen={isOpen}>
    <Scrollbars
      autoHide
      autoHideDuration={100}
    >
      <EmployerProfileLeftContent>
        {
          thumbnailUrl && (
            <EmployerProfileLeftLogoContainer>
              <Image
                scaleMe={false}
                source={thumbnailUrl}
              />
            </EmployerProfileLeftLogoContainer>
          )
        }

        {
          name && (
            <EmployerProfileLeftNameContainer>{name}</EmployerProfileLeftNameContainer>
          )
        }

        <EmployerProfileLeftTabsContainer>
          <TabsMenu
            itemsList={tabsList}
            onClick={onOptionChange}
          />
        </EmployerProfileLeftTabsContainer>

        {
          industries && (
            <EmployerProfileLeftIndustriesContainer>
              <Label label='Branże' />

              <EmployerProfileLeftIndustriesListContainer>
                {industries.map((industry, id) => (
                  <EmployerProfileLeftIndustry key={id}>{industry.label}</EmployerProfileLeftIndustry>
                ))}
              </EmployerProfileLeftIndustriesListContainer>
            </EmployerProfileLeftIndustriesContainer>
          )
        }

        {/* {
          (!isPreview && compatibility) && (
            <EmployerProfileLeftCompatibilityContainer>
              <Meter
                label={translationsList.employerProfiles.labels.complianceLabel}
                value={compatibility}
              />
            </EmployerProfileLeftCompatibilityContainer>
          )
        } */}

        {/* {hasQuickRecruitment &&
      <EmployerProfileLeftApplyButtonContainer>
        <ButtonComponent
          bgColor='purpleColor'
          color='whiteColor'
          fontBold
          fullWidth
          label='Aplikuj na Szybką Rekrutację'
          onClick={() => {
            if (isPreview) {
              toast.error('Nie możesz rekrutować sam do siebie :)', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
              })
            }
          }} // TODO
          size='sizeM'
        />
      </EmployerProfileLeftApplyButtonContainer>} */}

        {/* {
          !isPreview && (
            <EmployerProfileLeftButtonsContainer>
              <ToggleFavouriteButtonContainer companyId={companyId} />
            </EmployerProfileLeftButtonsContainer>
          )
        } */}

        {
          socialMedia && (
            <EmployerProfileLeftSocialMediaContainer>
              <SocialList
                facebook={socialMedia.facebook}
                linkedin={socialMedia.linkedin}
                twitter={socialMedia.twitter}
                youtube={socialMedia.youtube}
              />
            </EmployerProfileLeftSocialMediaContainer>
          )
        }
      </EmployerProfileLeftContent>
    </Scrollbars>
  </EmployerProfileLeftContainer>
)

export default EmployerProfileLeft

EmployerProfileLeft.propTypes = {
  compatibility: PropTypes.string,
  industries: PropTypes.array,
  name: PropTypes.string,
  socialMedia: PropTypes.shape({
    facebook: PropTypes.string,
    linkedin: PropTypes.string,
    twitter: PropTypes.string,
    youtube: PropTypes.string
  }),
  thumbnailUrl: PropTypes.string
}
