import React from 'react'
import PropTypes from 'prop-types'

import Icon from '../Icon'
import Input from '../Input'

import {
  UploadFileContainer,
  UploadFileInputContainer,
  UploadFileInputText,
  UploadFileTextContainer,
  UploadFileInputContent,
  UploadFileLoader
} from './components'

import loaderIconPath from '../../../../assets/icons/spinner.svg'

const UploadFile = ({
  buttonLabel,
  disabled,
  hasError,
  id,
  isLoading,
  name,
  onBlur,
  onChange,
  required,
  text,
  value
}) => (
  <UploadFileContainer data-test='UploadFileContainer'>
    {
      buttonLabel && (
        <UploadFileInputContainer
          data-test='UploadFileInputContainer'
          hasError={hasError}
        >
          <UploadFileInputContent data-test='UploadFileInputContent'>
            {
              isLoading && (
                <UploadFileLoader data-test='UploadFileLoader'>
                  <Icon
                    data-test='UploadFileIcon'
                    icon={loaderIconPath}
                  />
                </UploadFileLoader>
              )
            }

            <UploadFileInputText
              data-test='UploadFileInputText'
              isLoading={isLoading}
            >
              {buttonLabel}
            </UploadFileInputText>
          </UploadFileInputContent>

          <Input
            data-test='UploadFileInput'
            disabled={disabled}
            hasError={hasError}
            id={id}
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            required={required}
            type='file'
            value={value}
          />
        </UploadFileInputContainer>
      )
    }
    {
      text && (
        <UploadFileTextContainer data-test='UploadFileTextContainer'>
          {text}
        </UploadFileTextContainer>
      )
    }
  </UploadFileContainer>
)

export default UploadFile

UploadFile.propTypes = {
  buttonLabel: PropTypes.string,
  disabled: PropTypes.bool,
  hasError: PropTypes.bool,
  id: PropTypes.string,
  isLoading: PropTypes.bool,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  text: PropTypes.string
}

UploadFile.defaultProps = {
  isLoading: true
}
