import styled from 'styled-components'

export const ChatContainer = styled.div``

export const ChatIframe = styled.iframe`
  border: 0 solid transparent;
  display: block;
  width: 100%;

  @media (min-width: 1600px) {
    height: 600px;
  }

  @media (min-width: 1200px) and (max-width: 1599px) {
    height: 580px;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    height: 560px;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    height: 540px;
  }

  @media (min-width: 576px) and (max-width: 767px) {
    height: 520px;
  }

  @media (max-width: 575px) {
    height: 500px;
  }
`
