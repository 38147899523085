import React from 'react'

import SpacingLine from '../../../components/SpacingLine'
import Subtitle from '../../../components/Subtitle'

import AddBasicCompanyDataFormContainer from '../../../containers/PlatformContainer/CompanyStepsForms/AddBasicCompanyDataFormContainer'
import AddBranchFormContainer from '../../../containers/PlatformContainer/CompanyStepsForms/AddBranchFormContainer'
import ChangeStepButton from '../../../containers/PlatformContainer/CompanyStepsForms/ChangeStepButton'
// import StepOneIndustriesContainer from '../../../containers/PlatformContainer/CompanyStepsForms/SelectCompanyIndustriesContainer'

import {
  FormCompanyStepOneButtonContainer
} from './components'

const FormCompanyStepOne = () => (
  <>
    <Subtitle
      data-test='subtitleOne'
      label='Podstawowe dane Twojej firmy'
      option='experience'
    />

    <AddBasicCompanyDataFormContainer />

    <SpacingLine
      data-test='spacingLineOne'
      isFixed
    />

    <Subtitle
      data-test='subtitleTwo'
      label='Oddziały Twojej firmy'
      option='language'
    />

    <AddBranchFormContainer />

    <FormCompanyStepOneButtonContainer>
      <ChangeStepButton
        step='companyStep2'
        validateFunction={(company) => {
          if (company.name === undefined || company.name.trim() === '') { return false }
          if (company.logoUrl === undefined || company.logoUrl.trim() === '') { return false }
          if (company.website === undefined || company.website.trim() === '') { return false }
          // if (company.vectorLogoUrl === undefined || company.vectorLogoUrl.trim() === '') { return false }

          return true
        }}
      />
    </FormCompanyStepOneButtonContainer>
  </>
)

export default FormCompanyStepOne
