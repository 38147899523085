import React from 'react'
import { Redirect, Route } from 'react-router-dom'

import AuthService from '../../../store/services/AuthService'

export default ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => (
        AuthService.loggedIn()
          ? (
            <Redirect
              to={{
                pathname: '/platforma',
                state: { from: props.location }
              }}
            />
          )

          : (
            <Component {...props} />
          )
      )}
    />
  )
}
