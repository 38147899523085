import types from './types'

const fetchTrainingsError = (error) => ({
  type: types.FETCH_ERROR,
  payload: error
})

const fetchTrainingsSuccess = (data) => ({
  type: types.FETCH_SUCCESS,
  payload: data.trainings
})

const fetchTrainingsRequested = (eventId) => ({
  type: types.FETCH_REQUESTED,
  payload: { eventId }
})

const showMoreTrainings = () => ({
  type: types.SHOW_MORE
})

const showLessTrainings = () => ({
  type: types.SHOW_LESS
})

const toggleDescription = (key) => ({
  type: types.TOGGLE_DESCRIPTION,
  payload: { key }
})

const toggleEnrollment = (key) => ({
  type: types.TOGGLE_ENROLLMENT,
  payload: { key }
})

const addTraining = (training) => ({
  type: types.ADD_TRAINING,
  payload: { training }
})

const updateTraining = (trainingId, newTraining) => ({
  type: types.UPDATE_TRAINING,
  payload: { trainingId, newTraining }
})

const removeTraining = (trainingId) => ({
  type: types.REMOVE_TRAINING,
  payload: { trainingId }
})

export default {
  addTraining,
  fetchTrainingsError,
  fetchTrainingsRequested,
  fetchTrainingsSuccess,
  removeTraining,
  showLessTrainings,
  showMoreTrainings,
  toggleDescription,
  toggleEnrollment,
  updateTraining
}
