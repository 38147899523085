import styled from 'styled-components'

import {
  PurpleColorL,
  SilverColor
} from '../../../../assets/styles/colors'

export const TrainingItemSpeakerContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  text-align: left;
  
  @media (min-width: 992px) {
    grid-template-columns: 120px 1fr;
    grid-row-gap: 15px;
    margin: 0 0 30px;

    &:last-child {
      margin-bottom: 0px;
    }
  }
  
  @media (min-width: 576px) and (max-width: 991px) {
    grid-template-columns: 88px 1fr;
    grid-row-gap: 10px;
    margin: 0 0 10px;
  }

  @media (min-width: 320px) and (max-width: 575px) {
    grid-template-columns: 68px 1fr;
    grid-row-gap: 8px;
    margin: 0 0 8px;
  }
`

export const TrainingItemSpeakerAvatarContainer = styled.div`
  grid-row: 1/4;
  border-radius: 5px;
  overflow: hidden;

  @media (min-width: 1200px) {
    margin: 0 30px 0 0;
  }
  
  @media (min-width: 768px) and (max-width: 1199px) {
    margin: 0 20px 0 0;
  }

  @media (min-width: 576px) and (max-width: 767px) {
    margin: 0 10px 0 0;
  }

  @media (min-width: 320px) and (max-width: 575px) {
    margin: 0 8px 0 0;
  }
`

export const TrainingItemSpeakerName = styled.div`
  font-weight: 600;
  color: ${PurpleColorL};

  @media (min-width: 992px) {
    line-height: 27px;
    font-size: 18px;
  }

  @media (min-width: 576px) and (max-width: 991px) {
    line-height: 24px;
    font-size: 16px;
  }

  @media (min-width: 320px) and (max-width: 575px) {
    line-height: 21px;
    font-size: 14px;
  }
`

export const TrainingItemSpeakerAbout = styled.div`
  grid-row: 2/4;
  line-height: 18px;
  font-size: 12px;
  font-weight: 600;
  color: ${SilverColor};
`

export const TrainingItemSpeakerAboutPosition = styled.div``

export const TrainingItemSpeakerAboutCompany = styled.div``
