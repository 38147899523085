import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Formik } from 'formik'
import { toast } from 'react-toastify'

import * as eventAPI from '../../store/api/event'
import ApiService from '../../store/services/ApiService'
import { trainingsActions, trainingsSelectors } from '../../store/ducks/trainings'

import AddedItem from '../../components/List/AddedItem'
import FormPanel from '../../components/Form/FormWrappers/FormPanel'
import FormTwoColumns from '../../components/Form/FormWrappers/FormTwoColumns'
import InputField from '../../components/Form/FormFields/InputField'
import MultiSelectDropDownField from '../../components/Form/FormFields/MultiSelectDropDownField'
import RequiredFieldsText from '../../components/Form/FormBasicElements/RequiredFieldsText'
import ResetAndSaveButtons from '../../components/Form/FormComponents/ResetAndSaveButtons'

import translate from '../../stabs/errorMessagesTranslations'
import handleField from '../../utils/forms/handleField'

import { AddRecruiterFormNameAndSurnameContainer } from '../../components/Form/FormComponents/AddRecruiterForm/components'
import SpeakerAvatarUploadContainer from '../ComponentContainers/UploadContainers/SpeakerAvatarUploadContainer'

const AdminSpeakersContainer = () => {
  const [state, setState] = useState({
    error: null,
    isEditing: false,
    isLoading: false,
    editingItemId: null,
    selectedTrainingId: null,
    selectedTrainingTitle: null
  })

  const trainings = useSelector(trainingsSelectors.getTrainingsContent)

  const dispatch = useDispatch()
  const onSubmit = (values, { setSubmitting, resetForm }) => {
    if (state.selectedTrainingId === null) {
      toast.error('Musisz wybrać szkolenie!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      })

      setSubmitting(false)
      return
    }

    setState({ ...state, error: null, isLoading: true })

    ApiService
      .fetch(state.isEditing ? eventAPI.updateSpeaker(state.selectedTrainingId, state.editingItemId, values) : eventAPI.createSpeaker(state.selectedTrainingId, values))
      .then(data => {
        setSubmitting(false)

        toast.success('Zapisano!', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        })

        dispatch(trainingsActions.updateTraining(state.selectedTrainingId, data))

        setState({ error: null, isLoading: false, editingItemId: null, isEditing: false, selectedTrainingId: state.selectedTrainingId, selectedTrainingTitle: state.selectedTrainingTitle })
        resetForm()
      })
      .catch(error => {
        setSubmitting(false)
        console.log(error)
        console.log(error.details)
        setState({ ...state, error: translate('pl', error.details.msg), isLoading: false })
      })
  }

  const onDelete = (itemId) => {
    setState({ ...state, error: null, isLoading: true })

    ApiService
      .fetch(eventAPI.removeSpeaker(state.selectedTrainingId, itemId))
      .then(data => {
        setState({ error: null, isLoading: false, isEditing: false, editingItemId: null, selectedTrainingId: state.selectedTrainingId, selectedTrainingTitle: state.selectedTrainingTitle })

        toast.success('Usunięto!', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        })

        dispatch(trainingsActions.updateTraining(state.selectedTrainingId, data))
      })
      .catch(error => {
        console.log(error)
        console.log(error.details)
        setState({ error: translate('pl', error.details.msg), isLoading: false, isEditing: false, editingItemId: null })
      })
  }

  const onEdit = (setValues) => (itemId) => {
    setState({ ...state, isEditing: true, editingItemId: itemId })
    const editingItem = trainings
      .filter((training) => training._id === state.selectedTrainingId)[0].speakers
      .filter((speaker) => speaker._id === itemId)[0]

    const items = {
      name: editingItem.name,
      avatarUrl: editingItem.avatarUrl,
      position: editingItem.position,
      company: editingItem.company
    }

    setValues(items)
  }

  const onReset = (handleReset) => () => {
    setState({ ...state, isEditing: false, editingItemId: null })
    handleReset()
  }

  const mapItemToDisplay = (trainings) => {
    if (state.selectedTrainingId !== null) {
      const selectedTraining = trainings.filter((training) => training._id === state.selectedTrainingId)[0]

      return selectedTraining.speakers.map((item) => ({
        title: item.name,
        description: `${item.company} ${item.position}`,
        isEditing: state.editingItemId === item._id,
        _id: item._id
      }))
    }

    return []
  }

  return (
    <Formik
      initialValues={{
        avatarUrl: '',
        name: '',
        position: '',
        company: ''
      }}
      onSubmit={onSubmit}
      render={({
        errors,
        handleBlur,
        handleChange,
        handleReset,
        handleSubmit,
        isSubmitting,
        setValues,
        setFieldValue,
        setFieldTouched,
        touched,
        values
      }) => (
        <form onSubmit={handleSubmit}>
          <FormPanel
            errors={state.error}
            noMargin
          >

            <MultiSelectDropDownField
              data-test='multiSelectDropDownFieldFive'
              label='Szkolenie'
              errorMessage=''
              hasError={false}
              name='training'
              itemsList={trainings.map((training) => ({ label: training.title, value: training._id }))}
              onClick={(fieldName, item) => setState({
                selectedTrainingId: item.value,
                selectedTrainingTitle: item.label,
                error: null,
                isEditing: false,
                isLoading: false,
                editingItemId: null
              })}
              placeholder={state.selectedTrainingId === null ? 'Wybierz' : state.selectedTrainingTitle}
              value={state.selectedTrainingId}
              closeOnClick
            />

            {mapItemToDisplay(trainings).map((item, index) => (
              <AddedItem
                isInsideForm
                key={index}
                title={`${item.isEditing ? '(W trakcie edycji) ' : ''}${item.title}`}
                description={item.description}
                onClickDelete={() => onDelete(item._id)}
                onClickEdit={() => onEdit(setValues)(item._id)}
                isLoading={state.isLoading}
              />
            ))}

            <FormTwoColumns data-test='FormTwoColumnsOne'>
              <SpeakerAvatarUploadContainer
                error={errors.avatarUrl}
                touched={touched.avatarUrl}
                value={values.avatarUrl}
                handleField={(fieldName, value) => handleField(fieldName, value, { setFieldTouched, setFieldValue })}
              />

              <AddRecruiterFormNameAndSurnameContainer>
                <InputField
                  data-test='CompanyStreet'
                  errorMessage={errors.name}
                  hasError={errors.name && touched.name}
                  id='CompanyStreet'
                  label='Imię i nazwisko'
                  name='name'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder='Podaj imię i nazwisko prelegenta...'
                  value={values.name}
                />

                <InputField
                  data-test='CompanyStreet'
                  errorMessage={errors.position}
                  hasError={errors.position && touched.position}
                  id='CompanyStreet'
                  label='Stanowisko'
                  name='position'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder='Wpisz stanowisko prelegenta...'
                  value={values.position}
                />

                <InputField
                  data-test='CompanyStreet'
                  errorMessage={errors.company}
                  hasError={errors.company && touched.company}
                  id='CompanyStreet'
                  label='Firma'
                  name='company'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder='Wpisz gdzie prelegent pracuje...'
                  value={values.company}
                />
              </AddRecruiterFormNameAndSurnameContainer>
            </FormTwoColumns>

            <RequiredFieldsText />
          </FormPanel>

          <ResetAndSaveButtons
            data-test='ResetAndSaveButtonsOne'
            onClickReset={onReset(handleReset)}
            disabledSave={isSubmitting}
            isEditing={state.isEditing}
            isLoadingSave={state.isLoading}
            isSaveSubmitting
          />
        </form>
      )}
    />
  )
}

export default AdminSpeakersContainer
