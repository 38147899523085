import styled from 'styled-components'

import { LabelContainer } from '../Form/FormBasicElements/Label/components'

import {
  PurpleBoxShadowColor,
  PurpleColorL,
  WhiteColor
} from '../../assets/styles/colors'

export const LocationContainer = styled.div`
  background-color: ${WhiteColor};
  border-radius: 20px;
  box-shadow: ${PurpleBoxShadowColor};

  @media (min-width: 768px) {
    padding: 50px 30px;
  }

  @media (min-width: 320px) and (max-width: 767px) {
    padding: 30px;
  }
`

export const LocationContent = styled.div`
  display: inline-grid;

  @media (min-width: 768px) {
    grid-auto-flow: column;
    grid-column-gap: 100px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(3, auto);
    text-align: left;
  }

  @media (min-width: 320px) and (max-width: 767px) {
    text-align: center;

    ${LabelContainer} {
      text-align: center;
    }
  }

  @media (min-width: 576px) and (max-width: 767px) {
    grid-template-rows: auto auto 100px auto auto auto;
  }

  @media (min-width: 320px) and (max-width: 575px) {
    grid-template-rows: auto auto 80px auto auto auto;
  }
`

export const LocationText = styled.div`
  color: ${PurpleColorL};
  font-size: 16px;
  line-height: 24px;
  padding: 0 0 5px;
`

export const LocationButtonContainer = styled.div`
  @media (min-width: 768px) {
    margin: 20px 0 0;
  }
`

export const LocationDateAndTimeContainer = styled.div`
  align-items: center;
  color: ${PurpleColorL};
  display: flex;
  font-size: 16px;
  justify-content: start;
  line-height: 24px;
`
