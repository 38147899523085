import React, { useState } from 'react'
import { Formik } from 'formik'
import { toast } from 'react-toastify'

import ApiService from '../../store/services/ApiService'
import * as eventAPI from '../../store/api/event'

import FormPanel from '../../components/Form/FormWrappers/FormPanel'
import FormTwoColumns from '../../components/Form/FormWrappers/FormTwoColumns'
import InputField from '../../components/Form/FormFields/InputField'
import ResetAndSaveButtons from '../../components/Form/FormComponents/ResetAndSaveButtons'
import { useSelector } from 'react-redux'
import { primaryEventSelectors } from '../../store/ducks/primary-event'

const AdminChangeEventDate = ({ event }) => {
  const [state, setState] = useState({
    error: null,
    isLoading: false
  })

  const onSubmit = (values) => {
    setState({ ...state, isLoading: true })

    const {_id, ...primary } = event

    ApiService.fetch(eventAPI.updateEvent(_id, { ...primary, ...values }))
      .then(() => {
        toast.success('Zapisano!', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        })

        setState({
          error: null,
          isLoading: false
        })
      }).catch(() => {
        toast.error('Bład!', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        })
      })
  }

  return (
    <Formik
      initialValues={{ date: `${event.date}`, timeStart: `${event.timeStart}`, timeEnd: `${event.timeEnd}`  }}
      onSubmit={onSubmit}
      render={({
        values,
        handleChange,
        handleBlur,
        handleReset,
        handleSubmit
      }) => (
        <form onSubmit={handleSubmit}>
          <FormPanel
            errors={null}
            noMargin
          >
            <InputField
              label='Data'
              name='date'
              placeholder='Wpisz datę w formacie DD.MM.RRRR'
              required
              errorMessage=''
              hasError={false}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.date}
            />
            <FormTwoColumns data-test='FormTwoColumnsOne'>
              <InputField
                label='Godzina rozpoczęcia'
                name='timeStart'
                placeholder='Wpisz godzine w formacie HH:mm'
                required
                errorMessage=''
                hasError={false}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.timeStart}
              />
              <InputField
                label='Godzina końca'
                name='timeEnd'
                placeholder='Wpisz godzine w formacie HH:mm'
                required
                errorMessage=''
                hasError={false}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.timeEnd}
              />
            </FormTwoColumns>

            <ResetAndSaveButtons
              data-test='ResetAndSaveButtonsOne'
              onClickReset={handleReset}
              disabledSave={false}
              isEditing={false}
              isLoadingSave={false}
              isSaveSubmitting
            />
          </FormPanel>
        </form>
      )}
    />
  )
}

export default AdminChangeEventDate
