import React from 'react'
import PropTypes from 'prop-types'

import Icon from '../../../Form/FormBasicElements/Icon'

import {
  OfficeItemContainer,
  OfficeItemContent,
  OfficeItemIconContainer,
  OfficeItemIconContent,
  OfficeItemName,
  OfficeItemPhoneLabel,
  OfficeItemPhoneListElement,
  OfficeItemPhoneValue,
  OfficeItemPhonesListContainer,
  OfficeItemText
} from './components'

import iconPath from '../../../../assets/icons/subtitle/subtitle_pin.svg'
import translationsList from '../../../../stabs/translationsList'

const OfficeItem = ({
  city,
  phone,
  postalCode,
  street
}) => (
  <OfficeItemContainer>
    <OfficeItemIconContainer>
      <OfficeItemIconContent>
        <Icon icon={iconPath} />
      </OfficeItemIconContent>

      <OfficeItemName>{city}</OfficeItemName>
    </OfficeItemIconContainer>

    <OfficeItemContent>
      <OfficeItemText data-test='Street'>{street}</OfficeItemText>

      <OfficeItemText data-test='Street'>{postalCode}</OfficeItemText>

      {
        phone && (
          <OfficeItemPhonesListContainer>
            <OfficeItemPhoneListElement>
              <OfficeItemPhoneLabel data-test='PhoneLabel'>{translationsList.companyPanel.phoneLabel}</OfficeItemPhoneLabel>

              <OfficeItemPhoneValue data-test='PhoneValue'>{phone}</OfficeItemPhoneValue>
            </OfficeItemPhoneListElement>
          </OfficeItemPhonesListContainer>
        )
      }
    </OfficeItemContent>
  </OfficeItemContainer>
)

export default OfficeItem

OfficeItem.propTypes = {
  city: PropTypes.string,
  itemsList: PropTypes.arrayOf(PropTypes.string),
  name: PropTypes.string,
  street: PropTypes.string
}

OfficeItem.defaultProps = {
  city: '',
  name: '',
  street: ''
}
