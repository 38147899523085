import React, { useState } from 'react'
import { Formik } from 'formik'
import { toast } from 'react-toastify'

import ApiService from '../../store/services/ApiService'
import * as companiesAPI from '../../store/api/company'

import FormPanel from '../../components/Form/FormWrappers/FormPanel'
import FormTwoColumns from '../../components/Form/FormWrappers/FormTwoColumns'
import InputField from '../../components/Form/FormFields/InputField'
import MultiSelectDropDownField from '../../components/Form/FormFields/MultiSelectDropDownField'
import ResetAndSaveButtons from '../../components/Form/FormComponents/ResetAndSaveButtons'

const AdminChatToCompany = ({ companies }) => {
  const [state, setState] = useState({
    selectedCompany: null,
    selectedCompanyLabel: null,
    error: null,
    isLoading: false
  })

  const onSubmit = (values) => {
    setState({ ...state, isLoading: true })

    ApiService.fetch(companiesAPI.updateCompany(state.selectedCompany, { chatUrl: values.chatUrl }))
      .then(() => {
        toast.success('Zapisano!', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        })

        setState({
          selectedCompany: null,
          selectedCompanyLabel: null,
          error: null,
          isLoading: false
        })
      }).catch(() => {
        toast.error('Bład!', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        })
      })
  }

  return (
    <Formik
      initialValues={{ chatUrl: '' }}
      onSubmit={onSubmit}
      render={({
        values,
        handleChange,
        handleBlur,
        handleReset,
        handleSubmit
      }) => (
        <form onSubmit={handleSubmit}>
          <FormPanel
            errors={null}
            noMargin
          >
            <FormTwoColumns data-test='FormTwoColumnsOne'>
              <MultiSelectDropDownField
                label='Firma'
                errorMessage=''
                hasError={false}
                name='training'
                itemsList={companies.map((company) => ({
                  label: `${company.name || company.primaryEmail || 'Bez nazwy'}`,
                  value: company._id
                }))}
                onClick={(fieldName, item) => setState({
                  ...state,
                  selectedCompany: item.value,
                  selectedCompanyLabel: item.label
                })}
                placeholder={state.selectedCompany === null ? 'Wybierz' : state.selectedCompanyLabel}
                value={state.selectedCompany}
                closeOnClick
              />

              <InputField
                data-test='ChatUrl'
                id='ChatUrl'
                label='Chat URL'
                name='chatUrl'
                placeholder='Wpisz url do chatu'
                required
                errorMessage=''
                hasError={false}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.firstName}
              />
            </FormTwoColumns>

            <ResetAndSaveButtons
              data-test='ResetAndSaveButtonsOne'
              onClickReset={handleReset}
              disabledSave={false}
              isEditing={false}
              isLoadingSave={false}
              isSaveSubmitting
            />
          </FormPanel>
        </form>
      )}
    />
  )
}

export default AdminChatToCompany
