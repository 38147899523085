import React from 'react'
import PropTypes from 'prop-types'

import {
  FormTwoColumnsContainer
} from './components'

const FormTwoColumns = ({
  children,
  columnReverse
}) => (
  <FormTwoColumnsContainer
    columnReverse={columnReverse}
  >
    {children}
  </FormTwoColumnsContainer>
)

export default FormTwoColumns

FormTwoColumns.propTypes = {
  children: PropTypes.any,
  columnReverse: PropTypes.bool
}

FormTwoColumns.defaultProps = {
  columnReverse: false
}
