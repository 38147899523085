import trade from '../../assets/icons/trade_1.svg'
import engineering from '../../assets/icons/industries/inzynieria.svg'
import consulting from '../../assets/icons/industries/konsultng.svg'
import marketing from '../../assets/icons/industries/marketing.svg'
import realEstate from '../../assets/icons/industries/nieruchomosci.svg'
import helpdesk from '../../assets/icons/industries/obsluga-klienta.svg'
import law from '../../assets/icons/industries/prawo-administracja.svg'
import production from '../../assets/icons/industries/produkcja.svg'
import transport from '../../assets/icons/industries/transport.svg'
import sale from '../../assets/icons/industries/sprzedaz.svg'
import tourism from '../../assets/icons/industries/turystyka.svg'
import medicine from '../../assets/icons/industries/medycyna.svg'
import work from '../../assets/icons/industries/agencjapracy.svg'
import bpo from '../../assets/icons/industries/bpossc.svg'
import sea from '../../assets/icons/industries/morskie.svg'
import security from '../../assets/icons/industries/bezpieczenstwo.svg'
import power from '../../assets/icons/industries/electricity.svg'
import hr from '../../assets/icons/industries/edukacja i hr.svg'
import research from '../../assets/icons/industries/badania i rozwoj.svg'
import finance from '../../assets/icons/industries/banking.svg'

export default (icon) => {
  const icons = {
    it: trade,
    law: law,
    consulting: consulting,
    finance: finance,
    research: research,
    hr: hr,
    tourism: tourism,
    engineering: engineering,
    marketing: marketing,
    helpdesk: helpdesk,
    production: production,
    transport: transport,
    sale: sale,
    'real-estate': realEstate,
    medicine: medicine,
    work: work,
    bpo: bpo,
    sea: sea,
    security: security,
    power: power,
  }

  return icons[icon]
}
