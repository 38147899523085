import styled from 'styled-components'

export const ControlsContainer = styled.div`
  display: inline-grid;

  @media (min-width: 576px) {
    justify-content: center;

    ${props => props.hideLeft || props.hideRight
      ? ''
      : `
        grid-column-gap: 25px;
        grid-template-columns: 1fr 1fr;
      `
    }
  }

  @media (max-width: 575px) {
    ${props => props.hideLeft || props.hideRight
      ? ''
      : 'grid-row-gap: 10px;'
    }
  }
`
