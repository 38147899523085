import exampleImagePath1 from '../../assets/img/contact/staff_anna_scislowska.png'
import exampleImagePath3 from '../../assets/img/homepageBg.svg'

export const hardcodedRecruitersList = [
  {
    avatarUrl: exampleImagePath3,
    chatURL: 'chatURL',
    companyName: 'Long Long Long companyName companyName companyName companyName',
    firstName: 'Long Long Long firstName firstName firstName',
    lastName: 'Long Long Long lastName lastName lastName',
    position: 'Long Long Long position position position position'
  },
  {
    avatarUrl: exampleImagePath3,
    chatURL: 'chatURL',
    companyName: 'companyName',
    firstName: 'firstName',
    lastName: 'lastName',
    position: 'position'
  },
  {
    avatarUrl: exampleImagePath1,
    chatURL: 'chatURL',
    companyName: 'Long Long Long companyName companyName companyName companyName',
    firstName: 'Long Long Long firstName firstName firstName',
    lastName: 'Long Long Long lastName lastName lastName',
    position: 'Long Long Long position position position position'
  },
  {
    avatarUrl: exampleImagePath1,
    chatURL: 'chatURL',
    companyName: 'companyName',
    firstName: 'firstName',
    lastName: 'lastName',
    position: 'position'
  }
]

export const companiesDiamond = (companies) => companies.filter((company) => company.levelOfHighlighting === 'prestige')
export const companiesPlatinum = (companies) => companies.filter((company) => company.levelOfHighlighting === 'premium')
export const companiesPartner = (companies) => companies.filter((company) => company.levelOfHighlighting === 'partner')
// export const companiesGold = (companies) => companies.filter((company) => company.levelOfHighlighting === 'gold')
// export const companiesSilver = (companies) => companies.filter((company) => company.levelOfHighlighting === 'silver')
// export const companiesPartner = (companies) => companies.filter((company) => company.levelOfHighlighting === 'partner')
// export const companiesSponsor = (companies) => companies.filter((company) => company.levelOfHighlighting === 'sponsor')
export const companiesStandard = (companies) => companies.filter((company) => company.levelOfHighlighting !== 'prestige').filter((company) => company.levelOfHighlighting !== 'premium').filter((company) => company.levelOfHighlighting !== 'partner'); //.filter((company) => company.levelOfHighlighting !== 'silver').filter((company) => company.levelOfHighlighting !== 'partner').filter((company) => company.levelOfHighlighting !== 'sponsor')
