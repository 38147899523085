import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'

import EmployerProfile from '../../../components/EmployerProfile'

import translationsList from '../../../stabs/translationsList'
import { companiesSelectors } from '../../../store/ducks/companies'
import Loader from '../../../components/Loader'
import { valuesActions, valuesSelectors } from '../../../store/ducks/values'
import { industriesActions, industriesSelectors } from '../../../store/ducks/industries'
import AuthService from '../../../store/services/AuthService'
import { myUserSelectors } from '../../../store/ducks/my-user'
import CompatibilityService from '../../../store/services/CompatibilityService'
import isExpoDay from '../../../utils/isExpoDay'

const CompanyProfileContainer = ({ slug, company, isPreview }) => {
  const [option, setOption] = useState('general')

  const loadState = useSelector(companiesSelectors.getCompaniesLoadState)
  const companies = useSelector(companiesSelectors.getCompaniesContent)
  // const valuesState = useSelector(valuesSelectors.getValuesState)
  const industriesState = useSelector(industriesSelectors.getIndustriesState)
  const myUser = useSelector(myUserSelectors.getMyUserData)

  const selectedCompany = company !== undefined
    ? company
    : companies.filter((company) => company.slug === slug)[0]

  const dispatch = useDispatch()
  useEffect(() => {
    // if (valuesState.isLoading === false) {
    //   if (valuesState.data === null || valuesState.data.length === 0) {
    //     dispatch(valuesActions.fetchValuesRequested())
    //   }
    // }

    if (industriesState.isLoading === false) {
      if (industriesState.data === null || industriesState.data.length === 0) {
        dispatch(industriesActions.fetchIndustriesRequested())
      }
    }

    if (isExpoDay() && selectedCompany !== undefined && selectedCompany.chatUrl) {
      setOption('chat')
    }
  }, [dispatch, industriesState, selectedCompany])

  if (!loadState && selectedCompany === undefined) {
    return (
      <Redirect to='/' />
    )
  }

  if (industriesState.isLoading || loadState) {
    return <Loader isLoading />
  }

  const checkIfCommonValue = (value) => {
    return myUser.values.includes(value._id) || myUser.importantValues.includes(value._id)
  }

  const selectedIndustries = industriesState.data
    .filter((industry) => selectedCompany.industries.includes(industry._id))

  // const selectedValues = valuesState.data
  //   .filter((value) => selectedCompany.values.includes(value._id))
  //   .map((value) => ({ ...value, isActive: true, isMostImportant: checkIfCommonValue(value) }))

  const compatibility = (() => {
    if (AuthService.getUserType() !== 'user') {
      return null
    }

    const compatibility = CompatibilityService({
      userImportantValues: myUser.importantValues,
      userValues: myUser.values,
      companyValues: selectedCompany.values
    })

    return `${Math.round(compatibility)}`
  })()

  return (
    <EmployerProfile
      articlesList={selectedCompany.articles}
      awardsList={selectedCompany.awards}
      benefitsList={selectedCompany.benefits}
      chatUrl={selectedCompany.chatUrl}
      companyId={selectedCompany._id}
      compatibility={compatibility}
      hasQuickRecruitment={selectedCompany.hasQuickRecruitment}
      image={selectedCompany.bannerUrl}
      industries={selectedIndustries}
      isPreview={isPreview}
      jobsList={selectedCompany.jobOffers}
      name={selectedCompany.name || ''}
      officesList={selectedCompany.offices}
      onOptionChange={(name) => setOption(name)}
      option={option}
      ourMission={selectedCompany.mission || ''}
      recruitersList={selectedCompany.recruiters}
      recruitmentDetails={selectedCompany.recruitment}
      socialMedia={selectedCompany.socials}
      tabsList={generateTabsList(option, selectedCompany)}
      text={selectedCompany.aboutUs || ''}
      thumbnailUrl={selectedCompany.logoUrl}
      valueTags={[]}
      videosList={selectedCompany.videos}
    />
  )
}

const generateTabsList = (option, selectedCompany) =>
  isExpoDay()
    ? translationsList.tabs.tabsList_live
      .map((item) => item.value === option ? { ...item, isActive: true } : { ...item, isActive: false })
      .filter((item) => {
        if (item.value === 'benefits' && selectedCompany.benefits.length === 0) {
          return false
        }

        if (item.value === 'chat' && selectedCompany.chatUrl === undefined) {
          return false
        } else {
          if (item.value === 'chat') {
            return isExpoDay() || localStorage.getItem('test') === '1'
          }
        }

        if (item.value === 'awards' && selectedCompany.awards.length === 0) {
          return false
        }

        if (item.value === 'offices' && selectedCompany.offices.length === 0) {
          return false
        }

        if (item.value === 'recruitment' && selectedCompany.recruitment === undefined) {
          return false
        }

        if (item.value === 'jobs' && selectedCompany.jobOffers.length === 0) {
          return false
        }

        return true
      })

    : translationsList.tabs.tabsList
      .map((item) => item.value === option ? { ...item, isActive: true } : { ...item, isActive: false })
      .filter((item) => {
        if (item.value === 'benefits' && selectedCompany.benefits.length === 0) {
          return false
        }

        if (item.value === 'chat' && selectedCompany.chatUrl === undefined) {
          return false
        } else {
          if (item.value === 'chat') {
            return isExpoDay() || localStorage.getItem('test') === '1'
          }
        }

        if (item.value === 'awards' && selectedCompany.awards.length === 0) {
          return false
        }

        if (item.value === 'offices' && selectedCompany.offices.length === 0) {
          return false
        }

        if (item.value === 'recruitment' && selectedCompany.recruitment === undefined) {
          return false
        }

        if (item.value === 'jobs' && selectedCompany.jobOffers.length === 0) {
          return false
        }

        return true
      })

export default CompanyProfileContainer
