import noPhotoImagePath from '../../../assets/img/noPhoto.png'
import premiumIcon from '../../../assets/icons/star-purple-border.svg'
import prestigeIcon from '../../../assets/icons/star-purple-filled.svg'

export const checkIfClickable = (clickType, func) =>
  clickType === 'onClick' && func

export const assignClickType = clickType => {
  switch (clickType) {
    case 'external': return 'external'
    case 'internal': return 'internal'
    case 'onClick': return 'onClick'
    default: return 'internal'
  }
}

export const checkIfLink = (clickType, link) =>
  clickType !== 'onClick'
    ? link
    : 'onClick'

export const checkIfNoImage = image =>
  image || noPhotoImagePath

export const chooseIconType = levelOfHighlighting => {
  switch (levelOfHighlighting) {
    case 'premium': return premiumIcon
    case 'prestige': return prestigeIcon
    default: return ''
  }
}
