import { ofType } from 'redux-observable'
import { catchError, mergeMap } from 'rxjs/operators'
import { of } from 'rxjs'
import getError from '../../utils/getError'

import * as eventAPI from '../../api/event'

import actions from './actions'
import types from './types'

import { companiesActions } from '../companies'
import { trainingsActions } from '../trainings'
import { tagsActions } from '../tags'

const fetchPrimaryEvent = (action$, state$, { fetch }) => {
  return action$
    .pipe(
      ofType(types.FETCH_REQUESTED),
      mergeMap(() =>
        fetch(eventAPI.getPrimaryEvent().toObject()).pipe(
          mergeMap((response) => of(
            actions.fetchPrimaryEventSuccess(response.response),
            tagsActions.fetchTagsRequested(),
            companiesActions.loadCompanies(response.response.companies),
            trainingsActions.fetchTrainingsRequested(response.response._id)
          )),
          catchError((error) => of(actions.fetchPrimaryEventError(getError(error))))
        )
      )
    )
}

export default {
  fetchPrimaryEvent
}
