export const BannerBoxShadowColor = 'rgba(61,50,112,0.24)'
export const InsetPurpleBoxShadowColor = 'inset 0 0 10px 3px rgba(217,209,255,0.3)'
export const OrangeColor = '#FCD0A3'
export const PinkButtonBoxShadowColor = 'rgba(224,5,123,0.1)'
export const PinkColor = '#7fcc27'
export const PinkColorM = '#9a9b9f'
export const PinkColorS = '#9a9b9f'
export const PopUpBgColor = 'rgba(34, 27, 65, 0.68)'
export const PurpleBoxShadowColor = '0 10px 50px 0 rgba(154, 155, 159,0.3)'
export const PurpleColor = '#7fcc27'
export const PurpleColorL = '#6B677E'
export const PurpleColorM = '#9a9b9f'
export const PurpleColorS = '#d5d8de'
export const PurpleColorXL = '#9882FF'
export const PurpleColorXS = '#f9f9f9'
export const RedColor = '#FF1919'
export const SilverColor = '#BBB'
export const SilverColorM = '#f9f9f9'
export const SilverColorS = '#F9F9F9'
export const SilverColorXS = '#F8F8F8'
export const WhiteColor = '#FFF'
export const WhiteColorM = '#F9F9F9'

export const CheckBoxSelectedColor = '#3F3174'
export const FBColor = '#4167B2'
export const LinkedinColor = '#0077B5'
export const TwitterColor = '#1da1f2'
export const YTColor = '#E62218'
export const IGColor = 'linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%)'

export const TransitionEffect = 'ease-in-out'
export const TransitionTime = '200ms'
export const TransitionType = 'all'
export const Transition = `${TransitionType} ${TransitionTime} ${TransitionEffect}`
