import React from 'react'
import PropTypes from 'prop-types'

import {
  SpacingLineContainer,
  SpacingLineFixed
} from './components'

const SpacingLine = ({
  heightSize,
  id,
  isFixed,
  option
}) => (
  <SpacingLineContainer
    heightSize={heightSize}
    id={id}
    option={option}
  >
    {isFixed &&
      <SpacingLineFixed heightSize={heightSize} />}
  </SpacingLineContainer>
)

export default SpacingLine

SpacingLine.propTypes = {
  heightSize: PropTypes.string,
  id: PropTypes.string,
  isFixed: PropTypes.bool,
  option: PropTypes.string
}

SpacingLine.defaultProps = {
  heightSize: '3px',
  isFixed: false,
  option: ''
}
