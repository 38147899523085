import React, { useState } from 'react'
import _ from 'lodash'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'

import ApiService from '../../../../store/services/ApiService'
import * as companyAPI from '../../../../store/api/company'
import { myCompanyActions, myCompanySelectors } from '../../../../store/ducks/my-company'

import AddBenefitForm from '../../../../components/Form/FormComponents/AddBenefitForm'
import ResetAndSaveButtons from '../../../../components/Form/FormComponents/ResetAndSaveButtons'

import translate from '../../../../stabs/errorMessagesTranslations'
import handleIcon from '../../../../utils/forms/handleIcon'
import { toast } from 'react-toastify'

const AddBenefitFormContainer = () => {
  const [state, setState] = useState({
    error: null,
    isEditing: false,
    isLoading: false,
    editingItemId: null
  })

  const company = useSelector(myCompanySelectors.getMyCompany)

  const dispatch = useDispatch()
  const onSubmit = (values, { setSubmitting }) => {
    setState({ ...state, error: null, isLoading: true })

    ApiService
      .fetch(state.isEditing ? companyAPI.updateBenefit(state.editingItemId, values) : companyAPI.addBenefit(values))
      .then(data => {
        setSubmitting(false)

        toast.success('Zapisano!', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        })

        dispatch(myCompanyActions.fetchMyCompanySuccess(data))
      })
      .catch(error => {
        setSubmitting(false)
        console.log(error)
        setState({ ...state, error: translate('pl', error.details.msg), isLoading: false })
      })
  }

  const onDelete = (itemId) => {
    setState({ ...state, error: null, isLoading: true })

    ApiService
      .fetch(companyAPI.removeBenefit(itemId))
      .then(data => {
        setState({ error: null, isLoading: false, isEditing: false, editingItemId: null })

        toast.success('Usunięto!', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        })

        dispatch(myCompanyActions.fetchMyCompanySuccess(data))
      })
      .catch(error => {
        console.log(error)
        console.log(error.details)
        setState({ error: translate('pl', error.details.msg), isLoading: false, isEditing: false, editingItemId: null })
      })
  }

  const onEdit = (setValues) => (itemId) => {
    setState({ ...state, isEditing: true, editingItemId: itemId })
    const editingItem = company.benefits.filter((benefit) => benefit._id === itemId)[0]

    setValues(_.omit(editingItem, ['_id']))
  }

  const onReset = (handleReset) => () => {
    setState({ ...state, isEditing: false, editingItemId: null })
    handleReset()
  }

  const mapItemToDisplay = (items) => items.map((item) => ({
    _id: item._id,
    description: '',
    isEditing: state.editingItemId === item._id,
    title: item.title
  }))

  return (
    <Formik
      initialValues={{ title: '', description: '', icon: '' }}
      validationSchema={Schema}
      onSubmit={onSubmit}
      render={({
        errors,
        handleBlur,
        handleChange,
        handleReset,
        handleSubmit,
        isSubmitting,
        setValues,
        setFieldTouched,
        setFieldValue,
        touched,
        values
      }) => (
        <form onSubmit={handleSubmit}>
          <AddBenefitForm
            apiError={state.error}
            errors={errors}
            handleBlur={handleBlur}
            handleChange={handleChange}
            isLoading={state.isLoading}
            isEditing={state.isEditing}
            handleDelete={onDelete}
            handleEdit={onEdit(setValues)}
            handleIcon={(label) => handleIcon('icon', label, { setFieldTouched, setFieldValue })}
            items={mapItemToDisplay(company.benefits)}
            touched={touched}
            values={values}
          />

          <ResetAndSaveButtons
            data-test='ResetAndSaveButtonsOne'
            onClickReset={onReset(handleReset)}
            disabledSave={isSubmitting}
            isEditing={state.isEditing}
            isLoadingSave={state.isLoading}
            isSaveSubmitting
          />
        </form>
      )}
    />
  )
}

const Schema = Yup.object().shape({
  title: Yup.string()
    .required('Pole jest wymagane'),
  description: Yup.string(),
  icon: Yup.string()
    .required('Pole jest wymagane')
})

export default AddBenefitFormContainer
