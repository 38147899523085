import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Icon from '../../../components/Form/FormBasicElements/Icon'
import Image from '../../../components/Form/FormBasicElements/Image'
import ImageCrop from '../../../components/ImageCrop'
import LinkComponent from '../../../components/Buttons/LinkComponent'

import {
  ChooseImageButtonsContainer,
  ChooseImageButtonsContent,
  ChooseImageContainer,
  ChooseImageImageContainer,
  ChooseImageImageContent,
  ChooseImageInfo,
  ChooseImageLoaderContainer
} from './components'

import loaderIconPath from '../../../assets/icons/spinner.svg'
import translationsList from '../../../stabs/translationsList'
import selectIconPath from '../../../assets/icons/select.svg'

const ChooseImage = ({
  buttonSelectLabel,
  chooseFileButtonDescription,
  chooseFileButtonFieldlabel,
  chooseFileButtonLabel,
  chooseFileButtonSendLabel,
  chooseFileTitle,
  croppedImageHeadingText,
  hasError,
  image,
  isEditable,
  isLoading,
  onChange,
  withHash,
  withInfo
}) => {
  const [modalState, setModalState] = useState(false)
  const [imageHash, setImageHash] = useState(Date.now())

  const handleOnClose = () => {
    setImageHash(Date.now())
    setModalState(false)
  }

  return (
    <ChooseImageContainer>
      <ChooseImageImageContent
        hasError={hasError}
        isEditable={isEditable}
      >
        {
          isLoading
            ? (
              <>
                <ChooseImageLoaderContainer>
                  <Icon
                    data-test='loaderIcon'
                    icon={loaderIconPath}
                  />
                </ChooseImageLoaderContainer>
              </>
            )

            : (
              <ChooseImageImageContainer>
                <Image
                  scaleMe={false}
                  source={withHash ? `${image}?${imageHash}` : image}
                />
              </ChooseImageImageContainer>
            )
        }
      </ChooseImageImageContent>

      <ChooseImageButtonsContainer>
        <ChooseImageButtonsContent>
          <LinkComponent
            colorFill='purpleColor'
            colorStroke='purpleColor'
            colorText='purpleColor'
            fontSize='sizeM'
            iconBefore={selectIconPath}
            label={buttonSelectLabel}
            onClick={() => setModalState(true)}
          />

        </ChooseImageButtonsContent>
      </ChooseImageButtonsContainer>

      {
        withInfo && (
          <ChooseImageInfo>{translationsList.chooseImage.labels.chooseImageInfo}</ChooseImageInfo>
        )
      }

      {
        modalState && (
          <ImageCrop
            chooseFileButtonDescription={chooseFileButtonDescription}
            chooseFileButtonFieldlabel={chooseFileButtonFieldlabel}
            chooseFileButtonLabel={chooseFileButtonLabel}
            chooseFileButtonSendLabel={chooseFileButtonSendLabel}
            chooseFileTitle={chooseFileTitle}
            croppedImageHeadingText={croppedImageHeadingText}
            data-test='image-crop'
            onChange={onChange}
            onClose={handleOnClose}
          />
        )
      }
    </ChooseImageContainer>
  )
}

export default ChooseImage

ChooseImage.propTypes = {
  buttonSelectLabel: PropTypes.string,
  chooseFileButtonDescription: PropTypes.string,
  chooseFileButtonFieldlabel: PropTypes.string,
  chooseFileButtonLabel: PropTypes.string,
  chooseFileButtonSendLabel: PropTypes.string,
  chooseFileTitle: PropTypes.string,
  croppedImageHeadingText: PropTypes.string,
  hasError: PropTypes.bool,
  image: PropTypes.string,
  isEditable: PropTypes.bool,
  isLoading: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  withInfo: PropTypes.bool
}

ChooseImage.defaultProps = {
  buttonSelectLabel: translationsList.chooseImage.labels.chooseImageSelectButtonLabel,
  hasError: false,
  isEditable: false,
  isLoading: false,
  withHash: false,
  withInfo: false
}
