import React, { useState } from 'react'
import { Formik } from 'formik'
import { withRouter } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import * as Yup from 'yup'

import { myUserActions } from '../../../../store/ducks/my-user'
import { myCompanyActions } from '../../../../store/ducks/my-company'

import LoginPanel from '../../../../components/Form/FormComponents/LoginAndRegister/LoginPanel'
import ApiService from '../../../../store/services/ApiService'
import AuthService from '../../../../store/services/AuthService'
import * as AuthAPI from '../../../../store/api/auth'

import translate from '../../../../stabs/errorMessagesTranslations'
import translationsList from '../../../../stabs/translationsList'

const LoginFormContainer = ({ history }) => {
  const dispatch = useDispatch()
  const [state, setState] = useState({
    isLoading: false,
    error: null
  })

  const onSubmit = (values, { setSubmitting, resetForm }) => {
    setState({ error: null, isLoading: true })

    ApiService
      .fetch(AuthAPI.login(values))
      .then(data => {
        setSubmitting(false)
        setState({ error: null, isLoading: false })

        AuthService.setToken(data.token)
        dispatch(myUserActions.fetchMyUserSuccess(data.userData))

        if (data.companyData !== undefined) {
          dispatch(myCompanyActions.fetchMyCompanySuccess(data.companyData))
        }
      })
      .catch(error => {
        setSubmitting(false)
        console.log(error)
        console.log(error.details)
        if (error.details.msg === 'You need to confirm email') {
          setState({ error: `Twoje konto jest nieaktywne. Musisz potwierdzić swój email. <a href="https://careerexpo.pl/wyslij?uid=${error.details.errorCode.uid}">Kliknij</a>, aby wysłać potwierdzenie ponownie.`, isLoading: false })
        } else {
          setState({ error: translate('pl', error.details.msg), isLoading: false })
        }
      })
  }

  return (
    <Formik
      initialValues={{ email: '', password: '' }}
      validationSchema={LoginSchema}
      onSubmit={onSubmit}
      render={({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => (
        <LoginPanel
          isLoading={state.isLoading}
          apiError={state.error}
          errors={errors}
          handleBlur={handleBlur}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          isSubmitting={isSubmitting}
          touched={touched}
          values={values}
        />
      )}
    />
  )
}

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email(translationsList.expoRegister.validationErrors.email)
    .required(translationsList.expoRegister.validationErrors.required),
  password: Yup.string()
    .min(4, translationsList.expoRegister.validationErrors.tooShort)
    .required(translationsList.expoRegister.validationErrors.required)
})

export default withRouter(LoginFormContainer)
