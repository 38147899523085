import React from 'react'
import PropTypes from 'prop-types'

import Icon from '../../Form/FormBasicElements/Icon'
import InternalOrExternal from '../InternalOrExternal'

import {
  ButtonComponentContainer,
  ButtonComponentContent,
  ButtonComponentText,
  ButtonIsLoadingContainer
} from './component'

import loaderIconPath from '../../../assets/icons/spinner.svg'

const ButtonComponent = ({
  align,
  bgColor,
  border,
  boxShadow,
  buttonType,
  color,
  disabled,
  display,
  fontBold,
  fullWidth,
  inNewWindow,
  isLoading,
  isLoadingColor,
  label,
  link,
  onClick,
  size,
  type
}) => (
  <InternalOrExternal
    align={align}
    disabled={disabled}
    display={display}
    inNewWindow={inNewWindow}
    link={link}
    onClick={onClick}
    type={type}
  >
    <ButtonComponentContainer
      bgColor={bgColor}
      border={border}
      boxShadow={boxShadow}
      color={color}
      disabled={disabled}
      fontBold={fontBold}
      fullWidth={fullWidth}
      size={size}
      type={buttonType}
    >
      <ButtonComponentContent>
        {isLoading &&
          <ButtonIsLoadingContainer isLoadingColor={isLoadingColor}>
            <Icon icon={loaderIconPath} />
          </ButtonIsLoadingContainer>}

        <ButtonComponentText isLoading={isLoading}>{label}</ButtonComponentText>
      </ButtonComponentContent>
    </ButtonComponentContainer>
  </InternalOrExternal>
)

export default ButtonComponent

ButtonComponent.propTypes = {
  align: PropTypes.oneOf(['center', 'left', 'right']),
  bgColor: PropTypes.oneOf(
    ['pinkColor', 'pinkColorM', 'purpleColor', 'purpleColorM', 'purpleColorS', 'purpleColorXS', 'whiteColor']
  ),
  border: PropTypes.oneOf(['pinkColorS']),
  boxShadow: PropTypes.oneOf(['pink']),
  buttonType: PropTypes.oneOf(['submit', 'button']),
  color: PropTypes.oneOf(['pinkColorM', 'purpleColor', 'whiteColor']),
  disabled: PropTypes.bool,
  display: PropTypes.oneOf(['inline-flex']),
  fontBold: PropTypes.bool,
  fullWidth: PropTypes.bool,
  inNewWindow: PropTypes.bool,
  isLoading: PropTypes.bool,
  isLoadingColor: PropTypes.oneOf(['greyColor', 'purpleColorS', 'yellowColor']),
  label: PropTypes.string,
  link: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['sizeM', 'sizeL']),
  type: PropTypes.oneOf(['external', 'internal', 'onClick'])
}

ButtonComponent.defaultProps = {
  fontBold: false,
  fullWidth: false,
  isLoading: false,
  label: ''
}
