import React, { useState } from 'react'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import ApiService from '../../../../store/services/ApiService'

import * as knowledgeAPI from '../../../../store/api/knowledge'
import { myCompanyActions, myCompanySelectors } from '../../../../store/ducks/my-company'

import AddArticleForm from '../../../../components/Form/FormComponents/AddArticleForm'
import ResetAndSaveButtons from '../../../../components/Form/FormComponents/ResetAndSaveButtons'

import handleMultiSelect from '../../../../utils/forms/handleMultiSelect'
import handleEditor from '../../../../utils/forms/handleField'

import translate from '../../../../stabs/errorMessagesTranslations'
import { primaryEventSelectors } from '../../../../store/ducks/primary-event'
import { DateTime } from 'luxon'
import FormPanel from '../../../../components/Form/FormWrappers/FormPanel'
import AddedItem from '../../../../components/List/AddedItem'
import { DeadlineInfo } from '../../../../components/Form/FormComponents/AddArticleForm/components'
import * as recruitmentAPI from '../../../../store/api/recruitment'

const AddArticleFormContainer = () => {
  const [state, setState] = useState({
    isLoading: false,
    error: null,
    timestamp: (new Date()).getMilliseconds()
  })

  const primaryEvent = useSelector(primaryEventSelectors.getPrimaryEvent)
  const company = useSelector(myCompanySelectors.getMyCompany)

  const dispatch = useDispatch()
  const onSubmit = (values, { setSubmitting }) => {
    setState({ ...state, error: null, isLoading: true })

    ApiService
    .fetch(state.isEditing ? knowledgeAPI.updateArticle(state.editingItemId, values) : knowledgeAPI.addArticle(values))
    .then(data => {
      setSubmitting(false)
      setState({ error: null, isLoading: false, timestamp: (new Date()).getMilliseconds() })

      toast.success('Zapisano!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      })

      const articles = [...company.articles.filter((article) => article._id !== data._id), data]

      const newCompanyState = {
        ...company,
        articles
      }

      dispatch(myCompanyActions.fetchMyCompanySuccess(newCompanyState))
    })
    .catch(error => {
      setSubmitting(false)
      console.log(error)
      console.log(error.details)
      setState({ error: translate('pl', error.details.msg), isLoading: false, timestamp: (new Date()).getMilliseconds() })
    })
  }

  let articlesDeadline = ''
  if (primaryEvent.data.date !== '') {
    articlesDeadline = DateTime.fromFormat(primaryEvent.data.date.substring(3), 'dd.MM.yyyy').minus({ weeks: 1 }).setLocale('pl').toFormat('d LLLL y').toString()
  }

  const mapItemToDisplay = (items) => items.map((item) => ({
    title: `<a target="_blank" href="/artykuly-eksperckie/${item.slug}">${item.title}</a>`,
    description: item.isPublished ? 'Opublikowany' : 'Nie opublikowany',
    isEditing: state.editingItemId === item._id,
    _id: item._id
  })).sort((a, b) => a.isPublished - b.isPublished )

  const onEdit = (setValues) => (itemId) => {
    const {
      _id,
      __v,
      date,
      highlightState,
      slug,
      isPublished,
      ...editingItem
    } = company.articles.filter((article) => article._id === itemId)[0]

    setValues(editingItem)
    setState({ ...state, isEditing: true, editingItemId: itemId, timestamp: (new Date()).getMilliseconds() })
  }

  const onReset = (handleReset) => () => {
    handleReset()
    setState({ ...state, isEditing: false, editingItemId: null, timestamp: (new Date()).getMilliseconds() })
  }

  const onDelete = (itemId) => {
    setState({ ...state, error: null, isLoading: true })

    ApiService
    .fetch(knowledgeAPI.removeArticle(itemId))
    .then(data => {
      setState({ error: null, isLoading: false, isEditing: false, editingItemId: null })

      toast.success('Usunięto!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      })

      const articles = company.articles.filter((el) => el._id !== itemId)

      dispatch(myCompanyActions.fetchMyCompanySuccess({ ...company, articles }))
    })
    .catch(error => {
      console.log(error)
      console.log(error.details)
      setState({ error: translate('pl', error.details.msg), isLoading: false, isEditing: false, editingItemId: null })
    })
  }

  return (
    <Formik
      initialValues={{
        title: '',
        body: '',
        category: '',
        authorFirstName: '',
        authorLastName: '',
        authorPosition: '',
        authorDescription: '',
        articleThumbnailUrl: '',
        authorAvatarUrl: ''
      }}
      validationSchema={BasicCompanyDataSchema}
      onSubmit={onSubmit}
      render={({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        handleReset,
        isSubmitting,
        setFieldTouched,
        setFieldValue,
        setValues,
        touched,
        values
      }) => (
        <form onSubmit={handleSubmit}>
          <FormPanel errors={state.error}>

            {mapItemToDisplay(company.articles).map((item, index) => (
              <AddedItem
                isInsideForm
                key={index}
                title={`${item.isEditing ? '(W trakcie edycji) ' : ''}${item.title}`}
                description={item.description}
                onClickDelete={() => onDelete(item._id)}
                onClickEdit={() => onEdit(setValues)(item._id)}
                isLoading={state.isLoading}
              />
            ))}

            {/*<DeadlineInfo>Uwaga! Prosimy o dodanie artykułów przed: { articlesDeadline }.</DeadlineInfo>*/}
            <AddArticleForm
              errors={errors}
              handleBlur={handleBlur}
              handleChange={handleChange}
              isEditing={state.isEditing}
              handleMultiSelect={(fieldName, item, isMulti) =>
                handleMultiSelect(fieldName, item, isMulti, values, { setFieldValue, setFieldTouched })}
              handleEditor={(fieldName, value) =>
                handleEditor(fieldName, value, { setFieldValue, setFieldTouched })}
              handleField={(fieldName, value) => handleEditor(fieldName, value, { setFieldTouched, setFieldValue })}
              touched={touched}
              values={values}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              timestamp={state.timestamp}
            />
          </FormPanel>

          <ResetAndSaveButtons
            data-test='ResetAndSaveButtonsOne'
            onClickReset={onReset(handleReset)}
            disabledSave={isSubmitting}
            isEditing={state.isEditing}
            isLoadingSave={state.isLoading}
            isSaveSubmitting
          />
        </form>
      )}
    />
  )
}

const BasicCompanyDataSchema = Yup.object().shape({
  title: Yup.string()
  .required('Pole jest wymagane'),
  body: Yup.string()
  .required('Pole jest wymagane'),
  category: Yup.string()
  .required('Pole jest wymagane'),
  authorFirstName: Yup.string()
  .required('Pole jest wymagane'),
  authorLastName: Yup.string()
  .required('Pole jest wymagane'),
  authorPosition: Yup.string()
  .required('Pole jest wymagane'),
  authorDescription: Yup.string()
  .required('Pole jest wymagane')
})

export default AddArticleFormContainer
