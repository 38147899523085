import React, { PureComponent } from 'react'
import ReactCrop from 'react-image-crop'
import PropTypes from 'prop-types'
import 'react-image-crop/dist/ReactCrop.css'
import './style.css'

import ButtonComponent from '../Buttons/ButtonComponent'
import ChooseFileField from '../Form/FormFields/ChooseFileField'
import Modal from '../Modal'

import {
  ImageCropContainer,
  ImageCropHeadingText,
  ImageCropImage,
  ImageCropImageContainer,
  ImageCropInputContainer,
  ImageCropPreviewContainer,
  ImageCropSendButtonContainer,
  ImageCropTitle
} from './components'

class ImageCrop extends PureComponent {
  state = {
    src: null,
    crop: {
      unit: 'px'
    },
    file: null,
    isLoading: false
  };

  onSelectFile = e => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader()
      reader.addEventListener('load', () =>
        this.setState({ src: reader.result })
      )
      reader.readAsDataURL(e.target.files[0])
    }
  };

  // If you setState the crop in here you should return false.
  onImageLoaded = image => {
    this.imageRef = image

    // Center a square percent crop.
    const width = image.width > image.height ? (image.height / image.width) * 100 : 100
    const height = image.height > image.width ? (image.width / image.height) * 100 : 100
    const x = width === 100 ? 0 : (100 - width) / 2
    const y = height === 100 ? 0 : (100 - height) / 2

    this.setState({
      crop: {
        unit: '%',
        width,
        height,
        x,
        y
      }
    })

    return false // Return false if you set crop state in here.
  };

  onCropComplete = crop => {
    this.makeClientCrop(crop)
  };

  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ crop })
  };

  async makeClientCrop (crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        'newFile.jpeg'
      )
      this.setState({ croppedImageUrl })
    }
  }

  getCroppedImg (image, crop, fileName) {
    const canvas = document.createElement('canvas')
    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    canvas.width = crop.width
    canvas.height = crop.height
    const ctx = canvas.getContext('2d')

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    )

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          console.error('Canvas is empty')
          return
        }
        blob.name = fileName
        blob.lastModifiedDate = new Date()

        this.setState({ ...this.state, file: blob })

        window.URL.revokeObjectURL(this.fileUrl)
        this.fileUrl = window.URL.createObjectURL(blob)
        resolve(this.fileUrl)
      }, 'image/jpeg')
    })
  }

  render () {
    const handleOnChange = this.onSelectFile
    const handleOnCropChange = this.onCropChange
    const handleOnCropComplete = this.onCropComplete
    const handleOnImageLoaded = this.onImageLoaded

    const {
      crop,
      croppedImageUrl,
      src
    } = this.state

    const {
      chooseFileButtonDescription,
      chooseFileButtonFieldlabel,
      chooseFileButtonLabel,
      chooseFileButtonSendLabel,
      chooseFileTitle,
      croppYourImageHeadingText,
      croppedImageHeadingText
    } = this.props

    return (
      <Modal onClose={this.props.onClose}>
        <ImageCropContainer>
          <ImageCropTitle>{chooseFileTitle}</ImageCropTitle>

          <ImageCropInputContainer>
            <ChooseFileField
              buttonLabel={chooseFileButtonLabel}
              isLoading={false}
              label={chooseFileButtonFieldlabel}
              onChange={handleOnChange}
              text={chooseFileButtonDescription}
            />
          </ImageCropInputContainer>

          {
            src && (
              <>
                <ImageCropHeadingText>
                  {croppYourImageHeadingText}
                </ImageCropHeadingText>

                <ImageCropImageContainer>
                  <ReactCrop
                    crop={crop}
                    data-test='imageCropReactCrop'
                    keepSelection
                    onChange={handleOnCropChange}
                    onComplete={handleOnCropComplete}
                    onImageLoaded={handleOnImageLoaded}
                    ruleOfThirds
                    src={src}
                  />
                </ImageCropImageContainer>
              </>
            )
          }

          {
            croppedImageUrl && (
              <>
                <ImageCropPreviewContainer>
                  <ImageCropHeadingText>
                    {croppedImageHeadingText}
                  </ImageCropHeadingText>

                  <ImageCropImageContainer>
                    <ImageCropImage src={croppedImageUrl} />
                  </ImageCropImageContainer>
                </ImageCropPreviewContainer>

                <ImageCropSendButtonContainer>
                  <ButtonComponent
                    align='center'
                    bgColor='pinkColor'
                    color='whiteColor'
                    fontBold
                    isLoading={this.state.isLoading}
                    label={chooseFileButtonSendLabel}
                    onClick={() => { this.setState({ isLoading: true }); this.props.onChange(this.state.file, this.props.onClose) }}
                    size='sizeM'
                    type='onClick'
                  />
                </ImageCropSendButtonContainer>
              </>
            )
          }
        </ImageCropContainer>
      </Modal>
    )
  }
}

export default ImageCrop

ImageCrop.propTypes = {
  chooseFileButtonDescription: PropTypes.string,
  chooseFileButtonFieldlabel: PropTypes.string,
  chooseFileButtonLabel: PropTypes.string,
  chooseFileButtonSendLabel: PropTypes.string,
  chooseFileTitle: PropTypes.string,
  croppYourImageHeadingText: PropTypes.string,
  croppedImageHeadingText: PropTypes.string,
  onChange: PropTypes.func
}

ImageCrop.defaultProps = {
  chooseFileButtonLabel: 'Wybierz plik',
  chooseFileButtonSendLabel: 'Wyślij',
  croppYourImageHeadingText: 'Dopasuj wymiary przesłanego obrazu',
  croppedImageHeadingText: 'Podgląd'
}
