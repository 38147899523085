import styled from 'styled-components'

import { SelectableItemsContainer } from '../../../SelectableItems/SelectableItems/components'

import {
  PinkColorM,
  PurpleColorL,
  PurpleColorM
} from '../../../../assets/styles/colors'

export const TrainingItemLeftContainer = styled.div`
  @media (min-width: 992px) {
    flex: 7;
    padding: 0 10% 0 0;
    text-align: left;
  }

  @media (min-width: 320px) and (max-width: 991px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0;
    text-align: center;

    ${SelectableItemsContainer} {
      justify-content: center;
      order: 3;
    }
  }

  @media (min-width: 576px) and (max-width: 991px) {
    ${SelectableItemsContainer} {
      margin: 10px 0 0;
    }
  }

  @media (min-width: 320px) and (max-width: 575px) {
    ${SelectableItemsContainer} {
      margin: 8px 0 0;
    }
  }
`

export const TrainingItemLeftPlace = styled.div`
  color: ${PinkColorM};
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  margin: 30px 0 0;
  text-transform: uppercase;

  @media (min-width: 320px) and (max-width: 991px) {
    order: 1;
  }

  @media (min-width: 575px) and (max-width: 991px) {
    margin: 10px 0 0;
  }

  @media (min-width: 320px) and (max-width: 576px) {
    margin: 8px 0 0;
  }
`

export const TrainingItemLeftTimeContainer = styled.div`
  @media (min-width: 576px) {
    display: none;
  }

  @media (min-width: 320px) and (max-width: 575px) {
    display: flex;
    order: 2;
    justify-content: center;
    margin: 8px 0 0;
    color: ${PurpleColorM};
  }
`

export const TrainingItemLeftTimeStart = styled.div``

export const TrainingItemLeftTimeSeparator = styled.div`
  margin: 0 3px;
`

export const TrainingItemLeftTimeEnd = styled.div``

export const TrainingItemLeftDescription = styled.div`
  font-size: 14px;
  color: ${PurpleColorL};
  white-space: pre-wrap;

  @media (min-width: 1200px) {
    margin: 50px 0 0;
  }

  @media (min-width: 992px) {
    line-height: 30px;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    margin: 30px 0 0;
  }

  @media (min-width: 320px) and (max-width: 991px) {
    line-height: 24px;
    margin: 0;
  }
`

export const TrainingItemTagsContainer = styled.div`
  @media (min-width: 1200px) {
    margin: 100px 0 0;
  }
  
  @media (min-width: 992px) and (max-width: 1199px) {
    margin: 60px 0 0;
  }

  @media (min-width: 320px) and (max-width: 991px) {
    margin: 0;
  }
`

export const TrainingItemLeftButtonContainer = styled.div`
  @media (min-width: 992px) {
    margin: 50px 0 0;
  }

  @media (min-width: 320px) and (max-width: 991px) {
    order: 4;
  }

  @media (min-width: 576px) and (max-width: 991px) {
    margin: 30px auto 0;
  }

  @media (min-width: 320px) and (max-width: 575px) {
    margin: 20px auto 0;
  }
`
