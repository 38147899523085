import React from 'react'
import PropTypes from 'prop-types'

import Label from '../../Form/FormBasicElements/Label'
import OfficeItem from './OfficeItem'

import {
  OfficesContainer,
  OfficesLabelContainer
} from './components'

import translationsList from '../../../stabs/translationsList'

const Offices = ({
  itemsList
}) => (
  <>
    {
      itemsList && (
        <>
          <OfficesLabelContainer>
            <Label label={translationsList.companyPanel.officesLabel} />
          </OfficesLabelContainer>

          <OfficesContainer>
            {
              itemsList.map((office, id) => (
                <OfficeItem
                  city={office.city}
                  key={id}
                  phone={office.phone}
                  postalCode={office.postalCode}
                  street={office.street}
                />
              ))
            }
          </OfficesContainer>
        </>
      )
    }
  </>
)

export default Offices

Offices.propTypes = {
  itemsList: PropTypes.arrayOf(
    PropTypes.shape({
      city: PropTypes.string,
      itemsList: PropTypes.arrayOf(PropTypes.string),
      name: PropTypes.string,
      street: PropTypes.string
    })
  )
}
