import React from 'react'
import PropTypes from 'prop-types'

import Icon from '../../../../Form/FormBasicElements/Icon'
import InternalOrExternal from '../../../../Buttons/InternalOrExternal'

import {
  TrainingItemHeaderArrowContainer
} from './components'

import arrowIconPath from '../../../../../assets/icons/arrow_down.svg'

const TrainingItemHeaderArrow = ({
  isOpen,
  onClick
}) => (
  <InternalOrExternal onClick={onClick}>
    <TrainingItemHeaderArrowContainer isOpen={isOpen}>
      <Icon icon={arrowIconPath} />
    </TrainingItemHeaderArrowContainer>
  </InternalOrExternal>
)

export default TrainingItemHeaderArrow

TrainingItemHeaderArrow.propTypes = {
  isOpen: PropTypes.bool,
  onClick: PropTypes.func
}

TrainingItemHeaderArrow.defaultProps = {
  isOpen: false
}
