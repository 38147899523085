import React from 'react'
import PropTypes from 'prop-types'

import InternalOrExternal from '../../Buttons/InternalOrExternal'

import {
  TabsMenuItem,
  TabsMenuListContainer
} from './Atoms'

const TabsMenu = ({
  itemsList,
  onClick
}) => (
  <>
    {
      itemsList && (
        <TabsMenuListContainer data-test='TabsMenuListContainer'>
          {
            itemsList.map((item, index) => (
              <InternalOrExternal
                data-test='InternalOrExternal'
                key={index}
                onClick={() => onClick(item.value)}
              >
                <TabsMenuItem
                  data-test='TabsMenuItem'
                  isActive={item.isActive}
                  isBold={item.isBold}
                >
                  {item.label}
                </TabsMenuItem>
              </InternalOrExternal>
            ))
          }
        </TabsMenuListContainer>
      )
    }
  </>
)

export default TabsMenu

TabsMenu.propTypes = {
  itemsList: PropTypes.arrayOf(
    PropTypes.shape({
      isActive: PropTypes.bool,
      isBold: PropTypes.bool,
      label: PropTypes.string
    })
  ),
  onClick: PropTypes.func
}
