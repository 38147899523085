import styled from 'styled-components'

import {
  PurpleColor,
  PurpleColorM,
  PurpleColorS,
  PurpleColorXS,
  WhiteColor,
  PinkColorM
} from '../../../assets/styles/colors'

export const LinkComponentContainer = styled.div`
  display: inline-grid;
  grid-auto-flow: column;
  grid-column-gap: 8px;
  align-items: center;

  ${props => {
    switch (props.colorText) {
      case 'pinkColorM': return `color: ${PinkColorM};`
      case 'purpleColor': return `color: ${PurpleColor};`
      case 'purpleColorM': return `color: ${PurpleColorM};`
      case 'purpleColorS': return `color: ${PurpleColorS};`
      case 'purpleColorXS': return `color: ${PurpleColorXS};`
      case 'whiteColor': return `color: ${WhiteColor};`
      default: return ''
    }
  }}

  ${props => {
    switch (props.fontSize) {
      case 'sizeXS': return `
        line-height: 16px;
        font-size: 10px;
      `
      case 'sizeS': return `
        line-height: 18px;
        font-size: 12px;
      `
      case 'sizeM': return `
        line-height: 21px;
        font-size: 14px;
      `
      case 'sizeL': return `
        line-height: 24px;
        font-size: 16px;
      `
      case 'sizeXL': return `
        line-height: 27px;
        font-size: 18px;
      `
      default: return `
        line-height: 21px;
        font-size: 14px;
      `
    }
  }}

  ${props => {
    switch (props.fontBold) {
      case true: return 'font-weight: 600;'
      default: return 'font-weight: 300;'
    }
  }}
`

export const LinkComponentIconContainer = styled.div`
  ${props => {
    switch (props.colorFill) {
      case 'pinkColorM': return `
       & g {
          fill: ${PinkColorM};
        }
      `
      case 'purpleColor': return `
       & g {
          fill: ${PurpleColor}; 
        }
      `
      case 'purpleColorM': return `
       & g {
          fill: ${PurpleColorM};
        }
      `
      case 'purpleColorS': return `
       & g {
          fill: ${PurpleColorS};
        }
      `
      case 'purpleColorXS': return `
       & g {
          fill: ${PurpleColorXS};
        }
      `
      case 'whiteColor': return `
       & g {
          fill: ${WhiteColor};
        }
      `
      default: return ''
    }
  }}

  ${props => {
    switch (props.colorStroke) {
      case 'pinkColorM': return `
       & g {
          stroke: ${PinkColorM};
        }
      `
      case 'purpleColor': return `
       & g {
          stroke: ${PurpleColor}; 
        }
      `
      case 'purpleColorM': return `
       & g {
          stroke: ${PurpleColorM};
        }
      `
      case 'purpleColorS': return `
       & g {
          stroke: ${PurpleColorS};
        }
      `
      case 'purpleColorXS': return `
       & g {
          stroke: ${PurpleColorXS};
        }
      `
      case 'whiteColor': return `
       & g {
          stroke: ${WhiteColor};
        }
      `
      default: return ''
    }
  }}

  ${props => {
    switch (props.rotateIcons) {
      case '90': return 'transform: rotate(90deg);'
      case '180': return 'transform: rotate(180deg);'
      default: return ''
    }
  }}

  ${props => {
    switch (props.iconSize) {
      case 'sizeXS': return 'width: 10px;'
      case 'sizeS': return 'width: 12px;'
      case 'sizeM': return 'width: 14px;'
      case 'sizeL': return 'width: 16px;'
      case 'sizeXL': return 'width: 18px;'
      default: return 'width: 14px;'
    }
  }}
`

export const LinkComponentText = styled.div``
