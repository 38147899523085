import styled from 'styled-components'

import {
  PurpleColor,
  PurpleColorM
} from '../../assets/styles/colors'

export const ErrorPageContainer = styled.div`
  margin: 100px 0;
`

export const ErrorPageNumber = styled.div`
  line-height: 280px;
  font-size: 220px;
  color: ${PurpleColor};
`

export const ErrorPageTile = styled.div`
  margin: 20px 0 0;
  line-height: 42px;
  font-size: 32px;
  color: ${PurpleColorM};
`

export const ErrorPageText = styled.div`
  margin: 20px 0 0;
  line-height: 32px;
  font-size: 24px;
  color: ${PurpleColorM};
`

export const ErrorPageLinksContainer = styled.div`
  align-items: center;
  color: ${PurpleColorM};
  display: inline-flex;
  font-size: 16px;
  line-height: 24px;
  margin: 20px 0 0;
`

export const ErrorPageLinkContainer = styled.div`
  margin: 0 5px;
`
