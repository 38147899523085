import eject from '../utils/ejectEpics'

import articles, { articlesEpics } from './articles'
import companies from './companies'
import cv, { cvEpics } from './my-cv'
import cvs, { cvsEpics } from './cvs'
import flags from './flags'
import industries, { industriesEpics } from './industries'
import jobOffers, { jobOfferEpics } from './job-offers'
import myCompany, { myCompanyEpics } from './my-company'
import myUser, { myUserEpics } from './my-user'
import primaryEvent, { primaryEventEpics } from './primary-event'
import tags, { tagsEpics } from './tags'
import trainings, { trainingsEpics } from './trainings'
import values, { valuesEpics } from './values'

export default {
  articles,
  companies,
  cv,
  cvs,
  flags,
  industries,
  jobOffers,
  myCompany,
  myUser,
  primaryEvent,
  tags,
  trainings,
  values
}

export const epics = [
  ...eject(articlesEpics),
  ...eject(cvEpics),
  ...eject(cvsEpics),
  ...eject(industriesEpics),
  ...eject(jobOfferEpics),
  ...eject(myCompanyEpics),
  ...eject(myUserEpics),
  ...eject(primaryEventEpics),
  ...eject(tagsEpics),
  ...eject(trainingsEpics),
  // ...eject(valuesEpics)
]
