import React, { useState } from 'react'
import { Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'

import { myCompanyActions, myCompanySelectors } from '../../../../store/ducks/my-company'

import AddWhoWeAreLookingForForm from '../../../../components/Form/FormComponents/AddWhoWeAreLookingForForm'
import ResetAndSaveButtons from '../../../../components/Form/FormComponents/ResetAndSaveButtons'

import handleMultiSelect from '../../../../utils/forms/handleMultiSelect'
import * as Yup from 'yup'
import ApiService from '../../../../store/services/ApiService'
import * as companyAPI from '../../../../store/api/company'
import translate from '../../../../stabs/errorMessagesTranslations'
import { toast } from 'react-toastify'

const AddWhoWeAreLookingForFormContainer = () => {
  const [state, setState] = useState({
    isLoading: false,
    error: null
  })

  const company = useSelector(myCompanySelectors.getMyCompany)

  const dispatch = useDispatch()
  const onSubmit = (values, { setSubmitting }) => {
    setState({ ...state, error: null, isLoading: true })

    ApiService
      .fetch(companyAPI.updateMyRecruitment(values))
      .then(data => {
        setSubmitting(false)

        toast.success('Zapisano!', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        })

        dispatch(myCompanyActions.fetchMyCompanySuccess(data))
      })
      .catch(error => {
        setSubmitting(false)
        console.log(error)
        setState({ ...state, error: translate('pl', error.details.msg), isLoading: false })
      })
  }

  return (
    <Formik
      initialValues={{
        additionalInfo: company.recruitment.additionalInfo,
        assessmentCenter: company.recruitment.assessmentCenter,
        cities: company.recruitment.cities,
        departments: company.recruitment.departments,
        employmentTypes: company.recruitment.employmentTypes,
        foreignLanguages: company.recruitment.foreignLanguages,
        jobInterview: company.recruitment.jobInterview,
        needsCv: company.recruitment.needsCv,
        needsMotivationalList: company.recruitment.needsMotivationalList,
        preferredCandidates: company.recruitment.preferredCandidates,
        preferredDegreeCourses: company.recruitment.preferredDegreeCourses
      }}
      validationSchema={Schema}
      onSubmit={onSubmit}
      render={({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        handleReset,
        isSubmitting,
        setFieldTouched,
        setFieldValue,
        touched,
        values
      }) => (
        <form onSubmit={handleSubmit}>
          <AddWhoWeAreLookingForForm
            apiError={state.error}
            errors={errors}
            handleBlur={handleBlur}
            handleChange={handleChange}
            handleMultiSelect={(fieldName, item, isMulti) =>
              handleMultiSelect(fieldName, item, isMulti, values, { setFieldValue, setFieldTouched })}
            touched={touched}
            values={values}
          />

          <ResetAndSaveButtons
            data-test='ResetAndSaveButtonsOne'
            onClickReset={handleReset}
            disabledSave={isSubmitting}
            isLoadingSave={state.isLoading}
            isClearing={false}
            isSaveSubmitting
          />
        </form>
      )}
    />
  )
}

const Schema = Yup.object().shape({
  needsCv: Yup.bool()
    .required('Pole jest wymagane'),
  assessmentCenter: Yup.bool()
    .required('Pole jest wymagane'),
  needsMotivationalList: Yup.bool()
    .required('Pole jest wymagane'),
  jobInterview: Yup.bool()
    .required('Pole jest wymagane'),
  additionalInfo: Yup.string(),
  cities: Yup.string(),
  departments: Yup.string(),
  foreignLanguages: Yup.string(),
  preferredDegreeCourses: Yup.string(),
  employmentTypes: Yup.array(),
  preferredCandidates: Yup.array()
})

export default AddWhoWeAreLookingForFormContainer
