import React from 'react'
import PropTypes from 'prop-types'

import Icon from '../../Form/FormBasicElements/Icon'

import {
  ZoneMenuItemContainer,
  ZoneMenuItemIcon,
  ZoneMenuItemText
} from './components'

const ZoneMenuItem = ({
  icon,
  isActive,
  label
}) => (
  <ZoneMenuItemContainer isActive={isActive}>
    <ZoneMenuItemIcon>
      <Icon icon={icon} />
    </ZoneMenuItemIcon>

    <ZoneMenuItemText>{label}</ZoneMenuItemText>
  </ZoneMenuItemContainer>
)

export default ZoneMenuItem

ZoneMenuItem.propTypes = {
  isActive: PropTypes.bool,
  icon: PropTypes.string,
  label: PropTypes.string
}

ZoneMenuItem.defaultProps = {
  isActive: false,
  icon: '',
  label: ''
}
