import styled from 'styled-components'

import { SilverColor } from '../../../../assets/styles/colors'

export const LabelContainer = styled.label`
  display: block;
  line-height: 18px;
  font-size: 14px;
  font-weight: 600;
  color: ${SilverColor};
  letter-spacing: 1px;
  text-align: left;
  text-transform: uppercase;
  
  @media (min-width: 1200px) {
    padding: 0 0 8px;
  }

  @media (min-width: 576px) and (max-width: 1199px) {
    padding: 0 0 5px;
  }

  @media (min-width: 320px) and (max-width: 575px) {
    padding: 0 0 3px;
  }
`

export const LabelRequired = styled.span``
