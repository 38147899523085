import React from 'react'
import PropTypes from 'prop-types'

// import InternalOrExternal from '../../../Buttons/InternalOrExternal'
import LoginFormContainer from '../../../../containers/AuthContainers/AuthFormsContainer/LoginFormContainer'
import RegisterFormContainer from '../../../../containers/AuthContainers/AuthFormsContainer/RegisterFormContainer'
import RightSlideElement from './RightSlideElement'

import {
  LoginAndRegisterContainer,
  // LoginAndRegisterDotListItem,
  // LoginAndRegisterDotsList,
  LoginAndRegisterLeft,
  LoginAndRegisterRight,
  LoginAndRegisterRightContent
} from './components'

import translationsList from '../../../../stabs/translationsList'

const LoginAndRegister = ({
  isUser,
  login,
  registry
}) => (
  <LoginAndRegisterContainer>
    <LoginAndRegisterLeft>
      {login && (
        <LoginFormContainer />
      )}

      {registry && (
        <RegisterFormContainer isUser={isUser} />
      )}
    </LoginAndRegisterLeft>

    <LoginAndRegisterRight>
      <LoginAndRegisterRightContent>
        {translationsList.loginAndRegisterSliderItems.map((item, id) => (
          <RightSlideElement
            image={item.image}
            key={id}
            text={item.text}
            title={item.title}
          />
        ))}
      </LoginAndRegisterRightContent>

      {/* <LoginAndRegisterDotsList> */}
      {/* {translationsList.loginAndRegisterSliderItems.map((item, id) => ( */}
      {/* <InternalOrExternal */}
      {/* key={id} */}
      {/* onClick={() => null} // TODO */}
      {/* > */}
      {/* <LoginAndRegisterDotListItem isSelected={item.isSelected} /> */}
      {/* </InternalOrExternal> */}
      {/* ))} */}
      {/* </LoginAndRegisterDotsList> */}
    </LoginAndRegisterRight>
  </LoginAndRegisterContainer>
)

export default LoginAndRegister

LoginAndRegister.propTypes = {
  isCompany: PropTypes.bool,
  login: PropTypes.bool,
  registry: PropTypes.bool
}

LoginAndRegister.defaultProps = {
  isCompany: false,
  login: false,
  registry: false
}

// TODO FILL PROPTYPES
