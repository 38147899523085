import { createSelector } from 'reselect'

import flagsSelectors from '../flags/selectors'

const getTrainingsDisplay = (state) => state.trainings.display
const getTrainingsContent = (state) => state.trainings.data
const getTrainingsFiltered = createSelector(
  [getTrainingsContent, (state) => state.tags.data.filter((tag) => tag.isActive)],
  (content, tags) => content.filter((training) => {
    if (tags.length === 0) {
      return []
    }

    const selectedTagsIds = tags.map((tag) => tag._id)
    const trainingTagsIds = training.tags.map((tag) => tag._id)

    return selectedTagsIds.some(tagId => trainingTagsIds.indexOf(tagId) > -1)
  })
)

const getTrainingsAll = createSelector(
  [getTrainingsFiltered],
  (trainings) => trainings.sort((a, b) => {
    const trainingAStart = a.timeStart.split(':')
    const trainingBStart = b.timeStart.split(':')

    return trainingAStart[0] - trainingBStart[0] || trainingAStart[1] - trainingBStart[1]
  }).filter((training) => training.isPublished)
)

const getTrainingsEnrolled = createSelector(
  [getTrainingsAll],
  (trainings) => trainings.filter((training) => training.isEnrolled)
)

const getTrainings = createSelector(
  [getTrainingsAll, getTrainingsDisplay, flagsSelectors.getFlag('selectDay')],
  (trainings, display, selectedDay) => trainings.filter((training) => training.day === selectedDay).slice(0, display)
)

export default {
  getTrainings,
  getTrainingsAll,
  getTrainingsFiltered,
  getTrainingsEnrolled,
  getTrainingsContent,
  getTrainingsDisplay
}
