import styled from 'styled-components'

import {
  PinkColorM,
  PurpleColor,
  PurpleColorM,
  PurpleColorS,
  WhiteColor
} from '../../../assets/styles/colors'

export const StepItemContainer = styled.div`
  &:last-child {
    & > div > span {
      display: none;
    }
  }
`

export const StepItemSteps = styled.div`
  margin: 0 0 0 calc(50% - 16px);
  position: relative;
  width: 100%;
`

export const StepItemIconContainer = styled.div`
  align-items: center;
  border-radius: 50%;
  display: flex;
  height: 34px;
  justify-content: center;
  width: 34px;

  ${props => {
    switch (props.type) {
      case 'isCurrent': return `
        background-color: ${PinkColorM};
        border: 8px solid ${PinkColorM};
      `
      case 'isNext': return `
        background-color: ${WhiteColor};
        border: 8px solid ${PurpleColorS};
      `
      case 'isPrevious': return `
        background-color: ${PinkColorM};
        border: 8px solid ${PinkColorM};
      `
      default: return ''
    }
  }}

  & g {
    fill: ${WhiteColor};
    stroke: ${WhiteColor};
  }
`

export const StepItemRoad = styled.span`
  height: 8px;
  left: 34px;
  position: absolute;
  top: calc(50% - 4px);
  width: calc(100% - 10px);

  ${props => {
    switch (props.type) {
      case 'isCurrent': return `background: linear-gradient(270deg,${PurpleColorS} 0%,${PinkColorM} 50%,${PinkColorM} 100%);`
      case 'isNext': return `background: ${PurpleColorS};`
      case 'isPrevious': return `background: ${PinkColorM};`
      default: return ''
    }
  }}
`

export const StepItemNumberContainer = styled.div`
  @media (min-width: 768px) {
    padding: 32px 15px 0;
  }

  @media (min-width: 320px) and (max-width: 767px) {
    padding: 10px 5px 0;
  }
`

export const StepItemNumber = styled.h2`
  font-weight: 600;

  ${props => {
    switch (props.type) {
      case 'isCurrent': return `color: ${PinkColorM};`
      case 'isNext': return `color: ${PurpleColorS};`
      case 'isPrevious': return `color: ${PinkColorM};`
      default: return ''
    }
  }}

  @media (min-width: 768px) {
    font-size: 72px;
    line-height: 110px;
  }

  @media (min-width: 320px) and (max-width: 767px) {
    font-size: 36px;
    line-height: 50px;
  }
`

export const StepItemTitleContainer = styled.div`
  @media (min-width: 768px) {
    padding: 4px 15px 0;
  }

  @media (min-width: 320px) and (max-width: 767px) {
    padding: 0 5px;
  }
`

export const StepItemTitle = styled.h1`
  font-weight: 600;

  ${props => {
    switch (props.type) {
      case 'isCurrent': return `color: ${PinkColorM};`
      case 'isNext': return `color: ${PurpleColor};`
      case 'isPrevious': return `color: ${PinkColorM};`
      default: return ''
    }
  }}

  ${props =>
    props.withLinks && 'text-decoration: underline;'
  }

  @media (min-width: 768px) {
    font-size: 20px;
    line-height: 30px;
  }

  @media (min-width: 320px) and (max-width: 767px) {
    font-size: 12px;
    line-height: 18px;
  }
`

export const StepItemDescription = styled.div`
  color: ${PurpleColorM};
  font-size: 14px;
  line-height: 24px;

  @media (min-width: 768px) {
    padding: 32px 15px 0;
  }

  @media (min-width: 320px) and (max-width: 767px) {
    padding: 15px 5px 0;
  }

  @media (min-width: 320px) and (max-width: 480px) {
    display: none;
  }
`
