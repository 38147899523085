import types from './types'
import { createReducer } from '../../utils'

const initialState = {
  userStepFourValuesSelector: 'selectValues', // selectValues, selectImportant, summary
  qrInfo: false,
  showArticleGuideline: false,
  selectedCvTemplate: null,
  selectDay: 'day-1',
  platformShowCompanies: 12,
  platformShowValuesSelector: false
}

const myUserReducer = createReducer(initialState)({
  [types.CHANGE_FLAG]: (state, action) => ({ ...state, [action.payload.key]: action.payload.value })
})

export default myUserReducer
