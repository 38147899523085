import React from 'react'
import { useSelector } from 'react-redux'
import { companiesSelectors } from '../../../../store/ducks/companies'
import { withRouter } from 'react-router-dom'

const CompanySliderContainer = ({ renderSlider: RenderSlider, slug, history }) => {
  const companies = useSelector(companiesSelectors.getCompaniesContent)
  const companyPosition = getPosition(companies, slug)

  const onChange = (event) => {
    if (event === 'clickLeft' && companyPosition - 1 >= 0) {
      history.replace(`/pracodawcy/${companies[companyPosition - 1].slug}`)
      return
    }

    if (event === 'clickRight' && companyPosition + 1 < companies.length) {
      history.replace(`/pracodawcy/${companies[companyPosition + 1].slug}`)
    }
  }

  return (
    <RenderSlider action={onChange} showLeft={companyPosition !== 0} showRight={companyPosition !== companies.length} />
  )
}

const getPosition = (companies, slug) => {
  let companyPosition

  companies.forEach((company, index) => {
    if (company.slug === slug) {
      companyPosition = index
    }
  })

  return companyPosition
}

export default withRouter(CompanySliderContainer)
