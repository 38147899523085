import { ofType } from 'redux-observable'
import { catchError, mergeMap } from 'rxjs/operators'
import { of } from 'rxjs'
import getError from '../../utils/getError'

import * as companyAPI from '../../api/company'

import actions from './actions'
import types from './types'

const fetchMyCompany = (action$, state$, { fetch }) => {
  return action$
    .pipe(
      ofType(types.FETCH_REQUESTED),
      mergeMap(() =>
        fetch(companyAPI.getMe().toObject()).pipe(
          mergeMap((response) => of(
            actions.fetchMyCompanySuccess(response.response)
          )),
          catchError((error) => of(actions.fetchMyCompanyError(getError(error))))
        )
      )
    )
}

const addIndustryToCompany = (action$, state$, { fetch }) => {
  return action$
    .pipe(
      ofType(types.ADD_INDUSTRY),
      mergeMap((action) =>
        fetch(companyAPI.addIndustry(action.payload.industryId).toObject()).pipe(
          mergeMap((response) => of(
            actions.fetchMyCompanySuccess(response.response)
          )),
          catchError((error) => of(actions.fetchMyCompanyError(getError(error))))
        )
      )
    )
}

const removeIndustryFromCompany = (action$, state$, { fetch }) => {
  return action$
    .pipe(
      ofType(types.REMOVE_INDUSTRY),
      mergeMap((action) =>
        fetch(companyAPI.removeIndustry(action.payload.industryId).toObject()).pipe(
          mergeMap((response) => of(
            actions.fetchMyCompanySuccess(response.response)
          )),
          catchError((error) => of(actions.fetchMyCompanyError(getError(error))))
        )
      )
    )
}

export default {
  addIndustryToCompany,
  fetchMyCompany,
  removeIndustryFromCompany
}
