import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import AuthService from '../../store/services/AuthService'
import { primaryEventSelectors } from '../../store/ducks/primary-event'
import { myUserSelectors } from '../../store/ducks/my-user'
import { myCompanySelectors } from '../../store/ducks/my-company'

import FormStepsWrapper from '../../components/Wrappers/FormStepsWrapper'
import AdminPanelContainer from '../AdminPanelContainer'
import ErrorPage from '../../pages/ErrorPage'

const PlatformContainer = ({ step, type }) => {
  const primaryEvent = useSelector(primaryEventSelectors.getPrimaryEvent)
  const myUser = useSelector(myUserSelectors.getMyUser)
  const myCompany = useSelector(myCompanySelectors.getMyCompany)
  const history = useHistory()

  let selectedStep = step

  const userType = AuthService.getUserType()

  if (userType !== type && type !== undefined && type !== 'cv') {
    return <ErrorPage />
  }

  if (userType === 'admin') {
    return <AdminPanelContainer />
  }

  if (selectedStep === 0) {
    selectedStep = userType === 'user'
      ? myUser.data.step.replace('userStep', '')
      : myCompany.step.replace('companyStep', '')
  }

  if (userType === 'user' && (selectedStep === '4' || selectedStep === '5')) {
    selectedStep = 3
  }

  let option = `${userType}Step${selectedStep}`

  if (type === 'cv') {
    option = `creatorCvStep${step}`
  }

  return <FormStepsWrapper option={option} primaryEvent={primaryEvent.data} />
}

export default PlatformContainer
