import types from './types'

const fetchPrimaryEventError = (error) => ({
  type: types.FETCH_ERROR,
  payload: error
})

const fetchPrimaryEventRequested = () => ({
  type: types.FETCH_REQUESTED
})

const fetchPrimaryEventSuccess = (data) => ({
  type: types.FETCH_SUCCESS,
  payload: data
})

export default {
  fetchPrimaryEventError,
  fetchPrimaryEventRequested,
  fetchPrimaryEventSuccess
}
