import React from 'react'
import PropTypes from 'prop-types'
import ReactModal from 'react-modal'
import { Scrollbars } from 'react-custom-scrollbars'
import { withRouter } from 'react-router-dom'

import Icon from '../Form/FormBasicElements/Icon'
import InternalOrExternal from '../Buttons/InternalOrExternal'

import {
  ModalCloseButtonContainer,
  ModalContainer,
  ModalContainerStyles,
  ModalContent
} from './components'

import closeIconPath from '../../assets/icons/close.svg'

class Modal extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      showModal: true
    }

    this.handleOpenModal = this.handleOpenModal.bind(this)
    this.handleCloseModal = this.handleCloseModal.bind(this)
  }

  componentWillUnmount () {
    const bodyElement = document.getElementsByTagName('body')[0]
    bodyElement.classList.remove('ReactModal__Body--open')
  }

  handleOpenModal () {
    this.setState({ showModal: true })
  }

  handleCloseModal () {
    if (this.props.link !== undefined) {
      this.props.history.push(this.props.link)
    }

    this.props.onClose()

    if (this.props.hasClose) {
      this.setState({ showModal: false })
    }
  }

  render () {
    const {
      children,
      inModal
    } = this.props

    return (
      <ReactModal
        contentLabel='Modal Container'
        isOpen={this.state.showModal}
        onRequestClose={this.handleCloseModal}
        style={ModalContainerStyles}
      >
        <ModalContainer>
          <Scrollbars
            autoHide
            autoHideDuration={100}
          >
            <ModalCloseButtonContainer>
              <InternalOrExternal onClick={this.handleCloseModal}>
                <Icon icon={closeIconPath} />
              </InternalOrExternal>
            </ModalCloseButtonContainer>

            <ModalContent inModal={inModal}>
              {children}
            </ModalContent>
          </Scrollbars>
        </ModalContainer>
      </ReactModal>
    )
  }
}

export default withRouter(Modal)

Modal.propTypes = {
  children: PropTypes.any,
  inModal: PropTypes.bool
}

Modal.defaultProps = {
  onClose: () => null
}
