import styled from 'styled-components'

export const FormTwoColumnsContainer = styled.div`
  ${props => props.columnReverse && `
    display: flex;
    flex-direction: column-reverse;
  `
}

  @media (min-width: 992px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 30px;
  }
`
