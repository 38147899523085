const LOAD = 'companies/LOAD'

const SHOW_LESS = 'companies/SHOW_LESS'
const SHOW_MORE = 'companies/SHOW_MORE'

export default {
  LOAD,
  SHOW_LESS,
  SHOW_MORE
}
