import _ from 'lodash'
import types from './types'
import { createReducer } from '../../utils'

const initialState = {
  data: {
    _id: '',
    calendarUrl: '',
    city: '',
    date: '',
    drivingUrl: '',
    name: '',
    place: '',
    timeEnd: '',
    timeStart: ''
  },
  error: null,
  isLoading: false
}

const primaryEventReducer = createReducer(initialState)({
  [types.FETCH_ERROR]: (state, action) => ({ data: null, isLoading: false, error: action.payload }),
  [types.FETCH_REQUESTED]: () => ({ data: null, isLoading: true, error: null }),
  [types.FETCH_SUCCESS]: (state, action) => {
    const data = _.pick(action.payload, [
      '_id',
      'calendarUrl',
      'city',
      'date',
      'drivingUrl',
      'name',
      'place',
      'timeEnd',
      'timeStart'
    ])

    return { data, isLoading: false, error: null }
  }
})

export default primaryEventReducer
