import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import ButtonComponent from '../Buttons/ButtonComponent'
import Dropdown from '../Form/FormBasicElements/Dropdown'
import Icon from '../Form/FormBasicElements/Icon'
import IconAndTextElements from '../DropdownElements/DropdownMenus/IconAndTextElements'
import InternalOrExternal from '../Buttons/InternalOrExternal'
import LinkComponent from '../Buttons/LinkComponent'
import Navigation from '../Navigation'
import TopNavButton from '../DropdownElements/DropdownButtons/TopNavButton'
import Image from '../Form/FormBasicElements/Image'

import {
  HeaderContainer,
  HeaderLogoContainer,
  HeaderLogoDesktopContainer,
  HeaderLogoMobileContainer,
  HeaderRightSideButtonContainer,
  HeaderRightSideContainer,
  HeaderRightSideIsLoggedOffContainer,
  HeaderRightSideIsLoggedOnContainer,
  HeaderRightSideLinkAndButtonContainer,
  HeaderRightSideLinkContainer,
  HeaderRightSideNavigationContainer,
  HeaderRightSideNavigationContent
} from './Atoms'

import logoMobilePath from '../../assets/icons/heart.svg' // TODO Add proper Icon
import logoPath from '../../assets/icons/logo.svg'
import translationsList from '../../stabs/translationsList'
import userIconPath from '../../assets/icons/subtitle/subtitle_user.svg'
import ukr_flag from '../../assets/img/Ukraine-Flag-icon.png'
import { menuList } from './consts'

const Header = ({
  creatorCvPage,
  eventPage,
  isLoggedOn,
  menuItemsList,
  userData,
  withNav
}) => (
  <HeaderContainer data-test='HeaderContainer'>
    <HeaderLogoContainer data-test='HeaderLogoContainer'>
      <InternalOrExternal
        data-test='InternalOrExternal'
        link='/'
        type='internal'
      >
        <HeaderLogoDesktopContainer data-test='HeaderLogoDesktopContainer'>
          <Icon
            data-test='HeaderLogoDesktopIcon'
            icon={logoPath}
          />
        </HeaderLogoDesktopContainer>

        <HeaderLogoMobileContainer data-test='HeaderLogoMobileContainer'>
          <Icon
            data-test='HeaderLogoMobileIcon'
            icon={logoMobilePath}
          />
        </HeaderLogoMobileContainer>
      </InternalOrExternal>
    </HeaderLogoContainer>

    <HeaderRightSideContainer data-test='HeaderRightSideContainer'>
      {
        isLoggedOn
          ? (
            <HeaderRightSideIsLoggedOnContainer data-test='HeaderRightSideIsLoggedOnContainer'>
              <Dropdown
                Button={TopNavButton}
                MenuContent={IconAndTextElements}
                buttonProps={userData}
                data-test='isLoggedOnDropdown'
                disabled={false} // TODO
                menuContentProps={{
                  itemsList: menuList
                }}
                width='auto'
              />
            </HeaderRightSideIsLoggedOnContainer>
          )

          : (
            <HeaderRightSideIsLoggedOffContainer data-test='HeaderRightSideIsLoggedOffContainer'>
              <HeaderRightSideNavigationContainer data-test='HeaderRightSideNavigationContainer'>
                {
                  withNav && (
                    <HeaderRightSideNavigationContent data-test='HeaderRightSideNavigationContent'>
                      <Navigation
                        data-test='Navigation'
                        itemsList={menuItemsList}
                      />
                    </HeaderRightSideNavigationContent>
                  )
                }
              </HeaderRightSideNavigationContainer>

              
              <HeaderRightSideLinkAndButtonContainer data-test='HeaderRightSideLinkAndButtonContainer'>
                <Link to={{ pathname: "/ukr" }}>
                  <Image source={ukr_flag} />
                </Link>
                {/*<HeaderRightSideLinkContainer data-test='HeaderRightSideLinkContainer'>
                  <LinkComponent
                    colorText='purpleColorM'
                    colorStroke='purpleColorM'
                    data-test='isLoggedOffLinkComponent'
                    fontSize='sizeXL'
                    iconBefore={userIconPath}
                    label={translationsList.login}
                    link='/logowanie'
                    type='internal'
                  />
                </HeaderRightSideLinkContainer>

                <HeaderRightSideButtonContainer data-test='HeaderRightSideButtonContainer'>
                  {
                    creatorCvPage && (
                      <ButtonComponent
                        bgColor='pinkColor'
                        color='whiteColor'
                        data-test='creatorCvIsLoggedOffButtonComponent'
                        fontBold
                        fullWidth
                        label='Stwórz CV'
                        link='/kreator-cv'
                        size='sizeM'
                        type='internal'
                      />
                    )
                  }

                  {
                    eventPage && (
                      <ButtonComponent
                        bgColor='pinkColor'
                        color='whiteColor'
                        data-test='eventPageIsLoggedOffButtonComponent'
                        fontBold
                        fullWidth
                        label={translationsList.register}
                        link='/rejestracja'
                        size='sizeM'
                        type='internal'
                      />
                    )
                  }
                </HeaderRightSideButtonContainer>
                */}
              </HeaderRightSideLinkAndButtonContainer>
              
            </HeaderRightSideIsLoggedOffContainer>
          )
      }
    </HeaderRightSideContainer>
  </HeaderContainer>
)

export default Header

Header.propTypes = {
  creatorCvPage: PropTypes.bool,
  eventPage: PropTypes.bool,
  isLoggedOn: PropTypes.bool,
  menuItemsList: PropTypes.arrayOf(
    PropTypes.shape({
      fontBold: PropTypes.bool,
      inNewWindow: PropTypes.bool,
      label: PropTypes.string,
      link: PropTypes.string
    })
  ),
  userData: PropTypes.shape({
    alt: PropTypes.string,
    image: PropTypes.string,
    isMenuOpen: PropTypes.bool,
    name: PropTypes.string,
    position: PropTypes.string,
    surname: PropTypes.string
  }),
  withNav: PropTypes.bool
}

Header.defaultProps = {
  creatorCvPage: false,
  eventPage: true,
  isLoggedOn: false,
  userData: {},
  withNav: false
}
