import { of } from 'rxjs'
import { catchError, map, mergeMap } from 'rxjs/operators'
import { ofType } from 'redux-observable'

import actions from './actions'
import types from './types'
import * as recruitmentAPI from '../../api/recruitment'

import getError from '../../utils/getError'
import { toast } from 'react-toastify'

const fetchCvs = (action$, state$, { fetch }) => {
  return action$
    .pipe(
      ofType(types.FETCH_REQUESTED),
      mergeMap(() => {
        return fetch(recruitmentAPI.getMyCvs().toObject()).pipe(
          map((response) => actions.fetchCvsSuccess(response.response)),
          catchError((error) => of(actions.fetchCvsError(getError(error))))
        )
      })
    )
}

const deleteCv = (action$, state$, { fetch }) => {
  return action$
    .pipe(
      ofType(types.DELETE),
      mergeMap((action) => {
        return fetch(recruitmentAPI.deleteMyCV(action.payload.cvId).toObject()).pipe(
          map((response) => actions.fetchCvsSuccess(response.response)),
          catchError((error) => {
            toast.error('Wystąpił błąd!', {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true
            })

            console.error(error)
          })
        )
      })
    )
}

export default {
  fetchCvs,
  deleteCv
}
