import styled from 'styled-components'

import {
  PurpleColorL,
  SilverColor,
  SilverColorXS,
  WhiteColor
} from '../../assets/styles/colors'

export const SquarePanelContainer = styled.div`
  border-radius: 20px;
  box-shadow: 0 10px 15px 0 rgba(217, 209, 255, 0.3);
  padding: 30px;
  text-align: left;

  ${props => {
    switch (props.selected) {
      case true: return `background-color: ${SilverColorXS};`
      default: return `background-color: ${WhiteColor};`
    }
  }}
`

export const SquarePanelContent = styled.div``

export const SquarePanelIconContainer = styled.div`
  display: inline-flex;
  
  @media (min-width: 768px) {
    margin: 0 0 30px;
  }

  @media (min-width: 320px) and (max-width: 767px) {
    margin: 0 0 20px;
  }
`

export const SquarePanelDescription = styled.div`
  color: ${PurpleColorL};
`

export const SquarePanelTitle = styled.div`
  align-items: center;
  color: ${SilverColor};
  display: flex;
  font-size: 20px;
  font-weight: bold;
  height: 100%;
  justify-content: center;
  line-height: 26px;
  text-align: center;
`
