import types from './types'

const fetchTagsError = (error) => ({
  type: types.FETCH_ERROR,
  payload: error
})

const fetchTagsRequested = () => ({
  type: types.FETCH_REQUESTED
})

const fetchTagsSuccess = (data) => ({
  type: types.FETCH_SUCCESS,
  payload: data
})

const toggleTag = (key) => ({
  type: types.TOGGLE,
  payload: { key }
})

export default {
  fetchTagsError,
  fetchTagsRequested,
  fetchTagsSuccess,
  toggleTag
}
