import { createStore, applyMiddleware, compose, combineReducers } from 'redux'
import { ajax } from 'rxjs/ajax'
import { createEpicMiddleware, combineEpics } from 'redux-observable'
import reducers, { epics } from './ducks'

export default () => {
  const epicMiddleware = createEpicMiddleware({
    dependencies: { fetch: ajax }
  })

  const middleware = [
    epicMiddleware
  ]

  if (process.env.NODE_ENV === 'development') {
    const { logger } = require('redux-logger')

    middleware.push(require('redux-immutable-state-invariant').default())
    middleware.push(logger)
  }

  const rootReducer = combineReducers(reducers)
  const rootEpic = combineEpics(...epics)

  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
  const store = createStore(rootReducer, composeEnhancers(
    applyMiddleware(...middleware)
  ))

  epicMiddleware.run(rootEpic)

  return store
}
