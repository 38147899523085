import styled from 'styled-components'

export const JobContainer = styled.div`
  text-align: left;
`

export const JobContent = styled.div`
  margin: 40px 0;
`

export const JobButtonContainer = styled.div`
  text-align: center;
`
