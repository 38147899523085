import React from 'react'
import { withRouter } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'

import ApiService from '../../../../store/services/ApiService'
import * as companyAPI from '../../../../store/api/company'
import { myCompanyActions, myCompanySelectors } from '../../../../store/ducks/my-company'

import ButtonComponent from '../../../../components/Buttons/ButtonComponent'

import translationsList from '../../../../stabs/translationsList'

const ChangeStepButton = ({ step, history, validateFunction }) => {
  const myCompany = useSelector(myCompanySelectors.getMyCompany)
  const dispatch = useDispatch()

  const handleClick = () => {
    if (!validateFunction(myCompany)) {
      toast.error('Musisz uzupełnić podstawowe dane zanim przejdziesz na następną stronę!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      })

      return
    }

    ApiService
      .fetch(companyAPI.updateMe({ step }))
      .then(data => {
        dispatch(myCompanyActions.fetchMyCompanySuccess(data))

        history.push('/platforma')
      })
      .catch(() => {
        toast.error('Nastąpił błąd podczas przechodzenia na kolejną stronę!', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        })
      })
  }

  return (
    <ButtonComponent
      align='center'
      bgColor='pinkColor'
      color='whiteColor'
      display='inline-flex'
      fontBold
      label={translationsList.form.labels.buttonNextStepLabel}
      onClick={handleClick}
      size='sizeL'
      type='onClick'
    />
  )
}

export default withRouter(ChangeStepButton)

ChangeStepButton.defaultProps = {
  validateFunction: () => true
}
