import styled from 'styled-components'

import {
  PurpleBoxShadowColor,
  PurpleColor,
  PurpleColorL,
  WhiteColor
} from '../../../assets/styles/colors'

export const JobBoxContainer = styled.div`
  background-color: ${WhiteColor};
  border-radius: 20px;
  box-shadow: ${PurpleBoxShadowColor};
  display: flex;
  height: 100%;
  
  @media (max-width: 575px) {
    align-items: center;
    flex-direction: column;
  }

  @media (min-width: 768px) {
    padding: 30px;
  }

  @media (max-width: 767px) {
    padding: 20px;
  }
`

export const JobBoxImageContainer = styled.div`
  flex-shrink: 0;
  margin: 0 30px 0 0;

  & div {
    padding: 0;
  }

  & img {
    border-radius: 20px;
  }

  @media (min-width: 1600px) {
    width: 90px;
  }

  @media (min-width: 768px) and (max-width: 1599px) {
    width: 80px;
  }

  @media (max-width: 767px) {
    display: none;
  }
`

export const JobBoxTypeContainerMobile = styled.div`
  @media (min-width: 576px) {
    display: none;
  }

  @media (max-width: 575px) {
    margin: 0 0 20px;
    width: 28px;
  }
`

export const JobBoxDetailsContainer = styled.div`
  width: 100%;

  @media (min-width: 576px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: left;
  }

  @media (max-width: 575px) {
    text-align: center;
  }
`

export const JobBoxDetailsContent = styled.div``

export const JobBoxTitle = styled.h4`
  color: ${PurpleColor};
  font-weight: 600;

  @media (min-width: 1200px) {
    font-size: 18px;
    line-height: 27px;
  }

  @media (max-width: 1199px) {
    font-size: 16px;
    line-height: 21px;
  }
`

export const JobBoxCompanyNameContainer = styled.div`
  margin: 10px 0 0;
`

export const JobBoxCompanyName = styled.h6`
  color: ${PurpleColorL};
  font-size: 16px;
`

export const JobBoxCitiesContainer = styled.div`
  margin: 6px 0 0;
`

export const JobBoxCities = styled.p`
  color: ${PurpleColorL};
  font-size: 14px;
  margin: 0;
`

export const JobBoxCreatedAtContainer = styled.div`
  margin: 10px 0 0;
`

export const JobBoxCreatedAt = styled.small`
  color: ${PurpleColorL};
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  
  & + & {
    margin: 0 0 0 5px;
  }
`

export const JobBoxTypeAndButtonContainer = styled.div`
  @media (min-width: 576px) {
    align-items: flex-end;
    display: flex;

    ${({ levelOfHighlighting }) => {
      switch (levelOfHighlighting) {
        case 'premium': return `
          flex-direction: column;
          justify-content: space-between;
        `
        case 'prestige': return `
          flex-direction: column;
          justify-content: space-between;
        `
        case '': return ''
        case undefined: return ''
        case null: return ''
        default: return ''
      }
    }}
  }
  
  @media (min-width: 768px) {
    margin: 0 0 0 30px;
  }

  @media (min-width: 576px) and (max-width: 767px) {
    margin: 0 0 0 20px;
  }
`

export const JobBoxTypeContainer = styled.div`
  @media (min-width: 576px) {
    width: 30px;
  }

  @media (max-width: 575px) {
    display: none;
  }
`

export const JobBoxButtonContainer = styled.div`
  @media (min-width: 576px) {
    margin: 30px 0 0;
  }

  @media (max-width: 575px) {
    margin: 20px 0 0;
  }
`
