import styled from 'styled-components'

import {
  PurpleColor,
  SilverColor
} from '../../../assets/styles/colors'

export const CountdownComponentUIContainer = styled.div`
  display: flex;
  margin: 0 auto;
  max-width: 360px;
`

export const CountdownComponentUIColumn = styled.div`
  flex: 1;
`

export const CountdownComponentUILabel = styled.div`
  color: ${SilverColor};
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  text-transform: uppercase;
`

export const CountdownComponentUIValue = styled.div`
  color: ${PurpleColor};
  font-weight: 600;
  
  @media (min-width: 1200px) {
    font-size: 44px;
    line-height: 60px;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 30px;
    line-height: 40px;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 28px;
    line-height: 40px;
  }

  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 26px;
    line-height: 36px;
  }

  @media (min-width: 320px) and (max-width: 575px) {
    font-size: 24px;
    line-height: 30px;
  }
`
