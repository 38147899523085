import React from 'react'
import { Redirect, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import AuthService from '../../../store/services/AuthService'
import { cvActions } from '../../../store/ducks/my-cv'
import { myUserActions } from '../../../store/ducks/my-user'

export default ({ location }) => {
  const history = useHistory()
  const dispatch = useDispatch()

  if (AuthService.getGhostToken() !== undefined) {
    AuthService.logoutGhost()

    return (
      <Redirect
        to={{
          pathname: '/platforma',
          state: { from: location }
        }}
      />
    )
  }

  dispatch(cvActions.clearCV())
  dispatch(myUserActions.cleanUp())
  AuthService.logout()
  history.push('/')

  return (
    <div>Ładowanie...</div>
  )
}
