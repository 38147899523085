import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { anchorate } from 'anchorate'

import { primaryEventSelectors } from '../../../store/ducks/primary-event'
import { companiesSelectors } from '../../../store/ducks/companies'

import EventPage from '../../../pages/EventPage'

const EventPageContainer = () => {
  const primaryEvent = useSelector(primaryEventSelectors.getPrimaryEvent)
  const companies = useSelector(companiesSelectors.getCompanies)

  useEffect(() => {
    anchorate()
  })

  const event = { ...primaryEvent.data }
  event.dates = [
    { date: event.date.replace(/-\d\d/, ''), start: event.timeStart, end: event.timeEnd },
    { date: event.date.substring(3), start: event.timeStart, end: event.timeEnd }
  ]

  return (
    <EventPage
      event={event}
      companies={companies}
    />
  )
}

export default EventPageContainer
