import React from 'react'
import PropTypes from 'prop-types'

import RecruitmentDetailsItem from '../RecruitmentDetailsItem'

import translationsList from '../../../../stabs/translationsList'

const boolToString = (value) => (value ? 'Tak' : 'Nie')

const RecruitmentDetailsList = ({
  assessmentCenter,
  departments,
  employmentTypes,
  jobInterview,
  needsCv,
  needsMotivationalList,
  place,
  preferredCandidates,
  preferredDegreeCourses
}) => (
  <>
    {employmentTypes.length !== 0 &&
      <RecruitmentDetailsItem
        data-test='EmploymentTypes'
        label={translationsList.recruitment.labels.employmentTypesLabel}
        value={employmentTypes.join(', ')}
      />}

    {departments &&
      <RecruitmentDetailsItem
        data-test='Departments'
        label={translationsList.recruitment.labels.departmentsLabel}
        value={departments}
      />}

    {place &&
      <RecruitmentDetailsItem
        data-test='Place'
        label={translationsList.recruitment.labels.placeLabel}
        value={place}
      />}

    {preferredCandidates.length !== 0 &&
      <RecruitmentDetailsItem
        data-test='PreferredCandidates'
        label={translationsList.recruitment.labels.preferredCandidatesLabel}
        value={preferredCandidates.join(', ')}
      />}

    {preferredDegreeCourses &&
      <RecruitmentDetailsItem
        data-test='PreferredDegreeCourses'
        label={translationsList.recruitment.labels.preferredDegreeCoursesLabel}
        value={preferredDegreeCourses}
      />}

    <RecruitmentDetailsItem
      data-test='NeedsCv'
      label={translationsList.recruitment.labels.needsCvLabel}
      value={boolToString(needsCv)}
    />

    <RecruitmentDetailsItem
      data-test='NeedsMotivationalList'
      label={translationsList.recruitment.labels.needsMotivationalListLabel}
      value={boolToString(needsMotivationalList)}
    />

    <RecruitmentDetailsItem
      data-test='JobInterview'
      label={translationsList.recruitment.labels.jobInterviewLabel}
      value={boolToString(jobInterview)}
    />

    <RecruitmentDetailsItem
      data-test='AssessmentCenter'
      label={translationsList.recruitment.labels.assessmentCenterLabel}
      value={boolToString(assessmentCenter)}
    />
  </>
)

export default RecruitmentDetailsList

RecruitmentDetailsList.propTypes = {
  assessmentCenter: PropTypes.bool,
  departments: PropTypes.string,
  employmentTypes: PropTypes.arrayOf(PropTypes.string),
  jobInterview: PropTypes.bool,
  needsCv: PropTypes.bool,
  needsMotivationalList: PropTypes.bool,
  place: PropTypes.string,
  preferredCandidates: PropTypes.arrayOf(PropTypes.string),
  preferredDegreeCourses: PropTypes.string
}

RecruitmentDetailsList.defaultProps = {
  assessmentCenter: false,
  departments: '',
  employmentTypes: [],
  jobInterview: false,
  needsCv: false,
  needsMotivationalList: false,
  place: '',
  preferredCandidates: [],
  preferredDegreeCourses: ''
}
