import styled from 'styled-components'

import { PurpleColorM } from '../../../../assets/styles/colors'

export const IconTextContainer = styled.div`
  align-items: center;
  color: ${PurpleColorM};
  display: flex;
  font-size: 10px;
`

export const IconTextIconContainer = styled.div`
  width: 12px;
`

export const IconTextTextContainer = styled.div`
  margin: 0 0 0 7px;
`
