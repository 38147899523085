import React from 'react'
import Subtitle from '../../../../components/Subtitle'
import SpacingLine from '../../../../components/SpacingLine'
import { useSelector } from 'react-redux'
import { myCompanySelectors } from '../../../../store/ducks/my-company'
import ArticleItem from '../../../../components/Tabs/General/ArticleItem'
import SlideButtonsWrapper from '../../../../components/Sliders/SlideButtonsWrapper'

const ShowArticlesContainer = () => {
  const myCompany = useSelector(myCompanySelectors.getMyCompany)

  const unpublishedArticles = myCompany.articles.filter((article) => !article.isPublished)
  const publishedArticles = myCompany.articles.filter((article) => article.isPublished)

  return (
    <>
      {unpublishedArticles.length > 0 &&
        <>
          <Subtitle
            data-test='subtitleTwo'
            icon='education'
            label='Twoje artykuły eksperckie do akceptacji'
          />

          <SlideButtonsWrapper data-test='slideButtonsWrapperOne'>
            {unpublishedArticles.map((article, id) => (
              <ArticleItem
                data-test='articleItemOne'
                date={article.date}
                isPublished
                key={id}
                link={`/artykuly-eksperckie/${article.slug}`}
                thumbnailUrl={`${article.articleThumbnailUrl}`}
                title={article.title}
              />
            ))}
          </SlideButtonsWrapper>

          <SpacingLine
            data-test='spacingLineTwo'
            isFixed
          />
        </>}

      {publishedArticles.length > 0 &&
        <>
          <Subtitle
            data-test='subtitleThree'
            icon='education'
            label='Twoje opublikowane artykuły eksperckie'
          />

          <SlideButtonsWrapper data-test='slideButtonsWrapperTwo'>
            {publishedArticles.map((article, id) => (
              <ArticleItem
                data-test='articleItemTwo'
                date={article.date}
                isPublished={article.isPublished}
                key={id}
                link={`/artykuly-eksperckie/${article.slug}`}
                thumbnailUrl={`${article.articleThumbnailUrl}`}
                title={article.title}
              />
            ))}
          </SlideButtonsWrapper>

          <SpacingLine
            data-test='spacingLineThree'
            isFixed
          />
        </>}
    </>
  )
}

export default ShowArticlesContainer
