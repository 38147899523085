import styled from 'styled-components'

import {
  PinkColor,
  PurpleBoxShadowColor,
  PurpleColorXS,
  Transition,
  WhiteColor
} from '../../../assets/styles/colors'

export const SliderButtonContainer = styled.div`
  display: flex;
  padding: 5px;
  background-color: ${WhiteColor};
  border-radius: 50%;
  box-shadow: ${PurpleBoxShadowColor};
  z-index: 100;

  ${props => {
    switch (props.position) {
      case 'down': return 'transform: rotate(0deg);'
      case 'left': return 'transform: rotate(90deg);'
      case 'right': return 'transform: rotate(270deg);'
      case 'up': return 'transform: rotate(180deg);'
      default: return ''
    }
  }}

  & path:first-child {
    fill: ${PinkColor};
    transition: ${Transition};
  }

  &:hover {
    & path:first-child {
      fill: ${PurpleColorXS};
    }
  }

  @media (min-width: 768px) {
    width: 50px;
    height: 50px;
  }

  @media (max-width: 767px) {
    width: 45px;
    height: 45px;
  }
`
