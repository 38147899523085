import React from 'react'
import PropTypes from 'prop-types'

import Errors from '../../FormBasicElements/Errors'
import Label from '../../FormBasicElements/Label'
import TextArea from '../../FormBasicElements/TextArea'

import {
  TextAreaFieldContainer,
  TextAreaLengthContainer
} from './components'

const TextAreaField = ({
  disabled,
  errorMessage,
  hasError,
  id,
  label,
  maxLength,
  minLength,
  name,
  onBlur,
  onChange,
  placeholder,
  required,
  value
}) => (
  <TextAreaFieldContainer>
    <Label
      id={id}
      label={label}
      required={required}
    />

    <TextArea
      disabled={disabled}
      hasError={hasError}
      id={id}
      maxLength={maxLength}
      minLength={minLength}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      placeholder={placeholder}
      required={required}
      value={value}
    />

    {maxLength &&
      <TextAreaLengthContainer data-test='maxLength'>{`max. ${maxLength} znaków`}</TextAreaLengthContainer>}

    {minLength &&
      <TextAreaLengthContainer data-test='minLength'>{`min. ${minLength} znaków`}</TextAreaLengthContainer>}

    <Errors
      errorMessage={errorMessage}
      hasError={hasError}
    />
  </TextAreaFieldContainer>
)

export default TextAreaField

TextAreaField.propTypes = {
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  hasError: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string,
  maxLength: PropTypes.number,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.string
}
