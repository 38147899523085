import createSimpleFetchingEpic from '../../utils/createSimpleFetchingEpic'

import * as recruitmentAPI from '../../api/recruitment'

import actions from './actions'
import types from './types'

const fetchValues = createSimpleFetchingEpic(
  types.FETCH_REQUESTED,
  recruitmentAPI.getAllValues().toObject(),
  actions.fetchValuesSuccess,
  actions.fetchValuesError
)

export default {
  fetchValues
}
