import styled from 'styled-components'

import {
  PurpleColor,
  PurpleColorXS
} from '../../assets/styles/colors'

export const ModalContainerStyles = {
  content: {
    backgroundColor: 'transparent',
    border: '0 solid transparent',
    bottom: '0',
    left: '0',
    margin: '0 auto',
    overflowX: 'hidden',
    padding: '0',
    right: '0',
    top: '0'
  },
  overlay: {
    backgroundColor: 'rgba(34, 27, 65, 0.68)',
    zIndex: '99999'
  }
}

export const ModalContainer = styled.div`
  height: 100%;
  position: relative;
`

export const ModalCloseButtonContainer = styled.div`
  height: 24px;
  position: absolute;
  width: 24px;
  z-index: 99999;

  & g {
    stroke: ${PurpleColorXS};
  }

  @media (min-width: 1600px) {
    right: 25px;
    top: 25px;
  }

  @media (min-width: 1430px) and (max-width: 1599px) {
    right: 20px;
    top: 20px;
  }

  @media (min-width: 1200px) and (max-width: 1429px) {
    right: 60px;
    top: 60px;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    right: 55px;
    top: 55px;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    right: 50px;
    top: 50px;
  }

  @media (min-width: 320px) and (max-width: 767px) {
    right: 20px;
    top: 20px;
  }
`

export const ModalContent = styled.div`
  color: ${PurpleColor};
  max-width: 1400px;
  
  @media (min-width: 1600px) {
    margin: 50px auto;
  }

  @media (min-width: 1200px) and (max-width: 1599px) {
    margin: 40px auto;
  }

  @media (min-width: 768px) and (max-width: 1199px) {
    margin: 30px auto;
  }

  @media (min-width: 320px) and (max-width: 767px) {
    margin: ${({ inModal }) => inModal
      ? '3px auto'
      : '20px auto'
    }
  }
`
