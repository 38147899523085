import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'

import ButtonComponent from '../../Buttons/ButtonComponent'
import Grid from '../../Grid'
import Job from './Job'
import JobBox from '../../Boxes/JobBox'
import LinkComponent from '../../Buttons/LinkComponent'

import {
  JobsLinkContainer,
  JobsLiveButtonContainer,
  JobsLiveContainer,
  JobsLiveText
} from './Atoms'

import arrowIconPath from '../../../assets/icons/arrow_right.svg'
import isExpoDay from '../../../utils/isExpoDay'
import translationsList from '../../../stabs/translationsList'

const Jobs = ({
  itemsList,
  chatUrl,
  onOptionChange
}) => {
  const [selectedOffer, setSelectedOffer] = useState(null)

  return (
    <>
      {
        selectedOffer === null
          ? (
            <>
              {
                itemsList && (
                  <>
                    <Grid
                      columns={1}
                      data-test='JobsGrid'
                      noOutsideMargin
                    >
                      {
                        itemsList.map((job, index) => (
                          <JobBox
                          // cities, // TODO
                            clickType='onClick'
                            // companyName, // TODO
                            createdAt={DateTime.fromISO(job.createdAt).toISODate()}
                            data-test='JobsJobBox'
                            hideImage
                            key={job._id}
                            // levelOfHighlighting // TODO
                            onClick={() => setSelectedOffer(index)}
                            title={job.title}
                          />
                        ))
                      }
                    </Grid>

                    {/* // TODO Test ME! */}
                    {
                      (isExpoDay() && chatUrl) && (
                        <JobsLiveContainer>
                          <JobsLiveText>Nie znalazłeś/łaś oferty pracy dla siebie? Zapytaj rekrutera o inne możliwości zatrudnienia</JobsLiveText>

                          <JobsLiveButtonContainer>
                            <ButtonComponent
                              bgColor='purpleColor'
                              color='whiteColor'
                              data-test='JobBoxButton'
                              display='inline-flex'
                              fontBold
                              label='Zacznij rozmowę'
                              onClick={() => onOptionChange('chat')}
                              size='sizeM'
                              type='onClick'
                            />
                          </JobsLiveButtonContainer>
                        </JobsLiveContainer>
                      )
                    }
                  </>
                )
              }
            </>
          )

          : (
            <>
              <JobsLinkContainer data-test='JobsLinkContainer'>
                <LinkComponent
                  colorStroke='pinkColorM'
                  colorText='pinkColorM'
                  data-test='JobsLinkComponent'
                  fontSize='sizeL'
                  iconBefore={arrowIconPath}
                  label={translationsList.companyPanel.jobBack}
                  onClick={() => setSelectedOffer(null)}
                  rotateIcons='180'
                  type='onClick'
                />
              </JobsLinkContainer>

              <Job
                body={itemsList[selectedOffer].body}
                data-test='JobsJob'
                date={DateTime.fromISO(itemsList[selectedOffer].createdAt).toISODate()}
                link={itemsList[selectedOffer].applyUrl}
                title={itemsList[selectedOffer].title}
                showHeader
              />
            </>
          )
      }
    </>
  )
}

export default Jobs

Jobs.propTypes = {
  itemsList: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
      ]),
      date: PropTypes.string,
      title: PropTypes.string.isRequired
    })
  )
}

Jobs.defaultProps = {
  itemsList: []
}
