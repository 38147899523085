const FETCH_ERROR = 'cv/FETCH_ERROR'
const FETCH_REQUESTED = 'cv/FETCH_REQUESTED'
const FETCH_SUCCESS = 'cv/FETCH_SUCCESS'
const SET_HTML_AS_FETCHED = 'cv/SET_HTML_AS_FETCHED'
const CLEAR = 'cv/CLEAR'

export default {
  FETCH_ERROR,
  FETCH_REQUESTED,
  FETCH_SUCCESS,
  SET_HTML_AS_FETCHED,
  CLEAR
}
