import React from 'react'

import ButtonComponent from '../../../../Buttons/ButtonComponent'
import Icon from '../../../FormBasicElements/Icon'
import InputField from '../../../FormFields/InputField'
import InternalOrExternal from '../../../../Buttons/InternalOrExternal'
import LinkComponent from '../../../../Buttons/LinkComponent'
import SpacingLine from '../../../../SpacingLine'

import {
  LoginPanelButtonContainer,
  LoginPanelContent,
  LoginPanelForgotPasswordContainer,
  LoginPanelLinkContainer,
  LoginPanelLogoContainer,
  LoginPanelSpan
} from './components'

import logoPath from '../../../../../assets/icons/logo.svg'
import logoCvWorksPath from '../../../../../assets/icons/cvworks-logo.svg'
import translationsList from '../../../../../stabs/translationsList'
import { FormPanelErrorsContainer, FormPanelSuccessContainer } from '../../../FormWrappers/FormPanel/components'
import { withRouter } from 'react-router-dom'

const LoginPanel = ({
  apiError,
  errors,
  handleBlur,
  handleChange,
  location,
  handleSubmit,
  isLoading,
  isSubmitting,
  touched,
  values
}) => (
  <>
    <LoginPanelLogoContainer>
      <InternalOrExternal
        data-test='logo'
        link='/'
        type='internal'
      >
        <Icon
          icon={Number(process.env.REACT_APP_CV) === 0 ? logoPath : logoCvWorksPath}
        />
      </InternalOrExternal>
    </LoginPanelLogoContainer>

    <SpacingLine option='NoMargin' />

    <LoginPanelContent>
      {apiError &&
        <FormPanelErrorsContainer dangerouslySetInnerHTML={{ __html: apiError }} />}

      {location.search.includes('confirmed') && (
        <FormPanelSuccessContainer>{translationsList.registerSuccessMessage}</FormPanelSuccessContainer>
      )}
      <form onSubmit={handleSubmit}>
        <InputField
          data-test='email'
          errorMessage={errors.email}
          hasError={errors.email && touched.email}
          id='email'
          label='e-mail'
          name='email'
          onBlur={handleBlur}
          onChange={handleChange}
          placeholder={translationsList.expoRegister.emailPlaceholder}
          required
          type='email'
          value={values.email}
        />

        <InputField
          data-test='password'
          errorMessage={errors.password}
          hasError={errors.password && touched.password}
          id='password'
          label={translationsList.expoRegister.passwordLabel}
          name='password'
          onBlur={handleBlur}
          onChange={handleChange}
          placeholder={translationsList.expoRegister.passwordPlaceholder}
          required
          type='password'
          value={values.password}
        />

        <LoginPanelForgotPasswordContainer>
          <LinkComponent
            colorText='pinkColorM'
            data-test='forgot'
            display='inline-flex'
            label={translationsList.registryAndLogin.labels.forgotPassword}
            link='/przypomnij-haslo'
            type='internal'
          />
        </LoginPanelForgotPasswordContainer>

        <LoginPanelButtonContainer>
          <ButtonComponent
            bgColor='pinkColor'
            color='whiteColor'
            disabled={isSubmitting}
            isLoading={isLoading}
            fontBold
            fullWidth
            buttonType='submit'
            label={translationsList.registryAndLogin.labels.loginLabel}
            size='sizeM'
          />
        </LoginPanelButtonContainer>
      </form>

      <LoginPanelLinkContainer>
        { Number(process.env.REACT_APP_CV) === 0 ? (<LoginPanelSpan>{translationsList.registryAndLogin.labels.youDontHaveAnAccountLabel}</LoginPanelSpan>) : React.Fragment }

        <LinkComponent
          colorText='pinkColorM'
          data-test='register'
          fontSize='sizeM'
          label={translationsList.registryAndLogin.labels.registryLabel}
          link='/rejestracja'
          type='internal'
        />
      </LoginPanelLinkContainer>
    </LoginPanelContent>
  </>
)

export default withRouter(LoginPanel)

// TODO Add translations
