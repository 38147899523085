import ArticleGuidlinesForm from '../../../../components/Form/FormComponents/ArticleGuidlinesForm'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { flagsActions, flagsSelectors } from '../../../../store/ducks/flags'

const ArticleGuidlinesFormContainer = () => {
  const dispatch = useDispatch()
  const showArticleGuideline = useSelector(flagsSelectors.getFlag('showArticleGuideline'))

  return (
    <ArticleGuidlinesForm
      onClick={() => dispatch(flagsActions.changeFlag('showArticleGuideline', !showArticleGuideline))}
      showInfo={showArticleGuideline}
    />
  )
}

export default ArticleGuidlinesFormContainer
