import React from 'react'
import { withRouter } from 'react-router-dom'

import AddAboutCompanyFormContainer from '../../../containers/PlatformContainer/CompanyStepsForms/AddAboutCompanyFormContainer'
import AddMovieFormContainer from '../../../containers/PlatformContainer/CompanyStepsForms/AddMovieFormContainer'
import ChangeStepButton from '../../../containers/PlatformContainer/CompanyStepsForms/ChangeStepButton'
import SelectCompanyValuesContainer from '../../../containers/PlatformContainer/CompanyStepsForms/SelectCompanyValuesContainer'

import SpacingLine from '../../../components/SpacingLine'
import Subtitle from '../../../components/Subtitle'

import {
  FormCompanyStepTwoButtonContainer
} from './components'

const FormCompanyStepTwo = () => (
  <>
    <AddAboutCompanyFormContainer />

    <SpacingLine
      data-test='spacingLineOne'
      isFixed
    />

    {/*<Subtitle*/}
    {/*  data-test='subtitleOne'*/}
    {/*  icon='tag'*/}
    {/*  label='Macie piękną wizję. W jaki sposób ją realizujecie? Wybierz:'*/}
    {/*/>*/}

    {/*<SelectCompanyValuesContainer />*/}

    <SpacingLine
      data-test='spacingLineTwo'
      isFixed
    />

    <Subtitle
      data-test='subtitleTwo'
      icon='summary'
      label='Filmy Employer-brandingowe i inne'
    />

    <AddMovieFormContainer />

    <FormCompanyStepTwoButtonContainer>
      <ChangeStepButton step='companyStep3' />
    </FormCompanyStepTwoButtonContainer>
  </>
)

export default withRouter(FormCompanyStepTwo)
