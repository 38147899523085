import React from 'react'

import AddedItem from '../../../List/AddedItem'
import FormPanel from '../../FormWrappers/FormPanel'
import FormTwoColumns from '../../FormWrappers/FormTwoColumns'
import InputField from '../../FormFields/InputField'
import RequiredFieldsText from '../../FormBasicElements/RequiredFieldsText'
import SpacingLine from '../../../SpacingLine'

import {
  AddRecruiterFormNameAndSurnameContainer
} from './components'
import CompanyRecruiterAvatarUploadContainer from '../../../../containers/ComponentContainers/UploadContainers/CompanyRecruiterAvatarUploadContainer'

const AddRecruiterForm = ({
  apiError,
  errors,
  handleBlur,
  handleChange,
  handleDelete,
  handleEdit,
  handleField,
  isLoading,
  items,
  touched,
  values
}) => (
  <FormPanel
    errors={apiError}
  >
    {items.map((item, index) => (
      <AddedItem
        isInsideForm
        key={index}
        title={`${item.isEditing ? '(W trakcie edycji) ' : ''}${item.title}`}
        description={item.description}
        onClickDelete={() => handleDelete(item._id)}
        onClickEdit={() => handleEdit(item._id)}
        isLoading={isLoading}
      />
    ))}
    <FormTwoColumns data-test='FormTwoColumnsOne'>
      <CompanyRecruiterAvatarUploadContainer
        error={errors.avatarUrl}
        touched={touched.avatarUrl}
        value={values.avatarUrl}
        handleField={handleField}
      />

      <AddRecruiterFormNameAndSurnameContainer>
        <InputField
          data-test='Name'
          id='Name'
          label='Imię'
          name='firstName'
          placeholder='Wpisz imię'
          required
          errorMessage={errors.firstName}
          hasError={errors.firstName && touched.firstName}
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.firstName}
        />

        <InputField
          data-test='Surname'
          id='Surname'
          label='Nazwisko'
          name='lastName'
          placeholder='Wpisz nazwisko'
          required
          errorMessage={errors.lastName}
          hasError={errors.lastName && touched.lastName}
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.lastName}
        />
      </AddRecruiterFormNameAndSurnameContainer>

      <InputField
        data-test='Email'
        id='Email'
        label='E-mail'
        name='email'
        placeholder='Wpisz e-mail'
        type='email'
        errorMessage={errors.email}
        hasError={errors.email && touched.email}
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.email}
      />

      <InputField
        data-test='Position'
        id='Position'
        label='Stanowisko'
        name='position'
        placeholder='Wpisz stanowisko'
        required
        errorMessage={errors.position}
        hasError={errors.position && touched.position}
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.position}
      />

      <InputField
        data-test='Availability'
        id='Availability'
        label='Dostępność Dzień 1'
        name='availability1'
        placeholder='Wpisz dostępność (np. 14:00-20:00)'
        errorMessage={errors.availability1}
        hasError={errors.availability1 && touched.availability1}
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.availability1}
      />

      <InputField
        data-test='Availability'
        id='Availability'
        label='Dostępność Dzień 2'
        name='availability2'
        placeholder='Wpisz dostępność (np. 14:00-20:00)'
        errorMessage={errors.availability2}
        hasError={errors.availability2 && touched.availability2}
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.availability2}
      />
    </FormTwoColumns>

    <SpacingLine
      isFixed
      option='SizeS'
    />

    <FormTwoColumns data-test='FormTwoColumnsTwo'>
      <InputField
        data-test='Linkedin'
        id='Linkedin'
        label='Linkedin'
        name='linkedin'
        placeholder={'Wpisz link (https://www.linkedin.com/....)\n'}
        errorMessage={errors.linkedin}
        hasError={errors.linkedin && touched.linkedin}
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.linkedin}
      />
    </FormTwoColumns>

    <RequiredFieldsText />
  </FormPanel>
)

export default AddRecruiterForm

// TODO Fix translations
