import { ofType } from 'redux-observable'
import { catchError, map, mergeMap } from 'rxjs/operators'
import { of } from 'rxjs'

import * as eventAPI from '../../api/event'

import actions from './actions'
import types from './types'

import getError from '../../utils/getError'

const fetchTrainings = (action$, state$, { fetch }) => {
  return action$
    .pipe(
      ofType(types.FETCH_REQUESTED),
      mergeMap((action) => {
        return fetch(eventAPI.getAllTrainings(action.payload.eventId).toObject()).pipe(
          map((response) => actions.fetchTrainingsSuccess(response.response)),
          catchError((error) => of(actions.fetchTrainingsError(getError(error))))
        )
      })
    )
}

export default {
  fetchTrainings
}
