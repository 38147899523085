import styled from 'styled-components'

import { PurpleBoxShadowColor } from '../../../assets/styles/colors'

export const ChooseIconContainer = styled.div`
  border-radius: 20px;
  
  &:hover {
    box-shadow: ${PurpleBoxShadowColor};
  }
`
