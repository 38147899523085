import { of } from 'rxjs'
import { catchError, map, mergeMap } from 'rxjs/operators'
import { ofType } from 'redux-observable'

import actions from './actions'
import types from './types'
import * as recruitmentAPI from '../../api/recruitment'

import getError from '../../utils/getError'

const fetchCv = (action$, state$, { fetch }) => {
  return action$
    .pipe(
      ofType(types.FETCH_REQUESTED),
      mergeMap((action) => {
        return fetch(recruitmentAPI.getMyCv(action.payload.id).toObject()).pipe(
          map((response) => actions.fetchCvSuccess(response.response)),
          catchError((error) => of(actions.fetchCvError(getError(error))))
        )
      })
    )
}

export default {
  fetchCv
}
