import styled from 'styled-components'

import {
  PinkColorM,
  PurpleBoxShadowColor,
  PurpleColorXS,
  SilverColor,
  WhiteColor
} from '../../../assets/styles/colors'

export const CompanyItemWithInfoContainer = styled.div`
  align-items: center;
  display: none;
  grid-column-gap: 12px;
  justify-items: start;
  left: 20px;
  position: absolute;
  right: 20px;
  top: 20px;

  ${({ withIsFastButton }) => {
    switch (withIsFastButton) {
      case true: return 'grid-template-columns: 1fr 32px 24px;'
      default: return 'grid-template-columns: 1fr 16px;'
    }
  }}
`

export const CompanyItemContainer = styled.div`
  border-radius: 20px;
  display: flex;
  justify-content: center;
  overflow: hidden;
  position: relative;

  ${({ withSize }) => { // Added for CompanyProfile Sliders
    switch (withSize) {
      case true: return 'min-width: 240px;'
      default: return ''
    }
  }}

  ${({ insideBox }) => {
    switch (insideBox) {
      case true: return `
        padding: 0 0 80px;    

        & > span {
          display: grid;
        }

        @media (min-width: 1200px) {
          max-height: 360px;
        }

        @media (min-width: 992px) and (max-width: 1199px) {
          max-height: 320px;
        }

        @media (min-width: 768px) and (max-width: 991px) {
          max-height: 280px;
        }

        @media (min-width: 320px) and (max-width: 767px) {
          max-height: 250px;
        }
      `
      default: return `
        &:hover {
          background-color: ${WhiteColor};
          box-shadow: ${PurpleBoxShadowColor};

          @media (min-width: 320px) and (max-width: 479px) {
            & > span button {
              margin: 0 auto;
              width: auto;
            }
          }
        }

        @media (min-width: 1200px) {
          max-height: 350px;
          padding: 20px 20px 100px;          
        }

        @media (min-width: 992px) and (max-width: 1199px) {
          max-height: 320px;
          padding: 20px 20px 90px;
        }

        @media (min-width: 768px) and (max-width: 991px) {
          max-height: 280px;
          padding: 20px 20px 85px;
        }

        @media (min-width: 320px) and (max-width: 767px) {
          max-height: 250px;
          padding: 10px 10px 80px;
        }
      `
    }
  }}

  &:hover {
    & > ${CompanyItemWithInfoContainer} {
      display: grid;
    }
  }

  @media (min-width: 1200px) {
    margin: 0 30px 30px 0;  
    width: calc(25% - 25px);
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    margin: 0 30px 30px 0;    
    width: calc(33.3333333% - 20px);
    
    &:nth-child(3n) {
      margin: 0 0 30px 0;
    }
  }

  @media (min-width: 768px) and (max-width: 991px) {
    margin: 0 25px 25px 0;
    width: calc(50% - 12.5px);
    
    &:nth-child(even) {
      margin: 0 0 25px 0;
    }
  }

  @media (min-width: 320px) and (max-width: 767px) {
    margin: 0 20px 20px 0;
    width: calc(50% - 10px);
    
    &:nth-child(even) {
      margin: 0 0 20px 0;
    }
  }
`

export const CompanyItemTextContainer = styled.div`
  align-items: baseline;
  display: inline-grid;
  grid-column-gap: 6px;
  grid-template-columns: auto 1fr;
`

export const CompanyItemTextValue = styled.div`
  color: ${PurpleColorXS};
  font-size: 18px;
  font-weight: 600;
  line-height: 21px;
`

export const CompanyItemText = styled.div`
  color: ${SilverColor};
  font-size: 14px;
  line-height: 21px;
`

export const CompanyItemIconContainer = styled.div`
  & g {
    stroke: ${PurpleColorXS};
  }

  ${({ isFast }) => {
    switch (isFast) {
      case true: return `
        & g {
          fill: ${PinkColorM};
          stroke: ${PinkColorM};
        }
      `
      default: return ''
    }
  }}

  ${({ isFavourite }) => {
    switch (isFavourite) {
      case true: return `
        & g {
          fill: ${PinkColorM};
          stroke: ${PinkColorM};
        }
      `
      default: return ''
    }
  }}
`

export const CompanyItemButtonMoreContainer = styled.span`
  margin: 0 auto;
  position: absolute;

  ${({ insideBox }) => {
    switch (insideBox) {
      case true: return 'bottom: 0;'
      default: return `
        @media (min-width: 768px) {
          bottom: 20px;
        }

        @media (min-width: 320px) and (max-width: 767px) {
          bottom: 15px;
        }
      `
    }
  }}

  @media (min-width: 480px) {
    width: 200px;
  }
`
