import styled from 'styled-components'

import {
  PinkColorM,
  SilverColor
} from '../../../../../assets/styles/colors'

export const RegisterPanelLogoContainer = styled.div`
  @media (min-width: 992px) {
    margin: 40px auto;
  }

  @media (min-width: 320px) and (max-width: 991px) {
    margin: 20px auto;
  }

  @media (min-width: 480px) {
    max-width: 260px;
  }

  @media (min-width: 320px) and (max-width: 479px) {
    max-width: 220px;
  }
`

export const RegisterPanelContent = styled.div`
  @media (min-width: 992px) {
    padding: 40px 75px;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    padding: 40px;
  }

  @media (min-width: 480px) and (max-width: 767px) {
    padding: 30px;
  }

  @media (min-width: 320px) and (max-width: 479px) {
    padding: 20px;
  }
`

export const RegisterPanelTextLinkTextContainer = styled.div`
  text-align: left;
`

export const RegisterPanelTextLinkTextBold = styled.div`
  font-weight: bold;
`

export const RegisterPanelTextLinkTextSpacingContainer = styled.div`
  padding: 0 0 0 30px;
`

export const RegisterPanelCheckBoxLink = styled.a`
  color: ${PinkColorM};
  font-size: 12px;
  line-height: 18px;
  margin: 0 5px;
  text-decoration: none;
`

export const RegisterPanelButtonContainer = styled.div`
  display: grid;
  margin: 32px 0 0;
`

export const RegisterPanelLinkContainer = styled.div`
  align-items: center;
  color: ${SilverColor};
  display: inline-grid;
  font-size: 14px;
  grid-auto-flow: column;
  grid-column-gap: 6px;
  justify-content: center;
  line-height: 21px;
  margin: 15px 0 0;
`

export const RegisterPanelSpan = styled.span``
