import React from 'react'
import { withRouter } from 'react-router-dom'

import SpacingLine from '../../../components/SpacingLine'
import Subtitle from '../../../components/Subtitle'

import {
  FormCompanyStepFourButtonContainer
} from './components'

import AddAwardFormContainer from '../../../containers/PlatformContainer/CompanyStepsForms/AddAwardFormContainer'
import AddBenefitFormContainer from '../../../containers/PlatformContainer/CompanyStepsForms/AddBenefitFormContainer'
import ChangeStepButton from '../../../containers/PlatformContainer/CompanyStepsForms/ChangeStepButton'

const FormCompanyStepFour = ({
  history
}) => (
  <>
    <Subtitle
      data-test='benefits'
      icon='star'
      label='Benefity'
    />

    <AddBenefitFormContainer />

    <SpacingLine isFixed />

    <Subtitle
      data-test='awards'
      icon='badge'
      label='Wyróżnienia'
    />

    <AddAwardFormContainer />

    <FormCompanyStepFourButtonContainer>
      <ChangeStepButton step='companyStep5' />
    </FormCompanyStepFourButtonContainer>
  </>
)

export default withRouter(FormCompanyStepFour)

// TODO Fix Translations
