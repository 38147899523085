import React from 'react'
import PropTypes from 'prop-types'

import {
  TextValueTextValueContainer,
  TextValueTextValueContent,
  TextValueTextValueText,
  TextValueTextValueValue
} from './components'

const TextValueTextValue = ({
  textOne,
  valueOne,
  textTwo,
  valueTwo,
  textThree,
  valueThree
}) => (
  <TextValueTextValueContainer>
    <TextValueTextValueContent>
      {textOne &&
        <TextValueTextValueText data-test='TextOne'>{textOne}</TextValueTextValueText>}

      {valueOne &&
        <TextValueTextValueValue data-test='ValueOne'>{valueOne}</TextValueTextValueValue>}

      {textTwo &&
        <TextValueTextValueText data-test='TextTwo'>{textTwo}</TextValueTextValueText>}

      {valueTwo &&
        <TextValueTextValueValue data-test='ValueTwo'>{valueTwo}</TextValueTextValueValue>}

      {textThree &&
        <TextValueTextValueText data-test='TextThree'>{textThree}</TextValueTextValueText>}

      {valueThree &&
        <TextValueTextValueValue data-test='ValueThree'>{valueThree}</TextValueTextValueValue>}
    </TextValueTextValueContent>
  </TextValueTextValueContainer>
)

export default TextValueTextValue

TextValueTextValue.propTypes = {
  textOne: PropTypes.string,
  textThree: PropTypes.string,
  textTwo: PropTypes.string,
  valueOne: PropTypes.string,
  valueThree: PropTypes.string,
  valueTwo: PropTypes.string
}
