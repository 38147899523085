import styled from 'styled-components'

import {
  CheckBoxSelectedColor,
  PurpleColorL,
  PurpleColorS
} from '../../../../assets/styles/colors'

export const CheckBoxContainer = styled.label`
  align-items: flex-start;
  color: ${PurpleColorL};
  cursor: pointer;
  display: flex;
  font-size: 12px;
  margin: 0 0 5px;
  position: relative;
  user-select: none;
`

export const CheckBoxInput = styled.input`
  opacity: 0;
  position: absolute;

  &:checked ~ div::after {
    display: block;
  }
`

export const CheckBoxCheckContainer = styled.div``

export const CheckboxCheck = styled.div`
  &::before {
    align-self: flex-start;
    background-color: ${PurpleColorS};
    border-radius: 5px;
    content: '';
    display: flex;
    flex-shrink: 0;
    height: 25px;
    margin: 0 8px 0 0;
    position: relative;
    width: 25px;
  }

  &::after {
    border: solid ${CheckBoxSelectedColor};
    border-width: 0 3px 3px 0;
    content: '';
    display: none;
    height: 10px;
    left: 9px;
    position: absolute;
    top: 5px;
    transform: rotate(45deg);
    width: 5px;
  }
`

export const CheckBoxContent = styled.div``
