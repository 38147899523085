import React from 'react'
import PropTypes from 'prop-types'

import {
  JobButtonContainer,
  JobContainer,
  JobContent
} from './Atoms'

import ButtonComponent from '../../../Buttons/ButtonComponent'
import JobHeader from '../JobHeader'

import translationsList from '../../../../stabs/translationsList'

const Job = ({
  body,
  date,
  link,
  title,
  showHeader
}) => (
  <JobContainer data-test='JobContainer'>
    {
      showHeader && (
        <JobHeader
          data-test='JobHeader'
          date={date}
          link={link}
          title={title}
        />
      )
    }

    <JobContent
      data-test='JobContent'
      dangerouslySetInnerHTML={{ __html: body }}
    />

    <JobButtonContainer data-test='JobButtonContainer'>
      <ButtonComponent
        align='center'
        bgColor='pinkColor'
        color='whiteColor'
        data-test='JobButton'
        display='inline-flex'
        fontBold
        label={translationsList.jobOffers.apply}
        link={link}
        size='sizeM'
        type='external'
      />
    </JobButtonContainer>
  </JobContainer>
)

export default Job

Job.propTypes = {
  body: PropTypes.string,
  date: PropTypes.string,
  link: PropTypes.string,
  title: PropTypes.string,
  showHeader: PropTypes.bool
}

Job.defaultProps = {
  showHeader: false
}
