import React from 'react'
import PropTypes from 'prop-types'

import {
  GridChildrenWrapper,
  GridContainer,
  GridWrapper
} from './Atoms'

const Grid = ({
  children,
  columns,
  dontWrapChildrens,
  noOutsideMargin
}) => (
  <GridWrapper data-test='GridWrapper'>
    <GridContainer
      columns={columns}
      data-test='GridContainer'
      noOutsideMargin={noOutsideMargin}
    >
      {
        dontWrapChildrens
          ? (
            <>
              {children}
            </>
          )

          : (
            <>
              {
                React.Children.map(children, child => (
                  <GridChildrenWrapper data-test='GridChildrenWrapper'>
                    {
                      React.cloneElement(child)
                    }
                  </GridChildrenWrapper>
                ))
              }
            </>
          )
      }
    </GridContainer>
  </GridWrapper>
)

export default Grid

Grid.propTypes = {
  children: PropTypes.any,
  columns: PropTypes.oneOf([
    1, 2, 3, 4, 'noColumns'
  ]),
  dontWrapChildrens: PropTypes.bool,
  noOutsideMargin: PropTypes.bool
}

Grid.defaultProps = {
  dontWrapChildrens: false,
  noOutsideMargin: false
}
