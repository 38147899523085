import React from 'react'
import ReactDOM from 'react-dom'
import ReactModal from 'react-modal'
import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify'

import configureStore from './store'
import App from './App'

import './assets/styles/normalize.css'
import './assets/styles/standard.css'
import './index.css'
import 'react-toastify/dist/ReactToastify.css'

import { unregister as unregisterServiceWorker } from './utils/registerServiceWorker'
import setupSentry from './utils/setupSentry'

setupSentry()

ReactModal.setAppElement('#root')

ReactDOM.render((
  <Provider store={configureStore()}>
    <App />
    <ToastContainer />
  </Provider>
), document.getElementById('root'))

unregisterServiceWorker()
