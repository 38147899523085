import { DateTime } from 'luxon'

export default (date, timeStart, timeEnd) => {
  const dates = [
    { date: date, start: timeStart, end: timeEnd },

  ]

  let result = false

  dates.forEach(({ date, start, end }) => {
    const expoDateStart = DateTime.fromFormat(`${date} ${start}`, 'dd.MM.yy HH:mm')
    const expoDateEnd = DateTime.fromFormat(`${date} ${end}`, 'dd.MM.yy HH:mm')

    if (expoDateStart.diffNow('seconds') < 0 && expoDateEnd.diffNow('seconds') >= 0) {
      result = true
    }
  })

  return result
}
