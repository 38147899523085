import { createReducer } from '../../utils'
import types from './types'

const initialState = {
  data: [],
  error: null,
  fetchingState: 'none' // 'none', 'loading', 'done', 'error'
}

const jobOffersReducer = createReducer(initialState)({
  [types.FETCH_ERROR]: (state, action) => ({ data: null, fetchingState: 'error', error: action.payload }),
  [types.FETCH_REQUESTED]: () => ({ data: [], fetchingState: 'loading', error: null }),
  [types.FETCH_SUCCESS]: (state, action) => ({
    data: action.payload.data,
    fetchingState: 'done',
    error: null
  }),
  [types.ADD]: (state, action) => ({
    data: [...state.data, action.payload.data],
    fetchingState: 'done',
    error: null
  }),
  [types.UPDATE]: (state, action) => ({
    data: [...state.data.filter((el) => el.id !== action.payload.data._id), action.payload.data],
    fetchingState: 'done',
    error: null
  })
})

export default jobOffersReducer
