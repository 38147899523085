import styled from 'styled-components'

export const SelectableItemsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${props => {
    switch (props.align) {
      case 'center': return 'justify-content: center;'
      case 'left': return 'justify-content: left;'
      case 'right': return 'justify-content: right;'
      default: return ''
    }
  }}
`
