import React from 'react'
import { withRouter } from 'react-router-dom'

import InnerWrapper from '../../Wrappers/InnerWrapper'
import LoginAndRegister from '../../Form/FormComponents/LoginAndRegister'
import Modal from '../../Modal'

const RegisterModal = ({
  isUser,
  history
}) => (
  <Modal
    isFixed
    onClose={() => history.goBack()}
  >
    <InnerWrapper>
      <LoginAndRegister registry isUser={isUser} />
    </InnerWrapper>
  </Modal>
)

export default withRouter(RegisterModal)

RegisterModal.defaultProps = {
  company: false
}
