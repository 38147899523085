import React from 'react'
import PropTypes from 'prop-types'

import Copyrights from '../../Copyrights'
import Footer from '../../Footer'
import Header from '../../Header'
import InnerWrapper from '../InnerWrapper'

import {
  // CloseButtonContainer,
  // InfoBanner,
  SiteContent,
  SiteFooterContainer,
  SiteHeaderContainer,
} from './components'
import { useSelector } from 'react-redux'
import { myUserSelectors } from '../../../store/ducks/my-user'
import iconClosePath from '../../../assets/icons/close.svg'
import Icon from '../../Form/FormBasicElements/Icon'
import LinkComponent from '../../Buttons/LinkComponent'

const InfoBannerContainer = () => {
  const [visible, setVisible] = React.useState(true)

  // if (visible) {
  //   return <InfoBanner>
  //     <p>W 3 miesiące znajdź satysfakcjonującą pracę, dzięki kompleksowemu programowi szkoleń na
  //       platformie Career Academy. &nbsp;
  //       <a type='external' target='_blank' href={'https://careeracademy.pl/'}>Dowiedz się więcej</a>
  //     </p>
  //     <CloseButtonContainer onClick={() => setVisible(false)}>
  //       <Icon
  //         data-test='Close'
  //         icon={iconClosePath}
  //       />
  //     </CloseButtonContainer>
  //   </InfoBanner>
  // }

  return <div/>
}

const Site = ({
  children,
  creatorCvPage,
  eventPage,
  isLoggedOn,
  menuItemsList,
  userData,
  withNav,
  withBanner
}) => {
  const user = useSelector(myUserSelectors.getMyUserData)

  if (isLoggedOn) {
    userData.name = user.firstName !== undefined ? `${user.firstName} ${user.lastName}` : user.email
  }

  return (
    <>
      <SiteHeaderContainer data-test='SiteHeaderContainer'>
        { withBanner && <InfoBannerContainer /> }
        <Header
          creatorCvPage={creatorCvPage}
          data-test='SiteHeader'
          eventPage={eventPage}
          isLoggedOn={isLoggedOn}
          menuItemsList={menuItemsList}
          userData={userData}
          withNav={withNav}
        />
      </SiteHeaderContainer>

      <SiteContent data-test='SiteContent'>
        {children}
      </SiteContent>

      <InnerWrapper data-test='SiteInnerWrapper'>
        <SiteFooterContainer data-test='SiteFooterContainer'>
          <Footer data-test='SiteFooter' />
        </SiteFooterContainer>

        <Copyrights data-test='SiteCopyrights' />
      </InnerWrapper>
    </>
  )
}

export default Site

Site.propTypes = {
  children: PropTypes.any,
  creatorCvPage: PropTypes.bool,
  eventPage: PropTypes.bool,
  isLoggedOn: PropTypes.bool,
  menuItemsList: PropTypes.arrayOf(
    PropTypes.shape({
      fontBold: PropTypes.bool,
      inNewWindow: PropTypes.bool,
      label: PropTypes.string,
      link: PropTypes.string
    })
  ),
  userData: PropTypes.shape({
    alt: PropTypes.string,
    image: PropTypes.string,
    isMenuOpen: PropTypes.bool,
    name: PropTypes.string,
    position: PropTypes.string
  }),
  withNav: PropTypes.bool
}

Site.defaultProps = {
  creatorCvPage: false,
  eventPage: false,
  isLoggedOn: false,
  menuItemsList: [],
  userData: {},
  withNav: false,
  withBanner: true
}
