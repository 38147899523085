const FETCH_ERROR = 'trainings/FETCH_ERROR'
const FETCH_REQUESTED = 'trainings/FETCH_REQUESTED'
const FETCH_SUCCESS = 'trainings/FETCH_SUCCESS'

const SHOW_LESS = 'trainings/SHOW_LESS'
const SHOW_MORE = 'trainings/SHOW_MORE'

const TOGGLE_DESCRIPTION = 'trainings/TOGGLE_DESCRIPTION'

const TOGGLE_ENROLLMENT = 'trainings/TOGGLE_ENROLLMENT'

const ADD_TRAINING = 'trainings/ADD_TRAINING'
const REMOVE_TRAINING = 'trainings/REMOVE_TRAINING'
const UPDATE_TRAINING = 'trainings/UPDATE_TRAINING'

export default {
  ADD_TRAINING,
  FETCH_ERROR,
  FETCH_REQUESTED,
  FETCH_SUCCESS,
  REMOVE_TRAINING,
  SHOW_LESS,
  SHOW_MORE,
  TOGGLE_DESCRIPTION,
  TOGGLE_ENROLLMENT,
  UPDATE_TRAINING
}
