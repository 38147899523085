import React from 'react'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'

import ButtonComponent from '../Buttons/ButtonComponent'
import CountdownComponent from '../CountdownComponent'
import FormPanel from '../Form/FormWrappers/FormPanel'
import Label from '../Form/FormBasicElements/Label'

import isExpoDay from '../../utils/isExpoDay'
import translationsList from '../../stabs/translationsList'

import {
  InfoBoxButtonContainer,
  InfoBoxContent,
  InfoBoxDate,
  InfoBoxRemainedContainer
} from './Atoms'

const InfoBox = ({
  calendarUrl,
  dates,
  timeStart,
  timeEnd,
}) => (
  <FormPanel
    data-test='FormPanel'
    noMargin
  >
    <InfoBoxContent data-test='InfoBoxContent'>
      <>
        <Label
          data-test='LabelDateAndTime'
          label={translationsList.locations.labels.dateAndTimeLabel}
        />

        <InfoBoxDate data-test='InfoBoxDate'>
          {dates}
          <br/>
          {timeStart} - {timeEnd}
        </InfoBoxDate>
      </>

      <InfoBoxRemainedContainer data-test='InfoBoxRemainedContainer'>
        <Label
          data-test='LabelRemained'
          label={isExpoDay()
            ? 'Pozostało do końca targów'
            : 'Pozostało'}
        />

        <CountdownComponent
          data-test='CountdownComponent'
          date={isExpoDay(dates, timeStart, timeEnd) ? DateTime.fromFormat(`${dates} ${timeEnd}`, 'dd.MM.yyyy HH:mm').toJSDate() : DateTime.fromFormat(`${dates} ${timeStart}`, 'dd.MM.yyyy HH:mm').toJSDate()}
        />
      </InfoBoxRemainedContainer>

      <InfoBoxButtonContainer data-test='InfoBoxButtonContainer'>
        <ButtonComponent
          bgColor='pinkColor'
          color='whiteColor'
          display='inline-flex'
          data-test='ButtonComponent'
          fontBold
          inNewWindow
          label={translationsList.locations.labels.addToCalendarButtonLabel}
          link={calendarUrl}
          size='sizeM'
          type='external'
        />
      </InfoBoxButtonContainer>
    </InfoBoxContent>
  </FormPanel>
)

export default InfoBox

InfoBox.propTypes = {
  calendarUrl: PropTypes.string,
  dateTime: PropTypes.shape({
    date: PropTypes.string,
    timeEnd: PropTypes.string,
    timeStart: PropTypes.string
  })
}

InfoBox.defaultProps = {
  calendarUrl: '',
  dateTime: {
    date: '',
    timeEnd: '',
    timeStart: ''
  }
}
