import styled from 'styled-components'

import { PurpleColor } from '../../../assets/styles/colors'

export const FooterMenuTitle = styled.div`
  line-height: 27px;
  font-size: 18px;
  font-weight: 600;
  color: ${PurpleColor};

  @media (min-width: 992px) {
    margin: 0 0 32px;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    margin: 0 0 20px;
  }

  @media (min-width: 576px) and (max-width: 767px) {
    margin: 0 0 10px;
  }

  @media (max-width: 575px) {
    margin: 0;
  }
`

export const FooterMenuContainer = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`
