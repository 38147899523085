import types from './types'
import { createReducer } from '../../utils'

const initialState = {
  data: [],
  display: 100,
  isLoading: false,
  error: null
}

const trainingsReducer = createReducer(initialState)({
  [types.FETCH_ERROR]: (state, action) => ({ data: [], isLoading: false, error: action.payload, display: 5 }),
  [types.FETCH_REQUESTED]: () => ({ data: [], isLoading: true, error: null, display: 100 }),
  [types.FETCH_SUCCESS]: (state, action) => ({ data: action.payload.map((training) => ({ ...training, isOpen: false })), display: 100, isLoading: false, error: null }),
  [types.TOGGLE_DESCRIPTION]: (state, action) => ({
    ...state,
    data: state.data.map((training) => training._id === action.payload.key
      ? { ...training, isOpen: !training.isOpen }
      : { ...training, isOpen: false })
  }),
  [types.SHOW_LESS]: (state) => state.display - 5 <= 5 ? { ...state, display: 5 } : { ...state, display: state.display - 5 },
  [types.SHOW_MORE]: (state) => state.display + 5 > state.data.length
    ? { ...state, display: state.data.length }
    : { ...state, display: state.display + 5 },

  [types.TOGGLE_ENROLLMENT]: (state, action) => ({
    ...state,
    data: state.data.map((training) => training._id === action.payload.key
      ? { ...training, isEnrolled: !training.isEnrolled }
      : training)
  }),

  [types.ADD_TRAINING]: (state, action) => ({ ...state, data: [...state.data, action.payload.training] }),
  [types.REMOVE_TRAINING]: (state, action) => ({ ...state, data: state.data.filter((training) => training._id !== action.payload.trainingId) }),
  [types.UPDATE_TRAINING]: (state, action) => ({
    ...state,
    data: state.data.map((training) => {
      if (training._id === action.payload.trainingId) {
        return action.payload.newTraining
      }

      return training
    })
  })
})

export default trainingsReducer
