import React from 'react'
import PropTypes from 'prop-types'

import Label from '../../Form/FormBasicElements/Label'

import {
  TrainingsHeaderContainer
} from './components'

import translationsList from '../../../stabs/translationsList'

const TrainingsHeader = ({
  withHeader
}) => (
  <>
    {withHeader &&
      <TrainingsHeaderContainer>
        <Label
          data-test='Hours'
          label={translationsList.trainings.labels.trainingsHourFieldLabel}
        />

        <Label
          data-test='Subject'
          label={translationsList.trainings.labels.trainingsSubjectFieldLabel}
        />

        <Label
          data-test='Speakers'
          label={translationsList.trainings.labels.trainingsSpeakersFieldLabel}
        />
      </TrainingsHeaderContainer>}
  </>
)

export default TrainingsHeader

TrainingsHeader.propTypes = {
  withHeader: PropTypes.bool
}

TrainingsHeader.defaultProps = {
  withHeader: false
}
