import styled from 'styled-components'

import { LabelContainer } from '../../Form/FormBasicElements/Label/components'

export const RecruitmentContainer = styled.div`
  @media (min-width: 320px) and (max-width: 767px) {
    ${LabelContainer} {
      text-align: center;
    }
  }
`

export const RecruitmentRecruiterCardBoxContainer = styled.div`
  @media (min-width: 320px) {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 30px;
  }

  @media (min-width: 320px) and (max-width: 479px) {
    justify-content: center;
  }
`

export const RecruitmentWhoWeAreLookingFor = styled.div`
  @media (min-width: 1600px) {
    margin: 60px 0 0;
  }

  @media (min-width: 1200px) and (max-width: 1599px) {
    margin: 50px 0 0;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    margin: 40px 0 0;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    margin: 30px 0 0;
  }

  @media (min-width: 320px) and (max-width: 767px) {
    margin: 20px 0 0;
  }
`

export const RecruitmentDetailsListContainer = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`
