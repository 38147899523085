import React from 'react'

import FormPanel from '../../FormWrappers/FormPanel'
import LinkComponent from '../../../Buttons/LinkComponent'
import TextComponent from '../../FormBasicElements/TextComponent'
import TextComponentField from '../../FormFields/TextComponentField'

import {
  ArticleGuidlinesFormNotVisibleContainer,
  ArticleGuidlinesFormReadMoreLinkContainer,
  ArticleGuidlinesFormVisibleContainer
} from './components'

import translationsList from '../../../../stabs/translationsList'

const ArticleGuidlinesForm = ({
  onClick,
  showInfo
}) => (
  <FormPanel>
    <ArticleGuidlinesFormVisibleContainer>
      <TextComponent text={translationsList.form.companySteps.companyStepFive.visibleText} />

      <ArticleGuidlinesFormReadMoreLinkContainer>
        <LinkComponent
          align='right'
          colorText='purpleColor'
          display='inline-flex'
          fontSize='sizeM'
          label='Dowiedz się więcej'
          onClick={onClick}
        />
      </ArticleGuidlinesFormReadMoreLinkContainer>
    </ArticleGuidlinesFormVisibleContainer>

    {showInfo &&
      <ArticleGuidlinesFormNotVisibleContainer>
        <TextComponentField
          data-test='textComponentFieldOne'
          label={translationsList.form.companySteps.companyStepFive.howArticleShouldLookLabel}
          text={translationsList.form.companySteps.companyStepFive.howArticleShouldLookText}
        />

        <TextComponentField
          data-test='textComponentFieldTwo'
          label={translationsList.form.companySteps.companyStepFive.howArticleShouldNotLookLabel}
          text={translationsList.form.companySteps.companyStepFive.howArticleShouldNotLookText}
        />

        <TextComponentField
          data-test='textComponentFieldThree'
          label={translationsList.form.companySteps.companyStepFive.guidelinesLabel}
          text={translationsList.form.companySteps.companyStepFive.guidelinesText}
        />
      </ArticleGuidlinesFormNotVisibleContainer>}
  </FormPanel>
)

export default ArticleGuidlinesForm

// TODO Fix translations
