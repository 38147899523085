import React, { useState } from 'react'
import { Formik } from 'formik'
import { withRouter } from 'react-router-dom'
import * as Yup from 'yup'
import _ from 'lodash'

import RegisterPanel from '../../../../components/Form/FormComponents/LoginAndRegister/RegisterPanel'
import ApiService from '../../../../store/services/ApiService'
import * as AuthAPI from '../../../../store/api/auth'

import translate from '../../../../stabs/errorMessagesTranslations'
import translationsList from '../../../../stabs/translationsList'

function sendEmailMautic (email) {
  window.mt('send', 'pageview', { email, rejestracja: 'dodano-email' })
}

const debounceSendEmailMautic = _.debounce(sendEmailMautic, 500)

const RegisterFormContainer = ({ history, isUser }) => {
  const [state, setState] = useState({
    isLoading: false,
    error: null,
    cities: [
      { _id: 0, label: 'Wrocław', isActive: false }
    ],
    checkboxes: {
      all: false,
      regulations: false,
      policyPrivacy: false,
      notifications: false,
      shareProfile: false,
      recommendations: false
    }
  })

  const changeCity = (setFieldTouched, setFieldValue) => (id) => {
    setFieldTouched('city', true)
    setState({
      ...state,
      cities: state.cities.map((city) => {
        if (city._id === id) {
          setFieldValue('city', city.label)
          return { ...city, isActive: true }
        } else {
          return { ...city, isActive: false }
        }
      })
    })
  }

  const handleCheckbox = (setFieldTouched, setFieldValue) => (name) => {
    setFieldTouched('regulations', true)
    setFieldTouched('policyPrivacy', true)
    setFieldTouched('notifications', true)
    setFieldTouched('shareProfile', true)
    setFieldTouched('recommendations', true)

    if (name === 'all') {
      setFieldValue('regulations', !state.checkboxes.all)
      setFieldValue('policyPrivacy', !state.checkboxes.all)
      setFieldValue('notifications', !state.checkboxes.all)
      setFieldValue('shareProfile', !state.checkboxes.all)
      setFieldValue('recommendations', !state.checkboxes.all)

      setState({
        ...state,
        checkboxes: {
          all: !state.checkboxes.all,
          regulations: !state.checkboxes.all,
          policyPrivacy: !state.checkboxes.all,
          notifications: !state.checkboxes.all,
          shareProfile: !state.checkboxes.all,
          recommendations: !state.checkboxes.all
        }
      })
    } else {
      setFieldValue(name, !state.checkboxes[name])
      setState({
        ...state,
        checkboxes: {
          ...state.checkboxes,
          [name]: !state.checkboxes[name]
        }
      })
    }
  }

  const displayValidationError = (errors, touched) => {
    const err = []

    if (errors.city && touched.city) { err.push(errors.city) }
    if (errors.regulations && touched.regulations) { err.push(errors.regulations) }
    if (errors.policyPrivacy && touched.policyPrivacy) { err.push(errors.policyPrivacy) }

    return {
      hasError: err.length > 0,
      error: err[0]
    }
  }

  const onSubmit = (values, setSubmitting, resetForm) => {
    setState({ ...state, error: null, isLoading: true })

    const body = {
      email: values.email,
      password: values.password,
      city: 'Wrocław',
      marketingApproval: values.notifications,
      status: values.shareProfile ? 'allowAll' : 'disallow'
    }

    if (!isUser) {
      body.recommendations = values.recommendations
    }

    ApiService
      .fetch(isUser ? AuthAPI.register(body) : AuthAPI.registerCompany(body))
      .then(data => {
        setState({ ...state, error: null, isLoading: false })

        resetForm()
        history.push(`/powodzenie-rejestracji?uid=${data._id}`)

        if (isUser) {
          window.mt('send', 'pageview', { rejestracja: 'zarejestrowano', userstep1: true })
        }
      })
      .catch(error => {
        console.log(error)
        setState({ ...state, error: translate('pl', error.details.msg), isLoading: false })
      })

    setSubmitting(false)
  }

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
        city: '',
        regulations: false,
        policyPrivacy: false,
        notifications: false,
        shareProfile: false,
        recommendations: false
      }}
      validationSchema={RegisterSchema}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        onSubmit(values, setSubmitting, resetForm)
      }}
      render={({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        setFieldTouched,
        setFieldValue,
        values
      }) => (
        <RegisterPanel
          apiError={state.error}
          changeCity={changeCity(setFieldTouched, setFieldValue)}
          checkboxes={state.checkboxes}
          cities={state.cities}
          displayValidationError={displayValidationError(errors, touched)}
          errors={errors}
          handleBlur={handleBlur}
          handleEmail={(e) => {
            handleChange(e)
            debounceSendEmailMautic(e.target.value)
          }}
          handleChange={handleChange}
          handleCheckbox={handleCheckbox(setFieldTouched, setFieldValue)}
          handleSubmit={handleSubmit}
          isLoading={state.isLoading}
          isSubmitting={isSubmitting}
          isUser={isUser}
          touched={touched}
          values={values}
        />
      )}
    />
  )
}

const RegisterSchema = Yup.object().shape({
  notifications: Yup.boolean(),
  recommendations: Yup.boolean(),
  shareProfile: Yup.boolean(),
  regulations: Yup.boolean(),
  policyPrivacy: Yup.boolean(),
  email: Yup.string()
    .email(translationsList.expoRegister.validationErrors.email)
    .required(translationsList.expoRegister.validationErrors.required),
  password: Yup.string()
    .min(4, translationsList.expoRegister.validationErrors.tooShort)
    .required(translationsList.expoRegister.validationErrors.required)
})

export default withRouter(RegisterFormContainer)
