import styled from 'styled-components'

import {
  PurpleColorL,
  WhiteColor
} from '../../assets/styles/colors'

export const PrimaryEventContainer = styled.div`
  background-color: ${WhiteColor};
  border-radius: 3px;
  box-shadow: 0 25px 15px 0 rgba(217, 209, 255, 0.3);
  padding: 30px;
`

export const PrimaryEventHeader = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 0 50px;
`

export const PrimaryEventHeaderLeftContainer = styled.div`
  align-items: center;
  display: flex;
`

export const PrimaryEventLocationIconContainer = styled.div`
  margin: 0 10px 0 0;
  position: relative;
  width: 16px;

  &::after {
    background-color: rgba(252,208,163,0.3);
    bottom: 0;
    content: '';
    display: block;
    filter: blur(5px);
    height: 100%;
    position: absolute;
    width: 100%;
  }
`

export const PrimaryEventName = styled.h2`
  color: ${PurpleColorL};
`

export const PrimaryEventLogoIconContainer = styled.div`
  width: 30px;
`

export const PrimaryEventContent = styled.div``

export const PrimaryEventDateContainer = styled.div``

export const PrimaryEventDate = styled.div`
  color: ${PurpleColorL};
`

export const PrimaryEventPlaceContainer = styled.div``

export const PrimaryEventPlace = styled.div`
  color: ${PurpleColorL};
`
