export const menuList = [
  {
    label: 'Wyloguj',
    link: '/wyloguj'
  }
]

export const userZoneMenuList = Number(process.env.REACT_APP_CV) === 0 ? [
  {
    label: 'Kokpit',
    link: '/platforma'
  },
  {
    label: 'Ustawienia',
    link: '/ustawienia'
  },
  {
    label: 'Wyloguj',
    link: '/wyloguj'
  }
] : [
  {
    label: 'Kokpit',
    link: '/platforma'
  },
  {
    label: 'Ustawienia',
    link: '/ustawienia'
  },
  {
    label: 'Wyloguj',
    link: '/wyloguj'
  }
]

// TODO hide moje cv if user is from cvworks
