import styled from 'styled-components'

import {
  PinkColor,
  PinkColorM,
  PurpleBoxShadowColor,
  PurpleColorXS,
  SilverColor,
  WhiteColor
} from '../../../assets/styles/colors'

export const CompanyBoxContainer = styled.div`
  background-color: ${WhiteColor};
  border-radius: 20px;
  box-shadow: ${PurpleBoxShadowColor};
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;

  @media (min-width: 768px) {
    padding: 30px;
  }

  @media (max-width: 767px) {
    padding: 20px;
  }
`

export const CompanyBoxTopContainer = styled.div`
  align-items: flex-start;
  display: flex;
  justify-content: space-between;

  ${({ showTop }) => showTop && 'margin: 0 0 10px'};
`

export const CompanyBoxValueContainer = styled.div`
  align-items: baseline;
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const CompanyBoxValue = styled.div`
  color: ${PinkColor};
  font-size: 18px;
  font-weight: 600;
  line-height: 21px;
`

export const CompanyBoxText = styled.div`
  color: ${SilverColor};
  font-size: 14px;
  line-height: 21px;
  margin: 0 0 0 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const CompanyBoxIconsContainer = styled.div`
  align-items: flex-start;
  display: flex;
  margin: 0 0 0 5px;
`

export const CompanyBoxIsFastRecruitment = styled.div`
  margin: 0 10px 0 0;
  width: 30px;
  
  & g {
    stroke: ${PurpleColorXS};
  }

  ${({ isFast }) => isFast && `
      & g {
        fill: ${PinkColorM};
        stroke: ${PinkColorM};
      }
    `
  }
`

export const CompanyBoxIsFavourite = styled.div`
  width: 25px;

  & g {
    stroke: ${PurpleColorXS};
  }

  ${({ isFavourite }) => isFavourite && `
      & g {
        fill: ${PinkColorM};
        stroke: ${PinkColorM};
      }
    `
  }
`

export const CompanyBoxContentContainer = styled.div`
  height: 100%;

  ${({ showRightColumn }) => showRightColumn && `
    align-items: center;
    display: flex;
    justify-content: center;

    @media (max-width: 767px) {
      flex-direction: column-reverse;
    }
  `} 
`

export const CompanyBoxContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  @media (min-width: 768px) {
    justify-content: space-between;
  }

  ${({ showRightColumn }) => showRightColumn && `
      flex: 1;

      @media (max-width: 767px) {
        height: auto;
      }
    `
  }
`

export const CompanyBoxImageContainer = styled.div`
  display: flex;
  justify-content: center;

  & div {
    padding: 0;
  }

  & img {
    border-radius: 20px;
  }

  @media (min-width: 1200px) {
    height: 240px;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    height: 220px;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    height: 200px;
  }

  @media (min-width: 576px) and (max-width: 767px) {
    height: 180px;
  }

  @media (max-width: 575px) {
    height: 160px;
  }
`

export const CompanyBoxButtonContainer = styled.div`
  margin: 20px 0 0;
`

export const CompanyBoxVideo = styled.div`
  flex: 1;

  ${({ showRightColumn }) => showRightColumn && `
      @media (min-width: 768px) {
        padding: 0 0 0 30px;
      }
    `
  }

  @media (min-width: 768px) {
    height: 100%;
    min-height: 280px;
  }

  @media (min-width: 576px) and (max-width: 767px) {
    height: 260px;
    margin: 0 0 30px;
    max-width: 500px;
  }

  @media (max-width: 575px) {
    height: 300px;
    margin: 0 0 20px;
  }
`
