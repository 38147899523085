import React from 'react'
import PropTypes from 'prop-types'

import {
  TrainingItemHeaderTimeBox,
  TrainingItemHeaderTimeBoxTime
} from './components'

const TrainingItemHeaderTime = ({
  timeEnd,
  timeStart
}) => (
  <TrainingItemHeaderTimeBox>
    {timeStart && timeEnd &&
      <>
        <TrainingItemHeaderTimeBoxTime data-test='TimeStart'>{timeStart}</TrainingItemHeaderTimeBoxTime>

        <TrainingItemHeaderTimeBoxTime data-test='Separator'>-</TrainingItemHeaderTimeBoxTime>

        <TrainingItemHeaderTimeBoxTime data-test='TimeEnd'>{timeEnd}</TrainingItemHeaderTimeBoxTime>
      </>}
  </TrainingItemHeaderTimeBox>
)

export default TrainingItemHeaderTime

TrainingItemHeaderTime.propTypes = {
  timeEnd: PropTypes.string,
  timeStart: PropTypes.string
}
