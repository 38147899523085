import React from 'react'
import PropTypes from 'prop-types'

import Icon from '../../../Form/FormBasicElements/Icon'

import {
  BenefitItemContainer,
  BenefitItemDescription,
  BenefitItemIconContainer,
  BenefitItemRightContainer,
  BenefitItemTitle
} from './components'

const BenefitItem = ({
  description,
  icon,
  title
}) => (
  <BenefitItemContainer>
    {
      icon && (
        <BenefitItemIconContainer>
          <Icon icon={icon} />
        </BenefitItemIconContainer>
      )
    }

    <BenefitItemRightContainer>
      {
        title && (
          <BenefitItemTitle>{title}</BenefitItemTitle>
        )
      }

      {
        description && (
          <BenefitItemDescription>{description}</BenefitItemDescription>
        )
      }
    </BenefitItemRightContainer>
  </BenefitItemContainer>
)

export default BenefitItem

BenefitItem.propTypes = {
  description: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string
}
