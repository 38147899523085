import styled from 'styled-components'

import {
  PinkColorS,
  PurpleBoxShadowColor,
  RedColor,
  WhiteColor
} from '../../../../assets/styles/colors'

export const FormPanelContainer = styled.div`
  background: ${WhiteColor};
  border-radius: 20px;
  position: relative;

  ${props => {
    switch (props.boxShadow) {
      case 'noShadow': return 'box-shadow: none;'
      default: return `box-shadow: ${PurpleBoxShadowColor};`
    }
  }}

  ${({ height }) => height ? `height: ${height}` : ''};

  ${props => {
    switch (props.noMargin) {
      case true: return 'margin: 0;'
      default: return 'margin: 32px 0 0;'
    }
  }}

  ${props => {
    switch (props.paddingSize) {
      case 'noPadding': return 'padding: 0;'
      case 'sizeS': return `
        @media (min-width: 1200px) {
          padding: 30px;
        }
        
        @media (min-width: 992px) and (max-width: 1199px) {
          padding: 25px;
        }

        @media (min-width: 576px) and (max-width: 991px) {
          padding: 20px;
        }

        @media (max-width: 575px) {
          padding: 15px;
        }
      `
      case 'sizeM': return 'padding: 20px 40px 40px;'
      default: return `
        @media (min-width: 1200px) {
          padding: 40px 60px;
        }

        @media (min-width: 576px) and (max-width: 1199px) {
          padding: 40px;
        }

        @media (max-width: 575px) {
          padding: 30px;
        }
      `
    }
  }}
`

export const FormPanelLeftFader = styled.div`
  background: linear-gradient(270deg,rgba(255,255,255,0) 20%,#fff 100%);
  border-radius: 20px;
  height: 100%;
  left: 10px;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 20%;
  z-index: 10;
`

export const FormPanelErrorsContainer = styled.div`
  background-color: ${PinkColorS};
  border-left: 4px solid ${RedColor};
  border-radius: 5px;
  color: ${WhiteColor};
  margin: 0 0 20px;
  padding: 20px 25px;
  text-align: left;
`

export const FormPanelSuccessContainer = styled.div`
  background-color: #58ff6e;
  border-left: 4px solid #07bc0c;
  border-radius: 5px;
  color: ${WhiteColor};
  margin: 0 0 20px;
  padding: 20px 25px;
  text-align: center;
`

export const FormPanelContent = styled.div``

export const FormPanelRightFader = styled.div`
  background: linear-gradient(270deg,#fff 20%,rgba(255,255,255,0) 100%);
  border-radius: 20px;
  height: 100%;
  pointer-events: none;
  position: absolute;
  right: 10px;
  top: 0;
  width: 20%;
  z-index: 10;
`
