import styled from 'styled-components'

import {
  PinkColor,
  PurpleBoxShadowColor,
  PurpleColorL,
  WhiteColor
} from '../../../assets/styles/colors'

import { LabelContainer } from '../../Form/FormBasicElements/Label/components'
import { SelectableItemsContainer } from '../../SelectableItems/SelectableItems/components'

export const GeneralSectionContainer = styled.div`
  margin: 50px 0 0;

  &:first-child {
    margin: 0;
  }

  @media (min-width: 320px) and (max-width: 767px) {
    ${LabelContainer} {
      text-align: center;
    }

    ${SelectableItemsContainer} {
      justify-content: center;
    }
  }
`

export const GeneralOurMission = styled.div`
  background-color: ${PinkColor};
  border-radius: 20px;
  box-shadow: ${PurpleBoxShadowColor};
  color: ${WhiteColor};
  font-size: 14px;
  line-height: 30px;

  @media (min-width: 768px) {
    padding: 20px 30px;
    text-align: left;
    white-space: pre-wrap;
  }

  @media (min-width: 320px) and (max-width: 767px) {
    padding: 10px;
    text-align: justify;
  }
`

export const GeneralText = styled.div`
  color: ${PurpleColorL};
  font-size: 14px;
  line-height: 28px;
  text-align: justify;

  @media (min-width: 768px) {
    white-space: pre-wrap;
  }
`
