import React from 'react'
import PropTypes from 'prop-types'

import EmployerProfileLeft from './EmployerProfileLeft'
import EmployerProfileRight from './EmployerProfileRight'
import Icon from '../Form/FormBasicElements/Icon'

import {
  EmployerProfileContainer,
  EmployerProfileMenuTriggerButton,
  EmployerProfileMenuTriggerContainer
} from './components'

import burgerMenuIconPath from '../../assets/icons/menu_bars.svg'

class EmployerProfile extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      isOpen: false
    }

    this.toggleShow = this.toggleShow.bind(this)
  }

  toggleShow () {
    this.setState({ isOpen: !this.state.isOpen })
  }

  render () {
    const {
      articlesList,
      awardsList,
      benefitsList,
      chatUrl,
      companyId,
      compatibility,
      hasQuickRecruitment,
      image,
      industries,
      isPreview,
      jobsList,
      name,
      officesList,
      onOptionChange,
      option,
      ourMission,
      recruitersList,
      recruitmentDetails,
      socialMedia,
      tabsList,
      text,
      thumbnailUrl,
      valueTags,
      videosList
    } = this.props

    const handleOnClick = this.toggleShow

    return (
      <EmployerProfileContainer>
        <EmployerProfileMenuTriggerContainer>
          <EmployerProfileMenuTriggerButton onClick={handleOnClick}>
            <Icon icon={burgerMenuIconPath} />
          </EmployerProfileMenuTriggerButton>
        </EmployerProfileMenuTriggerContainer>

        <EmployerProfileLeft
          companyId={companyId}
          compatibility={compatibility}
          hasQuickRecruitment={hasQuickRecruitment}
          industries={industries}
          isOpen={this.state.isOpen}
          isPreview={isPreview}
          name={name}
          onOptionChange={onOptionChange}
          socialMedia={socialMedia}
          tabsList={tabsList}
          thumbnailUrl={thumbnailUrl}
        />

        <EmployerProfileRight
          articlesList={articlesList}
          awardsList={awardsList}
          benefitsList={benefitsList}
          chatUrl={chatUrl}
          image={image}
          jobsList={jobsList}
          officesList={officesList}
          onOptionChange={onOptionChange}
          option={option}
          ourMission={ourMission}
          recruitersList={recruitersList}
          recruitmentDetails={recruitmentDetails}
          tabsList={tabsList}
          text={text}
          valueTags={valueTags}
          videosList={videosList}
        />
      </EmployerProfileContainer>
    )
  }
}

export default EmployerProfile

EmployerProfile.propTypes = {
  articlesList: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string,
      isPublished: PropTypes.bool,
      text: PropTypes.string,
      thumbnailUrl: PropTypes.string,
      title: PropTypes.string
    })
  ),
  awardsList: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string,
      description: PropTypes.string,
      name: PropTypes.string,
      place: PropTypes.string
    })
  ),
  benefitsList: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      icon: PropTypes.string,
      title: PropTypes.string
    })
  ),
  chatUrl: PropTypes.string,
  compatibility: PropTypes.string,
  image: PropTypes.string,
  industries: PropTypes.array,
  jobsList: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string,
      title: PropTypes.string.isRequired
    })
  ),
  name: PropTypes.string,
  officesList: PropTypes.arrayOf(
    PropTypes.shape({
      city: PropTypes.string,
      itemsList: PropTypes.arrayOf(PropTypes.string),
      name: PropTypes.string,
      street: PropTypes.string
    })
  ),
  option: PropTypes.string,
  ourMission: PropTypes.string,
  recruitmentDetails: PropTypes.shape({
    assessmentCenter: PropTypes.bool,
    departments: PropTypes.string,
    employmentTypes: PropTypes.arrayOf(PropTypes.string),
    jobInterview: PropTypes.bool,
    needsCv: PropTypes.bool,
    needsMotivationalList: PropTypes.bool,
    place: PropTypes.string,
    preferredCandidates: PropTypes.arrayOf(PropTypes.string),
    preferredDegreeCourses: PropTypes.string
  }),
  recruitersList: PropTypes.arrayOf(
    PropTypes.shape({
      avatarUrl: PropTypes.string,
      email: PropTypes.string,
      itemsList: PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.string
        })
      ),
      name: PropTypes.string,
      role: PropTypes.string
    })
  ),
  socialMedia: PropTypes.shape({
    facebook: PropTypes.string,
    goldenline: PropTypes.string,
    instagram: PropTypes.string,
    linkedin: PropTypes.string,
    youtube: PropTypes.string
  }),
  tabsList: PropTypes.arrayOf(
    PropTypes.shape({
      isActive: PropTypes.bool,
      label: PropTypes.string
    })
  ),
  text: PropTypes.string,
  thumbnailUrl: PropTypes.string,
  valueTags: PropTypes.arrayOf(
    PropTypes.shape({
      isMostImportant: PropTypes.bool,
      isSelected: PropTypes.bool,
      label: PropTypes.string
    })
  ),
  videosList: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      thumbnailUrl: PropTypes.string
    })
  )
}
