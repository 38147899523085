import React from 'react'
import PropTypes from 'prop-types'

import Errors from '../../FormBasicElements/Errors'
import Label from '../../FormBasicElements/Label'
import MultiSelectDropDown from '../../FormBasicElements/MultiSelectDropDown'

import {
  MultiSelectDropDownFieldContainer
} from './components'

const MultiSelectDropDownField = ({
  closeOnClick,
  errorMessage,
  hasError,
  isMulti,
  itemsList,
  label,
  name,
  onClick,
  placeholder,
  required,
  value
}) => (
  <MultiSelectDropDownFieldContainer>
    <Label
      label={label}
      required={required}
    />

    <MultiSelectDropDown
      closeOnClick={closeOnClick}
      hasError={hasError}
      isMulti={isMulti}
      itemsList={itemsList}
      name={name}
      onClick={onClick}
      placeholder={placeholder}
      value={value}
    />

    <Errors
      errorMessage={errorMessage}
      hasError={hasError}
    />
  </MultiSelectDropDownFieldContainer>
)

export default MultiSelectDropDownField

MultiSelectDropDownField.propTypes = {
  errorMessage: PropTypes.string,
  hasError: PropTypes.bool,
  itemsList: PropTypes.arrayOf(
    PropTypes.shape({
      isActive: PropTypes.bool,
      label: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.number,
        PropTypes.string
      ])
    })
  ),
  label: PropTypes.string,
  onClick: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool
}
