import React from 'react'
import ReactPlayer from 'react-player'

import Modal from '../../Modal'

import {
  PlayModalContainer
} from './components'

const PlayModal = () => (
  <Modal
    isFixed
    link='/targi-pracy-wroclaw'
  >
    <PlayModalContainer>
      <ReactPlayer
        height='100%'
        url='https://www.youtube.com/watch?v=9gaKSuI1xg8'
        width='100%'
      />
    </PlayModalContainer>
  </Modal>
)

export default PlayModal
