import styled from 'styled-components'

import {
  PurpleColor,
  SilverColor,
  SilverColorXS,
  PurpleColorS,
  PinkColor,
  WhiteColor
} from '../../../assets/styles/colors'

export const ButtonSwitchContainer = styled.button`
  width: 100%;
  padding: 24px 0;
  line-height: 28px;
  font-size: 18px;
  border: 0px solid transparent;
  cursor: pointer;

  ${props => {
    switch (props.isActive) {
      case true: return `
        background-color: ${PinkColor};
        font-weight: 600;
        color: ${WhiteColor}
      `
      default: return `
        background-color: ${SilverColorXS};
        font-weight: 300;
        color: ${SilverColor};
      `
    }
  }}
`
