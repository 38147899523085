import React from 'react'
import PropTypes from 'prop-types'

import {
  TextAreaContainer
} from './components'

const TextArea = ({
  disabled,
  hasError,
  id,
  maxLength,
  minLength,
  name,
  onBlur,
  onChange,
  placeholder,
  required,
  value
}) => (
  <TextAreaContainer
    disabled={disabled}
    hasError={hasError}
    id={id}
    maxLength={maxLength}
    minLength={minLength}
    name={name}
    onBlur={onBlur}
    onChange={onChange}
    placeholder={placeholder}
    required={required}
    value={value}
  />
)

export default TextArea

TextArea.propTypes = {
  disabled: PropTypes.bool,
  hasError: PropTypes.bool,
  id: PropTypes.string,
  maxLength: PropTypes.number,
  minLength: PropTypes.number,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.string
}

TextArea.defaultProps = {
  disabled: false,
  hasError: false,
  id: '',
  name: '',
  // TODO onBlur: () => null,
  // TODO onChange: () => null,
  placeholder: '',
  required: false
}
