import types from './types'

const fetchCvError = (error) => ({
  type: types.FETCH_ERROR,
  payload: error
})

const fetchCvRequested = (id) => ({
  type: types.FETCH_REQUESTED,
  payload: {
    id
  }
})

const clearCV = () => ({
  type: types.CLEAR
})

const setHtmlAsFetched = () => ({
  type: types.SET_HTML_AS_FETCHED
})

const fetchCvSuccess = (data) => ({
  type: types.FETCH_SUCCESS,
  payload: {
    data
  }
})

export default {
  fetchCvError,
  fetchCvRequested,
  fetchCvSuccess,
  clearCV,
  setHtmlAsFetched
}
