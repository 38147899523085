import { of } from 'rxjs'
import { ofType } from 'redux-observable'
import { catchError, map, mergeMap } from 'rxjs/operators'

import getError from './getError'

export default (type, apiEndpoint, onSuccess, onError) => (action$, state$, { fetch }) => {
  return action$
    .pipe(
      ofType(type),
      mergeMap(() => {
        return fetch(apiEndpoint).pipe(
          map((response) => onSuccess(response.response)),
          catchError((error) => of(onError(getError(error))))
        )
      }
      )
    )
}
