import ApiService from '../services/ApiService'
import asUpload from '../../utils/asUpload'

export const getAllIndustries = () => new ApiService('GET', '/industries')
export const getAllCompanies = () => new ApiService('GET', '/companies?unpublished')

export const getMe = () => new ApiService('GET', '/companies/me', {}, { withAuth: true, allowGhost: true })
export const updateMe = (data) => new ApiService('PUT', '/companies/me', data, { withAuth: true, allowGhost: true })
export const updateMyRecruitment = (data) => new ApiService('POST', '/companies/recruitment', data, { withAuth: true, allowGhost: true })

export const addIndustry = (id) => new ApiService('POST', `/companies/industries/${id}`, {}, { withAuth: true, allowGhost: true })
export const removeIndustry = (id) => new ApiService('DELETE', `/companies/industries/${id}`, {}, { withAuth: true, allowGhost: true })

export const addValue = (id) => new ApiService('POST', `/companies/values/${id}`, {}, { withAuth: true, allowGhost: true })
export const removeValue = (id) => new ApiService('DELETE', `/companies/values/${id}`, {}, { withAuth: true, allowGhost: true })

export const addOffice = (data) => new ApiService('POST', '/companies/offices', data, { withAuth: true, allowGhost: true })
export const updateOffice = (id, data) => new ApiService('PUT', `/companies/offices/${id}`, data, { withAuth: true, allowGhost: true })
export const removeOffice = (id) => new ApiService('DELETE', `/companies/offices/${id}`, {}, { withAuth: true, allowGhost: true })

export const addVideo = (data) => new ApiService('POST', '/companies/videos', data, { withAuth: true, allowGhost: true })
export const updateVideo = (id, data) => new ApiService('PUT', `/companies/videos/${id}`, data, { withAuth: true, allowGhost: true })
export const removeVideo = (id) => new ApiService('DELETE', `/companies/videos/${id}`, {}, { withAuth: true, allowGhost: true })

export const addAward = (data) => new ApiService('POST', '/companies/awards', data, { withAuth: true, allowGhost: true })
export const updateAward = (id, data) => new ApiService('PUT', `/companies/awards/${id}`, data, { withAuth: true, allowGhost: true })
export const removeAward = (id) => new ApiService('DELETE', `/companies/awards/${id}`, {}, { withAuth: true, allowGhost: true })

export const addBenefit = (data) => new ApiService('POST', '/companies/benefits', data, { withAuth: true, allowGhost: true })
export const updateBenefit = (id, data) => new ApiService('PUT', `/companies/benefits/${id}`, data, { withAuth: true, allowGhost: true })
export const removeBenefit = (id) => new ApiService('DELETE', `/companies/benefits/${id}`, {}, { withAuth: true, allowGhost: true })

export const addRecruiter = (data) => new ApiService('POST', '/companies/recruitment/recruiters', data, { withAuth: true, allowGhost: true })
export const updateRecruiter = (id, data) => new ApiService('PUT', `/companies/recruitment/recruiters/${id}`, data, { withAuth: true, allowGhost: true })
export const removeRecruiter = (id) => new ApiService('DELETE', `/companies/recruitment/recruiters/${id}`, {}, { withAuth: true, allowGhost: true })

export const uploadVectorLogo = (body) => asUpload(new ApiService('POST', '/companies/me/vector-logo', {}, { withAuth: true, allowGhost: true }).toObject(), body)
export const uploadBanner = (body) => asUpload(new ApiService('POST', '/companies/me/banner', {}, { withAuth: true, allowGhost: true }).toObject(), body)
export const uploadLogo = (body) => asUpload(new ApiService('POST', '/companies/me/logo', {}, { withAuth: true, allowGhost: true }).toObject(), body)
export const uploadRecruiterAvatar = (body) => asUpload(new ApiService('POST', '/companies/me/recruiter-avatar', {}, { withAuth: true, allowGhost: true }).toObject(), body)

export const addUserToCompany = (id, data) => new ApiService('POST', `/companies/${id}/add-user`, data, { withAuth: true })
export const quickCreateCompany = (data) => new ApiService('POST', '/companies/quick-create', data, { withAuth: true })

export const updateCompany = (id, data) => new ApiService('PUT', `/companies/${id}`, data, { withAuth: true })
export const removeCompany = (id, data) => new ApiService('DELETE', `/companies/${id}`, data, { withAuth: true })
