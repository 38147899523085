import React from 'react'
import PropTypes from 'prop-types'

import Label from '../../FormBasicElements/Label'
import TextComponent from '../../FormBasicElements/TextComponent'

import {
  TextComponentFieldContainer
} from './components'

const TextComponentField = ({
  align,
  label,
  text
}) => (
  <TextComponentFieldContainer>
    <Label label={label} />

    <TextComponent
      align={align}
      text={text}
    />
  </TextComponentFieldContainer>
)

export default TextComponentField

TextComponentField.propTypes = {
  align: PropTypes.string,
  label: PropTypes.string,
  text: PropTypes.string
}
