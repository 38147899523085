import styled from 'styled-components'

import {
  OrangeColor,
  PurpleColorM,
  PurpleColorXS
} from '../../../../assets/styles/colors'

export const AwardItemContainer = styled.div`
  border-bottom: 1px solid #F6F5FF;
  display: flex;
  
  &:last-child {
    border-bottom: 0px solid transparent;
  }

  @media (min-width: 992px) {
    padding: 30px 0;

    &:first-child {
      padding: 6px 0 30px;
    }
  }

  @media (min-width: 768px) and (max-width: 991px) {
    padding: 25px 0;

    &:first-child {
      padding: 6px 0 25px;
    }
  }

  @media (min-width: 320px) and (max-width: 767px) {
    justify-content: center;
    padding: 20px 0;

    &:first-child {
      padding: 6px 0 20px;
    }
  }
`

export const AwardItemIconContainer = styled.div`
  flex-shrink: 0;
  width: 20px;

  & g {
    stroke: ${OrangeColor};
    stroke-width: 3;
  }
`

export const AwardItemContent = styled.div`
  display: flex;
  margin: 0 0 0 15px;

  @media (min-width: 992px) {
    justify-content: space-between;    
  }

  @media (min-width: 768px) {
    width: 100%;
  }

  @media (min-width: 320px) and (max-width: 991px) {
    flex-direction: column;
  }
`

export const AwardItemNameAndDecriptionContainer = styled.div``

export const AwardItemName = styled.div`
  color: ${PurpleColorM};
  font-size: 16px;
  font-weight: 600;
  line-height: 25px;
  text-align: left;
`

export const AwardItemDescription = styled.div`
  color: ${PurpleColorM};
  font-size: 12px;
  line-height: 18px;
  padding: 8px 0 0;
  text-align: left;
`

export const AwardItemDateAndPlaceContainer = styled.div`
  display: flex;

  @media (min-width: 320px) and (max-width: 991px) {
    margin: 6px 0 0;
  }
`

export const AwardItemDateContainer = styled.div`
  flex-shrink: 0;

  & g {
    stroke: ${PurpleColorXS};
  }

  @media (min-width: 992px) {
    width: 120px;
  }

  @media (min-width: 320px) and (max-width: 991px) {
    margin: 0 15px 0 0;
  }
`

export const AwardItemPlaceContainer = styled.div`
  flex-shrink: 0;

  & g {
    stroke: ${PurpleColorXS};
  }

  @media (min-width: 992px) {
    width: 150px;
  }
`
