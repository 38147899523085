import React from 'react'

import FormPanel from '../../FormWrappers/FormPanel'
import RequiredFieldsText from '../../FormBasicElements/RequiredFieldsText'
import TextAreaField from '../../FormFields/TextAreaField'

const AboutCompanyForm = ({
  apiError,
  errors,
  handleBlur,
  handleChange,
  touched,
  values
}) => {
  return (
    <FormPanel errors={apiError}>
      <TextAreaField
        data-test='companyMission'
        errorMessage={errors.mission}
        hasError={errors.mission && touched.mission}
        id='companyMission'
        label='Jaka jest misja Twojej firmy? '
        maxLength={500}
        name='mission'
        onBlur={handleBlur}
        onChange={handleChange}
        placeholder='Opisz pokrótce, jakie działania podejmujecie, aby zmienić świat na lepsze!'
        value={values.mission}
      />

      <TextAreaField
        data-test='companyAbout'
        errorMessage={errors.aboutUs}
        hasError={errors.aboutUs && touched.aboutUs}
        id='companyAbout'
        label='O nas'
        maxLength={1800}
        name='aboutUs'
        onBlur={handleBlur}
        onChange={handleChange}
        placeholder='Opisz pokrótce swoją firmę - czym się zajmujecie, jaki problem rozwiązujecie, komu pomagacie. Jakich ludzi szukacie, czym się kierujecie?'
        value={values.aboutUs}
      />

      <RequiredFieldsText />
    </FormPanel>
  )
}

export default AboutCompanyForm
