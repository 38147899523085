import React from 'react'

import FormPanel from '../../FormWrappers/FormPanel'
import FormTwoColumns from '../../FormWrappers/FormTwoColumns'
import InputField from '../../FormFields/InputField'
import RequiredFieldsText from '../../FormBasicElements/RequiredFieldsText'
import AddedItem from '../../../List/AddedItem'

const AddAwardForm = ({
  apiError,
  errors,
  handleBlur,
  handleChange,
  handleDelete,
  handleEdit,
  isLoading,
  items,
  touched,
  values
}) => (
  <FormPanel
    errors={apiError}
  >
    {items.map((item, index) => (
      <AddedItem
        isInsideForm
        key={index}
        title={`${item.isEditing ? '(W trakcie edycji) ' : ''}${item.title}`}
        description={item.description}
        onClickDelete={() => handleDelete(item._id)}
        onClickEdit={() => handleEdit(item._id)}
        isLoading={isLoading}
      />
    ))}
    <FormTwoColumns>
      <InputField
        data-test='Contest'
        errorMessage={errors.contestName}
        hasError={errors.contestName && touched.contestName}
        id='Contest'
        label='Konkurs'
        name='contestName'
        onBlur={handleBlur}
        onChange={handleChange}
        placeholder='Wpisz nazwę konkursu'
        value={values.contestName}
      />

      <InputField
        data-test='Award'
        errorMessage={errors.name}
        hasError={errors.name && touched.name}
        id='Award'
        label='Zdobyta nagroda lub wyróżnienie'
        name='name'
        onBlur={handleBlur}
        onChange={handleChange}
        placeholder='Wpisz zdobyte wyróżnienie'
        value={values.name}
      />

      <InputField
        data-test='Date'
        errorMessage={errors.date}
        hasError={errors.date && touched.date}
        id='Date'
        label='Data'
        name='date'
        onBlur={handleBlur}
        onChange={handleChange}
        placeholder='Wpisz datę w formacie DD-MM-RRRR'
        value={values.date}
      />

      <InputField
        data-test='Place'
        errorMessage={errors.place}
        hasError={errors.place && touched.place}
        id='Place'
        label='Miejsce'
        name='place'
        onBlur={handleBlur}
        onChange={handleChange}
        placeholder='Wpisz lokalizację'
        value={values.place}
      />
    </FormTwoColumns>

    <RequiredFieldsText />
  </FormPanel>
)

export default AddAwardForm

// TODO Fix Translations
