import types from './types'

const changeFlag = (key, value) => ({
  type: types.CHANGE_FLAG,
  payload: { key, value }
})

export default {
  changeFlag
}
