import React from 'react'
import PropTypes from 'prop-types'

import ButtonComponent from '../../../Buttons/ButtonComponent'

import {
  ResetAndSaveButtonsContainer,
  ResetAndSaveButtonsContent
} from './components'

import translationsList from '../../../../stabs/translationsList'

const ResetAndSaveButtons = ({
  disabledSave,
  isClearing,
  isEditing,
  isSaveSubmitting,
  isLoadingSave,
  onClickReset,
  onClickSave,
  saveLabel
}) => (
  <ResetAndSaveButtonsContainer>
    <ResetAndSaveButtonsContent>
      <ButtonComponent
        bgColor='purpleColorS'
        color='{#6B677E}'
        data-test='reset'
        fontBold
        fullWidth
        isLoading={false}
        isLoadingColor='yellowColor'
        label={isEditing ? translationsList.controlButtons.edit : (isClearing ? translationsList.controlButtons.clear : translationsList.controlButtons.reverse)}
        onClick={onClickReset}
        buttonType='button'
        size='sizeM'
      />

      <ButtonComponent
        bgColor='purpleColor'
        color='whiteColor'
        disabled={disabledSave}
        data-test='save'
        fontBold
        fullWidth
        isLoading={isLoadingSave}
        isLoadingColor='purpleColorS'
        label={saveLabel || translationsList.form.labels.buttonSaveLabel}
        buttonType={isSaveSubmitting ? 'submit' : null}
        onClick={isSaveSubmitting ? null : onClickSave}
        size='sizeM'
      />
    </ResetAndSaveButtonsContent>
  </ResetAndSaveButtonsContainer>
)

export default ResetAndSaveButtons

ResetAndSaveButtons.propTypes = {
  isEditing: PropTypes.bool,
  onClickReset: PropTypes.func,
  onClickSave: PropTypes.func
}

ResetAndSaveButtons.defaultProps = {
  isClearing: true
}
