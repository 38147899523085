import styled from 'styled-components'

export const TextComponentFieldContainer = styled.div`
  @media (min-width: 992px) {
    padding: 0 0 20px; 
  }

  @media (min-width: 320px) and (max-width: 991px) {
    padding: 0 0 15px;
  }
`
