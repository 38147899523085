import React from 'react'
import PropTypes from 'prop-types'

import Icon from '../../Form/FormBasicElements/Icon'
import InternalOrExternal from '../../Buttons/InternalOrExternal'

import {
  ChooseIconContainer
} from './components'

const ChooseIcon = ({
  icon,
  onClick
}) => (
  <ChooseIconContainer>
    <InternalOrExternal onClick={onClick}>
      <Icon icon={icon} />
    </InternalOrExternal>
  </ChooseIconContainer>
)

export default ChooseIcon

ChooseIcon.propTypes = {
  icon: PropTypes.string,
  onClick: PropTypes.func
}
