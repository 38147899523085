import styled from 'styled-components'

export const ZonesContainer = styled.div`
  @media (min-width: 1200px) {
    display: flex;
    justify-content: space-between;
    margin: 60px 0 0;
  }

  @media (min-width: 768px) and (max-width: 1199px) {
    margin: 30px 0 0;
  }

  @media (min-width: 320px) and (max-width: 767px) {
    margin: 20px 0 0;
  }
`

export const ZoneMenuItemContainer = styled.div`
  @media (min-width: 1200px) {
    flex-shrink: 0;
  }

  @media (min-width: 320px) and (max-width: 1199px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
`

export const ZoneMenuItemContent = styled.div`
  @media (min-width: 1200px) {
    margin: 0 0 20px;

    &:last-child {
      margin: 0;
    }
  }

  @media (min-width: 768px) and (max-width: 1199px) {
    margin: 0 15px 15px 0;
    
    &:last-child {
      margin: 0 0 15px;
    }
  }

  @media (min-width: 576px) and (max-width: 767px) {
    margin: 0 10px 10px 0;

    &:last-child {
      margin: 0 0 10px;
    }
  }

  @media (min-width: 320px) and (max-width: 575px) {
    margin: 0 8px 8px 0;

    &:last-child {
      margin: 0 0 8px;
    }
  }
`

export const ZoneRightContainer = styled.div`
  @media (min-width: 1600px) {
    margin: 0 0 0 120px;
  }

  @media (min-width: 1200px) and (max-width: 1599px) {
    margin: 0 0 0 60px;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    display: inline-flex;
    margin: 30px auto 0;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    display: inline-flex;
    margin: 25px auto 0;
  }
  
  @media (min-width: 576px) and (max-width: 767px) {
    display: inline-flex;
    margin: 20px auto 0;
  }

  @media (min-width: 320px) and (max-width: 575px) {
    display: inline-flex;
    margin: 15px auto 0;
  }
`
