import React, { useState } from 'react'
import _ from 'lodash'
import * as Yup from 'yup'
import { Formik } from 'formik'

import { useDispatch, useSelector } from 'react-redux'
import ResetAndSaveButtons from '../../../../components/Form/FormComponents/ResetAndSaveButtons'
import ApiService from '../../../../store/services/ApiService'
import * as companyAPI from '../../../../store/api/company'
import { myCompanyActions, myCompanySelectors } from '../../../../store/ducks/my-company'
import translate from '../../../../stabs/errorMessagesTranslations'
import { toast } from 'react-toastify'
import AddedItem from '../../../../components/List/AddedItem'
import FormTwoColumns from '../../../../components/Form/FormWrappers/FormTwoColumns'
import InputField from '../../../../components/Form/FormFields/InputField'
import RequiredFieldsText from '../../../../components/Form/FormBasicElements/RequiredFieldsText'
import FormPanel from '../../../../components/Form/FormWrappers/FormPanel'

const AddBranchFormContainer = () => {
  const [state, setState] = useState({
    error: null,
    isEditing: false,
    isLoading: false,
    editingItemId: null
  })

  const company = useSelector(myCompanySelectors.getMyCompany)

  const dispatch = useDispatch()
  const onSubmit = (values, { setSubmitting }) => {
    setState({ ...state, error: null, isLoading: true })

    ApiService
      .fetch(state.isEditing ? companyAPI.updateOffice(state.editingItemId, values) : companyAPI.addOffice(values))
      .then(data => {
        setSubmitting(false)

        toast.success('Zapisano!', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        })

        dispatch(myCompanyActions.fetchMyCompanySuccess(data))
      })
      .catch(error => {
        setSubmitting(false)
        console.log(error)
        console.log(error.details)
        setState({ ...state, error: translate('pl', error.details.msg), isLoading: false })
      })
  }

  const onDelete = (itemId) => {
    setState({ ...state, error: null, isLoading: true })

    ApiService
      .fetch(companyAPI.removeOffice(itemId))
      .then(data => {
        setState({ error: null, isLoading: false, isEditing: false, editingItemId: null })

        toast.success('Usunięto!', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        })

        dispatch(myCompanyActions.fetchMyCompanySuccess(data))
      })
      .catch(error => {
        console.log(error)
        console.log(error.details)
        setState({ error: translate('pl', error.details.msg), isLoading: false, isEditing: false, editingItemId: null })
      })
  }

  const onEdit = (setValues) => (itemId) => {
    setState({ ...state, isEditing: true, editingItemId: itemId })
    const editingItem = company.offices.filter((office) => office._id === itemId)[0]

    setValues(_.omit(editingItem, ['_id']))
  }

  const onReset = (handleReset) => () => {
    setState({ ...state, isEditing: false, editingItemId: null })
    handleReset()
  }

  const mapItemToDisplay = (items) => items.map((item) => ({
    title: `${item.city} - ${item.street}`,
    description: null,
    isEditing: state.editingItemId === item._id,
    _id: item._id
  }))

  return (
    <Formik
      initialValues={{ city: '', street: '', phone: '', postalCode: '' }}
      validationSchema={BranchSchema}
      onSubmit={onSubmit}
      render={({
        errors,
        handleBlur,
        handleChange,
        handleReset,
        handleSubmit,
        isSubmitting,
        setValues,
        touched,
        values
      }) => (
        <form onSubmit={handleSubmit}>
          <FormPanel
            errors={state.error}
            noMargin
          >
            {mapItemToDisplay(company.offices).map((item, index) => (
              <AddedItem
                isInsideForm
                key={index}
                title={`${item.isEditing ? '(W trakcie edycji) ' : ''}${item.title}`}
                description={item.description}
                onClickDelete={() => onDelete(item._id)}
                onClickEdit={() => onEdit(setValues)(item._id)}
                isLoading={state.isLoading}
              />
            ))}

            <FormTwoColumns>
              <InputField
                data-test='CompanyStreet'
                errorMessage={errors.street}
                hasError={errors.street && touched.street}
                id='CompanyStreet'
                label='Ulica i numer'
                name='street'
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder='Wpisz ulicę i numer...'
                value={values.street}
              />

              <InputField
                data-test='CompanyPostCode'
                errorMessage={errors.postalCode}
                hasError={errors.postalCode && touched.postalCode}
                id='CompanyPostCode'
                label='Kod pocztowy'
                name='postalCode'
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder='Wpisz kod pocztowy...'
                value={values.postalCode}
              />

              <InputField
                data-test='CompanyCity'
                errorMessage={errors.city}
                hasError={errors.city && touched.city}
                id='CompanyCity'
                label='Miasto'
                name='city'
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder='Wpisz miasto...'
                value={values.city}
              />

              <InputField
                data-test='CompanyPhone'
                errorMessage={errors.phone}
                hasError={errors.phone && touched.phone}
                id='CompanyPhone'
                label='Telefon'
                name='phone'
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder='Wpisz telefon...'
                value={values.phone}
              />
            </FormTwoColumns>

            <RequiredFieldsText />
          </FormPanel>

          <ResetAndSaveButtons
            data-test='ResetAndSaveButtonsOne'
            onClickReset={onReset(handleReset)}
            disabledSave={isSubmitting}
            isEditing={state.isEditing}
            isLoadingSave={state.isLoading}
            isSaveSubmitting
          />
        </form>
      )}
    />
  )
}

const BranchSchema = Yup.object().shape({
  city: Yup.string()
    .required('Pole jest wymagane'),
  postalCode: Yup.string()
    .required('Pole jest wymagane'),
  street: Yup.string()
    .required('Pole jest wymagane'),
  phone: Yup.string()
})

export default AddBranchFormContainer
