import createSimpleFetchingEpic from '../../utils/createSimpleFetchingEpic'

import * as eventAPI from '../../api/event'

import actions from './actions'
import types from './types'

const fetchTags = createSimpleFetchingEpic(
  types.FETCH_REQUESTED,
  eventAPI.getAllTags().toObject(),
  actions.fetchTagsSuccess,
  actions.fetchTagsError
)

export default {
  fetchTags
}
