import styled from 'styled-components'

import { SilverColor } from '../../../../../assets/styles/colors'

export const LoginPanelLogoContainer = styled.div`
  @media (min-width: 992px) {
    margin: 40px auto;
  }

  @media (min-width: 320px) and (max-width: 991px) {
    margin: 20px auto;
  }

  @media (min-width: 480px) {
    max-width: 260px;
  }

  @media (min-width: 320px) and (max-width: 479px) {
    max-width: 220px;
  }
`

export const LoginPanelContent = styled.div`
  @media (min-width: 992px) {
    padding: 40px 75px;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    padding: 40px;
  }

  @media (min-width: 480px) and (max-width: 767px) {
    padding: 30px;
  }

  @media (min-width: 320px) and (max-width: 479px) {
    padding: 20px;
  }
`

export const LoginPanelForgotPasswordContainer = styled.div`
  text-align: left;
`

export const LoginPanelButtonContainer = styled.div`
  display: grid;

  @media (min-width: 992px) {
    margin: 50px 0 0;
  }

  @media (min-width: 320px) and (max-width: 991px) {
    margin: 40px 0 0;
  }
`

export const LoginPanelLinkContainer = styled.div`
  align-items: center;
  color: ${SilverColor};
  display: inline-grid;
  font-size: 14px;
  grid-auto-flow: column;
  grid-column-gap: 6px;
  justify-content: center;
  line-height: 21px;
  margin: 15px 0 0;
`

export const LoginPanelSpan = styled.span``
