const FETCH_ERROR = 'articles/FETCH_ERROR'
const FETCH_REQUESTED = 'articles/FETCH_REQUESTED'
const FETCH_SUCCESS = 'articles/FETCH_SUCCESS'

const SET_FILTER = 'articles/SET_FILTER'

export default {
  FETCH_ERROR,
  FETCH_REQUESTED,
  FETCH_SUCCESS,
  SET_FILTER
}
