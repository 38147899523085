import styled from 'styled-components'

import {
  OrangeColor,
  PurpleColorM
} from '../../../../assets/styles/colors'

export const OfficeItemContainer = styled.div`
  @media (min-width: 768px) {
    margin: 0 0 20px;
    width: calc(50% - 20px);

    &:nth-child(odd) {
      margin: 0 20px 20px 0;
    }
  }

  @media (min-width: 320px) and (max-width: 767px) {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 0 0 20px;
  }
`

export const OfficeItemIconContainer = styled.div`
  display: flex;
`

export const OfficeItemIconContent = styled.div`
  align-self: start;
  flex-shrink: 0;
  margin: 0 12px 0 0;
  width: 15px;

  & g {
    stroke: ${OrangeColor};
  }
`

export const OfficeItemName = styled.div`
  color: ${PurpleColorM};
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;

  @media (min-width: 768px) {
    text-align: left;
  }

  @media (min-width: 320px) and (max-width: 767px) {
    text-align: center;
  }
`

export const OfficeItemContent = styled.div`
  @media (min-width: 768px) {
    padding: 0 0 0 27px;
  }
`

export const OfficeItemText = styled.div`
  color: ${PurpleColorM};
  font-size: 12px;
  line-height: 18px;

  @media (min-width: 768px) {
    text-align: left;
  }

  @media (min-width: 320px) and (max-width: 767px) {
    text-align: center;
  }
`

export const OfficeItemPhonesListContainer = styled.ul`
  list-style: none;
  padding: 0;

  @media (min-width: 768px) {
    margin: 16px 0 0;
  }

  @media (min-width: 320px) and (max-width: 767px) {
    margin: 8px 0 0;
  }
`

export const OfficeItemPhoneListElement = styled.li`
  display: flex;
  margin: 8px 0 0;

  @media (min-width: 320px) and (max-width: 767px) {
    justify-content: center;
  }
`

export const OfficeItemPhoneLabel = styled.div`
  color: ${PurpleColorM};
  font-size: 12px;
  line-height: 18px;
  margin: 0 5px 0 0;
`

export const OfficeItemPhoneValue = styled.div`
  color: ${PurpleColorM};
  font-size: 12px;
  line-height: 18px;
`
