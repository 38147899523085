import styled from 'styled-components'

import { PurpleColorL } from '../../assets/styles/colors'

export const SubtitleContainer = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: center;
  
  ${props => {
    switch (props.type) {
      case 'noTopMargin': return 'margin: 0 0 32px;'
      default: return `
        @media (min-width: 992px) {
          margin: 60px 0 32px;
        }

        @media (min-width: 576px) and (max-width: 991px) {
          margin: 50px 0 20px;
        }

        @media (min-width: 320px) and (max-width: 575px) {
          margin: 40px 0 15px;
        }
      `
    }
  }}
`

export const SubtitleIconContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15px;
  height: 20px;
  min-width: 15px;
  margin: 0 14px 0 0;
  line-height: 27px;

  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    filter: blur(5px);
    background-color: rgba(154, 155, 159,0.3);
  }
`

export const SubtitleLabel = styled.div`
  line-height: 24px;
  font-size: 18px;
  color: ${PurpleColorL};
`

export const SubtitleLabelH1 = styled.h1`
  line-height: 24px;
  font-size: 18px;
  color: ${PurpleColorL};
`
