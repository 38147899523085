import styled from 'styled-components'

const responsiveColumnsMargins = `
  @media (min-width: 768px) {
    margin: 15px;
  }

  @media (min-width: 576px) and (max-width: 767px) {
    margin: 10px;
  }

  @media (max-width: 575px) {
    margin: 0 0 20px;
    width: 100%;

    &:last-child {
      margin: 0;
    }
  }
`

const responsiveContainerMargins = `
  @media (min-width: 768px) {
    margin: -15px;
  }

  @media (min-width: 576px) and (max-width: 767px) {
    margin: -10px;
  }
`

export const GridWrapper = styled.div`
  width: 100%;
`

export const GridContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${({ noOutsideMargin }) => noOutsideMargin && `
    ${responsiveContainerMargins}
  `}

  ${({ columns }) => {
    switch (columns) {
      case 1: return `
        & > div {
          width: 100%;

          ${responsiveColumnsMargins}
        }
      `
      case 2: return `
        & > div {
          @media (min-width: 768px) {
            width: calc(50% - 30px);
          }

          @media (min-width: 576px) and (max-width: 767px) {
            width: calc(50% - 20px);
          }

          ${responsiveColumnsMargins}
        }
      `
      case 3: return `
        & > div {
          @media (min-width: 992px) {
            width: calc(33.33333% - 30px);
          }

          @media (min-width: 768px) and (max-width: 991px) {
            width: calc(50% - 30px);
          }

          @media (min-width: 576px) and (max-width: 767px) {
            width: calc(50% - 20px);
          }

          ${responsiveColumnsMargins}
        }
      `
      case 4: return `
        & > div {
          @media (min-width: 1200px) {
            width: calc(25% - 30px);
          }

          @media (min-width: 992px) and (max-width: 1199px) {
            width: calc(33.33333% - 30px);
          }

          @media (min-width: 768px) and (max-width: 991px) {
            width: calc(50% - 30px);
          }

          @media (min-width: 576px) and (max-width: 767px) {
            width: calc(50% - 20px);
          }

          ${responsiveColumnsMargins}
        }
      `
      case 'noColumns': return `
        & > div {
          ${responsiveColumnsMargins}
        }
      `
      default: return ''
    }
  }}
`

export const GridChildrenWrapper = styled.div``
