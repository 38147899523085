import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { primaryEventSelectors } from '../../../store/ducks/primary-event'
import { flagsActions, flagsSelectors } from '../../../store/ducks/flags'

import HomePage from '../../../pages/HomePage'

const HomePageContainer = ({ modal, match }) => {
  const primaryEvent = useSelector(primaryEventSelectors.getPrimaryEvent)
  const selectedCvTemplate = useSelector(flagsSelectors.getFlag('selectedCvTemplate'))

  const dispatch = useDispatch()
  const changeCvTemplate = (id) => {
    if (selectedCvTemplate === id) {
      dispatch(flagsActions.changeFlag('selectedCvTemplate', null))
    } else {
      dispatch(flagsActions.changeFlag('selectedCvTemplate', id))
    }
  }

  return (
    <HomePage
      selectedCvTemplate={selectedCvTemplate}
      changeCvTemplate={changeCvTemplate}
      modal={modal}
      event={primaryEvent.data}
      companyModalSlug={match.params.slug}
    />
  )
}

HomePageContainer.propTypes = {
  modal: PropTypes.string
}

HomePageContainer.defaultProps = {
  match: {
    params: {
      slug: null
    }
  }
}

export default HomePageContainer
