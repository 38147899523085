import styled from 'styled-components'

import {
  PurpleColor,
  PurpleColorL,
  WhiteColor
} from '../../assets/styles/colors'

import {
  UploadFileContainer,
  UploadFileInputContainer
} from '../Form/FormBasicElements/UploadFile/components'

export const ImageCropContainer = styled.div`
  background: ${WhiteColor};
  border-radius: 20px;

  @media (min-width: 1200px) {
    padding: 40px 60px;
  }

  @media (min-width: 576px) and (max-width: 1199px) {
    padding: 40px;
  }

  @media (min-width: 320px) and (max-width: 575px) {
    padding: 30px;
  }
`

export const ImageCropTitle = styled.h1`
  color: ${PurpleColorL};
  font-size: 20px;
  line-height: 26px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1000px;
  text-align: center;

  @media (min-width: 1200px) {
    margin-bottom: 40px;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    margin-bottom: 30px;
  }

  @media (min-width: 576px) and (max-width: 991px) {
    margin-bottom: 20px;
  }

  @media (min-width: 320px) and (max-width: 575px) {
    margin-bottom: 10px;
  }
`

export const ImageCropInputContainer = styled.div`
  & label {
    text-align: center;
  }

  & ${UploadFileInputContainer} {
    margin: 0 0 20px;
  }

  ${UploadFileContainer} {
    display: flex;
    justify-content: center;
  }
`

export const ImageCropImageContainer = styled.div`
  border-radius: 20px;
  box-shadow: 0 10px 100px 0 rgba(61,50,112,0.13);
  display: flex;
  justify-content: center;
  max-width: 800px;

  @media (min-width: 1200px) {
    margin: 30px auto 0;
    padding: 40px;
  }

  @media (min-width: 768px) and (max-width: 1199px) {
    margin: 20px auto 0;
    padding: 30px;
  }

  @media (min-width: 576px) and (max-width: 767px) {
    margin: 15px auto 0;
    padding: 20px;
  }

  @media (min-width: 320px) and (max-width: 575px) {
    margin: 10px auto 0;
    padding: 15px;
  }
`

export const ImageCropPreviewContainer = styled.div`
  @media (min-width: 1200px) {
    margin: 60px 0 0;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    margin: 50px 0 0;
  }

  @media (min-width: 576px) and (max-width: 991px) {
    margin: 40px 0 0;
  }

  @media (min-width: 320px) and (max-width: 575px) {
    margin: 30px 0 0;
  }
`

export const ImageCropHeadingText = styled.div`
  color: ${PurpleColor};
  font-size: 18px;
  line-height: 24px;
  text-align: center;
`

export const ImageCropImage = styled.img``

export const ImageCropSendButtonContainer = styled.div`
  @media (min-width: 1200px) {
    margin: 60px 0 0;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    margin: 50px 0 0;
  }

  @media (min-width: 576px) and (max-width: 991px) {
    margin: 40px 0 0;
  }

  @media (min-width: 320px) and (max-width: 575px) {
    margin: 30px 0 0;
  }
`
