import styled from 'styled-components'

import { LinkComponentText } from '../Buttons/LinkComponent/components'

export const HeaderContainer = styled.div`
  align-items: center;
  display: flex;
  margin: 0 auto;
  max-width: 1920px;

  @media (min-width: 1600px) {
    padding: 0 80px;
  }

  @media (min-width: 1200px) and (max-width: 1599px) {
    padding: 0 40px;
  }

  @media (min-width: 1200px) {
    height: 110px;
  }

  @media (min-width: 768px) and (max-width: 1199px) {
    height: 80px;
    padding: 0 30px;
  }

  @media (min-width: 576px) and (max-width: 767px) {
    height: 70px;
    padding: 0 15px;
  }

  @media (min-width: 320px) and (max-width: 575px) {
    height: 60px;
    padding: 0 10px;
  }
`

export const HeaderLogoContainer = styled.div`
  align-items: center;
  display: flex;
  flex-shrink: 0;

  @media (min-width: 1600px) {
    width: 270px;
  }

  @media (min-width: 576px) and (max-width: 1599px) {
    width: 160px;
  }

  @media (min-width: 576px) {
    margin: 0 30px 0 0;
  }

  @media (min-width: 320px) and (max-width: 575px) {
    margin: 0 25px 0 0;
    width: 30px;
  }
`

export const HeaderLogoDesktopContainer = styled.div`
  @media (min-width: 576px) {
    display: block;
  }

  @media (min-width: 320px) and (max-width: 575px) {
    display: none;
  }
`

export const HeaderLogoMobileContainer = styled.div`
  @media (min-width: 576px) {
    display: none;
  }

  @media (min-width: 320px) and (max-width: 575px) {
    display: block;
  }
`

export const HeaderRightSideContainer = styled.div`
  align-items: center;
  display: flex;
  flex-grow: 1;
`

export const HeaderRightSideIsLoggedOnContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: end;
  width: 100%;
`

export const HeaderRightSideIsLoggedOffContainer = styled.div`
  align-items: center;
  display: flex;
  width: 100%;

  @media (min-width: 1200px) {
    justify-content: space-between;
  }

  @media (min-width: 320px) and (max-width: 1199px) {
    justify-content: end;
  }
`

export const HeaderRightSideNavigationContainer = styled.div``

export const HeaderRightSideNavigationContent = styled.div`
  @media (min-width: 576px) {
    margin: 0 30px 0 0;
  }

  @media (min-width: 320px) and (max-width: 575px) {
    margin: 0 25px 0 0;
  }
`

export const HeaderRightSideLinkAndButtonContainer = styled.div`
  align-items: center;
  display: flex;
  flex-shrink: 0;
`

export const HeaderRightSideLinkContainer = styled.div`
  @media (min-width: 320px) and (max-width: 767px) {
    ${LinkComponentText} {
      display: none;
    }
  }
`

export const HeaderRightSideButtonContainer = styled.div`
  @media (min-width: 576px) {
    margin: 0 0 0 30px;
  }
  
  @media (min-width: 320px) and (max-width: 575px) {
    margin: 0 0 0 25px;
  }
`
