import styled from 'styled-components'

import {
  PurpleColorM,
  PurpleColorXS,
  RedColor
} from '../../../assets/styles/colors'

export const ChooseImageContainer = styled.div``

export const ChooseImageImageContent = styled.div`
  border-radius: 20px;
  height: 160px;
  position: relative;
  width: 160px;
  
  ${props => {
    switch (props.hasError) {
      case true: return `border: 2px solid ${RedColor};`
      default: return `border: 2px solid ${PurpleColorXS};`
    }
  }}  

  &:hover {
    ${props => props.isEditable && `
      background: linear-gradient(180deg,rgba(0,0,0,0) 0%, rgba(0,0,0,0.6) 100%);

      & > span {
        display: block;
      }
    `}
  }
`

export const ChooseImageLoaderContainer = styled.div`
  & path {
    fill: #fbd0a6;
  }
`

export const ChooseImageImageContainer = styled.div`
  height: 100%;
  
  & > div {
    padding: 0;
  }
`

export const ChooseImageButtonsContainer = styled.div`
  padding: 8px 0 0;
  text-align: left;
`

export const ChooseImageButtonsContent = styled.div`
  display: inline-grid;
  grid-auto-flow: column;
  grid-column-gap: 12px;
`

export const ChooseImageInfo = styled.div`
  color: ${PurpleColorM};
  font-size: 12px;
  padding: 8px 0 0;
  text-align: left;
`
