import styled from 'styled-components'

import {
  PinkColor,
  PurpleColor,
  PurpleColorXS,
  WhiteColor
} from '../../../../../assets/styles/colors'

export const TrainingItemHeaderArrowContainer = styled.div`
  display: flex;
  align-items: center;
  align-self: center;
  padding: 5px;
  border-radius: 50%;

  ${props => {
    switch (props.isOpen) {
      case true: return `
        background-color: ${PurpleColorXS};
        transform: rotate(180deg);

        & path:first-child {
          fill: ${PurpleColor};
        }
      `
      default: return `
        background-color: ${PinkColor};

        & path:first-child {
          fill: ${WhiteColor};
        }
      `
    }
  }}

  @media (min-width: 992px) {
    width: 40px;
    height: 40px;
  }

  @media (min-width: 320px) and (max-width: 991px) {
    min-width: 32px;
    width: 32px;
    height: 32px;
  }
`
