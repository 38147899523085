import React from 'react'
import PropTypes from 'prop-types'

import Icon from '../../../Form/FormBasicElements/Icon'
import Image from '../../../Form/FormBasicElements/Image'

import {
  TopNavButtonContainer,
  TopNavButtonIconCollapsedContainer,
  TopNavButtonIconContainer,
  TopNavButtonImageContainer,
  TopNavButtonName,
  TopNavButtonNameAndSurnameContainer,
  TopNavButtonPersonalDataContainer,
  TopNavButtonPosition,
  TopNavButtonPositionContainer
} from './Atoms'

import iconPath from '../../../../assets/icons/header_dropdown_arrow_down.svg'

const TopNavButton = ({
  alt,
  image,
  isMenuOpen,
  name,
  position,
  surname
}) => (
  <TopNavButtonContainer data-test='TopNavButtonContainer'>
    {
      image && (
        <TopNavButtonImageContainer data-test='TopNavButtonImageContainer'>
          <Image
            alt={alt}
            data-test='TopNavButtonImage'
            scaleMe={false}
            source={image}
          />
        </TopNavButtonImageContainer>
      )
    }

    <TopNavButtonPersonalDataContainer data-test='TopNavButtonPersonalDataContainer'>
      <TopNavButtonNameAndSurnameContainer data-test='TopNavButtonNameAndSurnameContainer'>
        <TopNavButtonName data-test='TopNavButtonName'>
          {name}
        </TopNavButtonName>
      </TopNavButtonNameAndSurnameContainer>

      <TopNavButtonPositionContainer data-test='TopNavButtonPositionContainer'>
        <TopNavButtonPosition data-test='TopNavButtonPosition'>
          {position}
        </TopNavButtonPosition>
      </TopNavButtonPositionContainer>
    </TopNavButtonPersonalDataContainer>

    <TopNavButtonIconContainer data-test='TopNavButtonIconContainer'>
      {
        isMenuOpen
          ? (
            <Icon
              data-test='TopNavButtonIconExpand'
              icon={iconPath}
            />
          )
          : (
            <TopNavButtonIconCollapsedContainer data-test='TopNavButtonIconCollapsedContainer'>
              <Icon
                data-test='TopNavButtonIconCollapsed'
                icon={iconPath}
              />
            </TopNavButtonIconCollapsedContainer>
          )
      }
    </TopNavButtonIconContainer>
  </TopNavButtonContainer>
)

export default TopNavButton

TopNavButton.propTypes = {
  alt: PropTypes.string,
  image: PropTypes.string,
  isMenuOpen: PropTypes.bool,
  name: PropTypes.string,
  position: PropTypes.string,
  surname: PropTypes.string
}

TopNavButton.defaultProps = {
  image: undefined
}
