import React from 'react'
import PropTypes from 'prop-types'

import {
  JobHeaderContainer,
  JobHeaderLeftContainer,
  JobHeaderRightContainer,
  JobHeaderTitle
} from './components'

import ButtonComponent from '../../../Buttons/ButtonComponent'
import JobDate from '../JobDate'

import translationsList from '../../../../stabs/translationsList'

const JobHeader = ({
  buttonType,
  date,
  link,
  onClick,
  title
}) => (
  <JobHeaderContainer data-test='JobHeaderContainer'>
    <JobHeaderLeftContainer data-test='JobHeaderLeftContainer'>
      <JobHeaderTitle data-test='JobHeaderTitle'>
        {title}
      </JobHeaderTitle>

      <JobDate
        data-test='JobDate'
        date={date}
      />
    </JobHeaderLeftContainer>

    <JobHeaderRightContainer data-test='JobHeaderRightContainer'>
      <ButtonComponent
        bgColor='pinkColor'
        color='whiteColor'
        data-test='JobHeaderButton'
        fontBold
        label={translationsList.jobOffers.apply}
        link={link}
        onClick={onClick}
        size='sizeM'
        type={buttonType}
      />
    </JobHeaderRightContainer>
  </JobHeaderContainer>
)

export default JobHeader

JobHeader.propTypes = {
  buttonType: PropTypes.oneOf([
    'external', 'internal', 'onClick'
  ]),
  date: PropTypes.string,
  link: PropTypes.string,
  onClick: PropTypes.func,
  title: PropTypes.string
}

JobHeader.defaultProps = {
  buttonType: 'external',
  onClick: () => null
}
