import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'

import CompanyProfileContainer from '../../../containers/PageContainers/CompanyProfileContainer'
import CompanySliderContainer from '../../../containers/PageContainers/CompanyProfileContainer/CompanySliderContainer'
import SimiliarCompaniesContainer from '../../../containers/PageContainers/CompanyProfileContainer/SimiliarCompaniesContainer'

import FormPanel from '../../Form/FormWrappers/FormPanel'
import InnerWrapper from '../InnerWrapper'
import SliderArrowsWrapper from '../../Sliders/SliderArrowsWrapper'
import Subtitle from '../../Subtitle'
import TrainingItem from '../../Trainings/TrainingItem'

import translationsList from '../../../stabs/translationsList'
import { useSelector } from 'react-redux'
import { companiesSelectors } from '../../../store/ducks/companies'
import { trainingsSelectors } from '../../../store/ducks/trainings'

const dates = [
  { date: '01.04.2020', start: '14:00', end: '20:00' },
  { date: '02.04.2020', start: '14:00', end: '20:00' }
]

const getDuration = (expoDay, start, end) => {
  const { date } = dates[expoDay === 'day-1' ? 0 : 1]

  const diff = DateTime.fromFormat(`${date} ${start}`, 'dd.MM.yy HH:mm').diffNow('seconds')
  const diffEnd = DateTime.fromFormat(`${date} ${end}`, 'dd.MM.yy HH:mm').diffNow('seconds')

  return diff > 0 ? diff.toFormat("d 'dni' h 'godzin' m 'minut'") : (diff <= 0 && diffEnd > 0 ? 'Trwa!' : null)
}

const PlatformCompanyWrapper = ({
  company,
  isPreview,
  slug
}) => {
  const companies = useSelector(companiesSelectors.getCompaniesContent)
  const trainings = useSelector(trainingsSelectors.getTrainingsAll)
  const [open, onOpen] = useState(false)

  const selectedCompany = companies.filter((company) => company.slug === slug)[0] || { training: null }
  const dedicatedTraining = trainings.filter((training) => training._id === selectedCompany.training)[0]

  return (
    <InnerWrapper inModal>
      {
        (dedicatedTraining && getDuration(dedicatedTraining.day, dedicatedTraining.timeStart, dedicatedTraining.timeEnd) !== null) && (
          <FormPanel
            boxShadow='noShadow'
            noMargin
            paddingSize='sizeS'
          >
            <TrainingItem
              dedicatedTraining={dedicatedTraining}
              dedicatedTrainingText={getDuration(dedicatedTraining.day, dedicatedTraining.timeStart, dedicatedTraining.timeEnd)}
              isEditable={false}
              isOpen={open}
              onClick={() => onOpen(!open)}
              trainingItemElement={dedicatedTraining}
            />
          </FormPanel>
        )
      }

      <FormPanel
        boxShadow='noShadow'
        noMargin={!dedicatedTraining}
        paddingSize='sizeS'
      >
        <CompanySliderContainer
          slug={slug}
          renderSlider={({ action, showLeft, showRight }) => (
            <SliderArrowsWrapper
              actionInsteadScroll={action}
              disableButtons={isPreview}
              disableScroll
              showLeft={showLeft}
              showRight={showRight}
              type='fixed'
            >
              <CompanyProfileContainer
                company={company}
                isPreview={isPreview}
                slug={slug}
              />
            </SliderArrowsWrapper>
          )}
        />
      </FormPanel>

      {
        !isPreview && (
          <FormPanel
            boxShadow='noShadow'
            paddingSize='sizeS'
            withFader
          >
            <Subtitle
              icon='star'
              label={translationsList.employerProfiles.labels.subtitleSimilarEmployersLabel}
              type='noTopMargin'
            />

            <SliderArrowsWrapper>
              <SimiliarCompaniesContainer slug={slug} />
            </SliderArrowsWrapper>
          </FormPanel>
        )
      }
    </InnerWrapper>
  )
}

export default PlatformCompanyWrapper

PlatformCompanyWrapper.propTypes = {
  dedicatedTraining: PropTypes.bool,
  dedicatedTrainingText: PropTypes.string,
  // company: PropTypes. // TODO
  isPreview: PropTypes.bool,
  slug: PropTypes.string,
  trainingItemElement: PropTypes.shape({
    _id: PropTypes.string,
    description: PropTypes.string,
    place: PropTypes.string,
    price: PropTypes.string,
    speakersList: PropTypes.arrayOf(
      PropTypes.shape({
        avatar: PropTypes.string,
        company: PropTypes.string,
        name: PropTypes.string,
        position: PropTypes.string
      })
    ),
    tags: PropTypes.arrayOf(
      PropTypes.shape({
        isSelected: PropTypes.bool,
        label: PropTypes.string
      })
    ),
    timeEnd: PropTypes.string,
    timeStart: PropTypes.string,
    title: PropTypes.string
  })
}
