import React from 'react'

import LoginModal from '../Modals/LoginModal'
import PlatformCompanyWrapperModal from '../Modals/PlatformCompanyWrapperModal'
import PlayModal from '../Modals/PlayModal'
import RegisterModal from '../Modals/RegisterModal'

const ModalController = ({ type, companyModalSlug }) => {
  switch (type) {
    case 'COMPANY': return <PlatformCompanyWrapperModal slug={companyModalSlug} />
    case 'LOGIN': return <LoginModal />
    case 'PLAY': return <PlayModal />
    case 'REGISTER': return <RegisterModal isUser />
    case 'REGISTER_COMPANY': return <RegisterModal isUser={false} />

    default: return <></>
  }
}

export default ModalController
