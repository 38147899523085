import React from 'react'

import AddedItem from '../../../List/AddedItem'
import FormPanel from '../../FormWrappers/FormPanel'
import FormTwoColumns from '../../FormWrappers/FormTwoColumns'
import InputField from '../../FormFields/InputField'
import RequiredFieldsText from '../../FormBasicElements/RequiredFieldsText'

const AddMovieForm = ({
  apiError,
  errors,
  handleBlur,
  handleChange,
  handleDelete,
  handleEdit,
  isLoading,
  items,
  touched,
  values
}) => (
  <FormPanel
    errors={apiError}
    noMargin
  >
    {items.map((item, index) => (
      <AddedItem
        isInsideForm
        key={index}
        title={`${item.isEditing ? '(W trakcie edycji) ' : ''}${item.title}`}
        description={item.description}
        onClickDelete={() => handleDelete(item._id)}
        onClickEdit={() => handleEdit(item._id)}
        isLoading={isLoading}
      />
    ))}
    <FormTwoColumns>
      <InputField
        data-test='CompanyMovieTitle'
        errorMessage={errors.title}
        hasError={errors.title && touched.title}
        id='CompanyMovieTitle'
        label='Tytuł filmu'
        name='title'
        onBlur={handleBlur}
        onChange={handleChange}
        placeholder='Wpisz tytuł filmu...'
        required
        value={values.title}
      />

      <InputField
        data-test='CompanyMovieLink'
        errorMessage={errors.url}
        hasError={errors.url && touched.url}
        id='CompanyMovieLink'
        label='Link do filmu'
        name='url'
        onBlur={handleBlur}
        onChange={handleChange}
        placeholder='Wpisz link do filmu (tylko z YouTube)...'
        required
        value={values.url}
      />
    </FormTwoColumns>

    <RequiredFieldsText />
  </FormPanel>
)

export default AddMovieForm

// TODO Fix translations
