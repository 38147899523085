import styled from 'styled-components'

import {
  PurpleColor,
  PurpleColorM,
  PurpleColorS,
  PurpleColorXS,
  RedColor,
  WhiteColor
} from '../../../../assets/styles/colors'

export const UploadFileContainer = styled.div`
  @media (min-width: 576px) {
    display: flex;
    align-items: center;
  }

  @media (min-width: 320px) and (max-width: 575px) {
    margin: 0 0 10px;
    text-align: left;
  }
`

export const UploadFileInputContainer = styled.div`
  position: relative;
  background-color: ${PurpleColorS};
  line-height: 21px;
  font-size: 14px;
  font-weight: bold;
  color: ${WhiteColor};
  border-radius: 10px;

  ${props => {
    switch (props.hasError) {
      case true: return `border: 2px solid ${RedColor};`
      default: return `border: 2px solid ${PurpleColorXS};`
    }
  }}

  @media (min-width: 1200px) {
    padding: 18px 25px;
  }

  @media (min-width: 576px) {
    display: flex;
    flex-shrink: 0;
    margin: 0 20px 0 0;
  }
  
  @media (min-width: 576px) and (max-width: 1199px) {
    padding: 15px 20px;
  }

  @media (min-width: 320px) and (max-width: 575px) {
    display: inline-flex;
    margin: 0 0 10px 0;
    padding: 10px 15px;
  }
`

export const UploadFileInputContent = styled.div`
  display: flex;
`

export const UploadFileLoader = styled.div`
  position: absolute;
  top: 0;
  left: 5px;
  bottom: 0;

  & path {
    fill: #fbd0a6;
  }
  
  @media (min-width: 1200px) {
    width: 42px;
  }
  
  @media (min-width: 576px) and (max-width: 1199px) {
    width: 36px;
  }
  
  @media (min-width: 320px) and (max-width: 575px) {
    width: 32px;
  }
`

export const UploadFileInputText = styled.div`
  ${props => props.isLoading && 'padding: 0 0 0 25px;'}
`

export const UploadFileTextContainer = styled.div`
  line-height: 21px;
  font-size: 16px;
  color: ${PurpleColorM};
  text-align: left;

  @media (min-width: 320px) and (max-width: 575px) {
    line-height: 18px;
    font-size: 14px;
  }
`
