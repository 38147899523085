import React, { Component } from 'react'

import ErrorPage from '../../components/ErrorPage'
import InnerWrapper from '../../components/Wrappers/InnerWrapper'
import Site from '../../components/Wrappers/Site'

import AuthService from '../../store/services/AuthService'
import translationsList from '../../stabs/translationsList'
import isExpoDay from '../../utils/isExpoDay'

class ErrorPageScreen extends Component {
  render () {
    return (
      <Site
        isLoggedOn={AuthService.loggedIn()}
        menuItemsList={[]}
        withNav
        eventPage
      >
        <InnerWrapper>
          <ErrorPage title={this.props.error.title} text={this.props.error.text} number={this.props.error.status} />
        </InnerWrapper>
      </Site>
    )
  }
}

export default ErrorPageScreen

ErrorPageScreen.defaultProps = {
  isLoggedOn: false,
  error: {
    status: '404',
    title: 'Nie znaleziono tej strony'
  }
}
