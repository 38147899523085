import React from 'react'
import PropTypes from 'prop-types'

import ChooseIcon from '../../../ChooseIcons/ChooseIcon'
import ChooseIconsWrapper from '../../../ChooseIcons/ChooseIconsWrapper'
import FormPanel from '../../FormWrappers/FormPanel'

const ChooseIconsForm = ({
  itemsList,
  onClick,
  toggleShow
}) => (
  <FormPanel noMargin>
    <ChooseIconsWrapper>
      {itemsList.map((item, id) =>
        <ChooseIcon
          icon={item.icon}
          key={id}
          label={item.label}
          onClick={() => { onClick(item.label); toggleShow() }}
        />
      )}
    </ChooseIconsWrapper>
  </FormPanel>
)

export default ChooseIconsForm

ChooseIconsForm.propTypes = {
  itemsList: PropTypes.arrayOf(PropTypes.shape(
    {
      icon: PropTypes.string,
      label: PropTypes.string
    }
  )),
  onClick: PropTypes.func
}
