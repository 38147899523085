import React from 'react'
import PropTypes from 'prop-types'

import Image from '../../../Form/FormBasicElements/Image'

import {
  TrainingItemSpeakerAbout,
  TrainingItemSpeakerAboutCompany,
  TrainingItemSpeakerAboutPosition,
  TrainingItemSpeakerAvatarContainer,
  TrainingItemSpeakerContainer,
  TrainingItemSpeakerName
} from './components'

const TrainingItemSpeaker = ({
  avatar,
  company,
  name,
  position
}) => (
  <TrainingItemSpeakerContainer>
    <TrainingItemSpeakerAvatarContainer>
      <Image
        alt={name}
        scaleMe={false}
        source={avatar}
      />
    </TrainingItemSpeakerAvatarContainer>

    <TrainingItemSpeakerName>{name}</TrainingItemSpeakerName>

    <TrainingItemSpeakerAbout>
      <TrainingItemSpeakerAboutPosition>{position}</TrainingItemSpeakerAboutPosition>

      <TrainingItemSpeakerAboutCompany>{company}</TrainingItemSpeakerAboutCompany>
    </TrainingItemSpeakerAbout>
  </TrainingItemSpeakerContainer>
)

export default TrainingItemSpeaker

TrainingItemSpeaker.propTypes = {
  avatar: PropTypes.string,
  company: PropTypes.string,
  name: PropTypes.string,
  position: PropTypes.string
}

TrainingItemSpeaker.defaultProps = {
  company: '',
  name: '',
  position: ''
}
