import React, { useState } from 'react'
import FormPanel from '../../components/Form/FormWrappers/FormPanel'
import MultiSelectDropDownField from '../../components/Form/FormFields/MultiSelectDropDownField'
import FormTwoColumns from '../../components/Form/FormWrappers/FormTwoColumns'
import ButtonComponent from '../../components/Buttons/ButtonComponent'
import ApiService from '../../store/services/ApiService'
import * as companiesAPI from '../../store/api/company'
import { toast } from 'react-toastify'
import { levelOfHighlighting } from '../../stabs/filterByLevelOfHighlighting'

const AdminHighlightCompany = ({ companies }) => {
  const [state, setState] = useState({
    selectedCompany: null,
    selectedCompanyLabel: null,
    selectedHighlight: null,
    selectedHighlightLabel: null,
    error: null,
    isLoading: false
  })

  return (
    <FormPanel
      errors={null}
      noMargin
    >
      <FormTwoColumns data-test='FormTwoColumnsOne'>
        <MultiSelectDropDownField
          label='Firma'
          errorMessage=''
          hasError={false}
          name='training'
          itemsList={companies.map((company) => ({
            label: `${company.name || company.primaryEmail || 'Bez nazwy'}`,
            value: company._id
          }))}
          onClick={(fieldName, item) => setState({
            ...state,
            selectedCompany: item.value,
            selectedCompanyLabel: item.label
          })}
          placeholder={state.selectedCompany === null ? 'Wybierz' : state.selectedCompanyLabel}
          value={state.selectedCompany}
          closeOnClick
        />

        <MultiSelectDropDownField
          label='Poziom'
          errorMessage=''
          hasError={false}
          name='level'
          itemsList={[
            { label: 'Poznaj także', value: 'partner' },
            { label: 'Pozostali', value: '' },
            { label: 'Polecani pracodawcy', value: 'premium' },
            { label: 'Kluczowi pracodawcy', value: 'prestige' },
            // ...levelOfHighlighting.map(level => ({ label: level, value: level })),
          ]}
          onClick={(fieldName, item) => setState({
            ...state,
            selectedHighlight: item.value,
            selectedHighlightLabel: item.label
          })}
          placeholder={state.selectedHighlight === null ? 'Wybierz' : state.selectedHighlightLabel}
          value={state.selectedHighlight}
          closeOnClick
        />
      </FormTwoColumns>

      <ButtonComponent
        bgColor='purpleColor'
        color='whiteColor'
        data-test='save'
        fontBold
        fullWidth
        isLoading={state.isLoading}
        isLoadingColor='purpleColorS'
        label='Zapisz'
        onClick={() => {
          setState({ ...state, isLoading: true })

          ApiService.fetch(companiesAPI.updateCompany(state.selectedCompany, { levelOfHighlighting: state.selectedHighlight }))
            .then(() => {
              toast.success('Zapisano!', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
              })

              setState({
                selectedCompany: null,
                selectedCompanyLabel: null,
                selectedHighlight: null,
                selectedHighlightLabel: null,
                error: null,
                isLoading: false
              })
            }).catch(() => {
              toast.error('Bład!', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
              })
            })
        }}
        size='sizeM'
      />
    </FormPanel>
  )
}

export default AdminHighlightCompany
