import types from './types'

const fetchIndustriesError = (error) => ({
  type: types.FETCH_ERROR,
  payload: error
})

const fetchIndustriesRequested = () => ({
  type: types.FETCH_REQUESTED
})

const fetchIndustriesSuccess = (data) => ({
  type: types.FETCH_SUCCESS,
  payload: data
})

const toggleIndustry = (key) => ({
  type: types.TOGGLE,
  payload: { key }
})

export default {
  fetchIndustriesError,
  fetchIndustriesRequested,
  fetchIndustriesSuccess,
  toggleIndustry
}
