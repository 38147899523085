import React from 'react'
import PropTypes from 'prop-types'

import FormPanel from '../Form/FormWrappers/FormPanel'
import Icon from '../Form/FormBasicElements/Icon'
import InternalOrExternal from '../Buttons/InternalOrExternal'
import SliderArrowsWrapper from '../Sliders/SliderArrowsWrapper'

import {
  IndustriesDesktop,
  IndustriesItemIconContainer,
  IndustriesItemListElement,
  IndustriesItemText,
  IndustriesMobile
} from './components'

import mapIconNameToSvg from './mapIconToSvg'

const Industries = ({
  itemsList,
  onClick
}) => (
  <>
    {itemsList &&
      <>
        <IndustriesDesktop>
          <FormPanel
            noMargin
            paddingSize='sizeS'
            withFader
          >
            <SliderArrowsWrapper
              showLeft
              showRight
              type='outside'
            >
              {
                itemsList.map((item, id) => (
                  <InternalOrExternal
                    key={id}
                    onClick={() => onClick(item._id)}
                  >
                    <IndustriesItemListElement isActive={item.isActive}>
                      <IndustriesItemIconContainer>
                        <Icon
                          data-test='industryIcon'
                          icon={mapIconNameToSvg(item.icon)}
                        />
                      </IndustriesItemIconContainer>

                      <IndustriesItemText isActive={item.isActive}>
                        {item.label}
                      </IndustriesItemText>
                    </IndustriesItemListElement>
                  </InternalOrExternal>
                ))
              }
            </SliderArrowsWrapper>
          </FormPanel>
        </IndustriesDesktop>

        <IndustriesMobile>
          <FormPanel noMargin paddingSize='sizeS'>
            {
              itemsList.map((item, id) => (
                <InternalOrExternal
                  key={id}
                  onClick={() => onClick(item._id)}
                >
                  <IndustriesItemListElement isActive={item.isActive}>
                    <IndustriesItemIconContainer>
                      <Icon
                        data-test='industryIcon'
                        icon={mapIconNameToSvg(item.icon)}
                      />
                    </IndustriesItemIconContainer>

                    <IndustriesItemText isActive={item.isActive}>
                      {item.label}
                    </IndustriesItemText>
                  </IndustriesItemListElement>
                </InternalOrExternal>
              ))
            }
          </FormPanel>
        </IndustriesMobile>
      </>}
  </>
)

export default Industries

Industries.propTypes = {
  itemsList: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      isActive: PropTypes.bool,
      label: PropTypes.string
    })
  ),
  onClick: PropTypes.func
}
