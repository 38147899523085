import React from 'react'
import PropTypes from 'prop-types'

import StepItem from '../StepItem'

import {
  StepsContainer,
  StepsContent,
  StepsTitle
} from './components'

const Steps = ({
  itemsList,
  option,
  title,
  type,
  withLinks
}) => (
  <StepsContainer>
    {
      title && (
        <StepsTitle>{title}</StepsTitle>
      )
    }

    {
      itemsList && (
        <StepsContent>
          {
            itemsList.map((step, id) => (
              <StepItem
                description={step.description}
                key={id}
                link={step.link}
                number={step.number}
                title={step.title}
                type={step.type}
                withLinks={step.withLinks}
              />
            ))
          }
        </StepsContent>
      )
    }
  </StepsContainer>
)

export default Steps

Steps.propTypes = {
  itemsList: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      link: PropTypes.string,
      number: PropTypes.string,
      title: PropTypes.string,
      type: PropTypes.string
    })
  ),
  title: PropTypes.string,
  withLinks: PropTypes.bool
}
