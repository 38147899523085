import React from 'react'
import PropTypes from 'prop-types'

import Icon from '../Form/FormBasicElements/Icon'

import {
  SubtitleContainer,
  SubtitleIconContainer,
  SubtitleLabel,
  SubtitleLabelH1
} from './components'

import badgeIcon from '../../assets/icons/subtitle/subtitle_badge.svg'
import educationIcon from '../../assets/icons/subtitle/subtitle_education.svg'
import experienceIcon from '../../assets/icons/subtitle/subtitle_experience.svg'
import filterIcon from '../../assets/icons/subtitle/subtitle_filter.svg'
import languageIcon from '../../assets/icons/subtitle/subtitle_language.svg'
import pinIcon from '../../assets/icons/subtitle/subtitle_pin.svg'
import skillIcon from '../../assets/icons/subtitle/subtitle_skill.svg'
import starIcon from '../../assets/icons/subtitle/subtitle_star.svg'
import summaryIcon from '../../assets/icons/subtitle/subtitle_summary.svg'
import tagIcon from '../../assets/icons/subtitle/subtitle_tag.svg'
import ticketIcon from '../../assets/icons/subtitle/subtitle_ticket.svg'
import userIcon from '../../assets/icons/subtitle/subtitle_user.svg'

const chooseIcon = (
  icon
) => {
  switch (icon) {
    case 'badge': return badgeIcon
    case 'education': return educationIcon
    case 'experience': return experienceIcon
    case 'filter': return filterIcon
    case 'language': return languageIcon
    case 'pin': return pinIcon
    case 'skill': return skillIcon
    case 'star': return starIcon
    case 'summary': return summaryIcon
    case 'tag': return tagIcon
    case 'ticket': return ticketIcon
    case 'user': return userIcon
    default: return ''
  }
}

const Subtitle = ({
  asH1,
  icon,
  id,
  label,
  type
}) => (
  <SubtitleContainer
    id={id}
    type={type}
  >
    {icon &&
      <SubtitleIconContainer>
        <Icon icon={chooseIcon(icon)} />
      </SubtitleIconContainer>}

    {asH1
      ? <SubtitleLabelH1>{label}</SubtitleLabelH1>
      : <SubtitleLabel>{label}</SubtitleLabel>}
  </SubtitleContainer>
)

export default Subtitle

Subtitle.propTypes = {
  asH1: PropTypes.bool,
  icon: PropTypes.oneOf(
    ['badge', 'education', 'experience', 'filter', 'language', 'pin', 'skill', 'star', 'summary', 'tag', 'ticket', 'user']
  ),
  id: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.oneOf(['noTopMargin'])
}

Subtitle.defaultProps = {
  asH1: false
}
