import styled from 'styled-components'

import { RedColor } from '../../../../assets/styles/colors'

export const ErrorsContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 8px;
  align-items: flex-end;
  margin: 5px 0 0;
  text-align: left;
`

export const ErrorsIconContainer = styled.div`
  width: 16px;
  height: 16px;

  & use {
    fill: ${RedColor};
  }
`

export const ErrorsText = styled.div`
  line-height: 18px;
  font-size: 12px;
  color: ${RedColor};
`
