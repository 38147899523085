import styled from 'styled-components'

export const FooterContainer = styled.div`
  display: grid;
  grid-column-gap: 30px;
  max-width: 1175px;
  margin: 0 auto;

  @media (min-width: 992px) {
    grid-row-gap: 30px;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    justify-items: center;
  }

  @media (min-width: 576px) and (max-width: 991px) {
    grid-row-gap: 20px;
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 576px) {
    text-align: left;
  }

  @media (max-width: 575px) {
    grid-row-gap: 15px;
    text-align: center;
  }
`

export const FooterColumn = styled.div`
  @media (max-width: 991px) {
    order: ${props => props.order}
  }
`

export const FooterSocialMediaLogo = styled.div`
  max-width: 167px;

  @media (min-width: 576px) {
    margin: 0 0 32px;
  }

  @media (max-width: 575px) {
    margin: 0 auto 20px;
  }
`
