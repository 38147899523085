import React, { useState } from 'react'
import ApiService from '../../../../store/services/ApiService'
import * as knowledgeAPI from '../../../../store/api/knowledge'
import ChooseImageField from '../../../../components/Form/FormFields/ChooseImageField'

const CompanyArticleAuthorAvatarUploadContainer = ({ value, error, touched, handleField }) => {
  const [state, setState] = useState({
    isLoading: false,
    apiError: null
  })

  const handleChange = (file, onSuccess) => {
    const formData = new FormData()
    formData.append('file', file, file.name)

    ApiService.fetch(knowledgeAPI.uploadArticleAuthorAvatar(formData))
      .then((data) => {
        setState({ ...state, isLoading: false })
        handleField('authorAvatarUrl', data.url)
        onSuccess()
      })
      .catch(err => setState({ ...state, apiError: err }))
  }

  return (
    <ChooseImageField
      buttonSelectLabel='Wybierz zdjęcie (PNG/JPG)'
      chooseFileButtonDescription='Dodaj zdjęcie autora.'
      chooseFileButtonFieldlabel='Zdjęcie autora'
      croppedImageHeadingText='Zaznacz obszar zdjęcia autora, a następnie wyślij plik.'
      errorMessage={error || state.apiError}
      hasError={(error && touched) || state.apiError !== null}
      image={value}
      isLoading={state.isLoading}
      onChange={handleChange}
      required
      withHash={false}
    />
  )
}

export default CompanyArticleAuthorAvatarUploadContainer
