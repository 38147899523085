import React from 'react'
import { Route, useHistory } from 'react-router-dom'

import AuthService from '../../../store/services/AuthService'
import ErrorPageScreen from '../../../pages/ErrorPage'
import Loader from '../../../components/Loader'
import ApiService from '../../../store/services/ApiService'
import { toast } from 'react-toastify'
import useDidMount from '@rooks/use-did-mount'
import * as eventApi from '../../../store/api/event'

export default ({ component: Component, lock, minimalType, ...rest }) => {
  const history = useHistory()
  const [isLoading, setLoading] = React.useState(true)
  const [isAllowed, setAllowed] = React.useState(null)

  const checkUser = () => {
    setAllowed(false)

    if (!AuthService.loggedIn()) {
      setLoading(false)
      return;
    }

    ApiService.fetch(eventApi.checkUser())
    .then(({ isEventPayable, hasTicket }) => {
      if ((isEventPayable && hasTicket) || !isEventPayable) {
        setAllowed(true)
      }

      setLoading(false)
    })
    .catch(() => {
      toast.error('Wystąpił błąd!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      })
    })
  }

  useDidMount(() => {
    if (isAllowed === null && lock) {
      checkUser()
    }
  }, [])

  if (isLoading && lock) {
    return <Loader isLoading />
  }

  if (!AuthService.loggedIn()) {
    history.push('/logowanie')
  }

  if (!isAllowed && lock) {
    history.push('/kup-bilet')
  }

  return (
    (
      <Route
        {...rest}
        render={() => (
          AuthService.isUserAllowed(minimalType)
            ? (
              <Component />
            )
            : (
              <ErrorPageScreen />
            )
        )}
      />
    )
  )
}

