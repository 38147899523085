import React from 'react'
import PropTypes from 'prop-types'

import ButtonComponent from '../Buttons/ButtonComponent'

import {
  ControlsContainer
} from './components'

const Controls = ({
  hideLeft,
  hideRight,
  labelLeft,
  labelRight,
  onClickLeft,
  onClickRight,
  reverseColors,
  size
}) => (
  <ControlsContainer
    hideLeft={hideLeft}
    hideRight={hideRight}
  >
    {
      !hideLeft && (
        <ButtonComponent
          bgColor={!reverseColors ? 'pinkColor' : null}
          border={reverseColors ? 'pinkColorS' : null}
          boxShadow='pink'
          color={reverseColors ? 'pinkColorM' : 'whiteColor'}
          data-test='left'
          fontBold
          fullWidth
          label={labelLeft}
          onClick={onClickLeft}
          size={size}
        />
      )
    }

    {
      !hideRight && (
        <ButtonComponent
          bgColor={reverseColors ? 'pinkColor' : null}
          border={!reverseColors ? 'pinkColorS' : null}
          boxShadow='pink'
          color={reverseColors ? 'whiteColor' : 'pinkColorM'}
          data-test='right'
          fontBold
          fullWidth
          label={labelRight}
          onClick={onClickRight}
          size={size}
        />
      )
    }
  </ControlsContainer>
)

export default Controls

Controls.propTypes = {
  hideLeft: PropTypes.bool,
  hideRight: PropTypes.bool,
  labelLeft: PropTypes.string,
  labelRight: PropTypes.string,
  onClickLeft: PropTypes.func,
  onClickRight: PropTypes.func,
  reverseColors: PropTypes.bool,
  size: PropTypes.string
}

Controls.defaultProps = {
  hideLeft: false,
  hideRight: false,
  reverseColors: false,
  size: 'sizeL'
}
