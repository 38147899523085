import styled from 'styled-components'

export const SliderArrowsWrapperContainer = styled.div`
  max-width: 100%;
  position: relative;
  user-select: none;
`

export const SliderArrowsWrapperLeftArrowContainer = styled.div`
  top: calc(50% - (50px / 2));
  z-index: 100;

  ${props => {
    switch (props.type) {
      case 'fixed': return `
        position: fixed;

        @media (min-width: 1200px) {
          left: 25px;
        }

        @media (min-width: 768px) and (max-width: 1199px) {
          left: 5px;
        }

        @media (min-width: 320px) and (max-width: 767px) {
          display: none;
        }
      `
      case 'outside': return `
        left: calc(-50px / 2);
        position: absolute;
      `
      default: return `
        left: 0;
        position: absolute;
      `
    }
  }}
`

export const SliderArrowsWrapperContent = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: hidden;
  z-index: 90;
  
  & > div {
    @media (min-width: 992px) {
      margin: 0 30px 0 0;

      &:last-child {
        margin: 0;
      }
    }

    @media (min-width: 768px) and (max-width: 991px) {
      margin: 0 20px 0 0;

      &:last-child {
        margin: 0;
      }
    }

    @media (max-width: 767px) {
      margin: 0 15px 0 0;

      &:last-child {
        margin: 0;
      }
    }
  }
`

export const SliderArrowsWrapperRightArrowContainer = styled.div`
  top: calc(50% - (50px / 2));
  z-index: 100;

  ${props => {
    switch (props.type) {
      case 'fixed': return `
        position: fixed;

        @media (min-width: 1200px) {
          right: 25px;
        }

        @media (min-width: 768px) and (max-width: 1199px) {
          right: 5px;
        }

        @media (min-width: 320px) and (max-width: 767px) {
          display: none;
        }
      `
      case 'outside': return `
        position: absolute;
        right: calc(-50px / 2);
      `
      default: return `
        position: absolute;
        right: 0;
      `
    }
  }}
`
