const FETCH_ERROR = 'cvs/FETCH_ERROR'
const FETCH_REQUESTED = 'cvs/FETCH_REQUESTED'
const FETCH_SUCCESS = 'cvs/FETCH_SUCCESS'

const DELETE = 'cvs/DELETE'

export default {
  FETCH_ERROR,
  FETCH_REQUESTED,
  FETCH_SUCCESS,
  DELETE
}
