import types from './types'

const fetchCvsError = (error) => ({
  type: types.FETCH_ERROR,
  payload: error
})

const fetchCvsRequested = () => ({
  type: types.FETCH_REQUESTED
})

const fetchCvsSuccess = (data) => ({
  type: types.FETCH_SUCCESS,
  payload: data
})

const deleteCV = (cvId) => ({
  type: types.DELETE,
  payload: { cvId }
})

export default {
  fetchCvsError,
  fetchCvsRequested,
  fetchCvsSuccess,
  deleteCV
}
