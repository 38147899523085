import styled from 'styled-components'

import {
  PurpleBoxShadowColor,
  PurpleColor,
  PurpleColorL,
  PurpleColorM,
  PurpleColorXS,
  WhiteColor
} from '../../../assets/styles/colors'

export const RecruiterCardBoxContainer = styled.div`
  background-color: ${WhiteColor};
  border-radius: 20px;
  box-shadow: ${PurpleBoxShadowColor};
  display: flex;
  height: 100%;

  @media (max-width: 1199px) {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }

  @media (min-width: 768px) {
    padding: 30px;
  }

  @media (max-width: 767px) {
    padding: 20px;
  }
`

export const RecruiterCardBoxImageContainer = styled.div`
  display: flex;
  flex-shrink: 0;

  & div {
    padding: 0;
  }

  & img {
    border-radius: 20px;
  }

  @media (min-width: 1600px) {
    width: 150px;
  }

  @media (min-width: 1200px) and (max-width: 1599px) {
    width: 110px;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    flex: 1;
    width: 120px;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    flex: 1;
    width: 120px;
  }

  @media (min-width: 576px) and (max-width: 767px) {
    flex: 1;
    width: 100px;
  }

  @media (max-width: 575px) {
    width: 90px;
  }
`

export const RecruiterCardBoxContent = styled.div`
  ${({ avatarUrl }) => avatarUrl === ''
    ? 'width: 100%;'
    : `
      @media (min-width: 1600px) {
        width: calc(100% - 150px);
      }

      @media (min-width: 1200px) and (max-width: 1599px) {
        width: calc(100% - 110px);
      }

      @media (min-width: 1200px) {
        padding: 0 0 0 30px;
      }

      @media (min-width: 576px) and (max-width: 1199px) {
        margin: 30px 0 0;
      }

      @media (max-width: 575px) {
        margin: 20px 0 0;
      }
    `
  }

  @media (min-width: 1200px) {
    text-align: left;
  }

  @media (max-width: 1199px) {
    text-align: center;
    width: 100%;
  }
`

export const RecruiterCardBoxNameAndSurnameContainer = styled.div``

export const RecruiterCardBoxNameAndSurname = styled.h4`
  color: ${PurpleColor};
  font-weight: 600;

  @media (min-width: 1200px) {
    font-size: 18px;
    line-height: 27px;
  }

  @media (max-width: 1199px) {
    font-size: 16px;
    line-height: 21px;
  }
`

export const RecruiterCardBoxPositionContainer = styled.div`
  margin: 10px 0 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const RecruiterCardBoxPosition = styled.span`
  color: ${PurpleColorL};
  font-size: 12px;
`

export const RecruiterCardBoxEmailContainer = styled.div`
  margin: 6px 0 0;
`

export const RecruiterCardBoxEmailLink = styled.a`
  color: ${PurpleColorM};
  font-size: 12px;
  word-break: break-all;
`

export const RecruiterCardBoxSocialMediaContainer = styled.div`
  margin: 6px 0 0;

  @media (max-width: 1199px) {
    display: flex;
    justify-content: center;
  }
`

export const RecruiterCardBoxSocialLinkedinContainer = styled.div`
  background-color: ${PurpleColorXS};
  border-radius: 2px;
  height: 22px;
  width: 22px;
`

export const RecruiterCardBoxAvailabilityContainer = styled.div`
  margin: 20px 0 0;
`

export const RecruiterCardBoxAvailabilityHeader = styled.label`
  color: ${PurpleColorL};
  font-size: 16px;
`

export const RecruiterCardBoxAvailabilityDay = styled.div`
  margin: 6px 0 0;
`

export const RecruiterCardBoxAvailability = styled.span`
  color: ${PurpleColorL};
  font-size: 16px;
`
