import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Industries from '../../../../components/Industries'
import { industriesActions, industriesSelectors } from '../../../../store/ducks/industries'
import { myCompanyActions, myCompanySelectors } from '../../../../store/ducks/my-company'
import ApiService from '../../../../store/services/ApiService'
import * as companyAPI from '../../../../store/api/company'
import { toast } from 'react-toastify'

const StepOneIndustriesContainer = () => {
  const industriesState = useSelector(industriesSelectors.getIndustriesState)
  const myCompany = useSelector(myCompanySelectors.getMyCompany)

  const dispatch = useDispatch()
  useEffect(() => {
    if (industriesState.isLoading === false) {
      if (industriesState.data === null || industriesState.data.length === 0) {
        dispatch(industriesActions.fetchIndustriesRequested())
      }
    }
  }, [dispatch, industriesState])

  const industries = industriesState.data.map((industry) => ({
    ...industry,
    isActive: myCompany.industries.includes(industry._id)
  }))

  const handleOnClick = (id) => {
    const industry = industries.filter((el) => el._id === id)[0]

    if (industry.isActive) {
      dispatch(myCompanyActions.fetchMyCompanySuccess({
        ...myCompany,
        industries: myCompany.industries.filter((industry) => industry !== id)
      }))

      ApiService.fetch(companyAPI.removeIndustry(id))
        .catch(() => {
          toast.error('Nastąpił błąd podczas wybierania branży!', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          })

          dispatch(myCompanyActions.fetchMyCompanySuccess({
            ...myCompany,
            industries: [...myCompany.industries, id]
          }))
        })
    } else {
      dispatch(myCompanyActions.fetchMyCompanySuccess({
        ...myCompany,
        industries: [...myCompany.industries, id]
      }))

      ApiService.fetch(companyAPI.addIndustry(id))
        .catch(() => {
          toast.error('Nastąpił błąd podczas wybierania branży!', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          })

          dispatch(myCompanyActions.fetchMyCompanySuccess({
            ...myCompany,
            industries: myCompany.industries.filter((industry) => industry !== id)
          }))
        })
    }
  }

  return (
    <Industries
      itemsList={industries}
      onClick={handleOnClick}
    />
  )
}

export default StepOneIndustriesContainer
