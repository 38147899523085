import React from 'react'
import PropTypes from 'prop-types'

import {
  InputContainer
} from './components'

const Input = (
  props
//   {
//   disabled,
//   hasError,
//   id,
//   name,
//   onBlur,
//   onChange,
//   placeholder,
//   required,
//   type,
//   value
// }
) => (
  <InputContainer
    {...props}
    // disabled={disabled}
    // hasError={hasError}
    // id={id}
    // name={name}
    // onBlur={onBlur}
    // onChange={onChange}
    // placeholder={placeholder}
    // required={required}
    // type={type}
    // value={value}
  />
)

export default Input

Input.propTypes = {
  disabled: PropTypes.bool,
  hasError: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.string
}

Input.defaultProps = {
  disabled: false,
  hasError: false,
  id: '',
  name: '',
  placeholder: '',
  required: false,
  type: 'text'
}
