import styled from 'styled-components'

export const VideosWrapperContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media (min-width: 768px) {
    & > div {
      margin: 0 30px 30px 0;
      width: 290px;
    }
  }

  @media (min-width: 320px) and (max-width: 767px) {
    flex-direction: column;
    justify-content: center;

    & > div {
      margin: 0 auto 30px;
      width: 290px;
    }
  }
`
