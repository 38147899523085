import React from 'react'

import AddedItem from '../../../List/AddedItem'
import ChooseFileField from '../../FormFields/ChooseFileField'
import FormPanel from '../../FormWrappers/FormPanel'
import InputField from '../../FormFields/InputField'
import RequiredFieldsText from '../../FormBasicElements/RequiredFieldsText'
import TextAreaField from '../../FormFields/TextAreaField'

import {
  AddBenefitFormImageInput
} from './components'

import iconGifts from '../../../../assets/icons/benefits/benefits_gifts.svg'
import iconHealthCare from '../../../../assets/icons/benefits/benefits_health_care.svg'
import iconInsurance from '../../../../assets/icons/benefits/benefits_insurance.svg'
import iconLayette from '../../../../assets/icons/benefits/benefits_layette.svg'
import iconMentor from '../../../../assets/icons/benefits/benefits_mentor.svg'
import iconPreparing from '../../../../assets/icons/benefits/benefits_preparing.svg'
import iconSafety from '../../../../assets/icons/benefits/benefits_safety.svg'
import benefit2 from '../../../../assets/icons/benefits/2.svg'
import benefit3 from '../../../../assets/icons/benefits/3.svg'
import benefit4 from '../../../../assets/icons/benefits/4.svg'
import benefit5 from '../../../../assets/icons/benefits/5.svg'
import benefit6 from '../../../../assets/icons/benefits/6.svg'
import benefit7 from '../../../../assets/icons/benefits/7.svg'

const testItemsList = [
  { icon: iconGifts, label: 'iconGifts' },
  { icon: iconHealthCare, label: 'iconHealthCare' },
  { icon: iconInsurance, label: 'iconInsurance' },
  { icon: iconLayette, label: 'iconLayette' },
  { icon: iconMentor, label: 'iconMentor' },
  { icon: iconPreparing, label: 'iconPreparing' },
  { icon: iconSafety, label: 'iconSafety' },
  { icon: benefit2, label: 'benefit2' },
  { icon: benefit3, label: 'benefit3' },
  { icon: benefit4, label: 'benefit4' },
  { icon: benefit5, label: 'benefit5' },
  { icon: benefit6, label: 'benefit6' },
  { icon: benefit7, label: 'benefit7' }
]

const AddBenefitForm = ({
  apiError,
  errors,
  handleBlur,
  handleChange,
  handleDelete,
  handleEdit,
  handleIcon,
  isLoading,
  items,
  touched,
  values
}) => (
  <FormPanel
    errors={apiError}
  >
    {items.map((item, index) => (
      <AddedItem
        isInsideForm
        key={index}
        title={`${item.isEditing ? '(W trakcie edycji) ' : ''}${item.title}`}
        description={item.description}
        onClickDelete={() => handleDelete(item._id)}
        onClickEdit={() => handleEdit(item._id)}
        isLoading={isLoading}
      />
    ))}
    <AddBenefitFormImageInput>
      <ChooseFileField
        buttonLabel={values.icon.trim() === '' ? 'Wybierz ikonę' : 'Ikona wybrana!'}
        errorMessage={errors.icon}
        hasError={errors.icon && touched.icon}
        itemsList={testItemsList}
        label='Ikona'
        onClick={handleIcon}
        name='icon'
        type='onClick'
      />

      <InputField
        id='name'
        label='Nazwa'
        name='title'
        placeholder='Wpisz nazwę'
        errorMessage={errors.title}
        hasError={errors.title && touched.title}
        onBlur={handleBlur}
        onChange={handleChange}
        required
        value={values.title}
      />
    </AddBenefitFormImageInput>

    <TextAreaField
      id='description'
      label='Opis'
      maxLength={500}
      name='description'
      errorMessage={errors.description}
      hasError={errors.description && touched.description}
      onBlur={handleBlur}
      onChange={handleChange}
      value={values.description}
    />

    <RequiredFieldsText />
  </FormPanel>
)

export default AddBenefitForm

// TODO Fix Translations
