import React from 'react'
import PropTypes from 'prop-types'

import {
  ChatContainer,
  ChatIframe
} from './Atoms'

const Chat = ({
  chatUrl
}) => (
  <ChatContainer data-test='ChatContainer'>
    <ChatIframe
      data-test='ChatIframe'
      src={chatUrl}
      title='Chat'
    />
  </ChatContainer>
)

export default Chat

Chat.propTypes = {
  chatUrl: PropTypes.string
}
