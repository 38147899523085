import styled from 'styled-components'

import {
  InsetPurpleBoxShadowColor,
  PurpleColorM,
  WhiteColor
} from '../../../../assets/styles/colors'

export const IconAndTextElementsContainer = styled.div`
  background-color: ${WhiteColor};
  border-radius: 0 0 15px 15px;
  box-shadow: ${InsetPurpleBoxShadowColor};
  min-width: 200px;
  
  @media (min-width: 1200px) {
    margin: 20px 0 0;
    padding: 10px 20px;
  }

  @media (min-width: 768px) and (max-width: 1199px) {
    margin: 18px 0 0;
    padding: 10px 15px;
  }

  @media (min-width: 320px) and (max-width: 767px) {
    padding: 10px;
  }

  @media (min-width: 576px) and (max-width: 767px) {
    margin: 17px 0 0;
  }

  @media (min-width: 320px) and (max-width: 575px) {
    margin: 12px 0 0;
  }
`

export const IconAndTextElementsContent = styled.div`
  align-items: center;
  display: flex;
  text-align: left;

  + div {
    border-top: 1px solid ${PurpleColorM};
  }

  @media (min-width: 768px) {
    padding: 8px 15px;
  }

  @media (min-width: 320px) and (max-width: 767px) {
    padding: 8px 10px;
  }
`
export const IconAndTextElementsIconContainer = styled.div`
  margin: 0 10px 0 0;
  width: 22px;
`

export const IconAndTextElementsLabel = styled.a`
  color: ${PurpleColorM};
  cursor: pointer;
  text-decoration: none;

  @media (min-width: 1200px) {
    font-size: 18px;
    line-height: 27px;
  }

  @media (min-width: 320px) and (max-width: 1199px) {
    font-size: 16px;
    line-height: 24px;
  }
`
