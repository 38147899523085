import _ from 'lodash'
import types from './types'
import { createReducer } from '../../utils'
import slugify from 'slugify'

const initialState = {
  data: {
    _id: '',
    aboutUs: '',
    articles: [],
    awards: [],
    bannerUrl: '',
    benefits: [],
    hasQuickRecruitment: false,
    industries: [],
    jobOffers: [],
    invoiceData: {},
    isPublished: false,
    logoUrl: '',
    mission: '',
    name: '',
    offices: [],
    recommendations: false,
    recruiters: [],
    recruitment: {
      additionalInfo: '',
      assessmentCenter: '',
      cities: '',
      departments: '',
      employmentTypes: [],
      foreignLanguages: '',
      jobInterview: '',
      needsCv: '',
      needsMotivationalList: '',
      preferredCandidates: [],
      preferredDegreeCourses: ''
    },
    socials: {
      facebook: '',
      instagram: '',
      youtube: '',
      linkedin: '',
      goldenline: ''
    },
    step: '',
    values: [],
    vectorLogoUrl: '',
    videos: [],
    website: ''
  },
  error: null,
  isLoading: false
}

const myCompanyReducer = createReducer(initialState)({
  [types.FETCH_ERROR]: (state, action) => ({ data: initialState.data, isLoading: false, error: action.payload }),
  [types.FETCH_REQUESTED]: () => ({ data: null, isLoading: true, error: null }),
  [types.FETCH_SUCCESS]: (state, action) => {
    const data = _.pick(action.payload, [
      '_id',
      'aboutUs',
      'articles',
      'awards',
      'bannerUrl',
      'benefits',
      'hasQuickRecruitment',
      'industries',
      'jobOffers',
      'invoiceData',
      'isPublished',
      'logoUrl',
      'mission',
      'name',
      'offices',
      'recommendations',
      'recruiters',
      'recruitment',
      'socials',
      'step',
      'values',
      'vectorLogoUrl',
      'videos',
      'website'
    ])

    data.slug = slugify(data.name || Math.random().toString(36).substring(7), { lower: true })

    if (data.recruitment === undefined) {
      data.recruitment = initialState.data.recruitment
    }

    if (data.socials === undefined) {
      data.socials = initialState.data.socials
    }

    if (data.jobOffers === undefined) {
      data.jobOffers = []
    }

    return { data, isLoading: false, error: null }
  }
})

export default myCompanyReducer
