import React from 'react'
import PropTypes from 'prop-types'

import Description from './Description'
import Title from './Title'

const Section = ({
  alignCenter,
  description,
  id,
  title,
  level,
  html
}) => (
  <>
    {title &&
      <Title
        alignCenter={alignCenter}
        id={id}
        level={level}
        label={title}
      />}

    {description &&
      <Description
        alignCenter={alignCenter}
        description={description}
        html={html}
      />}
  </>
)

export default Section

Section.propTypes = {
  alignCenter: PropTypes.bool,
  html: PropTypes.bool,
  description: PropTypes.string,
  id: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  title: PropTypes.string,
  level: PropTypes.oneOf(['default', 'h1', 'h2'])
}

Section.defaultProps = {
  alignCenter: false,
  level: 'default'
}
