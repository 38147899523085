import React from 'react'
import PropTypes from 'prop-types'

import {
  SquarePanelContainer,
  SquarePanelDescription,
  SquarePanelIconContainer,
  SquarePanelTitle
} from './components'

import Icon from '../Form/FormBasicElements/Icon'

const SquarePanel = ({
  description,
  icon,
  selected,
  title
}) => (
  <SquarePanelContainer data-test='SquarePanelContainer' selected={selected}>
    {
      selected
        ? <SquarePanelTitle data-test='SquarePanelTitle'>{title}</SquarePanelTitle>
        : (
          <>
            <SquarePanelIconContainer data-test='SquarePanelIconContainer'>
              <Icon icon={icon} data-test='SquarePanelIcon' />
            </SquarePanelIconContainer>
            <SquarePanelDescription data-test='SquarePanelDescription'>{description}</SquarePanelDescription>
          </>
        )
    }
  </SquarePanelContainer>
)

export default SquarePanel

SquarePanel.propTypes = {
  description: PropTypes.string,
  icon: PropTypes.string,
  selected: PropTypes.bool,
  title: PropTypes.string
}
