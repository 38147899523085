import translationsListPL from './translationListPL'
import translationsListEN from './translationListEN'

export default (() => {
  // const lang = window.localStorage.getItem('language')
  //
  // if (lang === null) {
  //   window.localStorage.setItem('language', 'pl-pl')
  // }
  //
  // if (lang === 'en-us') {
  //   return translationsListEN
  // }

  return translationsListPL
})()
