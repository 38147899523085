import styled from 'styled-components'

export const DropdownContainer = styled.div`
  position: relative;

  min-width: ${({ containerMinWidth }) => containerMinWidth || ''};
  width: ${({ containerWidth }) => containerWidth || ''};
`

export const DropdownButtonContainer = styled.div``

export const DropdownMenuContainer = styled.div`
  position: absolute;
  top: 100%;
  z-index: 10;

  bottom: ${({ menuContentBottom }) => menuContentBottom || ''};
  left: ${({ menuContentLeft }) => menuContentLeft || ''};
  right: ${({ menuContentRight }) => menuContentRight || ''};
`
