import React from 'react'
import PropTypes from 'prop-types'

import {
  TextComponentContainer
} from './components'

const TextComponent = ({
  align,
  text
}) => (
  <TextComponentContainer align={align}>{text}</TextComponentContainer>
)

export default TextComponent

TextComponent.propTypes = {
  align: PropTypes.string,
  text: PropTypes.string
}

TextComponent.defaultProps = {
  align: '',
  text: ''
}
