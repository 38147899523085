import styled from 'styled-components'

import {
  PinkColorM,
  PurpleColorS,
  WhiteColor
} from '../../../../assets/styles/colors'

export const LoginAndRegisterContainer = styled.div`
  border-radius: 20px;
  overflow: hidden;
  position: relative;

  @media (min-width: 768px) {
    display: flex;
  }
`

export const LoginAndRegisterLeft = styled.div`
  background-color: ${WhiteColor};
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    flex: 1;
  }
`

export const LoginAndRegisterRight = styled.div`
  @media (min-width: 768px) {
    flex: 1;
    position: relative;
  }
  
  @media (min-width: 320px) and (max-width: 767px) {
    display: none;
  }
`

export const LoginAndRegisterRightContent = styled.ul`
  display: grid;
  grid-template-columns: 100% 100% 100%;
  height: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
`

export const LoginAndRegisterDotsList = styled.ul`
  bottom: 40px;
  display: grid;
  grid-column-gap: 30px;
  grid-row-gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(12px, 12px));
  justify-content: center;
  left: 20px;
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 20px;
`

export const LoginAndRegisterDotListItem = styled.li`
  width: 12px;
  height: 12px;
  border-radius: 100%;

  ${props => {
    switch (props.isSelected) {
      case true: return `background-color: ${PinkColorM};`
      default: return `background-color: ${PurpleColorS};`
    }
  }}
`
