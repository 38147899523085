import styled from 'styled-components'

import {
  PurpleColorM,
  PurpleColorS,
  PurpleColorXS,
  RedColor,
  WhiteColor,
  WhiteColorM
} from '../../../../assets/styles/colors'

export const MultiSelectDropDownContainer = styled.div`
  position: relative;
`

export const MultiSelectDropDownFieldContainer = styled.div`
  width: 100%;
`

export const MultiSelectDropDownIconContainer = styled.div`
  position: absolute;
  top: 20px;
  bottom: 20px;
  display: flex;
  align-items: center;
  
  ${props => {
    switch (props.isOpen) {
      case true: return 'transform: rotate(180deg);'
      default: return ''
    }
  }}

  @media (min-width: 1200px) {
    right: 25px;
  }

  @media (min-width: 767px) and (max-width: 1199px) {
    right: 15px;
  }

  @media (min-width: 320px) and (max-width: 767px) {
    right: 10px;
  }
`

export const MultiSelectDropDownIconContent = styled.div`
  width: 21px;
  height: 21px;
  background-color: ${PurpleColorXS};
  border-radius: 50%;

  & svg > path:first-child {
    fill: ${WhiteColor};
  }
`

export const MultiSelectDropDownInputPlaceholder = styled.div`
  display: flex;
  align-items: center;
  background: ${WhiteColor};
  font-size: 14px;
  color: #D5D8DE;
  border-radius: 10px;

  ${props => {
    switch (props.hasError) {
      case true: return `border: 2px solid ${RedColor};`
      default: return `border: 2px solid ${PurpleColorXS};`
    }
  }}

  ${props => {
    switch (props.isOpen) {
      case true: return `
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      `
      default: return 'border-radius: 10px;'
    }
  }}

  @media (min-width: 1200px) {
    padding: 0px 50px 0 25px;
  }

  @media (min-width: 767px) and (max-width: 1199px) {
    padding: 0px 40px 0 15px;
  }

  @media (min-height: 768px) {
    height: 61px;
  }

  @media (min-width: 320px) and (max-width: 767px) {
    height: 41px;
    padding: 0px 35px 0 10px;
  }
`

export const MultiSelectDropDownListContainer = styled.ul`
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  display: block;
  left: 0;
  list-style-type: none;
  margin: 0;
  max-height: 200px;
  overflow: scroll;
  padding: 0;
  position: absolute;
  right: 0;
  text-align: left;
  top: 100%;
  z-index: 100;

  ${props => {
    switch (props.hasError) {
      case true: return `
        border-left: 2px solid ${RedColor};
        border-right: 2px solid ${RedColor};
        border-bottom: 2px solid ${RedColor};
      `
      default: return `
        border-left: 2px solid ${PurpleColorXS};
        border-right: 2px solid ${PurpleColorXS};
        border-bottom: 2px solid ${PurpleColorXS};
      `
    }
  }}
`

export const MultiSelectDropDownListElement = styled.li`
  border-top: 1px solid ${PurpleColorS};

  &:first-child {
    border-top: 0px solid transparent;
  }
`

export const MultiSelectDropDownItemContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 18px;
  grid-column-gap: 10px;
  width: 100%;
  padding: 5px 25px;
  background-color: ${WhiteColor};
  line-height: 21px;

  &:hover {
    background-color: ${WhiteColorM};
  }
`

export const MultiSelectDropDownItemContent = styled.div`
  line-height: 21px;
  font-size: 14px;
  color: ${PurpleColorM};
`

export const MultiSelectDropDownItemIcon = styled.div`
  width: 21px;

  & g {
    fill: #C0FF83;
    stroke: #C0FF83;
  }
`
