import React from 'react'
import PropTypes from 'prop-types'

import {
  ButtonSwitchContainer
} from './components'

const ButtonSwitch = ({
  isActive,
  onClick,
  label
}) => (
  <ButtonSwitchContainer
    isActive={isActive}
    onClick={onClick}
  >
    {label}
  </ButtonSwitchContainer>
)

export default ButtonSwitch

ButtonSwitch.propTypes = {
  isActive: PropTypes.bool,
  label: PropTypes.string
}

ButtonSwitch.defaultProps = {
  isActive: false,
  label: ''
}
