import styled from 'styled-components'

import {
  PurpleColor,
  PurpleColorM,
  PurpleColorS,
  PurpleColorXS,
  RedColor
} from '../../../../assets/styles/colors'

export const ChooseFileContainer = styled.div`
  display: grid;
  align-items: center;

  ${props => {
    switch (props.text) {
      case true: return `
        grid-template-columns: auto 1fr;
        grid-column-gap: 20px;
      `
      default: return ''
    }
  }}
`

export const ChooseFileButton = styled.div`
  background-color: ${PurpleColorS};
  padding: 20px 25px;
  line-height: 21px;
  font-size: 14px;
  font-weight: 600;
  color: ${PurpleColor};
  border-radius: 10px;

  ${props => {
    switch (props.hasError) {
      case true: return `border: 2px solid ${RedColor};`
      default: return `border: 2px solid ${PurpleColorXS};`
    }
  }}
  
  @media (min-width: 768px) and (max-width: 1199px) {
    padding: 10px 15px;
  }

  @media (max-width: 767px) {
    padding: 8px 10px;
  }
`

export const ChooseFileText = styled.div`
  line-height: 21px;
  font-size: 16px;
  color: ${PurpleColorM};
  text-align: left;
`
