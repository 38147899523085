import styled from 'styled-components'

export const AwardsHeaderContainer = styled.div`
  display: flex;

  @media (min-width: 768px) {
    justify-content: space-between;
  }

  @media (min-width: 320px) and (max-width: 767px) {
    justify-content: center;
  }
`

export const AwardsHeaderLeftContainer = styled.div``

export const AwardsHeaderRightContainer = styled.div`
  @media (min-width: 992px) {
    width: 270px;
  }

  @media (min-width: 320px) and (max-width: 991px) {
    display: none;
  }
`

export const AwardsContainer = styled.div``
