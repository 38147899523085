import React from 'react'
import PropTypes from 'prop-types'

import {
  RequiredFieldsTextContainer
} from './components'

import translationsList from '../../../../stabs/translationsList'

const RequiredFieldsText = ({
  option
}) => (
  <RequiredFieldsTextContainer option={option}>
    {translationsList.form.labels.requiredFieldsText}
  </RequiredFieldsTextContainer>
)

export default RequiredFieldsText

RequiredFieldsText.propTypes = {
  option: PropTypes.string
}

RequiredFieldsText.defaultProps = {
  option: ''
}
