const FETCH_ERROR = 'myUser/FETCH_ERROR'
const FETCH_REQUESTED = 'myUser/FETCH_REQUESTED'
const FETCH_SUCCESS = 'myUser/FETCH_SUCCESS'

const TOGGLE_VALUE = 'myUser/TOGGLE_VALUE'
const CLEAN_UP = 'myUser/CLEAN_UP'

export default {
  FETCH_ERROR,
  FETCH_REQUESTED,
  FETCH_SUCCESS,
  TOGGLE_VALUE,
  CLEAN_UP
}
