import createSimpleFetchingEpic from '../../utils/createSimpleFetchingEpic'

import * as knowledgeAPI from '../../api/knowledge'

import actions from './actions'
import types from './types'

const fetchArticles = createSimpleFetchingEpic(
  types.FETCH_REQUESTED,
  knowledgeAPI.getAllArticles().toObject(),
  actions.fetchArticlesSuccess,
  actions.fetchArticlesError
)

export default {
  fetchArticles
}
