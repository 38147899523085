import React from 'react'
import PropTypes from 'prop-types'

import AwardItem from './AwardItem'
import Label from '../../Form/FormBasicElements/Label'

import {
  AwardsContainer,
  AwardsHeaderContainer,
  AwardsHeaderLeftContainer,
  AwardsHeaderRightContainer
} from './components'

import translationsList from '../../../stabs/translationsList'

const Awards = ({
  itemsList
}) => (
  <>
    {
      itemsList && (
        <>
          <AwardsHeaderContainer>
            <AwardsHeaderLeftContainer>
              <Label
                data-test='Price'
                label={translationsList.companyPanel.prizelabel}
              />
            </AwardsHeaderLeftContainer>

            <AwardsHeaderRightContainer>
              <Label
                data-test='DateAndPlace'
                label={translationsList.companyPanel.dateAndPlaceLabel}
              />
            </AwardsHeaderRightContainer>
          </AwardsHeaderContainer>

          <AwardsContainer>
            {
              itemsList.map((item, id) => (
                <AwardItem
                  date={item.date}
                  contestName={item.contestName}
                  key={id}
                  name={item.name}
                  place={item.place}
                />
              ))
            }
          </AwardsContainer>
        </>
      )
    }
  </>
)

export default Awards

Awards.propTypes = {
  itemsList: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string,
      description: PropTypes.string,
      name: PropTypes.string,
      place: PropTypes.string
    })
  )
}
