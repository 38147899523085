import styled from 'styled-components'

import {
  PurpleColorL,
  PurpleColorXS,
  RedColor,
  WhiteColor,
  WhiteColorM
} from '../../../../assets/styles/colors'

export const TextAreaContainer = styled.textarea`
  width: 100%;
  background-color: ${WhiteColorM};
  line-height: 21px;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  color: ${PurpleColorL}
  border-radius: 10px;
  box-shadow: none;
  resize: vertical;

  ${props => {
    switch (props.hasError) {
      case true: return `border: 2px solid ${RedColor};`
      default: return `border: 2px solid ${PurpleColorXS};`
    }
  }}

  &:placeholder-shown {
    background-color: ${WhiteColor};
    color: #D5D8DE;
  }

  &::placeholder {
    color: #D5D8DE;
  }

  @media (min-width: 1200px) {
    min-height: 150px;
    padding: 20px 25px;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    min-height: 120px;
    padding: 10px 15px;
  }

  @media (min-width: 320px) and (max-width: 767px) {
    min-height: 100px;
    padding: 8px 10px;
  }
`
