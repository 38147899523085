import styled from 'styled-components'

import {
  PurpleColor,
  SilverColor
} from '../../../../../assets/styles/colors'

export const TrainingItemHeaderSpeakersContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: auto auto;
  align-items: center;
`

export const TrainingItemHeaderSpeakerContainer = styled.div`
  @media (min-width: 1200px) {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    line-height: 18px;
    font-size: 12px;
    font-weight: 600;
    color: ${SilverColor};
  }

  @media (min-width: 320px) and (max-width: 1199px) {
    display: none;
  }
`

export const TrainingItemHeaderSpeakerName = styled.span`
  color: ${PurpleColor};
`

export const TrainingItemHeaderSpeakerSeparator = styled.span`
  margin: 0 3px;
`

export const TrainingItemHeaderSpeakerCompany = styled.span``
