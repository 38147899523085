import React, { useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import ScrollToTop from 'react-router-scroll-top'
// import { Helmet } from 'react-helmet'
// import useDidMount from '@rooks/use-did-mount'

import { primaryEventActions, primaryEventSelectors } from './store/ducks/primary-event'
import { myUserActions, myUserSelectors } from './store/ducks/my-user'
import { myCompanyActions, myCompanySelectors } from './store/ducks/my-company'
import AuthService from './store/services/AuthService'
import RouterExpo from './RouterExpo'
// import RouterCV from './RouterCV'

import ErrorPage from './pages/ErrorPage'

import Loader from './components/Loader'
import { AppMainWrapper } from './components'

const AppExpo = () => {
  const primaryEvent = useSelector(primaryEventSelectors.getPrimaryEvent)
  const myUser = useSelector(myUserSelectors.getMyUser)
  const myCompany = useSelector(myCompanySelectors.getMyCompanyState)

  const dispatch = useDispatch()
  useEffect(() => {
    if (AuthService.loggedIn()) {
      if (myUser.isLoading === false) {
        if (myUser.data === null || myUser.data._id === '') {
          dispatch(myUserActions.fetchMyUserRequested())
        }
      }

      if (AuthService.getUserType() === 'company') {
        if (myCompany.isLoading === false) {
          if (myCompany.data === null || myCompany.data._id === '') {
            dispatch(myCompanyActions.fetchMyCompanyRequested())
          }
        }
      }
    }

    if (primaryEvent.isLoading === false) {
      if (primaryEvent.data === null || primaryEvent.data._id === '') {
        dispatch(primaryEventActions.fetchPrimaryEventRequested())
      }
    }
  }, [dispatch, primaryEvent, myUser, myCompany])

  if (primaryEvent.error !== null) {
    return <ErrorPage error={{ status: `${primaryEvent.error.status}`, title: primaryEvent.error.msg }} />
  }

  return (
    <>
      {
        primaryEvent.isLoading || myUser.isLoading || myCompany.isLoading
          ? <Loader isLoading />
          : (
            <AppMainWrapper>
              <BrowserRouter>
                <ScrollToTop>
                  <RouterExpo />
                </ScrollToTop>
              </BrowserRouter>
            </AppMainWrapper>
          )
      }
    </>
  )
}

// const AppCV = () => {
//   const myUser = useSelector(myUserSelectors.getMyUser)
//
//   const dispatch = useDispatch()
//   useDidMount(() => {
//     if (AuthService.loggedIn()) {
//       if (myUser.isLoading === false) {
//         if (myUser.data === null || myUser.data._id === '') {
//           dispatch(myUserActions.fetchMyUserRequested())
//         }
//       }
//     }
//   }, [dispatch, myUser])
//
//   return (
//     <>
//       {
//         myUser.isLoading
//           ? <Loader isLoading />
//           : (
//             <AppMainWrapper>
//               <BrowserRouter>
//                 <ScrollToTop>
//                   <RouterCV />
//                 </ScrollToTop>
//               </BrowserRouter>
//             </AppMainWrapper>
//           )
//       }
//     </>
//   )
// }

export default () => {
  // if (Number(process.env.REACT_APP_CV) === 1) {
  //   return <>
  //     <Helmet>
  //       <title>CVworks | CV, które działa</title>
  //     </Helmet>
  //     <AppCV />
  //   </>
  // }

  return <>
    <AppExpo />
  </>
}
