import React, { useState, useEffect } from 'react'

import Site from '../../components/Wrappers/Site'
import AddedItem from '../../components/List/AddedItem'
import FormPanel from '../../components/Form/FormWrappers/FormPanel'
import Subtitle from '../../components/Subtitle'

import styled from 'styled-components'
import ApiService from '../../store/services/ApiService'
import * as companyAPI from '../../store/api/company'
import * as authAPI from '../../store/api/auth'
import * as eventAPI from '../../store/api/event'
import { toast } from 'react-toastify'
import AuthService from '../../store/services/AuthService'
import { useDispatch, useSelector } from 'react-redux'
import { companiesActions, companiesSelectors } from '../../store/ducks/companies'
import { primaryEventSelectors } from '../../store/ducks/primary-event'

import AdminTrainingsContainer from './AdminTrainingsContainer'
import AdminSpeakersContainer from './AdminSpeakersContainer'
import AdminAddUserToCompany from './AdminAddUserToCompany'
import AdminQuickCompanyCreationContainer from './AdminQuickCompanyCreationContainer'
import AdminHighlightCompany from './AdminHighlightCompany'
import AdminChatToCompany from './AdminChatToCompany'
import AdminChangeEventDate from './AdminChangeEventDate'

const Wrapper = styled.div`
  margin: 50px 100px 0;
`

const getHighlight = (item) => item.levelOfHighlighting !== undefined && item.levelOfHighlighting !== '' ? '[' + item.levelOfHighlighting + ']' : ''

const AdminPanelContainer = () => {
  const [items, setItems] = useState([])
  const companies = useSelector(companiesSelectors.getCompaniesContent)
  const event = useSelector(primaryEventSelectors.getPrimaryEventData)

  const dispatch = useDispatch()

  const companiesIds = companies.map((company) => company._id)

  async function fetchData (signal) {
    ApiService
      .fetch(companyAPI.getAllCompanies(), signal)
      .then(data => {
        setItems(data)
      })
      .catch(error => {
        if (error.name !== 'AbortError') {
          console.error(error)
          toast.error('Wystąpił błąd !', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          })
        }
      })
  }

  useEffect(() => {
    const abortController = new AbortController()
    const signal = abortController.signal
    fetchData(signal)

    return () => {
      abortController.abort()
    }
  }, [])

  const handleEdit = (companyId) => {
    ApiService
      .fetch(authAPI.ghostLogin({ companyId }))
      .then(data => {
        AuthService.setGhostToken(data.token)
        window.location.reload()
      })
      .catch(error => {
        console.log(error)

        toast.error('Wystąpił błąd!', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        })
      })
  }

  const handleEvent = (companyId, isAdded) => {
    if (isAdded) {
      ApiService
        .fetch(eventAPI.removeCompanyFromEvent(event._id, companyId))
        .then(data => {
          dispatch(companiesActions.loadCompanies(data.companies))
        })
        .catch(error => {
          console.log(error)

          toast.error('Wystąpił błąd !', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          })
        })
    } else {
      ApiService
        .fetch(eventAPI.addCompanyToEvent(event._id, companyId))
        .then(data => {
          dispatch(companiesActions.loadCompanies(data.companies))
        })
        .catch(error => {
          console.log(error)

          toast.error('Wystąpił błąd!', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          })
        })
    }
  }

  const handleDelete = (companyId) => {
    if (window.confirm(`Czy napewno chcesz usunąć tą firmę? Ta akcja jest nieodwracalna i może wpłynąć na konto użytkownika, który należy do tej firmy.`)) {
      ApiService.fetch(companyAPI.removeCompany(companyId)).then(() => {
        toast.success('Usunięto!', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          onClose: () => window.location.reload()
        })
      })
    }
  }

  return (
    <Site isLoggedOn>
      <Wrapper>

        <Subtitle
          data-test='benefits'
          icon='education'
          label='Szkolenia'
        />

        <AdminTrainingsContainer />

        <Subtitle
          data-test='benefits'
          icon='badge'
          label='Prelegenci'
        />

        <AdminSpeakersContainer />

        <Subtitle
          data-test='benefits'
          icon='badge'
          label='Szybkie Tworzenie Firmy'
        />

        <AdminQuickCompanyCreationContainer />

        <Subtitle
          data-test='benefits'
          icon='badge'
          label='Dodawanie Kont do Firmy'
        />

        <AdminAddUserToCompany companies={items} />

        <Subtitle
          data-test='benefits'
          icon='star'
          label='Oznacz firmę'
        />

        <AdminHighlightCompany companies={items} />

        <Subtitle
          data-test='benefits'
          icon='star'
          label='Dodaj chat do firmy'
        />

        <AdminChatToCompany companies={items} />

        <Subtitle
          data-test='benefits'
          icon='star'
          label='Edytuj wydarzenie'
        />

        <AdminChangeEventDate event={event} />

        <Subtitle
          data-test='benefits'
          icon='star'
          label='Ghost Login'
        />

        <FormPanel errors=''>
          {items.map((item, index) => (
            <AddedItem
              isInsideForm
              key={index}
              title={`${getHighlight(item)}${item.name || item.primaryEmail || 'Bez nazwy'}`}
              description={`${item._id}${item.isPublished ? ' - ZAKOŃCZONO' : ''}${item.chatUrl !== undefined && item.chatUrl !== '' ? ' - Dodano CHAT ' : ''}`}
              onClickDelete={() => handleDelete(item._id)}
              onClickEdit={() => handleEdit(item._id)}
              onClickAddToEvent={() => handleEvent(item._id, companiesIds.includes(item._id))}
              isInEvent={companiesIds.includes(item._id)}
              isLoading={false}
            />
          ))}
        </FormPanel>
      </Wrapper>
    </Site>
  )
}

export default AdminPanelContainer
