import styled from 'styled-components'

import {
  OrangeColor,
  PurpleColor
} from '../../assets/styles/colors'

import { ButtonPlayContainer } from '../../components/Buttons/ButtonPlay/components'
import { ImageComponentContainer } from '../../components/Form/FormBasicElements/Image/components'

import bgImg from '../../assets/img/background.jpg'
import sectionOnemageCoverPath from '../../assets/img/home_bg.jpg'
import { DescriptionContainer } from '../../components/Section/Description/components'
import { Label, Underline } from '../../components/Section/Title/components'
import { TextValueTextValueContainer } from '../../components/Form/FormBasicElements/TextValueTextValue/components'

export const SectionThreeSubtitleContainer = styled.div`
  @media (min-width: 992px) {
    margin: 98px 0 32px;
  }

  @media (min-width: 320px) and (max-width: 991px) {
    margin: 40px 0 16px;
  }
`

export const SectionTwo = styled.div`
  max-width: 800px;

  @media (min-width: 1200px) {
    margin: 180px auto 0;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    margin: 80px auto 0;
  }

  @media (min-width: 576px) and (max-width: 991px) {
    margin: 60px auto 0;
  }

  @media (min-width: 320px) and (max-width: 575px) {
    margin: 50px 0 0;
  }
`

export const SectionThree = styled.div`
  margin: 0 auto;

  @media (min-width: 992px) {
    width: 900px;
  }
`

export const SectionOne = styled.div`
  position:relative;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(255,255,255);
    background: -moz-linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.9) 40%, rgba(255,255,255,0) 70%);
    background: -webkit-linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.9) 40%, rgba(255,255,255,0) 70%);
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.9) 40%, rgba(255,255,255,0) 70%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1); 
  }

  @media (min-width: 992px) {
    background-image: url(${sectionOnemageCoverPath});
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  }
`

export const SectionOneContainer = styled.div`
  position:relative;
  z-index: 1;

  ${Label} {
    color: #000;
  }

  ${DescriptionContainer} {
    color: #000;
  }

  ${Underline} {
    background-color: #FCD0A3;
  }

  @media (min-width: 1600px) {
    max-width: 50%;
    padding: 150px 0 100px;
  }

  @media (min-width: 1200px) and (max-width: 1599px) {
    max-width: 50%;
    padding: 130px 0 70px;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    max-width: 50%;
    padding: 100px 0 50px;
  }

  @media (min-width: 320px) and (max-width: 991px) {
    padding: 50px 0 0;

    ${Label} {
      text-align: center;
    }

    ${Underline} {
      margin: 10px auto;
    }

    ${DescriptionContainer} {
      text-align: center;
      color: $#fff
    }
  }
`

export const SectionOneButtonContainer = styled.div`
  text-align: left;
  
  @media (min-width: 1200px) {
    margin: 80px 0 0;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    margin: 50px 0 0;
  }
  
  @media (min-width: 576px) and (max-width: 991px) {
    margin: 30px 0 0;
  }

  @media (min-width: 320px) and (max-width: 991px) {
    text-align: center;
  }
  
  @media (min-width: 320px) and (max-width: 575px) {
    margin: 20px 0 0;
  }
`

const smallBreakepoints = () => `
  @media (min-width: 1600px) {
    margin: 80px 0 0;
  }

  @media (min-width: 1200px) and (max-width: 1599px) {
    margin: 70px 0 0;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    margin: 60px 0 0;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    margin: 50px 0 0;
  }

  @media (min-width: 320px) and (max-width: 767px) {
    margin: 40px 0 0;
  }
`

const bigBreakepoints = () => `
  @media (min-width: 1600px) {
    margin: 220px 0 0;
  }

  @media (min-width: 1400px) and (max-width: 1599px) {
    margin: 200px 0 0;
  }

  @media (min-width: 1200px) and (max-width: 1399px) {
    margin: 180px 0 0;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    margin: 160px 0 0;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    margin: 140px 0 0;
  }

  @media (min-width: 576px) and (max-width: 767px) {
    margin: 120px 0 0;
  }

  @media (min-width: 320px) and (max-width: 575px) {
    margin: 100px 0 0;
  }
`

export const HomePageTopContainer = styled.div`
  display: flex;
  justify-content: end;
  position: relative;

  @media (min-width: 1600px) {
    margin: 100px 0 0;
  }

  @media (min-width: 1200px) and (max-width: 1599px) {
    margin: 80px 0 0;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    margin: 60px 0 0;
  }

  @media (min-width: 320px) and (max-width: 991px) {
    display: flex;
    flex-direction: column-reverse;
    margin: 10px auto 0;
  }
`

export const HomePageTopContent = styled.div`
  @media (min-width: 1200px) {
    max-width: 500px;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    max-width: 400px;
  }

  @media (min-width: 992px) {
    left: 0;
    position: absolute;
    top: 0;
  }
`

export const HomePageTopButtonContainer = styled.div`
  text-align: left;

  ${smallBreakepoints}
`

export const HomePageTopImageContainer = styled.div`
  @media (min-width: 1200px) {
    margin-left: 520px;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    margin-left: 420px;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    margin: 0 auto 20px;
    max-width: 500px;
  }

  @media (min-width: 320px) and (max-width: 767px) {
    margin: 0 auto 10px;
    max-width: 450px;
  }
`

export const HomePageTopImage = styled.img``

export const HomePageCardsContainer = styled.div`
  ${bigBreakepoints}
`

export const HomePageCreatorCVContainer = styled.div`
  ${bigBreakepoints}
`

export const HomePageGuidlineStepsContainer = styled.div`
  @media (min-width: 768px) {
    display: flex;
  }

  ${smallBreakepoints}
`

export const HomePageChooseTemplateContainer = styled.div`
  ${bigBreakepoints}  
`

export const HomePageCvTemplatesContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const HomePageChooseTemplateButtonContainer = styled.div`
  ${smallBreakepoints}
`

export const HomePageEmployerFeaturesContainer = styled.div`
  ${bigBreakepoints}  
`

export const HomePageEmployersListContainer = styled.div`
  ${bigBreakepoints}  
`

export const HomePageEmployerContainer = styled.div`
  padding: 0 30px;

  img {
    max-width: unset;
  }
`

export const HomePageEmployerFeaturesLeftSideContainer = styled.div``

export const HomePageEmployerFeaturesRightSideContainer = styled.div`
  @media (min-width: 768px) {
    margin: 140px 0 0;
  }

  @media (min-width: 576px) and (max-width: 767px) {
    margin: 120px 0 0;
  }

  @media (min-width: 320px) and (max-width: 575px) {
    margin: 100px 0 0;
  }
`

export const HomePageEmployerFeaturesRightSideContent = styled.div`
  display: grid;

  @media (min-width: 576px) {
    grid-template-columns: calc(50% - 15px) calc(50% - 15px);
    grid-column-gap: 30px;
    grid-row-gap: 30px;
  }

  @media (min-width: 320px) and (max-width: 575px) {
    grid-row-gap: 20px;
  }
`

export const HomePageEmployerFeatureButtonContainer = styled.div`
  text-align: left;

  ${smallBreakepoints}
`

export const HomePageJobFairsContainer = styled.div`
  ${bigBreakepoints}  
`

export const HomePageJobFairLeftContainer = styled.div`
  position: relative;

  & > ${ImageComponentContainer} {
    padding: 0;
  }

  & > ${ButtonPlayContainer} {
    left: calc(50% - 60px);
    position: absolute;
    top: calc(50% - 60px);
  }

  @media (max-width: 991px) {
    margin: 40px 0 0;
  }
`

export const HomePageJobFairRightContainer = styled.div`
  @media (min-width: 576px) and (max-width: 992px) {
    padding: 0 30px;
  }

  @media (min-width: 320px) and (max-width: 575px) {
    padding: 0 20px;
  }

  ${smallBreakepoints}
`

export const HomePageJobFairIndustryContainer = styled.div`
  max-width: 500px;
  text-align: left;

  ${smallBreakepoints}
`

export const HomePageJobFairIndustryHeader = styled.div`
  color: ${PurpleColor};
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
`

export const HomePageJobFairIndustryContent = styled.div`
  @media (min-width: 768px) {
    margin: 30px 0 0;
  }

  @media (min-width: 320px) and (max-width: 767px) {
    margin: 20px 0 0;
  }
`

export const HomePageJobFairRightContent = styled.div`
  @media (min-width: 991px) {
    max-width: 690px;
  }

  @media (min-width: 1600px) {
    padding: 0 60px 0 0;
  }

  @media (min-width: 1200px) and (max-width: 1599px) {
    padding: 0 50px 0 0;
  }

  @media (min-width: 991px) and (max-width: 1199px) {
    padding: 0 20px 0 0;
  }
`

export const HomePageJobFairText = styled.div`
  border-left: 2px solid ${OrangeColor};
  color: ${PurpleColor};
  font-size: 16px;
  font-weight: 600;
  padding: 0 0 0 10px;
  text-align: left;

  ${smallBreakepoints}
`

export const HomePageJobFairButtonContainer = styled.div`
  text-align: left;

  ${smallBreakepoints}
`

export const HomePageReviewsContainer = styled.div`
  position: relative;

  ${bigBreakepoints}  
`

export const HomePageReviewsImageContainer = styled.div`
  @media (min-width: 320px) and (max-width: 767px) {
    display: none;
  }
  
  & > div {
    padding: 0;
  }

  & > div > img {
    width: 100%;
  }
`

export const HomePageReviewContainer = styled.div`
  @media (min-width: 1600px) {
    max-width: 30%;
    right: 8%;
    top: 22%;
  }

  @media (min-width: 1400px) and (max-width: 1599px) {
    max-width: 38%;
    right: 5%;
    top: 20%;
  }

  @media (min-width: 1200px) and (max-width: 1399px) {
    max-width: 45%;
    right: 40px;
    top: 20%;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    max-width: 45%;
    right: 30px;
    top: 18%;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    max-width: 45%;
    right: 30px;
    top: 8%;
  }

  @media (min-width: 768px) {
    position: absolute;    
  }

  @media (min-width: 576px) and (max-width: 767px) {
    padding: 0 30px;
  }

  @media (min-width: 320px) and (max-width: 575px) {
    padding: 0 20px;
  }
`

export const HomePageReviewerContainer = styled.div`
  color: ${PurpleColor};
  font-size: 14px;
  font-weight: 600;
  margin: 20px 0 0;
`

export const HomePageStepsContainer = styled.div`
  max-width: 1175px;

  @media (min-width: 1200px) {
    margin: 160px auto 0;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    margin: 120px auto 0;
  }

  @media (min-width: 576px) and (max-width: 991px) {
    margin: 100px 0 0;
  }

  @media (max-width: 575px) {
    margin: 80px 0 0;
  }
`

export const HomePageStepsButtonContainer = styled.div`
  @media (min-width: 1200px) {
    margin: 100px auto 0;
  }
  
  @media (min-width: 992px) and (max-width: 1199px) {
    margin: 80px auto 0;
  }

  @media (min-width: 576px) and (max-width: 991px) {
    margin: 60px auto 0;
  }

  @media (max-width: 575px) {
    margin: 50px auto 0;
  }
`
