import React from 'react'
import PropTypes from 'prop-types'

// import Icon from '../../../Form/FormBasicElements/Icon'
import Image from '../../../Form/FormBasicElements/Image'
import InternalOrExternal from '../../../Buttons/InternalOrExternal'
// import LinkComponent from '../../../Buttons/LinkComponent'

import {
  // ArticleItemButtonsContainer,
  ArticleItemContainer,
  // ArticleItemDate,
  // ArticleItemDateContainer,
  // ArticleItemDateIconContainer,
  ArticleItemImageContainer,
  ArticleItemText,
  ArticleItemTitle,
  ArticleItemTitleContainer
} from './components'

// import calendarIconPath from '../../../../assets/icons/calendar.svg'

const ArticleItem = ({
  date,
  isPublished,
  link,
  text,
  thumbnailUrl,
  title
}) => (
  <>
    {
      isPublished && (
        <ArticleItemContainer>
          {
            thumbnailUrl && (
              <ArticleItemImageContainer>
                <Image
                  alt={title}
                  source={thumbnailUrl}
                />
              </ArticleItemImageContainer>
            )
          }

          <ArticleItemTitleContainer>
            <InternalOrExternal
              link={link}
              type='internal'
            >
              <ArticleItemTitle>{title}</ArticleItemTitle>
            </InternalOrExternal>
          </ArticleItemTitleContainer>

          {
            text && (
              <ArticleItemText
                dangerouslySetInnerHTML={{ __html: text.replace(/<[^>]*>?/gm, '').split(' ').slice(0, 30).join(' ') }}
              />
            )
          }

          {/* <ArticleItemButtonsContainer>
            <ArticleItemDateContainer>
              <ArticleItemDateIconContainer>
                <Icon icon={calendarIconPath} />
              </ArticleItemDateIconContainer>

              <ArticleItemDate>{date}</ArticleItemDate>
            </ArticleItemDateContainer>

            <LinkComponent
              colorText='purpleColor'
              fontSize='sizeM'
              label='Czytaj więcej'
              link={link}
              type='internal'
            />
          </ArticleItemButtonsContainer> */}
        </ArticleItemContainer>
      )
    }
  </>
)

export default ArticleItem

ArticleItem.propTypes = {
  date: PropTypes.string,
  isPublished: PropTypes.bool,
  link: PropTypes.string,
  text: PropTypes.string,
  thumbnailUrl: PropTypes.string,
  title: PropTypes.string
}

ArticleItem.defaultProps = {
  date: '',
  text: '',
  title: ''
}

// TODO Fix Translations
