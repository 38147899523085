const FETCH_ERROR = 'jobOffers/FETCH_ERROR'
const FETCH_REQUESTED = 'jobOffers/FETCH_REQUESTED'
const FETCH_SUCCESS = 'jobOffers/FETCH_SUCCESS'
const ADD = 'jobOffers/ADD'
const UPDATE = 'jobOffers/UPDATE'

export default {
  ADD,
  UPDATE,
  FETCH_ERROR,
  FETCH_REQUESTED,
  FETCH_SUCCESS
}
