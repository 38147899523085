import React, { useState } from 'react'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { toast } from 'react-toastify'

import ApiService from '../../../../store/services/ApiService'

import * as companyAPI from '../../../../store/api/company'

import AddBasicCompanyDataForm from '../../../../components/Form/FormComponents/AddBasicCompanyDataForm'
import ResetAndSaveButtons from '../../../../components/Form/FormComponents/ResetAndSaveButtons'

import translate from '../../../../stabs/errorMessagesTranslations'
import { myCompanyActions, myCompanySelectors } from '../../../../store/ducks/my-company'
import handleField from '../../../../utils/forms/handleField'

const AddBasicCompanyDataFormContainer = ({ history }) => {
  const [state, setState] = useState({
    isLoading: false,
    error: null
  })

  const company = useSelector(myCompanySelectors.getMyCompany)

  const dispatch = useDispatch()
  const onSubmit = (values, { setSubmitting }) => {
    setState({ error: null, isLoading: true })

    ApiService
      .fetch(companyAPI.updateMe(values))
      .then(data => {
        setSubmitting(false)
        setState({ error: null, isLoading: false })

        toast.success('Zapisano!', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        })

        dispatch(myCompanyActions.fetchMyCompanySuccess(data))

        history.push('/platforma')
      })
      .catch(error => {
        setSubmitting(false)
        console.log(error)
        console.log(error.details)
        setState({ error: translate('pl', error.details.msg), isLoading: false })
      })
  }

  return (
    <Formik
      initialValues={{
        bannerUrl: company.bannerUrl,
        logoUrl: company.logoUrl,
        facebook: company.socials.facebook,
        goldenline: company.socials.goldenline,
        instagram: company.socials.instagram,
        linkedin: company.socials.linkedin,
        name: company.name,
        vectorLogoUrl: company.vectorLogoUrl,
        website: company.website,
        youtube: company.socials.youtube
      }}
      validationSchema={BasicCompanyDataSchema}
      onSubmit={onSubmit}
      render={({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        handleReset,
        isSubmitting,
        setFieldTouched,
        setFieldValue,
        touched,
        values
      }) => (
        <form onSubmit={handleSubmit}>
          <AddBasicCompanyDataForm
            apiError={state.error}
            errors={errors}
            handleBlur={handleBlur}
            handleChange={handleChange}
            handleField={(fieldName, value) => handleField(fieldName, value, { setFieldTouched, setFieldValue })}
            touched={touched}
            values={values}
          />

          <ResetAndSaveButtons
            data-test='ResetAndSaveButtonsOne'
            onClickReset={handleReset}
            disabledSave={isSubmitting}
            isLoadingSave={state.isLoading}
            isClearing={false}
            isSaveSubmitting
          />
        </form>
      )}
    />
  )
}

const BasicCompanyDataSchema = Yup.object().shape({
  name: Yup.string()
    .required('Pole jest wymagane'),
  website: Yup.string()
    .url('Podany adres musi być poprawnym linkiem')
    .required('Pole jest wymagane'),
  facebook: Yup.string()
    .url('Podany adres musi być poprawnym linkiem'),
  instagram: Yup.string()
    .url('Podany adres musi być poprawnym linkiem'),
  youtube: Yup.string()
    .url('Podany adres musi być poprawnym linkiem'),
  goldenline: Yup.string()
    .url('Podany adres musi być poprawnym linkiem'),
  linkedin: Yup.string()
    .url('Podany adres musi być poprawnym linkiem')
})

export default withRouter(AddBasicCompanyDataFormContainer)
