import React from 'react'

import EditorField from '../../FormFields/EditorField'
// import FormPanel from '../../FormWrappers/FormPanel'
import FormTwoColumns from '../../FormWrappers/FormTwoColumns'
import InputField from '../../FormFields/InputField'
import MultiSelectDropDownField from '../../FormFields/MultiSelectDropDownField'
import RequiredFieldsText from '../../FormBasicElements/RequiredFieldsText'
import SpacingLine from '../../../SpacingLine'
import TextAreaField from '../../FormFields/TextAreaField'

import CompanyArticleThumbnailUploadContainer from '../../../../containers/ComponentContainers/UploadContainers/CompanyArticleThumbnailUploadContainer'
import CompanyArticleAuthorAvatarUploadContainer from '../../../../containers/ComponentContainers/UploadContainers/CompanyArticleAuthorAvatarUploadContainer'

import {
  AddArticleFormNameAndSurnameContainer
  // DeadlineInfo
} from './components'

import categories from '../../../../stabs/articleCategories'
// import AddedItem from '../../../List/AddedItem'

const AddArticleForm = ({
  isEditing,
  errors,
  handleBlur,
  handleChange,
  setFieldTouched,
  setFieldValue,
  handleField,
  handleMultiSelect,
  timestamp,
  touched,
  values
}) => (
  <>
    <FormTwoColumns data-test='formTwoColumnsOne'>
      <CompanyArticleThumbnailUploadContainer
        error={errors.articleThumbnailUrl}
        touched={touched.articleThumbnailUrl}
        value={values.articleThumbnailUrl}
        handleField={handleField}
      />

      <MultiSelectDropDownField
        data-test='multiSelectDropDownFieldTwo'
        label='Kategoria'
        errorMessage={errors.category}
        hasError={errors.category && touched.category}
        name='category'
        closeOnClick
        itemsList={categories}
        onClick={handleMultiSelect}
        placeholder={values.category.trim() === '' ? 'Wybierz' : categories.filter((el) => el.label === values.category)[0].label}
        value={values.category}
        required
      />
    </FormTwoColumns>

    <InputField
      data-test='title'
      errorMessage={errors.title}
      hasError={errors.title && touched.title}
      id='title'
      label='Tytuł'
      name='title'
      onBlur={handleBlur}
      onChange={handleChange}
      placeholder='Wpisz tytuł'
      required
      value={values.title}
    />

    <EditorField
      errorMessage={errors.body}
      hasError={errors.body && touched.body}
      onChange={(value) => handleField('body', value, { setFieldTouched, setFieldValue })}
      isEditing={isEditing}
      name='body'
      value={values.body}
      label='Treść'
      placeholder='Wpisz treść'
      required
      key={timestamp}
    />

    <SpacingLine
      isFixed
      option='SizeS'
    />

    <FormTwoColumns data-test='formTwoColumnsTwo'>
      <CompanyArticleAuthorAvatarUploadContainer
        error={errors.authorAvatarUrl}
        touched={touched.authorAvatarUrl}
        value={values.authorAvatarUrl}
        handleField={handleField}
      />

      <AddArticleFormNameAndSurnameContainer>
        <InputField
          data-test='name'
          errorMessage={errors.authorFirstName}
          hasError={errors.authorFirstName && touched.authorFirstName}
          id='name'
          label='Imię'
          name='authorFirstName'
          onBlur={handleBlur}
          onChange={handleChange}
          placeholder='Wpisz imię'
          required
          value={values.authorFirstName}
        />

        <InputField
          data-test='surname'
          errorMessage={errors.authorLastName}
          hasError={errors.authorLastName && touched.authorLastName}
          id='surname'
          label='Nazwisko'
          name='authorLastName'
          onBlur={handleBlur}
          onChange={handleChange}
          placeholder='Wpisz nazwisko'
          required
          value={values.authorLastName}
        />
      </AddArticleFormNameAndSurnameContainer>
    </FormTwoColumns>

    <InputField
      data-test='position'
      errorMessage={errors.authorPosition}
      hasError={errors.authorPosition && touched.authorPosition}
      id='position'
      label='Stanowisko'
      name='authorPosition'
      onBlur={handleBlur}
      onChange={handleChange}
      placeholder='Wpisz stanowisko'
      required
      value={values.authorPosition}
    />

    <TextAreaField
      errorMessage={errors.authorDescription}
      hasError={errors.authorDescription && touched.authorDescription}
      id='biography'
      label='Biogram'
      maxLength={300}
      name='authorDescription'
      onBlur={handleBlur}
      onChange={handleChange}
      placeholder='Wpisz biogram'
      required
      value={values.authorDescription}
    />

    <RequiredFieldsText option='WithMargin' />
  </>
)

export default AddArticleForm

// TODO Fix translations
