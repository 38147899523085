import { createSelector } from 'reselect'

import { trainingsSelectors } from '../trainings'

const getTagsState = (state) => state.tags
const getTags = (state) => state.tags.data
const getSelectedTags = createSelector(
  getTags,
  (tags) => tags.filter((tag) => tag.isActive)
)

// TODO test it!!!
const getTagsOnlyUsedInTrainings = createSelector(
  [getTags, trainingsSelectors.getTrainingsContent],
  (tags, trainings) => tags.filter((tag) => {
    let result = false
    trainings.forEach((training) => {
      training.tags.forEach((trainingTag) => {
        if (trainingTag._id === tag._id) {
          result = true
        }
      })
    })

    return result
  })
)

export default {
  getSelectedTags,
  getTags,
  getTagsOnlyUsedInTrainings,
  getTagsState
}
