import styled from 'styled-components'

import {
  PinkColor,
  PinkColorM,
  PurpleBoxShadowColor,
  Transition,
  WhiteColor
} from '../../assets/styles/colors'

export const ChooseCVContainer = styled.div``

export const ChooseCVContent = styled.div`
  border-radius: 35px;
  box-shadow: ${PurpleBoxShadowColor};
  display: inline-block;
  overflow: hidden;
  position: relative;
  transition: ${Transition};
  
  cursor: ${({ disabled }) => disabled ? 'default' : 'pointer'};

  &:hover {
    transform: scale(1.1);
  }

  margin: ${({ noMargin }) => noMargin
    ? '0'
    : '0 10px'
  };

  & > div {
    padding: 0;
  }

  ${({ halfWidth }) => halfWidth
    ? 'max-width: 50%;'
    : `
      @media (min-width: 1200px) {
          & img {
            width: 220px;
          }
        }

        @media (min-width: 992px) and (max-width: 1199px) {
          & img {
            width: 250px;
          }
        }

        @media (min-width: 768px) and (max-width: 991px) {
          & img {
            width: 230px;
          }
        }

        @media (max-width: 767px) {
          & img {
            width: 210px;
          }
        }
      `
  }
`

export const ChooseCVFaderContainer = styled.div`
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
`

export const ChooseCVFader = styled.div`
  background-image: linear-gradient(106deg, ${PinkColor}, ${PinkColorM});
  border-radius: 35px;
  bottom: 0;
  left: 0;
  opacity: 0.9;
  position: absolute;
  right: 0;
  top: 0;
`

export const ChooseCVFaderIconContainer = styled.div`
  background-color: ${PinkColorM};
  border-radius: 50%;
  height: 50px;
  left: 0;
  margin: 0 auto;
  padding: 10px;
  position: absolute;
  right: 0;
  top: 60px;
  width: 50px;
  z-index: 1;
`

export const ChooseCVFaderTitle = styled.div`
  bottom: 40px;
  color: ${WhiteColor};
  font-size: 18px;
  left: 0;
  padding: 0 10px;
  position: absolute;
  right: 0;
  text-align: center;
  z-index: 1;
`
