import { createSelector } from 'reselect'

import { myUserSelectors } from '../my-user'
import { companiesSelectors } from '../companies'

const getValuesState = (state) => state.values
const getValues = (state) => state.values.data

const getUserValues = createSelector(
  [getValues, myUserSelectors.getMyUserData],
  (values, user) => values.map((value) => ({
    ...value,
    isActive: user.values.includes(String(value._id)) || user.importantValues.includes(String(value._id)),
    isMostImportant: user.importantValues.includes(String(value._id))
  }))
)

const getCompanyValues = (companyId) => createSelector(
  [getValues, companiesSelectors.getCompaniesContent],
  (values, companies) => {
    const selectedCompanies = companies.filter((company) => company._id === companyId)

    if (selectedCompanies.length === 0) {
      return []
    }

    return values.filter((value) => selectedCompanies[0].values.includes(value._id))
  }
)

export default {
  getValues,
  getValuesState,
  getUserValues,
  getCompanyValues
}
