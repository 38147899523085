import styled from 'styled-components'

import { PurpleColorM } from '../../../../assets/styles/colors'

export const BenefitItemContainer = styled.div`
  display: flex;

  @media (min-width: 1200px) {
    margin: 0 0 50px;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    margin: 0 0 40px;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    margin: 0 0 30px;
  }

  @media (min-width: 320px) and (max-width: 767px) {
    flex-direction: column;
    margin: 0 0 25px;
  }
`

export const BenefitItemIconContainer = styled.div`
  flex-shrink: 0;
  width: 58px;

  & > div {
    align-items: flex-start;
  }

  @media (min-width: 320px) and (max-width: 767px) {
    margin: 0 auto 10px;
  }
`

export const BenefitItemRightContainer = styled.div`
  @media (min-width: 768px) {
    margin: 0 0 0 20px;
  }
`

export const BenefitItemTitle = styled.div`
  color: ${PurpleColorM};
  font-size: 16px;
  font-weight: 600;
  line-height: 25px;

  @media (min-width: 768px) {
    text-align: left;
  }

  @media (min-width: 320px) and (max-width: 767px) {
    text-align: center;
  }
`

export const BenefitItemDescription = styled.div`
  color: ${PurpleColorM};
  font-size: 12px;
  line-height: 24px;
  padding: 12px 0 0;

  @media (min-width: 768px) {
    text-align: left;
  }

  @media (min-width: 320px) and (max-width: 767px) {
    text-align: center;
  }
`
