import styled from 'styled-components'

export const ImageComponentScaleMe = styled.img`
  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center;
  width: 100%;
`

export const ImageComponentContainer = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  padding: 5px;
`

export const ImageComponent = styled.img`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  max-height: 100%;
  max-width: 100%;
  text-align: center;
  width: auto;
`
