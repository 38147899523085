import styled from 'styled-components'

import {
  OrangeColor,
  PinkColorM,
  PurpleColor,
  PurpleColorM
} from '../../../assets/styles/colors'

export const TabsMenuListContainer = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;

  @media (min-width: 320px) and (max-width: 767px) {
    flex-direction: column;
  }
`

export const TabsMenuItem = styled.div`
  font-size: 14px;
  line-height: 21px;
  position: relative;

  ${props => {
    switch (props.isActive) {
      case true: return `
        color: ${PurpleColor};
        font-weight: 600;

        &:after {
          content: '';
          background-color: ${OrangeColor};
          bottom: 0;
          display: block;
          height: 2px;
          left: 0;
          position: absolute;
          right: 0;
          width: 100%;
        }
      `
      default: return `
        color: ${PurpleColorM};
        font-weight: 300;
      `
    }
  }}

  ${props => {
    switch (props.isBold) {
      case true: return `
        color: ${PinkColorM};
        font-weight: 600;
      `
      default: return ''
    }
  }}

  @media (min-width: 1200px) {
    padding: 15px 25px;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    padding: 15px 20px;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    padding: 15px;
  }

  @media (min-width: 320px) and (max-width: 767px) {
    padding: 8px 20px 8px 0;
    width: 100%;
  }
`
