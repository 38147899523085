import Cookies from 'js-cookie'
import decode from 'jwt-decode'
import uuid from 'uuid/v4'

import ApiService from './ApiService'
import * as authAPI from '../api/auth'

export default {
  loggedIn () {
    const token = this.getToken()
    return !!token && !this.isTokenExpired(token)
  },

  getUserId () {
    if (!this.loggedIn()) {
      return uuid()
    }

    const decoded = decode(this.getToken())
    return decoded.userId
  },

  isTokenExpired (token) {
    try {
      const decoded = decode(token)
      const now = Date.now() / 1000

      if (decoded.exp - now < 3600) {
        this.refreshToken()
      }

      return decoded.exp < now
    } catch (err) {
      return false
    }
  },

  getUserType () {
    if (!this.loggedIn()) {
      return 'guest'
    }

    if (this.getGhostToken() !== undefined) {
      return decode(this.getGhostToken()).scope
    }

    return decode(this.getToken()).scope
  },

  isUserAllowed (minimalType) {
    if (!this.loggedIn()) {
      return false
    }

    const types = {
      guest: 0,
      user: 1,
      company: 2,
      admin: 3
    }

    return types[this.getUserType()] >= types[minimalType]
  },

  setToken (token) {
    // set token to cookie with expire time 2h
    Cookies.set('token', token, { expires: 0.08333333333333333 })
  },

  getToken () {
    return Cookies.get('token')
  },

  logout () {
    Cookies.remove('token')
  },

  refreshToken () {
    if (Cookies.get('isRefreshing') === 'true') {
      return
    }

    Cookies.set('isRefreshing', 'true')

    ApiService.fetch(authAPI.refreshToken())
      .then((data) => {
        Cookies.remove('isRefreshing')
        this.setToken(data.token)
      })
      .catch(() => {
        Cookies.remove('isRefreshing')
        this.logout()
      })
  },

  getGhostToken () {
    return Cookies.get('ghost-token')
  },

  setGhostToken (token) {
    Cookies.set('ghost-token', token, { expires: 0.020833333333333332 })
  },

  logoutGhost () {
    Cookies.remove('ghost-token')
  }
}
