import React from 'react'

import SpacingLine from '../SpacingLine'
import Image from '../../components/Form/FormBasicElements/Image'

import covid from '../../assets/img/covid.png'
import niw from '../../assets/img/niw.jpg'

import {
  CopyrightsText,
  FooterImages,
  FooterCovid
} from './components'

import translationsList from '../../stabs/translationsList'

const Copyrights = () => (
  <>
    <SpacingLine option='SizeS' />

    <FooterImages>
	    <Image source={niw} />
	    <FooterCovid>
	    	{'Sfinansowano przez Narodowy Instytut Wolności ze środków Programu Wsparcia Doraźnego Organizacji Pozarządowych w Zakresie Przeciwdziałania COVID-19'}
	    </FooterCovid>
	    <Image source={covid} />
    </FooterImages>

    <CopyrightsText>{translationsList.copyright}</CopyrightsText>
  </>
)

export default Copyrights
