import React from 'react'
import PropTypes from 'prop-types'

import {
  ChooseIconsWrapperContainer
} from './components'

const ChooseIconsWrapper = ({
  children
}) => (
  <ChooseIconsWrapperContainer>{children}</ChooseIconsWrapperContainer>
)

export default ChooseIconsWrapper

ChooseIconsWrapper.props = {
  children: PropTypes.any
}
