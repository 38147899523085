import React from 'react'
import PropTypes from 'prop-types'

import ButtonComponent from '../../../Buttons/ButtonComponent'
import SelectableItems from '../../../SelectableItems/SelectableItems'

import {
  TrainingItemLeftButtonContainer,
  TrainingItemLeftContainer,
  TrainingItemLeftDescription,
  TrainingItemLeftPlace,
  TrainingItemLeftTimeContainer,
  TrainingItemLeftTimeEnd,
  TrainingItemLeftTimeSeparator,
  TrainingItemLeftTimeStart,
  TrainingItemTagsContainer
} from './components'

import translationsList from '../../../../stabs/translationsList'

const TrainingItemLeft = ({
  description,
  enrolled,
  isEnrolled,
  limit,
  onClickSign,
  onClickUnsign,
  place,
  tagsList,
  timeEnd,
  timeStart
}) => (
  <TrainingItemLeftContainer>
    {
      description && (
        <TrainingItemLeftDescription dangerouslySetInnerHTML={{ __html: description }} />
      )
    }

    {
      place && (
        <>
          <TrainingItemLeftPlace>{place}</TrainingItemLeftPlace>

          <TrainingItemLeftTimeContainer>
            <TrainingItemLeftTimeStart>{timeStart}</TrainingItemLeftTimeStart>
            <TrainingItemLeftTimeSeparator> - </TrainingItemLeftTimeSeparator>
            <TrainingItemLeftTimeEnd>{timeEnd}</TrainingItemLeftTimeEnd>
          </TrainingItemLeftTimeContainer>
        </>
      )
    }

    {/*<TrainingItemLeftButtonContainer>*/}
    {/*  <ButtonComponent*/}
    {/*    bgColor={*/}
    {/*      isEnrolled*/}
    {/*        ? 'purpleColorS'*/}
    {/*        : 'purpleColor'*/}
    {/*    }*/}
    {/*    color={*/}
    {/*      isEnrolled*/}
    {/*        ? 'purpleColor'*/}
    {/*        : 'whiteColor'*/}
    {/*    }*/}
    {/*    display='inline-flex'*/}
    {/*    disabled={limit !== 0 && limit === enrolled && !isEnrolled}*/}
    {/*    fontBold*/}
    {/*    label={*/}
    {/*      isEnrolled*/}
    {/*        ? translationsList.trainings.labels.trainingsButtonUnBuyLabel*/}
    {/*        : translationsList.trainings.labels.trainingsButtonBuyLabel*/}
    {/*    }*/}
    {/*    onClick={*/}
    {/*      isEnrolled*/}
    {/*        ? onClickUnsign*/}
    {/*        : onClickSign*/}
    {/*    }*/}
    {/*    size='sizeM'*/}
    {/*  />*/}
    {/*</TrainingItemLeftButtonContainer>*/}

    {
      tagsList && (
        <TrainingItemTagsContainer>
          <SelectableItems
            itemsList={
              tagsList.map((tag) => ({
                label: tag.name,
                isActive: true
              }))
            }
          />
        </TrainingItemTagsContainer>
      )
    }
  </TrainingItemLeftContainer>
)

export default TrainingItemLeft

TrainingItemLeft.propTypes = {
  description: PropTypes.string,
  onClick: PropTypes.func,
  place: PropTypes.string,
  tagsList: PropTypes.arrayOf(
    PropTypes.shape({
      isSelected: PropTypes.bool,
      label: PropTypes.string
    })
  ),
  timeEnd: PropTypes.string,
  timeStart: PropTypes.string
}
