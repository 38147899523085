import React from 'react'
import PropTypes from 'prop-types'

import {
  ImageComponent,
  ImageComponentContainer,
  ImageComponentScaleMe
} from './components'

import noPhotoImagePath from '../../../../assets/img/noPhoto.png'

const Image = ({
  alt,
  scaleMe,
  source
}) => (
  <>
    {
      scaleMe
        ? (
          <ImageComponentScaleMe
            alt={alt}
            src={source}
          />
        )

        : (
          <ImageComponentContainer>
            <ImageComponent
              alt={alt}
              src={source}
            />
          </ImageComponentContainer>
        )
    }
  </>
)

export default Image

Image.propTypes = {
  alt: PropTypes.string,
  scaleMe: PropTypes.bool,
  source: PropTypes.string
}

Image.defaultProps = {
  alt: '',
  scaleMe: true,
  source: noPhotoImagePath
}
