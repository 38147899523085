import React from 'react'
import PropTypes from 'prop-types'

import InternalOrExternal from '../Buttons/InternalOrExternal'
import ZoneMenuItem from './ZoneMenuItem'
import ZoneRight from './ZoneRight'

import {
  ZoneMenuItemContainer,
  ZoneMenuItemContent,
  ZoneRightContainer,
  ZonesContainer
} from './components'

const Zones = ({
  itemsList,
  onClick,
  selectedItem
}) => (
  <ZonesContainer>
    <ZoneMenuItemContainer>
      {itemsList.map((item, id) => (
        <ZoneMenuItemContent key={id}>
          <InternalOrExternal onClick={() => onClick(id)}> {/* // TODO ten id nie zadziała (testy) ? */}
            <ZoneMenuItem
              icon={item.icon}
              isActive={item.isActive}
              label={item.label}
            />
          </InternalOrExternal>
        </ZoneMenuItemContent>
      ))}
    </ZoneMenuItemContainer>

    <ZoneRightContainer>
      <ZoneRight
        description={selectedItem.description}
        image={selectedItem.image}
      />
    </ZoneRightContainer>
  </ZonesContainer>
)

export default Zones

Zones.propTypes = {
  itemsList: PropTypes.arrayOf(
    PropTypes.shape({
      isActive: PropTypes.bool,
      label: PropTypes.string
    })
  ),
  onClick: PropTypes.func,
  selectedItem: PropTypes.shape({
    description: PropTypes.string,
    icon: PropTypes.string,
    image: PropTypes.string
  })
}

Zones.defaultProps = {
  itemsList: [],
  // TODO onClick: () => null,
  selectedItem: {
    description: '',
    icon: '',
    image: ''
  }
}
