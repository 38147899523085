import React from 'react'
import { withRouter } from 'react-router-dom'

import Modal from '../../Modal'
import PlatformCompanyWrapper from '../../Wrappers/PlatformCompanyWrapper'

const PlatformCompanyWrapperModal = ({ slug, company, onClose, isPreview, history }) => (
  <Modal
    inModal
    isFixed
    onClose={onClose !== undefined ? onClose : () => history.goBack()}
  >
    <PlatformCompanyWrapper slug={slug} company={company} isPreview={isPreview} />
  </Modal>
)

export default withRouter(PlatformCompanyWrapperModal)

PlatformCompanyWrapperModal.defaultProps = {
  isPreview: false
}
