import styled from 'styled-components'

import {
  PurpleColorL,
  PurpleColorM,
  PurpleColorXS
} from '../../../../assets/styles/colors'

export const RecruitmentDetailsItemListElement = styled.li`
  color: ${PurpleColorM};
  display: flex;
  font-size: 14px;
  line-height: 21px;

  @media (min-width: 768px) {
    align-items: flex-start;
    padding: 6px 0;
  }

  @media (min-width: 320px) and (max-width: 767px) {
    align-items: center;
    flex-direction: column;
    padding: 8px 0;
  }
`

export const RecruitmentDetailsItemLabelContainer = styled.div`
  align-items: center;
  display: flex;
  flex-shrink: 0;

  @media (min-width: 768px) {
    margin: 0 8px 0 0;
    width: 200px;
  }

  @media (min-width: 320px) and (max-width: 767px) {
    justify-content: center;
  }
`

export const RecruitmentDetailsItemIconContainer = styled.div`
  margin: 0 8px 0 0;
  width: 12px;

  & g {
    fill: ${PurpleColorXS};
  }
`

export const RecruitmentDetailsItemLabel = styled.div``

export const RecruitmentDetailsItemValueContainer = styled.div``

export const RecruitmentDetailsItemValue = styled.div`
  color: ${PurpleColorL};

  @media (min-width: 768px) {
    text-align: left;
  }

  @media (min-width: 320px) and (max-width: 767px) {
    text-align: center;
  }
`
