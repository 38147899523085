import React from 'react'
import PropTypes from 'prop-types'

import ChooseImageContainer from '../../../../containers/ComponentContainers/ChooseImageContainer'
import Errors from '../../FormBasicElements/Errors'
import Label from '../../FormBasicElements/Label'

import {
  ChooseImageFieldContainer
} from './components'

const ChooseImageField = ({
  buttonSelectLabel,
  chooseFileButtonDescription,
  chooseFileButtonFieldlabel,
  chooseFileButtonLabel,
  chooseFileButtonSendLabel,
  chooseFileTitle,
  croppedImageHeadingText,
  errorMessage,
  hasError,
  image,
  isEditable,
  isLoading,
  onChange,
  required,
  withHash,
  withInfo
}) => (
  <ChooseImageFieldContainer>
    <Label
      label={chooseFileButtonFieldlabel}
      required={required}
    />

    <ChooseImageContainer
      buttonSelectLabel={buttonSelectLabel}
      chooseFileButtonDescription={chooseFileButtonDescription}
      chooseFileButtonFieldlabel={chooseFileButtonFieldlabel}
      chooseFileButtonLabel={chooseFileButtonLabel}
      chooseFileButtonSendLabel={chooseFileButtonSendLabel}
      chooseFileTitle={chooseFileTitle}
      croppedImageHeadingText={croppedImageHeadingText}
      data-test='choose-image-container'
      hasError={hasError}
      image={image}
      isEditable={isEditable}
      isLoading={isLoading}
      onChange={onChange}
      withHash={withHash}
      withInfo={withInfo}
    />

    <Errors
      errorMessage={errorMessage}
      hasError={hasError}
    />
  </ChooseImageFieldContainer>
)

export default ChooseImageField

ChooseImageField.propTypes = {
  buttonSelectLabel: PropTypes.string,
  chooseFileButtonDescription: PropTypes.string,
  chooseFileButtonFieldlabel: PropTypes.string,
  chooseFileButtonLabel: PropTypes.string,
  chooseFileButtonSendLabel: PropTypes.string,
  chooseFileTitle: PropTypes.string,
  croppedImageHeadingText: PropTypes.string,
  errorMessage: PropTypes.string,
  hasError: PropTypes.bool,
  image: PropTypes.string,
  isEditable: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  withHash: PropTypes.bool,
  withInfo: PropTypes.bool
}
