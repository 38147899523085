import styled from 'styled-components'

import {
  PurpleColorL,
  PurpleColorXS,
  RedColor,
  WhiteColor,
  WhiteColorM
} from '../../assets/styles/colors'

export const TextAreaContainer = styled.div`
  width: 100%;
  background-color: ${WhiteColorM};
  line-height: 21px;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  color: ${PurpleColorL}
  border-radius: 0 0 10px 10px;
  box-shadow: none;
  resize: vertical;
  text-align: start;

  ${props => {
  switch (props.hasError) {
    case true: return `border: 2px solid ${RedColor};`
    default: return `border: 2px solid ${PurpleColorXS};`
  }
}}

  &:placeholder-shown {
    background-color: ${WhiteColor};
    color: #DAD5F2;
  }

  &::placeholder {
    color: #DAD5F2;
  }

  @media (min-width: 1200px) {
    min-height: 150px;
    padding: 20px 25px;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    min-height: 120px;
    padding: 10px 15px;
  }

  @media (min-width: 320px) and (max-width: 767px) {
    min-height: 100px;
    padding: 8px 10px;
  }
  
  
  
  border-top: none;
`

export const Button = styled.span`
  cursor: pointer;
  width: 16px;
  height: 16px;
  
  ${props => {
  switch (props.reversed) {
    case true:
      return `color: ${props.active ? 'white' : '#aaa'};`
    default:
      return `color: ${props.active ? 'black' : '#ccc'};`
  }
}}
  
  ${props => {
  switch (props.icon) {
    case 'italic':
      return `width: 13px;`
    default:
      return `width: 16px;`
  }
}}
`

export const Toolbar = styled.div`
  & > * {
    display: inline-block;
  }

  & > * + * {
    margin-left: 15px;
  }
  
  text-align: start;
  padding: 10px 10px 7px;
  border-radius: 10px 10px 0 0;
  border: 2px solid ${PurpleColorXS};
  background-color: ${WhiteColorM};
`
