import * as Sentry from '@sentry/browser'
import getError from '../utils/getError'
import AuthService from './AuthService'
import { toast } from 'react-toastify'

export default class ApiService {
  constructor (method, url, body = {}, options = {}) {
    this.domain = ApiService.getDomain()
    this.method = method

    this.url = `${this.domain}${url}`

    if (options.external) {
      this.url = url
    }

    this.body = body
    this.options = options
  }

  static getDomain () {
    return process.env.REACT_APP_API_URL
  }

  toObject () {
    const endpoint = {
      url: this.url,
      method: this.method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...this.options.headers
      },
      options: this.options
    }

    if (this.method !== 'GET') {
      endpoint.body = JSON.stringify(this.body)
    }

    const isLoggedIn = AuthService.loggedIn()
    if ((endpoint.options.withAuth === true || endpoint.options.optionalAuth === true) && isLoggedIn) {
      endpoint.headers.authorization = AuthService.getToken()

      if (endpoint.options.allowGhost) {
        const ghostToken = AuthService.getGhostToken()

        if (ghostToken !== undefined) {
          endpoint.headers.authorization = ghostToken
        }
      }
    }

    if (endpoint.options.withAuth === true && !isLoggedIn) {
      toast.error('Nastąpiło wylogowanie ze względu na zbyt długi czas nieaktywności!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        onClose: () => {
          window.location.pathname = '/'
        }
      })

      throw Error('Unauthorized')
    }

    if (endpoint.options.optionalAuth === true && !isLoggedIn) {
      delete endpoint.headers.authorization
    }

    return endpoint
  }

  static fetch (apiEndpoint, signal) {
    const endpoint = apiEndpoint.toObject()

    if (signal !== undefined) {
      return fetch(endpoint.url, { ...endpoint, signal })
        .then((response) => _checkResponse(response, endpoint))
    }

    return fetch(endpoint.url, endpoint)
      .then((response) => _checkResponse(response, endpoint))
  }
}

const _checkResponse = async (response, endpoint) => {
  const data = await response.json()

  if (response.status >= 200 && response.status < 300) {
    return data
  }

  const error = new Error(data.message)
  error.details = getError({ ...error, response: data })

  const sentryError = new Error(data.message)
  sentryError.details = getError({ ...error, response: data })
  sentryError.request = endpoint
  Sentry.captureException(error)

  throw error
}
