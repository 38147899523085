import React from 'react'
import PropTypes from 'prop-types'

import LinkComponent from '../../Buttons/LinkComponent'

import {
  AddedItemButtonsContainer,
  AddedItemContainer,
  AddedItemDescription,
  AddedItemTextContainer,
  AddedItemTitle
} from './components'

import deleteIconPath from '../../../assets/icons/delete.svg'
import editIconPath from '../../../assets/icons/edit.svg'
import addToEventIconPath from '../../../assets/icons/recommend.svg'

const AddedItem = ({
  description,
  isInEvent,
  isInsideForm,
  isLoading,
  onClickAddToEvent,
  onClickDelete,
  onClickEdit,
  title
}) => (
  <AddedItemContainer isInsideForm={isInsideForm}>
    <AddedItemTextContainer>
      {title &&
        <AddedItemTitle dangerouslySetInnerHTML={{ __html: title }} />}

      {description &&
        <AddedItemDescription dangerouslySetInnerHTML={{ __html: description }} />}
    </AddedItemTextContainer>

    <AddedItemButtonsContainer>
      <LinkComponent
        colorFill='purpleColor'
        colorText='purpleColor'
        data-test='edit'
        fontSize='sizeM'
        iconBefore={editIconPath}
        label='edytuj'
        onClick={onClickEdit}
        disabled={isLoading}
      />

      {onClickAddToEvent && (
        <LinkComponent
          colorFill={isInEvent ? '#6B677E' : '#6B677E'}
          colorText={isInEvent ? '#6B677E' : '#6B677E'}
          colorStroke={isInEvent ? '#6B677E' : '#6B677E'}
          data-test='addtoEvent'
          fontSize='sizeM'
          iconBefore={addToEventIconPath}
          label={isInEvent ? 'usuń z eventu' : 'dodaj do eventu'}
          onClick={onClickAddToEvent}
          disabled={isLoading}
        />
      )}

      <LinkComponent
        colorFill='{#6B677E}'
        colorStroke='{#6B677E}'
        colorText='{#6B677E}'
        data-test='delete'
        fontSize='sizeM'
        iconBefore={deleteIconPath}
        label='usuń'
        onClick={onClickDelete}
        disabled={isLoading}
      />

    </AddedItemButtonsContainer>
  </AddedItemContainer>
)

export default AddedItem

AddedItem.propTypes = {
  description: PropTypes.string,
  isInsideForm: PropTypes.bool,
  onClickDelete: PropTypes.func,
  onClickEdit: PropTypes.func,
  title: PropTypes.string
}

AddedItem.defaultProps = {
  isInsideForm: false
}
