import styled from 'styled-components'

import { LabelContainer } from '../../Form/FormBasicElements/Label/components'
import {
  PurpleBoxShadowColor,
  SilverColorXS
} from '../../../assets/styles/colors'

export const TrainingItemContainer = styled.div`
  border-radius: 20px;
  box-shadow: ${PurpleBoxShadowColor};

  @media (min-width: 992px) {
    margin: 0 0 20px;
  }

  @media (min-width: 576px) and (max-width: 991px) {
    margin: 0 0 10px;
  }

  @media (min-width: 320px) and (max-width: 575px) {
    margin: 0 0 8px;
  }
`

export const TrainingItemContent = styled.div`
  display: flex;
  padding: 30px 50px 50px 50px;
  border-top: 1px solid ${SilverColorXS};
  border-radius: 0 20px 20px 0;

  @media (min-width: 320px) and (max-width: 991px) {
    flex-direction: column-reverse;
  }

  @media (min-width: 576px) and (max-width: 991px) {
    padding: 10px 10px 30px;
  }

  @media (min-width: 320px) and (max-width: 575px) {
    padding: 8px 8px 20px;
  }
`

export const TrainingItemRightContent = styled.div`
  flex: 5;

  @media (min-width: 1200px) {
    ${LabelContainer} {
      display: none;
    }
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    ${LabelContainer} {
      text-align: left;
    }
  }

  @media (min-width: 320px) and (max-width: 991px) {
    ${LabelContainer} {
      text-align: center;
    }
  }

  @media (min-width: 320px) and (max-width: 991px) {
    ${LabelContainer} {
      display: none;
    }
  }
`
