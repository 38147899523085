export default (error) => {
  if (error.response === null) {
    return {
      status: 500,
      msg: 'Nastąpił nieoczekiwany problem. Spróbuj ponownie później.',
      errorCode: 0
    }
  }

  return {
    msg: error.response.message,
    errorCode: error.response.errorCode,
    status: error.status
  }
}
