import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import {
  InternalOrExternalLink,
  InternalOrExternalContainer,
  InternalOrExternalOnClickContainer
} from './components'

const InternalOrExternal = ({
  align,
  children,
  disabled,
  display,
  inNewWindow,
  link,
  onClick,
  type
}) => (
  <>
    {type === 'external' &&
      <InternalOrExternalContainer
        align={align}
        data-test='external'
        disabled={disabled}
        display={display}
      >
        <InternalOrExternalLink
          href={link}
          target={inNewWindow ? 'blank' : undefined}
        >
          {children}
        </InternalOrExternalLink>
      </InternalOrExternalContainer>}

    {type === 'internal' &&
      <InternalOrExternalContainer
        align={align}
        data-test='internal'
        disabled={disabled}
        display={display}
      >
        <Link to={link}>{children}</Link>
      </InternalOrExternalContainer>}

    {type === 'onClick' &&
      <InternalOrExternalOnClickContainer
        align={align}
        disabled={disabled}
        display={display}
        onClick={onClick}
      >
        {children}
      </InternalOrExternalOnClickContainer>}
  </>
)

export default InternalOrExternal

InternalOrExternal.propTypes = {
  align: PropTypes.oneOf(['center', 'left', 'right']),
  children: PropTypes.any,
  disabled: PropTypes.bool,
  display: PropTypes.oneOf(['inline-flex']),
  inNewWindow: PropTypes.bool,
  link: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.oneOf(['external', 'internal', 'onClick'])
}

InternalOrExternal.defaultProps = {
  align: 'left',
  disabled: false,
  inNewWindow: false,
  link: '',
  // TODO onClick: () => null
  type: 'onClick'
}
