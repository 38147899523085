import React, { Suspense, lazy } from 'react'
import { Route, Switch, Redirect, withRouter } from 'react-router-dom'

import AuthRoute from './containers/AuthContainers/AuthRoute'
import ProtectedRoute from './containers/AuthContainers/ProtectedRoute'
import Logout from './containers/AuthContainers/Logout'
import HomePageContainer from './containers/PageContainers/HomePageContainer'
import EventPageContainer from './containers/PageContainers/EventPageContainer'
import PlatformContainer from './containers/PlatformContainer'

import Loader from './components/Loader'

import { PageView } from './utils/AnalyticsProvider'
import retry from './utils/retry'

const ActivateContainer = lazy(() => retry(() => import('./containers/PageContainers/ActivateContainer')))
const ArticlePage = lazy(() => retry(() => import('./pages/ArticlePage')))
const ArticlesPage = lazy(() => retry(() => import('./pages/ArticlesPage')))
const ContactPage = lazy(() => retry(() => import('./pages/ContactPage')))
const UkrPage = lazy(() => retry(() => import('./pages/UkrPage')))
const ForCompanies = lazy(() => retry(() => import('./pages/ForCompanies')))
const JobOffersContainer = lazy(() => retry(() => import('./containers/PageContainers/JobOffersContainer/JobOffersPageContainer')))
const SingleJobOfferPageContainer = lazy(() => retry(() => import('./containers/PageContainers/JobOffersContainer/SingleJobOfferPageContainer')))
// const CvCreatorPage = lazy(() => retry(() => import('./pages/CvCreatorPage')))
const ErrorPage = lazy(() => retry(() => import('./pages/ErrorPage')))
const FaqPage = lazy(() => retry(() => import('./pages/FaqPage')))
const ForgotPasswordPage = lazy(() => retry(() => import('./pages/ForgotPasswordPage')))
// const PartnersPage = lazy(() => retry(() => import('./pages/PartnersPage')))
// const PrivacyPolicyPage = lazy(() => retry(() => import('./pages/PrivacyPolicyPage')))
const RegisterSuccessPage = lazy(() => retry(() => import('./pages/RegisterSuccessPage')))
// const RegulationsPage = lazy(() => retry(() => import('./pages/RegulationsPage')))
const ResetPasswordPage = lazy(() => retry(() => import('./pages/ResetPasswordPage')))
// const TestPage = lazy(() => retry(() => import('./pages/TestScreen')))
const UnsubscribeContainer = lazy(() => retry(() => import('./containers/PageContainers/UnsubscribeContainer')))
const ResendActivationContainer = lazy(() => retry(() => import('./containers/PageContainers/ResendActivationContainer')))
// const ExpoRegister = lazy(() => retry(() => import('./containers/PageContainers/ExpoRegister')))
// const TicketChecker = lazy(() => retry(() => import('./containers/PageContainers/TicketChecker')))

const Router = ({ history }) => {
  history.listen(() => {
    PageView()
  })

  return (
    <Suspense fallback={<Loader isLoading />}>
      <Switch>
        <Route path='/' exact component={() => <EventPageContainer />} />

        {/*<Route path='/pl' exact component={() => changeLanguage('pl-PL')} />*/}
        {/*<Route path='/en' exact component={() => changeLanguage('en-US')} />*/}

        {/*<Route path='/targi-pracy-wroclaw' exact component={() => <EventPageContainer />} />*/}
        {/*<Route path='/targi-pracy/wroclaw' exact component={() => <EventPageContainer />} />*/}
        {/*<Route path='/targi-pracy/wroclaw-night' exact component={() => <EventPageContainer />} />*/}
        {/*<Route path='/play' exact component={() => <HomePageContainer modal='PLAY' />} />*/}
        <Route path='/pracodawcy/:slug' exact component={({ match }) => <HomePageContainer modal='COMPANY' match={match} />} />
        {/*<Route path='/wspolpraca' exact component={() => { window.location.href = 'https://dla-firm.careerexpo.pl'; return null }} />*/}
        {/*<Route path='/regulamin-ce-eb-awards' exact component={() => { window.location.href = 'https://docs.google.com/document/d/1_fyWKwNsIHT-xcgSo2CmCYlJ_OTskNCIiwijNCbe2mg/'; return null }} />*/}
        {/*<Route path='/glosowanie' exact component={() => { window.location.href = 'https://docs.google.com/forms/d/e/1FAIpQLScWkN3zvsoOGaSpwUJtGDON6lJpEubW-oyb0IoQToOH1Czehg/viewform?vc=0&c=0&w=1'; return null }} />*/}
        {/*<Route path='/glosuj' exact component={() => { window.location.href = 'https://docs.google.com/forms/d/e/1FAIpQLScWkN3zvsoOGaSpwUJtGDON6lJpEubW-oyb0IoQToOH1Czehg/viewform?vc=0&c=0&w=1'; return null }} />*/}
        <Route path='/oferty-pracy' exact component={JobOffersContainer} />
        <Route path='/oferty-pracy/:slug' exact component={SingleJobOfferPageContainer} />

        {/*<Route path='/bilet' exact component={ExpoRegister} />*/}
        {/*<Route path='/ticket' exact component={() => { changeLanguage('en-US', '/bilet') }} />*/}
        {/*<Route path='/ticket/:slug' exact component={({ match }) => <TicketChecker slug={match.params.slug} />} />*/}
        {/*<Route path='/sprawdz' exact component={TicketChecker} />*/}

        <AuthRoute path='/logowanie-dla-firm' exact component={() => <HomePageContainer modal='LOGIN' />} />
        {/*<AuthRoute path='/rejestracja' exact component={() => <HomePageContainer modal='REGISTER' />} />*/}
        <AuthRoute path='/rejestracja-firmy' exact component={() => <HomePageContainer modal='REGISTER_COMPANY' />} />
        <AuthRoute path='/register-company' exact component={() => <Redirect to='/rejestracja-firmy' />} />
        <AuthRoute path='/przypomnij-haslo' exact component={ForgotPasswordPage} />
        <AuthRoute path='/zresetuj-haslo' exact component={ResetPasswordPage} />
        <AuthRoute path='/powodzenie-rejestracji' exact component={RegisterSuccessPage} />

        <Route path='/wypisz-sie' exact component={UnsubscribeContainer} />
        <Route path='/aktywuj' exact component={ActivateContainer} />
        <Route path='/wyslij' exact component={ResendActivationContainer} />

        <Route path='/ukr' exact component={UkrPage} />
        <Route path='/dla-firm' exact component={ForCompanies} />

        <Route path='/kontakt' exact component={ContactPage} />
        <Route path='/faq' exact component={FaqPage} />
        {/*<Route path='/partnerzy' exact component={PartnersPage} />*/}
        {/*<Route path='/polityka-prywatnosci' exact component={PrivacyPolicyPage} />*/}
        {/*<Route path='/regulamin' exact component={RegulationsPage} />*/}
        <Route path='/artykuly-eksperckie/:slug' component={ArticlePage} />
        <Route path='/artykuly-eksperckie' exact component={ArticlesPage} />
        {/*<Route path='/test' exact component={TestPage} />*/}

        {/*<Route path='/kreator-cv' exact component={CvCreatorPage} />*/}

        {/*<Route path='/kreator-cv/podstawowe-dane' exact component={() => <PlatformContainer type='cv' step={1} />} />*/}
        {/*<Route path='/kreator-cv/doswiadczenie' exact component={() => <PlatformContainer type='cv' step={2} />} />*/}
        {/*<Route path='/kreator-cv/szablon' exact component={() => <PlatformContainer type='cv' step={3} />} />*/}

        <ProtectedRoute path='/platforma' minimalType='user' exact component={() => <PlatformContainer step={0} />} />

        <ProtectedRoute path='/platforma/podstawowe-dane' minimalType='user' exact component={() => <PlatformContainer step={1} />} />

        {/*<ProtectedRoute path='/platforma/moje-szkolenia' minimalType='user' exact component={() => <PlatformContainer type='user' step={2} />} />*/}
        <ProtectedRoute path='/platforma/szczegoly' minimalType='company' exact component={() => <PlatformContainer type='company' step={2} />} />

        {/* <ProtectedRoute path='/platforma/moje-cv' minimalType='user' exact component={() => <PlatformContainer type='user' step={3} />} /> */}
        <ProtectedRoute path='/platforma/rekrutacja' minimalType='company' exact component={() => <PlatformContainer type='company' step={3} />} />

        {/* <ProtectedRoute path='/platforma/moja-kariera' minimalType='user' exact component={() => <PlatformContainer type='user' step={4} />} /> */}
        <ProtectedRoute path='/platforma/benefity-i-wyroznienia' minimalType='company' exact component={() => <PlatformContainer type='company' step={4} />} />

        {/*<ProtectedRoute path='/platforma/pobierz-bilet' minimalType='user' exact component={() => <PlatformContainer type='user' step={3} />} />*/}
        {/*<ProtectedRoute path='/platforma/pobierz-bilet/do-zobaczenia' minimalType='user' exact component={() => <PlatformContainer type='user' step={3} />} />*/}
        <ProtectedRoute path='/platforma/edukacja' minimalType='company' exact component={() => <PlatformContainer type='company' step={5} />} />

        <ProtectedRoute path='/wyloguj' minimalType='user' exact component={Logout} />

        <Route path='/500' exact component={() => <ErrorPage error={{ status: '500', title: 'Nastąpił problem.' }} />} />
        <Route path='/404' exact component={ErrorPage} />
        <Route component={ErrorPage} />
      </Switch>
    </Suspense>
  )
}

const changeLanguage = (lang, redirect = '/') => {
  window.localStorage.setItem('language', lang.toLowerCase())
  window.location.href = redirect

  return null
}

export default withRouter(Router)
