import styled from 'styled-components'

import { PurpleColorM } from '../../../../assets/styles/colors'

export const TextAreaFieldContainer = styled.div`
  @media (min-width: 992px) {
    padding: 0 0 20px;
  }

  @media (min-width: 320px) and (max-width: 991px) {
    padding: 0 0 15px;
  }
`

export const TextAreaLengthContainer = styled.div`
  margin: 5px 0 0;
  line-height: 18px;
  font-size: 12px;
  color: ${PurpleColorM};
  text-align: left;
`
