import { createSelector } from 'reselect'

const getArticles = (state) => state.articles
const getArticlesData = createSelector(
  getArticles,
  (articles) => articles.data === null ? [] : articles.data
)

const getArticlesByFilter = createSelector(
  [getArticlesData, getArticles],
  (articles, articlesState) => articlesState.filterText === null
    ? articles
    : articles
      .filter((article) => article.isPublished)
      .filter((article) => article.category === articlesState.filterText)
)

const getArticleBySlug = (slug) => createSelector(
  getArticlesData,
  (articles) => articles.filter((article) => article.slug === slug)[0]
)

const getArticlesByHighlightState = (highlightState) => createSelector(
  getArticlesByTime,
  (articles) => articles.filter((article) => article.highlightState === highlightState)
)

const getArticlesByTime = createSelector(
  getArticlesByFilter,
  // (articles) => [...articles].sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
  (articles) => [...articles].reverse()
)

const getArticlesPublished = createSelector(
  getArticlesData,
  (articles) => articles.filter((article) => article.isPublished)
)

export default {
  getArticleBySlug,
  getArticles,
  getArticlesByHighlightState,
  getArticlesByTime,
  getArticlesData,
  getArticlesPublished
}
