import styled from 'styled-components'

import { PurpleColorM } from '../../../../assets/styles/colors'

export const RequiredFieldsTextContainer = styled.div`
  line-height: 21px;
  font-size: 12px;
  color: ${PurpleColorM};
  text-align: left;

  ${props => {
    switch (props.option) {
      case 'WithMargin': return 'margin: 0 0 20px;'
      default: return ''
    }
  }}
`
