import styled from 'styled-components'

import bgImg from '../../assets/img/background.jpg'
import sectionOnemageCoverPath from '../../assets/img/home_bg.jpg'
import { DescriptionContainer } from '../../components/Section/Description/components'
import { Label, Underline } from '../../components/Section/Title/components'
import { TextValueTextValueContainer } from '../../components/Form/FormBasicElements/TextValueTextValue/components'

export const AppMainWrapper = styled.div`

`

export const SectionOne = styled.div`
  position:relative;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(255,255,255);
    background: -moz-linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.9) 40%, rgba(255,255,255,0) 70%);
    background: -webkit-linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.9) 40%, rgba(255,255,255,0) 70%);
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.9) 40%, rgba(255,255,255,0) 70%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1); 
  }

  @media (min-width: 992px) {
    background-image: url(${sectionOnemageCoverPath});
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  }
`

export const SectionOneContainer = styled.div`
  position:relative;
  z-index: 1;

  ${Label} {
    color: #000;
  }

  ${DescriptionContainer} {
    color: #000;
  }

  ${Underline} {
    background-color: #FCD0A3;
  }

  @media (min-width: 1600px) {
    max-width: 50%;
    padding: 150px 0 100px;
  }

  @media (min-width: 1200px) and (max-width: 1599px) {
    max-width: 50%;
    padding: 130px 0 70px;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    max-width: 50%;
    padding: 100px 0 50px;
  }

  @media (min-width: 320px) and (max-width: 991px) {
    padding: 50px 0 0;

    ${Label} {
      text-align: center;
    }

    ${Underline} {
      margin: 10px auto;
    }

    ${DescriptionContainer} {
      text-align: center;
      color: $#fff
    }
  }
`

export const SectionOneButtonContainer = styled.div`
  text-align: left;
  
  @media (min-width: 1200px) {
    margin: 80px 0 0;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    margin: 50px 0 0;
  }
  
  @media (min-width: 576px) and (max-width: 991px) {
    margin: 30px 0 0;
  }

  @media (min-width: 320px) and (max-width: 991px) {
    text-align: center;
  }
  
  @media (min-width: 320px) and (max-width: 575px) {
    margin: 20px 0 0;
  }
`

export const SectionTwo = styled.div`
  max-width: 800px;

  @media (min-width: 1200px) {
    margin: 180px auto 0;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    margin: 80px auto 0;
  }

  @media (min-width: 576px) and (max-width: 991px) {
    margin: 60px auto 0;
  }

  @media (min-width: 320px) and (max-width: 575px) {
    margin: 50px 0 0;
  }
`

export const SectionThree = styled.div`
  margin: 0 auto;

  @media (min-width: 992px) {
    width: 900px;
  }
`

export const SectionThreeSubtitleContainer = styled.div`
  @media (min-width: 992px) {
    margin: 98px 0 32px;
  }

  @media (min-width: 320px) and (max-width: 991px) {
    margin: 40px 0 16px;
  }
`

export const SectionFour = styled.div`
  @media (min-width: 992px) {
    margin: 100px auto 0;
  }

  @media (min-width: 320px) and (max-width: 991px) {
    margin: 40px auto 0;
  }
`

export const TrainingsControlsContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  @media (min-width: 992px) {
    margin: 100px 0 0;
  }

  @media (min-width: 320px) and (max-width: 991px) {
    margin: 24px 0 0;
  }
`

export const SectionSix = styled.div`
  @media (min-width: 1200px) {
    margin: 120px 0 0;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    margin: 80px 0 0;
  }

  @media (min-width: 576px) and (max-width: 991px) {
    margin: 60px 0 0;
  }
  
  @media (min-width: 320px) and (max-width: 991px) {
    ${TextValueTextValueContainer} {
      text-align: center;
    }
  }

  @media (min-width: 320px) and (max-width: 575px) {
    margin: 50px 0 0;
  }
`

export const CompaniesContainer = styled.div`
  @media (min-width: 768px) {
    margin: 30px 0 0;
  }

  @media (min-width: 576px) and (max-width: 767px) {
    margin: 20px 0 0;
  }

  @media (max-width: 575px) {
    margin: 10px 0 0;
  }
`

export const CompaniesControls = styled.div`
  @media (min-width: 1200px) {
    margin: 100px 0 0;
  }
  
  @media (min-width: 992px) and (max-width: 1199px) {
    margin: 80px 0 0;
  }

  @media (min-width: 576px) and (max-width: 991px) {
    margin: 60px 0 0;
  }

  @media (max-width: 575px) {
    margin: 50px 0 0;
  }
`

export const SectionEight = styled.div`
  @media (min-width: 1200px) {
    margin: 280px 0 0;
  }
  
  @media (min-width: 992px) and (max-width: 1199px) {
    margin: 120px 0 0;
  }

  @media (min-width: 576px) and (max-width: 991px) {
    margin: 100px 0 0;
  }

  @media (max-width: 575px) {
    margin: 80px 0 0;
  }
`

export const SectionNine = styled.div`
  max-width: 1175px;

  @media (min-width: 1200px) {
    margin: 160px auto 0;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    margin: 120px auto 0;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    margin: 100px 0 0;
  }

  @media (min-width: 320px) and (max-width: 767px) {
    margin: 80px 0 0;
  }
`

export const SectionNineButtonContainer = styled.div`
  @media (min-width: 1200px) {
    margin: 100px auto 0;
  }
  
  @media (min-width: 992px) and (max-width: 1199px) {
    margin: 80px auto 0;
  }

  @media (min-width: 576px) and (max-width: 991px) {
    margin: 60px auto 0;
  }

  @media (max-width: 575px) {
    margin: 50px auto 0;
  }
`

export const SectionOnePlayButtonContainer = styled.div`
  @media (min-width: 1200px) {
    position: absolute;
    right: 255px;
    top: 300px;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    position: absolute;
    right: 190px;
    top: 225px;
  }

  @media (max-width: 991px) {
    display: none;
  }
`

export const SectionOneMobileMovieContainer = styled.div`
  @media (min-width: 992px) {
    display: none;
  }

  @media (min-width: 320px) and (max-width: 991px) {
    position: relative;
  }
`

export const SectionOneMobileMovieButtonContainer = styled.div`
  @media (min-width: 992px) {
    display: none;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    left: calc(50% - 60px);
    position: absolute;
    top: calc(50% - 60px);
  }

  @media (min-width: 320px) and (max-width: 767px) {
    left: calc(50% - 40px);
    position: absolute;
    top: calc(50% - 40px);
  }
`

export const SectionCommonContainer = styled.div`
`