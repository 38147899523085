import types from './types'

const fetchMyCompanyError = (error) => ({
  type: types.FETCH_ERROR,
  payload: error
})

const fetchMyCompanyRequested = () => ({
  type: types.FETCH_REQUESTED
})

const fetchMyCompanySuccess = (data) => ({
  type: types.FETCH_SUCCESS,
  payload: data
})

const addIndustry = (industryId) => ({
  type: types.ADD_INDUSTRY,
  payload: { industryId }
})

const removeIndustry = (industryId) => ({
  type: types.REMOVE_INDUSTRY,
  payload: { industryId }

})

export default {
  addIndustry,
  fetchMyCompanyError,
  fetchMyCompanyRequested,
  fetchMyCompanySuccess,
  removeIndustry
}
