import React from 'react'
import PropTypes from 'prop-types'

import Image from '../../Form/FormBasicElements/Image'

import {
  ZoneDescription,
  ZoneImage,
  ZoneImageContainer,
  ZoneRightContainer
} from './components'

const ZoneRight = ({
  description,
  image
}) => (
  <ZoneRightContainer>
    <ZoneImageContainer>
      <ZoneImage>
        <Image
          scaleMe={false}
          source={image}
        />
      </ZoneImage>
    </ZoneImageContainer>

    <ZoneDescription>{description}</ZoneDescription>
  </ZoneRightContainer>
)

export default ZoneRight

ZoneRight.propTypes = {
  description: PropTypes.string,
  image: PropTypes.string
}

ZoneRight.defaultProps = {
  description: '',
  image: ''
}
