import React from 'react'
import PropTypes from 'prop-types'

import SliderButton from '../SliderButton'

import {
  SliderArrowsWrapperContainer,
  SliderArrowsWrapperContent,
  SliderArrowsWrapperLeftArrowContainer,
  SliderArrowsWrapperRightArrowContainer
} from './components'

class SliderArrowsWrapper extends React.Component {
  constructor (props) {
    super(props)
    this.sliderContent = React.createRef()

    this.onClickLeftHandler.bind(this)
    this.onClickRightHandler.bind(this)
  }

  onClickLeftHandler = () => {
    if (!this.props.disableScroll) {
      this.refs.sliderContent.scrollBy({
        left: -300,
        behavior: 'smooth'
      })
    } else {
      this.props.actionInsteadScroll('clickLeft')
    }
  }

  onClickRightHandler = () => {
    if (!this.props.disableScroll) {
      this.refs.sliderContent.scrollBy({
        left: 300,
        behavior: 'smooth'
      })
    } else {
      this.props.actionInsteadScroll('clickRight')
    }
  }

  render () {
    const handleOnClickLeftHandler = this.onClickLeftHandler
    const handleOnClickRightHandler = this.onClickRightHandler

    return (
      <>
        {
          this.props.children && (
            <SliderArrowsWrapperContainer>
              <SliderArrowsWrapperLeftArrowContainer type={this.props.type}>
                {
                  (!this.props.disableButtons && this.props.showLeft) && (
                    <SliderButton
                      data-test='leftArrow'
                      onClick={handleOnClickLeftHandler}
                      position='left'
                    />
                  )
                }
              </SliderArrowsWrapperLeftArrowContainer>

              <SliderArrowsWrapperContent ref='sliderContent'>
                {this.props.children}
              </SliderArrowsWrapperContent>

              <SliderArrowsWrapperRightArrowContainer type={this.props.type}>
                {
                  (!this.props.disableButtons && this.props.showRight) && (
                    <SliderButton
                      data-test='rightArrow'
                      onClick={handleOnClickRightHandler}
                      position='right'
                    />
                  )
                }
              </SliderArrowsWrapperRightArrowContainer>
            </SliderArrowsWrapperContainer>
          )
        }
      </>
    )
  }
}

export default SliderArrowsWrapper

SliderArrowsWrapper.propTypes = {
  children: PropTypes.any,
  type: PropTypes.oneOf(['fixed', 'outside'])
}

SliderArrowsWrapper.defaultProps = {
  disableButtons: false,
  disableScroll: false,
  showLeft: true,
  showRight: true
}
