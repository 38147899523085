import styled from 'styled-components'

import { PurpleColorM } from '../../../../assets/styles/colors'

export const AddArticleFormNameAndSurnameContainer = styled.div``

export const DeadlineInfo = styled.div`
  font-size: 17px;
  margin-top: 10px;
  margin-bottom: 40px;
  text-align: center;
  color: ${PurpleColorM}
`
