import styled from 'styled-components'

export const ResetAndSaveButtonsContainer = styled.div`
  @media (min-width: 992px) {
    display: flex;
    justify-content: flex-end;
  }

  @media (min-width: 576px) {
    margin: 40px 0 0;
  }
  
  @media (min-width: 320px) and (max-width: 575px) {
    margin: 30px 0 0;
  }
`

export const ResetAndSaveButtonsContent = styled.div`
  @media (min-width: 576px) {
    display: inline-grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 15px;
  }

  @media (min-width: 320px) and (max-width: 575px) {
    display: inline-flex;
    flex-direction: column-reverse;

    & > div {
      &:last-child {
        margin-bottom: 10px;
      }
    }
  }
`
