import styled from 'styled-components'

export const InnerWrapperContainer = styled.div`
  height: 100%;
  margin: 0 auto;
  max-width: 1410px;

  @media (min-width: 1600px) {
    padding: 0 50px;
  }

  @media (min-width: 1200px) and (max-width: 1599px) {
    padding: 0 40px;
  }

  @media (min-width: 768px) and (max-width: 1199px) {
    padding: 0 30px;
  }

  @media (min-width: 320px) and (max-width: 767px) {
    padding: ${({ inModal }) => inModal
      ? '0 3px'
      : '0 20px'
    };
  }
`
