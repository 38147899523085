import styled from 'styled-components'

export const InternalOrExternalContainer = styled.div`
  & > a {
    height: 100%;
    text-decoration: none;
    border-radius: 100%;
    outline: none;

    ${props => {
    switch (props.align) {
      case 'center': return 'justify-content: center;'
      case 'left': return 'justify-content: flex-start;'
      case 'right': return 'justify-content: flex-end;'
      default: return ''
    }
  }}

  ${props => {
    switch (props.disabled) {
      case true: return 'cursor: default;'
      default: return ''
    }
  }}

  ${props => {
    switch (props.display) {
      case 'inline-flex': return 'display: inline-flex;'
      default: return 'display: flex;padding: 0 15px;'
    }
  }}
  }
`

export const InternalOrExternalLink = styled.a``

export const InternalOrExternalOnClickContainer = styled.div`
  height: 100%;
  border-radius: 100%;

  ${props => {
    switch (props.align) {
      case 'center': return 'justify-content: center;'
      case 'left': return 'justify-content: flex-start;'
      case 'right': return 'justify-content: flex-end;'
      default: return ''
    }
  }}

  ${props => {
    switch (props.disabled) {
      case true: return 'cursor: default;'
      default: return 'cursor: pointer;'
    }
  }}

  ${props => {
    switch (props.display) {
      case 'inline-flex': return 'display: inline-flex;'
      default: return 'display: flex;'
    }
  }}
`
