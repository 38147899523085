import styled from 'styled-components'

export const FormCompanyStepFiveButtonContainer = styled.div`
  @media (min-width: 1200px) {
    margin: 100px 0 0;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    margin: 80px 0;
  }

  @media (min-width: 576px) and (max-width: 991px) {
    margin: 60px 0 0;
  }

  @media (min-width: 320px) and (max-width: 575px) {
    margin: 50px 0 0;
  }
`

export const FormCompanyStepFiveTextComponentContainer = styled.div`
  margin: 50px 0 0;
`
