import React from 'react'
import Countdown from 'react-countdown'

import CountdownComponentUI from './CountdownComponentUI'

class CountdownComponent extends React.Component {
  render () {
    return (
      <Countdown
        data-test='Countdown'
        {...this.props}
      />
    )
  }
}

export default CountdownComponent

CountdownComponent.defaultProps = {
  renderer: CountdownComponentUI
}
