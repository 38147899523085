import styled from 'styled-components'

export const ArticleGuidlinesFormVisibleContainer = styled.div`

  @media (min-width: 768px) {
    text-align: right;
  }

  @media (min-width: 320px) and (max-width: 767px) {
    text-align: center;
  }
`

export const ArticleGuidlinesFormReadMoreLinkContainer = styled.div`
  padding: 20px 0 0;
`

export const ArticleGuidlinesFormNotVisibleContainer = styled.div`
  padding: 20px 0 0;
`
