import React from 'react'
import PropTypes from 'prop-types'

import Icon from '../../Form/FormBasicElements/Icon'
import InternalOrExternal from '../../Buttons/InternalOrExternal'

import {
  StepItemContainer,
  StepItemDescription,
  StepItemIconContainer,
  StepItemNumber,
  StepItemNumberContainer,
  StepItemRoad,
  StepItemSteps,
  StepItemTitle,
  StepItemTitleContainer
} from './components'

import checkIconPath from '../../../assets/icons/check.svg'

const StepItem = ({
  description,
  link,
  number,
  title,
  type,
  withLinks
}) => (
  <StepItemContainer>
    <StepItemSteps>
      <StepItemIconContainer type={type}>
        {
          type === 'isPrevious' && (
            <Icon icon={checkIconPath} />
          )
        }
      </StepItemIconContainer>

      <StepItemRoad type={type} />
    </StepItemSteps>

    {
      number && withLinks ? (
        (
          <StepItemNumberContainer>
            <InternalOrExternal
              data-test='step-item-number-link'
              display='inline-flex'
              link={link}
              type='internal'
            >
              <StepItemNumber type={type}>
                {number}
              </StepItemNumber>
            </InternalOrExternal>
          </StepItemNumberContainer>
        )
      )
        : number && (
          <StepItemNumberContainer>
            <StepItemNumber type={type}>
              {number}
            </StepItemNumber>
          </StepItemNumberContainer>
        )
    }

    {
      title && withLinks ? (
        <StepItemTitleContainer>
          <InternalOrExternal
            data-test='step-item-title-link'
            display='inline-flex'
            link={withLinks && link}
            type='internal'
          >
            <StepItemTitle
              type={type}
              withLinks={withLinks}
            >
              {title}
            </StepItemTitle>
          </InternalOrExternal>
        </StepItemTitleContainer>
      )
        : title && (
          <StepItemTitleContainer>
            <StepItemTitle
              type={type}
              withLinks={withLinks}
            >
              {title}
            </StepItemTitle>
          </StepItemTitleContainer>
        )
    }

    {
      description && (
        <StepItemDescription>{description}</StepItemDescription>
      )
    }
  </StepItemContainer>
)

export default StepItem

StepItem.propTypes = {
  description: PropTypes.string,
  link: PropTypes.string,
  number: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
  withLinks: PropTypes.bool
}

StepItem.defaultProps = {
  type: 'isPrevious'
}
