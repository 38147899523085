import React from 'react'

import Awards from '../../Tabs/Awards'
import BenefitItems from '../../Tabs/Benefits'
import Chat from '../../Tabs/Chat'
import General from '../../Tabs/General'
import Jobs from '../../Tabs/Jobs'
import Offices from '../../Tabs/Offices'
import Recruitment from '../../Tabs/Recruitment'

import { companyTabsTypes } from './const'

export const chooseTab = (
  articlesList,
  awardsList,
  benefitsList,
  chatUrl,
  jobsList,
  officesList,
  option,
  ourMission,
  recruitersList,
  recruitmentDetails,
  text,
  valueTags,
  videosList,
  onOptionChange
) => (
  <>
    {
      option === companyTabsTypes.awards && (
        <Awards
          data-test='Awards'
          itemsList={awardsList}
        />
      )
    }

    {
      option === companyTabsTypes.benefits && (
        <BenefitItems
          data-test='BenefitItems'
          itemsList={benefitsList}
        />
      )
    }

    {
      option === companyTabsTypes.chat && (
        <Chat
          chatUrl={chatUrl}
          data-test='Chat'
        />
      )
    }

    {
      option === companyTabsTypes.general && (
        <General
          data-test='General'
          articlesList={articlesList}
          ourMission={ourMission}
          text={text}
          valueTags={valueTags}
          videosList={videosList}
        />
      )
    }

    {
      option === companyTabsTypes.jobs && (
        <Jobs
          data-test='Jobs'
          itemsList={jobsList}
          onOptionChange={onOptionChange}
          chatUrl={chatUrl}
        />
      )
    }

    {
      option === companyTabsTypes.offices && (
        <Offices
          data-test='Offices'
          itemsList={officesList}
        />
      )
    }

    {
      option === companyTabsTypes.recruitment && (
        <Recruitment
          data-test='Recruitment'
          recruitersList={recruitersList}
          recruitmentDetails={recruitmentDetails}
        />
      )
    }
  </>
)
