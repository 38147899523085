import styled from 'styled-components'

export const FooterMenuItemListElement = styled.li`
  @media (min-width: 992px) {
    padding: 10px 0;
  }

  @media (min-width: 576px) and (max-width: 991px) {
    padding: 5px 0;
  }

  @media (max-width: 575px) {
    padding: 3px 0;
  }
`
