import styled from 'styled-components'

export const ChooseDayWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  max-width: 620px;
  margin: 50px auto 0;
  border-radius: 10px;
  overflow: hidden;
`
