import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { industriesActions, industriesSelectors } from '../../../store/ducks/industries'

import Industries from '../../../components/Industries'

const IndustriesContainer = () => {
  const industriesState = useSelector(industriesSelectors.getIndustriesState)

  const dispatch = useDispatch()
  useEffect(() => {
    if (industriesState.isLoading === false) {
      if (industriesState.data === null || industriesState.data.length === 0) {
        dispatch(industriesActions.fetchIndustriesRequested())
      }
    }
  }, [dispatch, industriesState])

  const industries = useSelector(industriesSelectors.getIndustries)

  return (
    <Industries
      itemsList={industries}
      onClick={(key) => dispatch(industriesActions.toggleIndustry(key))}
    />
  )
}

export default IndustriesContainer
