import * as Sentry from '@sentry/browser'

export default () => {
  // if (process.env.NODE_ENV !== 'production') {
  //   console.log('App is under devlopment enviroment')
  // } else {
  Sentry.init({ dsn: 'https://4f376cf5a3ef403c88b6e52cea8f6432@sentry.io/1402230' })

  Sentry.configureScope((scope) => {
    scope.setLevel('warning')
  })
  // }
}
