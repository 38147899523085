import styled from 'styled-components'

export const OfficesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media (min-width: 320px) and (max-width: 767px) {
    align-items: center;
    flex-direction: column;
  }
`

export const OfficesLabelContainer = styled.div`
  @media (min-width: 320px) and (max-width: 767px) {
    & > label {
      text-align: center;
    }
  }
`
