import React from 'react'
import PropTypes from 'prop-types'

import Icon from '../Icon'

import {
  ErrorsContainer,
  ErrorsIconContainer,
  ErrorsText
} from './components'

import errorIconPath from '../../../../assets/icons/error.svg'

const Errors = ({
  errorMessage,
  hasError
}) => (
  <>
    {hasError && errorMessage &&
      <ErrorsContainer>
        <ErrorsIconContainer>
          <Icon icon={errorIconPath} />
        </ErrorsIconContainer>

        <ErrorsText hasError={hasError}>{errorMessage}</ErrorsText>
      </ErrorsContainer>}
  </>
)

export default Errors

Errors.propTypes = {
  errorMessage: PropTypes.string,
  hasError: PropTypes.bool
}
