import styled from 'styled-components'

import {
  PinkColorM,
  SilverColor
} from '../../../../assets/styles/colors'

export const TextValueTextValueContainer = styled.div`
  text-align: left;
`

export const TextValueTextValueContent = styled.div`
  display: inline-grid;
  grid-auto-flow: column;
  grid-column-gap: 6px;
  align-items: baseline;
`

export const TextValueTextValueText = styled.div`
  font-size: 18px;
  color: ${SilverColor};
`

export const TextValueTextValueValue = styled.div`
  font-weight: 600;
  font-size: 20px;
  color: ${PinkColorM};
`
