import cv from '../../../assets/icons/zones/strefa-cv.svg'
import copy from '../../../assets/icons/zones/strefa-ksero.svg'
import coaching from '../../../assets/icons/zones/strefa-coaching.svg'
import job from '../../../assets/icons/zones/strefa-ofert-pracy.svg'
import relax from '../../../assets/icons/zones/strefa-relaxu.svg'
import qr from '../../../assets/icons/zones/strefa-szybkich-rekrutacji.svg'
import translationsList from '../../../stabs/translationsList'

const icons = {
  cv,
  copy,
  job,
  coaching,
  relax,
  qr
}

export default [
  {
    description: translationsList.zones.one.description,
    icon: icons.qr,
    image: '/images/Zones/szybkie-rekrutacje.png',
    isActive: true,
    label: translationsList.zones.one.label
  },
  {
    description: translationsList.zones.two.description,
    icon: icons.cv,
    image: '/images/Zones/consult.png',
    isActive: false,
    label: translationsList.zones.two.label
  },
  {
    description: translationsList.zones.three.description,
    icon: icons.coaching,
    image: '/images/Zones/strefa-coachingu.png',
    isActive: false,
    label: translationsList.zones.three.label
  },
  {
    description: translationsList.zones.four.description,
    icon: icons.job,
    image: '/images/Zones/strefa-ofert-pracy.png',
    isActive: false,
    label: translationsList.zones.four.label
  },
  {
    description: translationsList.zones.five.description,
    icon: icons.copy,
    image: '/images/Zones/strefa-ksero.png',
    isActive: false,
    label: translationsList.zones.five.label
  },
  {
    description: translationsList.zones.six.description,
    icon: icons.relax,
    image: '/images/Zones/strefa-relaksu.png',
    isActive: false,
    label: translationsList.zones.six.label
  }
]
