import React from 'react'
import PropTypes from 'prop-types'

import Icon from '../../Form/FormBasicElements/Icon'

import {
  SelectableItemCloseContainer,
  SelectableItemContainer,
  SelectableItemText
} from './components'

import iconClosePath from '../../../assets/icons/close.svg'

const SelectableItem = ({
  isMostImportant,
  isRemoveable,
  isSelected,
  label
}) => (
  <SelectableItemContainer
    isMostImportant={isMostImportant}
    isRemoveable={isRemoveable}
    isSelected={isSelected}
  >
    <SelectableItemText>{label}</SelectableItemText>

    {isRemoveable && isSelected &&
      <SelectableItemCloseContainer isMostImportant={isMostImportant}>
        <Icon icon={iconClosePath} />
      </SelectableItemCloseContainer>}
  </SelectableItemContainer>
)

export default SelectableItem

SelectableItem.propTypes = {
  isMostImportant: PropTypes.bool,
  isRemoveable: PropTypes.bool,
  isSelected: PropTypes.bool,
  label: PropTypes.string
}

SelectableItem.defaultProps = {
  isMostImportant: false,
  isRemoveable: false,
  isSelected: false,
  label: ''
}
