import styled from 'styled-components'

export const AddBenefitFormImageInput = styled.div`
  display: grid;
  grid-column-gap: 30px;

  @media (min-width: 576px) {
    grid-template-columns: auto 1fr;
  }
`
