import _ from 'lodash'

export default (args) => {
  if (!args.companyValues.length) {
    return 0
  }

  const pointsForValue = 40 / (args.companyValues.length - 3)
  let result = 0

  const userImportantValues = args.userImportantValues.map(element => `${element._id}`)

  const userValues = args.userValues.map(element => `${element._id}`)

  const companyValues = args.companyValues.map(element => `${element._id}`)

  _.intersection(userImportantValues, companyValues).forEach(() => { result += 20 })

  _.intersection(userValues, companyValues).forEach(() => { result += pointsForValue })

  return result
}
