import React from 'react'

import FooterMenu from './FooterMenu'
import Icon from '../Form/FormBasicElements/Icon'
import SocialList from '../SocialMedia/SocialList'

import {
  FooterColumn,
  FooterContainer,
  FooterSocialMediaLogo
} from './components'

import logoPath from '../../assets/icons/logo.svg'
import logoCVWorksPath from '../../assets/icons/cvworks-logo.svg'
import translationsList from '../../stabs/translationsList'

const Footer = () => (
  <FooterContainer>
    <FooterColumn
      data-test='ColumnOne'
      order='4'
    >
      <FooterSocialMediaLogo>
        <Icon icon={Number(process.env.REACT_APP_CV) === 0 ? logoPath : logoCVWorksPath} />
      </FooterSocialMediaLogo>

      <SocialList
        facebook={translationsList.SocialMediaCE.facebook}
        linkedin={translationsList.SocialMediaCE.linkedin}
        twitter={translationsList.SocialMediaCE.twitter}
        youtube={translationsList.SocialMediaCE.youtube}
        instagram={translationsList.SocialMediaCE.instagram}
      />
    </FooterColumn>

    <FooterColumn
      data-test='ColumnTwo'
      order='1'
    >
      <FooterMenu
        data-test='MenuOne'
        itemsList={translationsList.footer.leftColumn.linksList}
        title={translationsList.footer.leftColumn.title}
      />
    </FooterColumn>

    <FooterColumn
      data-test='ColumnThree'
      order='2'
    >
      <FooterMenu
        data-test='MenuTwo'
        itemsList={translationsList.footer.centerColumn.linksList}
        title={translationsList.footer.centerColumn.title}
      />
    </FooterColumn>

    <FooterColumn
      data-test='ColumnFour'
      order='3'
    >
      
    </FooterColumn>
  </FooterContainer>
)

export default Footer
