import styled from 'styled-components'

import { WhiteColor } from '../../assets/styles/colors'

export const EmployerProfileContainer = styled.div`
  background-color: ${WhiteColor};
  display: flex;
  width: 100%;

  @media (min-width: 320px) and (max-width: 767px) {
    position: relative;
    width: 100%;
  }
`

export const EmployerProfileMenuTriggerContainer = styled.div`
  @media (min-width: 768px) {
    display: none;
  }

  @media (min-width: 320px) and (max-width: 767px) {
    height: 26px;
    left: 0;
    position: absolute;
    top: 0;
    width: 26px;
    z-index: 20;
  }
`

export const EmployerProfileMenuTriggerButton = styled.button`
  border: 0 solid transparent;
  cursor: pointer;
  display: block;
  padding: unset;
`
