import styled from 'styled-components'

import {
  PinkColorM,
  PurpleColor,
  PurpleColorM
} from '../../assets/styles/colors'

export const GuidlineStepContainer = styled.div`
  @media (min-width: 768px) {
    display: flex;
    flex: 1;
    margin: 0 30px 0 0;
  }

  @media (min-width: 320px) and (max-width: 767px) {
    margin: 0 0 20px;
  }
`

export const GuidlineStepLeft = styled.div`
  color: ${PinkColorM};
  flex: 1;
  font-size: 60px;
  font-weight: 600;
  line-height: 60px;

  @media (min-width: 768px) {
    margin: 0 20px 0 0;
  }
`

export const GuidlineStepRight = styled.div`
  flex: 6;

  @media (min-width: 768px) {
    text-align: left;
  }
`

export const GuidlineStepTitle = styled.div`
  color: ${PurpleColor};
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;

  @media (min-width: 768px) {
    margin: 0 0 20px;
  }

  @media (min-width: 576px) and (max-width: 767px) {
    margin: 0 0 15px;
  }

  @media (min-width: 320px) and (max-width: 575px) {
    margin: 0 0 10px;
  }
`

export const GuidlineStepDescription = styled.div`
  color: ${PurpleColorM};
  font-size: 16px;
  line-height: 24px;
`
