import { ofType } from 'redux-observable'
import { catchError, mergeMap } from 'rxjs/operators'
import { of } from 'rxjs'
import getError from '../../utils/getError'

import * as userAPI from '../../api/user'
import AuthService from '../../services/AuthService'

import actions from './actions'
import types from './types'

import { myCompanyActions } from '../my-company'

const fetchMyUser = (action$, state$, { fetch }) => {
  return action$
    .pipe(
      ofType(types.FETCH_REQUESTED),
      mergeMap(() =>
        fetch(userAPI.getMe().toObject()).pipe(
          mergeMap((response) => {
            if (AuthService.getUserType() === 'company') {
              return of(
                actions.fetchMyUserSuccess(response.response),
                myCompanyActions.fetchMyCompanyRequested()
              )
            }

            return of(
              actions.fetchMyUserSuccess(response.response)
            )
          }),
          catchError((error) => of(actions.fetchMyUserError(getError(error))))
        )
      )
    )
}

export default {
  fetchMyUser
}
