import React, { useState } from 'react'
import ChooseFileField from '../../../../components/Form/FormFields/ChooseFileField'
import ApiService from '../../../../store/services/ApiService'
import * as companyAPI from '../../../../store/api/company'
import eventToFormData from '../../../../utils/forms/eventToFormData'
import { toast } from 'react-toastify'

const CompanyVectorLogoUploadContainer = ({ value, error, touched, handleField }) => {
  const [state, setState] = useState({
    isLoading: false,
    apiError: ''
  })

  const handleChange = (e) => {
    setState({ ...state, isLoading: true })
    const formData = eventToFormData(e)

    ApiService.fetch(companyAPI.uploadVectorLogo(formData))
      .then((data) => {
        setState({ ...state, isLoading: false })
        handleField('vectorLogoUrl', data.url)

        toast.success('Zapisano!', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        })
      })
      .catch(err => {
        setState({ ...state, apiError: err })

        toast.error('Nie właściwy format pliku!', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        })
      })
  }

  return (
    <ChooseFileField
      buttonLabel={value === undefined || value === '' ? 'Wybierz plik' : 'Dodano!'}
      data-test='ChooseFileFieldOne'
      errorMessage={error}
      hasError={error && touched}
      label='Wybierz logo'
      name='vectorLogo'
      onChange={handleChange}
      isLoading={state.isLoading}
      text='Dodaj logotyp wektorowy w formacie PDF/CDR/AI/PSD'
    />
  )
}

export default CompanyVectorLogoUploadContainer
