import styled from 'styled-components'

import {
  PinkColor,
  PurpleColorL,
  WhiteColor
} from '../../../../assets/styles/colors'

export const TrainingItemHeaderContainer = styled.div`
  align-items: center;
  background-color: ${WhiteColor};
  border-radius: 10px;
  display: grid;

  ${props => {
    switch (props.isEditable) {
      case true: return `
        @media (min-width: 1600px) {
          grid-template-columns: 130px 1fr auto 40px;
        }

        @media (min-width: 1200px) and (max-width: 1599px) {
          grid-template-columns: 130px 1fr auto 40px;
        }

        @media (max-width: 1199px) {
          grid-template-columns: 100px 1fr auto 40px;
        }

        @media (min-width: 576px) and (max-width: 767px) {
          grid-template-columns: 50px 1fr auto auto;
        }

        @media (max-width: 575px) {
          grid-template-columns: 1fr auto auto;
        }
      `
      default: return `
        @media (min-width: 1600px) {
          grid-template-columns: 130px 1fr 500px auto;
        }

        @media (min-width: 1200px) and (max-width: 1599px) {
          grid-template-columns: 130px 1fr 400px auto;
        }

        @media (min-width: 992px) and (max-width: 1199px) {
          grid-template-columns: 110px 1fr auto auto;
        }

        @media (min-width: 768px) and (max-width: 991px) {
          grid-template-columns: 100px 1fr auto auto;
        }

        @media (min-width: 576px) and (max-width: 767px) {
          grid-template-columns: 50px 1fr auto auto;
        }

        @media (min-width: 320px) and (max-width: 575px) {
          grid-template-columns: 1fr auto auto;
        }
      `
    }
  }}

  @media (min-width: 992px) {
    padding: 20px 40px;
  }

  @media (min-width: 576px) {
    grid-column-gap: 30px;
  }

  @media (min-width: 576px) and (max-width: 991px) {
    padding: 20px;
  }

  @media (min-width: 320px) and (max-width: 575px) {
    grid-column-gap: 10px;
    padding: 15px;
  }
`

export const TrainingItemHeaderTitle = styled.div`
  align-items: center;
  color: ${PurpleColorL};
  display: flex;
  font-weight: 600;
  text-align: left;

  @media (min-width: 992px) {
    font-size: 16px;
    line-height: 24px;
  }

  @media (min-width: 576px) {
    font-size: 16px;
  }

  @media (min-width: 576px) and (max-width: 991px) {
    line-height: 21px;
  }
  
  @media (min-width: 320px) and (max-width: 575px) {
    font-size: 14px;
    line-height: 18px;
  }
`

export const TrainingItemHeaderDedicatedTrainingContainer = styled.div`
  display: flex;
`

export const TrainingItemHeaderDedicatedTrainingText = styled.div`
  color: ${PinkColor};
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  
  & + div {
    margin-left: 5px;
  }
`

export const TrainingItemHeaderPriceAndButtonContainer = styled.div`
  align-items: center;
  display: flex;
`

export const TrainingItemHeaderPrice = styled.div`
  color: ${PinkColor};
  display: none; // Set to none untill we start using Price's
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
  margin: 0 40px 0 0;
  min-width: 90px;
  text-align: right;
`
