import React from 'react'
import PropTypes from 'prop-types'

import Errors from '../../FormBasicElements/Errors'
import Label from '../../FormBasicElements/Label'
import RichTextEditorField from '../../../RichTextEditorField'

import {
  EditorFieldContainer
} from './components'


const EditorField = ({
  errorMessage,
  hasError,
  label,
  name,
  onChange,
  placeholder,
  required,
  value
}) => (
  <EditorFieldContainer>
    <Label
      label={label}
      required={required}
    />

    <RichTextEditorField
      placeholder={placeholder}
      name={name}
      onChange={onChange}
      value={value}
    />

    <Errors
      errorMessage={errorMessage}
      hasError={hasError}
    />
  </EditorFieldContainer>
)

export default EditorField

EditorField.propTypes = {
  errorMessage: PropTypes.string,
  hasError: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool
}
